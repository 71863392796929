import React from "react";
import RadarChart from "./RadarChart/RadarChart";
import { Row, Col, Card, Typography, Button } from "antd";
import CalculatorJson from "./calculator.json";

const Results = ({ idea, startRecalculate }) => {
  const calculator = CalculatorJson.home;

  return (
    <Card
      type="inner"
      title={
        <Typography.Title level={5} className="primary-color">
          {`Resultados`}
        </Typography.Title>
      }
    >
      <Row gutter={[20, 8]} justify="center">
        <Col span={24}>
          <RadarChart idea={idea} />
        </Col>
        <Col span={2}></Col>
        <Col span={6}>
          <Typography.Title level={5}>
            {idea.viabilityCalculator.brainInfo.highAvg}
          </Typography.Title>
        </Col>
        <Col span={16}>
          <Typography.Text strong>
            {calculator[idea.viabilityCalculator.brainInfo.result]}
          </Typography.Text>
        </Col>
        <Col span={2}></Col>
        <Col span={6}>
          <Row gutter={[0, 8]} justify="center">
            <Col span={24}>
              <Typography.Title level={5}>
                {idea.viabilityCalculator.complexityResult
                  ? idea.viabilityCalculator.complexityResult.definition.effort
                  : ""}
              </Typography.Title>
            </Col>
            <Col span={24}>
              <Typography.Title level={5}>
                {idea.viabilityCalculator.complexityResult
                  ? calculator[
                      idea.viabilityCalculator.complexityResult.complexityName
                    ]
                  : ""}
              </Typography.Title>
            </Col>
          </Row>
        </Col>
        <Col span={16}>
          <Typography.Text strong>
            {idea.viabilityCalculator.complexityResult
              ? calculator[
                  idea.viabilityCalculator.complexityResult.definition
                    .definition
                ]
              : ""}
          </Typography.Text>
        </Col>
        <Col span={24} style={{ margin: "100px 0 30px 0" }}>
          <Button
            type="primary"
            block
            danger
            onClick={() => startRecalculate()}
          >
            <Typography.Title level={5} strong style={{ color: "white" }}>
              Recalcular
            </Typography.Title>
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default Results;
