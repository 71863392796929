import React from "react";
import ReactApexChart from "react-apexcharts";

export default class Charts extends React.Component {
  render() {
    return (
      <div id="chart">
        <ReactApexChart
          series={[
            {
              name: "Creadas",
              type: "column",
              data: this.props.days,
            },
            {
              name: "Completadas",
              type: "line",
              data: this.props.completed,
            },
          ]}
          options={{
            chart: {
              height: 350,
              type: "line",
            },
            stroke: {
              width: [0, 4],
            },
            title: {
              text: "Ideas por día",
            },
            dataLabels: {
              enabled: true,
              enabledOnSeries: [1],
            },
            labels: this.props.dias,
            xaxis: {
              position: "top",
            },
            yaxis: [
              {
                title: {
                  text: "Ideas creadas",
                },
              },
              {
                opposite: true,
                title: {
                  text: "Pase a producción",
                },
              },
            ],
          }}
          type="line"
          height={350}
        />
      </div>
    );
  }
}
