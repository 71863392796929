export const tipoTarea = [
  { key: "0", value: "Innovación" },
  { key: "1", value: "Tecnológico" },
  { key: "2", value: "Negocio" },
];

// Departamentos
export const departaments = [
  { key: "0", value: "Contabilidad" },
  { key: "1", value: "Recursos Humanos" },
  { key: "2", value: "Operaciones" },
  { key: "3", value: "Comercial" },
];

// Ejecución del proceso
// Manual y repetitivo
export const manrep = [
  { key: "0", value: "Al completo" },
  { key: "1", value: "Bastante" },
  { key: "2", value: "Algo" },
  { key: "3", value: "Poco" },
  { key: "4", value: "Nada" },
];

export const frecuencia = [
  { key: "0", value: "Continuo" },
  { key: "1", value: "Multidiario" },
  { key: "2", value: "Diario" },
  { key: "3", value: "Semanal" },
  { key: "4", value: "Mensual" },
  { key: "5", value: "Anual" },
];

export const volumentrias = [
  { key: "0", value: "Día" },
  { key: "1", value: "Mes" },
  { key: "2", value: "Año" },
];

export const duration = [
  { key: "0", value: "Segundos" },
  { key: "1", value: "Minutos" },
  { key: "2", value: "Horas" },
];

export const afectacion = [
  { key: "0", value: "Eficiencia Operativa" },
  { key: "1", value: "Cumplimiento" },
  { key: "2", value: "Impacto directo en cliente" },
];

// Gestión de datos

export const consistencia = [
  { key: "0", value: "Sí" },
  { key: "1", value: "En ocaciones" },
  { key: "2", value: "No" },
];

export const reglas = [
  { key: "0", value: "Sí" },
  { key: "1", value: "Poco claras" },
  { key: "2", value: "Basadas en la experiencia del usuario" },
];

export const estandarizacion = [
  { key: "0", value: "Sí" },
  { key: "1", value: "Sí, pero es necesaria una transformación, simple" },
  { key: "2", value: "Sí, pero es necesaria una transformación, grande" },
  { key: "3", value: "Nada estandarizados" },
];

export const legibilidad = [
  {
    key: "0",
    value: "Fácil (Excel, datos estructurados, aplicación, sistema web)",
  },
  { key: "1", value: "Media (PDF, texto desestructurado)" },
  { key: "2", value: "Difícil (Imagen, PDF no nativo)" },
  { key: "3", value: "Crítico (Escritura manuscrita, sonido)" },
];

export const porcentajeHabitual = [
  { key: "0", value: ">80%" },
  { key: "1", value: "60-80%" },
  { key: "2", value: "<60%" },
];

// Entorno

export const procesos = [
  { key: "0", value: "No" },
  { key: "1", value: "Sí, a medio plazo (periodo de tiempo > 1 año" },
  { key: "2", value: "Sí, de manera frecuente" },
];

export const sistemas = [
  { key: "0", value: "No, los sistemas no tendrán cambios" },
  {
    key: "1",
    value: "Se esperan cambios a medio plazo (periodo de tiempo > 1 año",
  },
  { key: "2", value: "Los sistemas van a cambiar en fechas cercanas" },
];
