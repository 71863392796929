import React, { useState, useEffect } from "react";
import { Row, Col, Table, Space, Button, Drawer, message } from "antd";
import InputSearch from "../../InputSearch";
import { useTranslation } from "react-i18next";
import { updateIdeaStatus } from "../../../../../../db/firedataCalls";
import CompanyIdeaDetails from "../../../Details/CompanyIdeaDetails";

const InProgress = (props) => {
  const { t } = useTranslation();

  const [inProgressData, setInProgressData] = useState({
    ideas: [],
    atRisk: 0,
    total: 0,
    offTrack: 0,
  });

  const [showDatailsDrawer, setShowDetailsDrawer] = useState({
    idea: null,
    visible: false,
    idIdea: null,
  });

  const handleRowClick = (row) => {
    setShowDetailsDrawer({ visible: true, idea: row, idIdea: row.id });
  };

  const success = () => {
    message.success(t("Idea aprobada exitosamente"));
  };

  const onChangeApproved = async (idea) => {
    updateIdeaStatus(idea, 3);
    success();
  };

  const onClose = () => {
    setShowDetailsDrawer({ idea: null, visible: false, idIdea: null });
  };

  const columns = [
    {
      title: "Idea",
      dataIndex: "name",
      render: (text, record) => (
        <span
          style={{ padding: "13px 13px 6px 6px", cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {record.name}
        </span>
      ),
    },
    {
      title: t("table.headers.workCategory"),
      dataIndex: "workCategory",
    },
    {
      title: t("Usuario asignado"),
      dataIndex: "asigneeName",
    },
    {
      title: t("Actividades completadas"),
      dataIndex: "activities",
      render: (text, record) =>
        record.activities && record.activities.length
          ? `${(
              (record.activities.filter((activity) => activity.completed)
                .length /
                record.activities.length) *
              100
            ).toFixed(2)}%`
          : "Sin actividades",
    },
    {
      title: t("Pase a producción"),
      fixed: "right",
      width: 190,
      render: (record) => (
        <Space size="middle">
          <Button
            style={{ width: "170px" }}
            key={record.id}
            onClick={(e) => onChangeApproved(record, true)}
            className="ant-btn"
          >
            {t("Pase a producción")}
          </Button>
        </Space>
      ),
    },
  ];

  const [tableState, setTableState] = useState({
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
  });

  const handleOnKeyChange = (key) => {
    if (!key) setTableState({ data: inProgressData.ideas });

    key = key.toLowerCase();
    const filteredData = inProgressData.ideas.filter(
      (entry) =>
        entry.name.toLowerCase().includes(key) ||
        (entry.id && entry.id.toString().includes(key)) ||
        (entry.workCategory &&
          entry.workCategory.toLowerCase().includes(key)) ||
        (entry.asigneeName && entry.asigneeName.toLowerCase().includes(key))
    );

    setTableState({ data: filteredData });
  };

  useEffect(() => {
    if (props.ideas) {
      let ideas = [];
      props.ideas.forEach(
        (doc) =>
          doc.status === 2 &&
          ideas.push({
            ...doc,
          })
      );
      setTableState({ data: ideas });
      setInProgressData({
        ideas: ideas,
      });
      if (showDatailsDrawer.visible) {
        let index = props.ideas.findIndex(
          (idea) => idea.id === showDatailsDrawer.idIdea
        );
        setShowDetailsDrawer({
          visible: true,
          idea: props.ideas[index],
          idIdea: props.ideas[index].id,
        });
      }
    }
  }, [props.ideas, showDatailsDrawer.idIdea, showDatailsDrawer.visible]);
  return (
    <>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <div>
            <Drawer
              style={{ background: "white" }}
              theme="light"
              width="100vw"
              onClose={onClose}
              visible={showDatailsDrawer.visible}
              footer={
                <div
                  style={{
                    textAlign: "right",
                  }}
                ></div>
              }
              destroyOnClose
            >
              <CompanyIdeaDetails
                company={props.company}
                onCloseDrawer={onClose}
                userInfo={props.userInfo}
                companyName={props.companyName}
                mappings={props.mappings}
                idea={showDatailsDrawer.idea}
              />
            </Drawer>
            <Row>
              <Col span={6}>
                <InputSearch handleOnKeyChange={handleOnKeyChange} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Table
                  pagination={{
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                  }}
                  bordered
                  columns={columns}
                  dataSource={tableState.data}
                  rowKey={(record) => record.id}
                  size="small"
                  scroll={{ x: 1300 }}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default InProgress;
