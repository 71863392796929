import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Spin,
  Drawer,
  Table,
  Tag,
  Tooltip,
  Typography,
  Button,
} from "antd";
import "../../../../../../App.css";
import CompanyIdeaDetails from "../../../Details/CompanyIdeaDetails";
import InputSearch from "../../InputSearch";
import LALAComments from "../../../Details/LALA/LALAComments";
import LALAAssigned from "../../../Details/LALA/LALAAssigned";
import { presetPalettes } from "@ant-design/colors";
import ReactExport from "react-data-export";
import moment from "moment";

const Maintenances = (props) => {
  const [maintenances, setMaintenances] = useState([]);
  const [users, setUsers] = useState([]);
  const [arrayExcel, setArrayExcel] = useState([]);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const [showDatailsDrawer, setShowDetailsDrawer] = useState({
    idea: null,
    visible: false,
    idIdea: null,
  });

  const statusColor = {
    0: "default",
    1: "warning",
    2: "success",
    3: "purple",
  };

  const statusText = {
    0: "PENDING",
    1: "IN PROGRESS",
    2: "COMPLETED",
    3: "ON HOLD",
  };

  const columns = [
    {
      title: "Maintenance Type",
      dataIndex: "type",
      width: 160,
    },
    {
      title: "Idea Name",
      dataIndex: "ideaName",
      className: "clickable-col",
      render: (name, record) => (
        <span
          style={{ padding: "13px 13px 6px 6px", cursor: "pointer" }}
          onClick={() => handleRowClick(record.ideaId)}
        >
          {name}
        </span>
      ),
      width: 220,
    },
    {
      title: "Bot Name",
      dataIndex: "botName",
      width: 150,
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{ rows: 3, expandable: true, symbol: "more" }}
        >
          {text}
        </Typography.Paragraph>
      ),
      width: 400,
    },
    {
      title: "Comments",
      render: (text, record) => {
        return (
          <LALAComments
            record={record}
            setPopoverRecord={() => false}
            users={users}
            ideas={props.ideas}
            userInfo={props.userInfo}
            origin={"MaintenancesSummary"}
          />
        );
      },
      width: 250,
    },
    {
      title: "Team",
      render: (text, record) => {
        return (
          <LALAAssigned
            record={record}
            setPopoverRecord={() => false}
            users={users}
            userInfo={props.userInfo}
            ideas={props.ideas}
            origin={"MaintenancesSummary"}
          />
        );
      },
      width: 180,
    },
    {
      title: "Priority",
      dataIndex: "priority",
      onFilter: (value, record) => record.priority === value,
      filters: [
        { text: "1", value: 1 },
        { text: "2", value: 2 },
        { text: "3", value: 3 },
        { text: "4", value: 4 },
        { text: "5", value: 5 },
        { text: "6", value: 6 },
        { text: "7", value: 7 },
        { text: "8", value: 8 },
        { text: "9", value: 9 },
        { text: "10", value: 10 },
      ],
      fixed: "right",
      width: 80,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => {
        return (
          <Tag color={statusColor[text]} style={{ width: "95%" }}>
            {statusText[text]}
          </Tag>
        );
      },
      onFilter: (value, record) => record.status === value,
      filters: [
        { text: "PENDING", value: 0 },
        { text: "IN PROGRESS", value: 1 },
        { text: "ON HOLD", value: 3 },
        { text: "COMPLETED", value: 2 },
      ],
      width: 100,
      fixed: "right",
    },
    {
      title: "Due date",
      dataIndex: "dueDateDiff",
      render: (text, record) => {
        if (text === -1)
          return (
            <Tag color="default" style={{ width: "88%" }}>
              NOT SET
            </Tag>
          );
        else
          return text === 5 ? (
            <Tag color="blue" style={{ width: "88%" }}>
              COMPLETED
            </Tag>
          ) : (
            <Tooltip
              title={moment
                .unix(record.schdEndDate.seconds)
                .format("dddd, MMMM Do YYYY")}
            >
              {text === 1 ? (
                <Tag color="red" style={{ width: "88%" }}>
                  OVERDUE
                </Tag>
              ) : text === 2 ? (
                <Tag color="orange" style={{ width: "88%" }}>
                  THIS WEEK
                </Tag>
              ) : text === 3 ? (
                <Tag color="lime" style={{ width: "88%" }}>
                  THIS MONTH
                </Tag>
              ) : (
                <Tag color="green" style={{ width: "88%" }}>
                  ON TIME
                </Tag>
              )}
            </Tooltip>
          );
      },
      onFilter: (value, record) => record.dueDateDiff === value,
      filters: [
        { text: "OVERDUE", value: 1 },
        { text: "THIS WEEK", value: 2 },
        { text: "THIS MONTH", value: 3 },
        { text: "ON TIME", value: 4 },
        { text: "NOT SET", value: -1 },
        { text: "COMPLETED", value: 5 },
      ],
      width: 100,
      fixed: "right",
    },
  ];

  useEffect(() => {
    if (props.ideas) {
      let tempMaintenances = [];
      let users = [];
      let colors = [];
      let diff = 0;
      props.ideas.forEach((doc) => {
        if (doc.bots) {
          doc.bots.forEach((bot) => {
            if (bot.maintenance) {
              bot.maintenance.forEach((maintenance) => {
                diff = -1;
                if (maintenance.realEndDate) {
                  diff = 5;
                } else if (maintenance.schdEndDate) {
                  if (
                    Number(
                      moment
                        .unix(maintenance.schdEndDate.seconds)
                        .diff(moment(), "days")
                    ) < 0
                  ) {
                    diff = 1;
                  } else if (
                    Number(
                      moment
                        .unix(maintenance.schdEndDate.seconds)
                        .diff(moment().startOf("week"), "days")
                    ) < 7
                  ) {
                    diff = 2;
                  } else if (
                    Number(
                      moment
                        .unix(maintenance.schdEndDate.seconds)
                        .diff(moment().endOf("month"), "days")
                    ) < 0
                  ) {
                    diff = 3;
                  } else {
                    diff = 4;
                  }
                }
                tempMaintenances.push({
                  ...maintenance,
                  bot: bot,
                  ideaId: doc.id,
                  ideaName: doc.name,
                  botName: bot.name,
                  dueDateDiff: diff,
                });
              });
            }
          });
        }
      });
      setMaintenances(tempMaintenances);
      setTableState({ data: tempMaintenances });
      if (props.company.users) users = Object.values(props.company.users);

      Object.values(presetPalettes).forEach((colorSet) => {
        colors.push(colorSet.primary);
      });
      let res = users.length / colors.length;
      if (res > 1) {
        for (let a = 0; a < Math.round(res); a++) {
          colors.push(...colors);
        }
      }
      // users=[{companyId:"QrEN5Mn25tnNhdPET601",email:"juane.soto@softtek.com",firstName:"Juan",lastName1:"Soto",lastName2:"Luévano",rol:"User",status:"Active",uid:"7FVuUhLFfBUVXOfDeEo3UQvax7k1",username:"juane.soto@softtek.com",},{companyId:"QrEN5Mn25tnNhdPET601",email:"luis.luna@softtek.com",firstName:"Luis",lastName1:"deLuna",lastName2:"Saucedo",rol:"User",status:"Active",uid:"7FVuUFfBUVXOfDeEo3UQvax7k1",username:"luis.luna@softtek.com",},{companyId:"QrEN5Mn25tnNhdPET601",email:"javier.gutierrez@softtek.com",firstName:"Javier",lastName1:"Gutiérrez",lastName2:"Pérez",rol:"User",status:"Active",uid:"7FVuUhLFfVXOfDeEo3UQvax7k1",username:"javier.gutierrez@softtek.com",},{companyId:"QrEN5Mn25tnNhdPET601",email:"jorge.bohorquez@softtek.com",firstName:"Jorge",lastName1:"Bohorquez",lastName2:"Ibarra",rol:"User",status:"Active",uid:"7FVuUhLFfBUVXOfDeEovax7k1",username:"jorge.bohorquez@softtek.com",},];
      users = users.map((user, index) => ({ ...user, color: colors[index] }));
      setUsers(users);
      if (showDatailsDrawer.visible) {
        let index = props.ideas.findIndex(
          (idea) => idea.id === showDatailsDrawer.idIdea
        );
        setShowDetailsDrawer({
          visible: true,
          idea: props.ideas[index],
          idIdea: props.ideas[index].id,
        });
      }
    }
  }, [
    props.ideas,
    props.company.activityStatus,
    props.company.users,
    showDatailsDrawer.idIdea,
    showDatailsDrawer.visible,
  ]);

  const downloadData = async () => {
    var arrayExcel = [];
    var dueStatus = "";
    var date = "";
    // var status = "";

    maintenances.forEach((maintenance) => {
      var team = "";
      if (maintenance.dueDateDiff === -1) {
        dueStatus = "NOT SET";
      } else if (maintenance.dueDateDiff === 1) {
        dueStatus = "OVERDUE";
      } else if (maintenance.dueDateDiff === 2) {
        dueStatus = "THIS WEEK";
      } else if (maintenance.dueDateDiff === 4) {
        dueStatus = "ON TIME";
      } else if (maintenance.dueDateDiff === 5) {
        dueStatus = "COMPLETED";
      } else if (maintenance.dueDateDiff === 3) {
        dueStatus = "THIS MONTH";
      }

      if (maintenance.realEndDate) {
        date = moment
          .unix(maintenance.realEndDate.seconds)
          .format("DD/MM/YYYY");
      } else if (maintenance.schdEndDate) {
        date = moment
          .unix(maintenance.schdEndDate.seconds)
          .format("DD/MM/YYYY");
      }

      maintenance.asignee.forEach((member) => {
        team += member.name + ", ";
      });
      arrayExcel.push({
        type: maintenance.type,
        ideaName: maintenance.ideaName,
        botName: maintenance.botName,
        description: maintenance.description,
        team: team,
        priority: maintenance.priority,
        status: statusText[maintenance.status],
        dueStatus: dueStatus,
        dueDate: date,
      });
    });
    setArrayExcel(arrayExcel);
    setTimeout(() => {
      var link = document.getElementById("excelMain");
      link.click();
    }, 1000);
  };

  const Loader = () => (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        textAlign: "center",
        marginTop: "25%",
      }}
    >
      <Spin size="large" spinning={true}></Spin>
    </div>
  );

  const onClose = () => {
    setShowDetailsDrawer({ idea: null, visible: false, idIdea: null });
  };

  const handleRowClick = (rowId) => {
    let index = props.ideas.findIndex((idea) => idea.id === rowId);
    setShowDetailsDrawer({
      visible: true,
      idea: props.ideas[index],
      idIdea: props.ideas[index].id,
    });
  };

  const [tableState, setTableState] = useState({
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
  });

  const handleOnKeyChange = (key) => {
    if (!key) setTableState({ data: maintenances });

    key = key.toLowerCase();
    const filteredData = maintenances.filter(
      (entry) =>
        entry.ideaName.toLowerCase().includes(key) ||
        entry.type.toLowerCase().includes(key) ||
        entry.botName.toLowerCase().includes(key) ||
        entry.description.toLowerCase().includes(key)
    );

    setTableState({ data: filteredData });
  };

  return maintenances ? (
    <div style={{ padding: 10 }}>
      <Drawer
        style={{ background: "white" }}
        theme="light"
        width="100vw"
        onClose={onClose}
        visible={showDatailsDrawer.visible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          ></div>
        }
        destroyOnClose
      >
        <CompanyIdeaDetails
          companyName={props.company.name}
          company={props.company}
          onCloseDrawer={onClose}
          userInfo={props.userInfo}
          mappings={props.mappings}
          idea={showDatailsDrawer.idea}
          activeKey={"4"}
        />
      </Drawer>
      <Row gutter={[20, 30]}>
        <Col span={24}>
          <Row>
            <Col span={6}>
              <InputSearch handleOnKeyChange={handleOnKeyChange} />
            </Col>
            <Col span={16}></Col>
            <Col span={2}>
              <Button
                onClick={() => {
                  downloadData();
                }}
                style={{ color: "white", backgroundColor: "#009900" }}
              >
                Excel
              </Button>
              <ExcelFile
                element={
                  <button id={"excelMain"} style={{ display: "none" }}>
                    Download Data
                  </button>
                }
                filename={"BotsMaintenances"}
              >
                <ExcelSheet key={"0"} data={arrayExcel} name={"Maintenance"}>
                  <ExcelColumn label="Maintenance Type" value="type" key={0} />
                  <ExcelColumn label="Idea" value="ideaName" key={1} />
                  <ExcelColumn label="Bot" value="botName" key={2} />
                  <ExcelColumn
                    label="Description"
                    value="description"
                    key={3}
                  />
                  <ExcelColumn label="Team" value="team" key={4} />
                  <ExcelColumn label="Priority" value="priority" key={5} />
                  <ExcelColumn label="Status" value="status" key={6} />
                  <ExcelColumn
                    label="Due date status"
                    value="dueStatus"
                    key={7}
                  />
                  <ExcelColumn label="Due date" value="dueDate" key={8} />
                </ExcelSheet>
              </ExcelFile>
            </Col>
          </Row>

          <Row>
            <Col>
              <Table
                pagination={{
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }}
                bordered
                rowKey={(record) =>
                  record.id + "_" + record.ideaId + "_" + record.bot.key
                }
                columns={columns}
                dataSource={tableState.data}
                scroll={{ x: "max-content" }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  ) : (
    <Loader />
  );
};

export default Maintenances;
