import React from 'react';

const ForecastBenefitStatsCard = (props) => {
  return (
    <>
      <span>{`$${new Intl.NumberFormat('es-MX', { maximumSignificantDigits: 3 }).format(props.benefitNotProcessed)}`}</span>
    </>
  );
};

export default ForecastBenefitStatsCard;