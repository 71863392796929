import React, { useEffect, useState } from "react";
import firebase from "../../../../../server/firebase";
import { getDownloadURL, removeFile } from "../../../../../db/firedataCalls";
import {
  Avatar,
  Select,
  Form,
  Button,
  Space,
  Row,
  Col,
  Spin,
  Card,
  List,
  Tabs,
  message,
  Typography,
  Divider,
  Tag,
  Popconfirm,
  Timeline,
  Empty,
} from "antd";
import DetailsForm from "./DetailsForm";
import ABANCAActivities from "./ABANCAActivities";
import JustificacionIdea from "./JustificacionIdea";
import CostBenefit from "./CostBenefit";
import ABANCAOperacion from "./ABANCAOperacion";
import "../../../../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { UserOutlined, EditOutlined } from "@ant-design/icons";
const { Option } = Select;

const ABANCAIdeaDetails = (props) => {
  const [idea, setIdea] = useState({});
  const [users, setUsers] = useState([]);
  const [deletedUsers, setDeletedUsers] = useState([]);
  const [editValues, setEditValues] = useState({
    designer: false,
    operator: false,
  });
  const [loading, setLoading] = useState(true);
  const [asigneeForm] = Form.useForm();
  const { TabPane } = Tabs;

  const getUserAndCode = (userUid) => {
    let user = users.find(({ uid }) => uid === userUid);
    let deletedUser = deletedUsers.find(({ uid }) => uid === userUid);
    return user
      ? user.firstName +
          " " +
          user.lastName1 +
          (user.lastName2 ? " " + user.lastName2 : "") +
          " (" +
          user.username +
          ")"
      : deletedUser.firstName +
          " " +
          deletedUser.lastName1 +
          (deletedUser.lastName2 ? " " + deletedUser.lastName2 : "") +
          " (" +
          deletedUser.username +
          ")";
  };

  const onChangeAsigneeUser = (value, type) => {
    value = value.asignee || value.operator;
    let user = {};
    let index = users.findIndex((user) => user.uid === value);
    let asigneeName =
      users[index].firstName +
      " " +
      users[index].lastName1 +
      (users[index].lastName2 ? " " + users[index].lastName2 : "");
    if (type === "operator") {
      user = { operator: value, operatorName: asigneeName };
    } else {
      user = { asignee: value, asigneeName: asigneeName };
    }
    console.log(user);
    firebase
      .database()
      .ref()
      .child("ideas")
      .child(idea.id)
      .update({ ...user })
      .then(() => {
        message.success("Información actualizada correctamente");
        asigneeForm.resetFields();
        setEditValues({});
      })
      .catch((err) => {
        message.error("Error al actualizar la información");
        console.error(err);
      });
  };

  const onRemoveIdea = async () => {
    firebase.database().ref().child("deletes").child("ABANCA").push({
      user: firebase.auth().currentUser.providerData[0].email,
      ideaName: props.idea.name,
      ideaId: props.idea.id,
    });
    props.onCloseDrawer();
    firebase.database().ref().child("ideas").child(props.idea.id).remove();
  };

  useEffect(() => {
    if (props.idea) {
      setLoading(true);
      setIdea({ ...props.idea });
      setLoading(false);
      console.log(props.idea);
    }
    if (props.company) {
      setUsers(Object.values(props.company.users));
      setDeletedUsers(Object.values(props.company.deletedUsers));
    } else setUsers([]);
  }, [props.idea, props.userInfo.companyId, props.company]);

  const Loader = () => (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        textAlign: "center",
        marginTop: "25%",
      }}
    >
      <Spin size="large" spinning={true}></Spin>
    </div>
  );

  const MapPriority = (priority) => {
    if (priority < 0.5) return "#94B8CC";
    if (priority < 1.5) return "#97AC85";
    if (priority < 2.5) return "#CECE94";
    if (priority < 3) return "#CCB894";
    if (priority >= 3) return "#C41B24";
  };

  const MapStatus = (idea) => {
    if (idea.status === -1) return "Sin procesar";
    if (idea.status === 2) return "En progreso";
    if (idea.status === 3) {
      if (idea.productionStatus) {
        if (idea.productionStatus === -1) return "Detenido";
        if (idea.productionStatus === 1) return "Finalizado";
      }
      return "En producción";
    } else return "Rechazada";
  };

  const MapStatusColor = (idea) => {
    if (idea.status === -1) return "volcano";
    if (idea.status === 2) return "cyan";
    if (idea.status === 3) {
      if (idea.productionStatus) {
        if (idea.productionStatus === -1) return "red";
        if (idea.productionStatus === 1) return "lime";
      }
      return "orange";
    } else return "purple";
  };
  const downloadFile = async (fileName) => {
    let url = await getDownloadURL(props.idea.id, fileName);
    window.open(url, "_blank");
  };
  const deleteFile = async (fileName) => {
    let response = await removeFile(props.idea.id, fileName);
    if (response) {
      let index = props.idea.uploads.findIndex(
        (upload) => upload.name === fileName
      );
      let uploads = props.idea.uploads;
      uploads.splice(index, 1);
      firebase
        .database()
        .ref()
        .child("ideas")
        .child(props.idea.id)
        .update({ uploads });
    } else message.error("No se eliminó el archivo");
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <Divider orientation="left">
        <Space size="middle">
          {(idea.idABANCA ? idea.idABANCA + " - " : "") + idea.name}
          {idea.priority && (
            <Tag color={MapPriority(idea.priority)}>{idea.priority}</Tag>
          )}
          {idea.status && (
            <Tag color={MapStatusColor(idea)}>
              {MapStatus(idea).toUpperCase()}
            </Tag>
          )}
        </Space>
      </Divider>
      <Tabs
        theme="light"
        defaultActiveKey={
          props.modifiers
            ? props.modifiers.activeKey && props.modifiers.activeKey
            : "1"
        }
        type="card"
        size="medium"
        style={{ marginRight: 20, marginLeft: 20, paddingTop: 10 }}
      >
        <TabPane tab={"Detalles"} key="1">
          <Row gutter={[10, 20]}>
            <Col span={12}>
              <Card
                bodyStyle={{ background: "white" }}
                type="inner"
                title={"Personal"}
                style={{ height: "270px" }}
              >
                <Space
                  direction="vertical"
                  size="large"
                  style={{ width: "100%" }}
                >
                  <Row>
                    <Avatar
                      size="large"
                      style={{ backgroundColor: "#73d13d" }}
                      icon={
                        <UserOutlined style={{ verticalAlign: "middle" }} />
                      }
                    />

                    <Col span={8} style={{ padding: "7px 20px" }}>
                      <Typography.Text
                        strong
                        style={{
                          verticalAlign: "middle",
                          color: "#73d13d",
                        }}
                      >
                        Responsable de creación:
                      </Typography.Text>
                    </Col>

                    <Typography.Text
                      strong
                      style={{ verticalAlign: "middle", margin: "7px 0" }}
                    >
                      {` ${idea.createdBy.firstName || "No capturado"} ${
                        idea.createdBy.lastName1 || ""
                      } ${idea.createdBy.lastName2 || ""}`}
                      {idea.createdBy.username &&
                        `${" (" + idea.createdBy.username + ")"}`}
                    </Typography.Text>
                  </Row>
                  <Row>
                    <Avatar
                      size="large"
                      style={{ backgroundColor: "#40a9ff" }}
                      icon={
                        <UserOutlined style={{ verticalAlign: "middle" }} />
                      }
                    />
                    <Col span={8} style={{ padding: "7px 20px" }}>
                      <Typography.Text
                        strong
                        style={{
                          verticalAlign: "middle",
                          color: "#40a9ff",
                        }}
                      >
                        Responsable del diseño:
                      </Typography.Text>
                    </Col>
                    {editValues.designer ? (
                      <>
                        <Form
                          form={asigneeForm}
                          name="designer"
                          layout="inline"
                          onFinish={(values) =>
                            onChangeAsigneeUser(values, "designer")
                          }
                        >
                          <Form.Item name="asignee">
                            <Select
                              showSearch
                              placeholder="Selecciona un usuario"
                              optionFilterProp="children"
                            >
                              {users.map((user) => (
                                <Option key={user.uid} value={user.uid}>
                                  {getUserAndCode(user.uid)}
                                </Option>
                              ))}
                              {deletedUsers.map((user) => (
                                <Option
                                  key={user.uid}
                                  value={user.uid}
                                  disabled
                                >
                                  {getUserAndCode(user.uid)}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <Form.Item>
                            <Button
                              style={{ marginLeft: "10px" }}
                              type="primary"
                              htmlType="submit"
                            >
                              Guardar
                            </Button>
                          </Form.Item>

                          <Button
                            style={{ marginLeft: "10px" }}
                            onClick={() => {
                              setEditValues({ designer: false });
                            }}
                          >
                            Cancelar
                          </Button>
                        </Form>
                      </>
                    ) : (
                      <>
                        <Typography.Text
                          strong
                          style={{ verticalAlign: "middle", margin: "7px 0" }}
                        >
                          {(idea.asignee && getUserAndCode(idea.asignee)) ||
                            "Sin responsable de diseño"}
                        </Typography.Text>
                        <Button
                          shape="circle"
                          type="link"
                          icon={<EditOutlined />}
                          onClick={() => {
                            setEditValues({ designer: true });
                            asigneeForm.setFieldsValue({ ...idea });
                          }}
                        ></Button>
                      </>
                    )}
                  </Row>
                  <Row>
                    <Avatar
                      size="large"
                      style={{ backgroundColor: "#ff4d4f" }}
                      icon={
                        <UserOutlined style={{ verticalAlign: "middle" }} />
                      }
                    />
                    <Col span={8} style={{ padding: "7px 20px" }}>
                      <Typography.Text
                        strong
                        style={{
                          verticalAlign: "middle",
                          color: "#ff4d4f",
                        }}
                      >
                        Responsable de operación:
                      </Typography.Text>
                    </Col>
                    {editValues.operator ? (
                      <>
                        <Form
                          form={asigneeForm}
                          name="operator"
                          layout="inline"
                          onFinish={(values) =>
                            onChangeAsigneeUser(values, "operator")
                          }
                        >
                          <Form.Item name="operator">
                            <Select
                              showSearch
                              placeholder="Selecciona un usuario"
                              optionFilterProp="children"
                            >
                              {users.map((user) => (
                                <Option key={user.uid} value={user.uid}>
                                  {getUserAndCode(user.uid)}
                                </Option>
                              ))}
                              {deletedUsers.map((user) => (
                                <Option
                                  key={user.uid}
                                  value={user.uid}
                                  disabled
                                >
                                  {getUserAndCode(user.uid)}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <Form.Item>
                            <Button
                              style={{ marginLeft: "10px" }}
                              type="primary"
                              htmlType="submit"
                            >
                              Guardar
                            </Button>
                          </Form.Item>

                          <Button
                            style={{ marginLeft: "10px" }}
                            onClick={() => {
                              setEditValues({ designer: false });
                            }}
                          >
                            Cancelar
                          </Button>
                        </Form>
                      </>
                    ) : (
                      <>
                        <Typography.Text
                          strong
                          style={{ verticalAlign: "middle", margin: "7px 0" }}
                        >
                          {(idea.operator && getUserAndCode(idea.operator)) ||
                            "Sin responsable de operación"}
                        </Typography.Text>
                        <Button
                          shape="circle"
                          type="link"
                          icon={
                            <EditOutlined
                              onClick={() => {
                                setEditValues({ operator: true });
                                asigneeForm.setFieldsValue({ ...idea });
                              }}
                            />
                          }
                        ></Button>
                      </>
                    )}
                  </Row>
                </Space>
              </Card>
            </Col>
            <Col span={12}>
              <Timeline style={{ margin: "20px 0 0 20px" }}>
                <Timeline.Item color="green">
                  <p>Dirección General:</p>
                  <p>{idea && idea.dir_general && idea.dir_general}</p>
                </Timeline.Item>
                <Timeline.Item color="blue">
                  <p>Dirección:</p>
                  <p>{idea && idea.direccion && idea.direccion}</p>
                </Timeline.Item>
                <Timeline.Item color="red">
                  <p>Área:</p>
                  <p>{idea && idea.area && idea.area}</p>
                </Timeline.Item>
              </Timeline>
            </Col>
          </Row>
          <Row gutter={[10, 20]}>
            <Col span={24}>
              <Card
                bodyStyle={{ background: "white" }}
                type="inner"
                title={"Datos de la idea"}
                style={{ marginTop: 20 }}
              >
                <DetailsForm
                  userInfo={props.userInfo}
                  ideaData={props.idea}
                  company={props.company}
                />
              </Card>
              {idea.uploads && (
                <Card
                  bodyStyle={{ background: "white" }}
                  type="inner"
                  title={"Archivos"}
                  style={{ marginTop: 20 }}
                >
                  <List
                    itemLayout="horizontal"
                    dataSource={idea.uploads}
                    renderItem={(item) => (
                      <List.Item>
                        <List.Item.Meta
                          title={
                            <Button
                              type="link"
                              onClick={() => downloadFile(item.name)}
                            >
                              {item.name}
                            </Button>
                          }
                        />
                        <List.Item.Meta
                          title={
                            <Button
                              type="primary"
                              onClick={() => deleteFile(item.name)}
                            >
                              Eliminar archivo
                            </Button>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </Card>
              )}
              <Card
                bodyStyle={{ background: "white" }}
                type="inner"
                title={"Archivos Rerobot"}
                style={{ marginTop: 20 }}
              >
                {idea.innotekAttachments ? (
                  <>
                    <List
                      itemLayout="horizontal"
                      dataSource={idea.innotekAttachments}
                      split={false}
                      renderItem={(item) => (
                        <List.Item style={{ paddingLeft: "10px" }}>
                          {<a href={item.url}>{item.name}</a>}
                        </List.Item>
                      )}
                    />
                  </>
                ) : (
                  <>
                    <Empty
                      description={"Sin archivos"}
                      style={{ height: "100px" }}
                      imageStyle={{ height: "75%" }}
                    />
                  </>
                )}
              </Card>
              <Card
                bodyStyle={{ background: "white" }}
                type="inner"
                title={"Justificación de la idea"}
                style={{ marginTop: 20 }}
              >
                <JustificacionIdea idIdea={props.idIdea} ideaData={idea} />
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tab={"Actividades"} key="2">
          <ABANCAActivities
            ideaData={idea}
            userInfo={props.userInfo}
            company={props.company}
          ></ABANCAActivities>
        </TabPane>

        <TabPane tab={"Pase a producción"} key="3">
          <CostBenefit
            idIdea={props.idIdea}
            ideaData={idea}
            userInfo={props.userInfo}
            company={props.company}
          />
        </TabPane>
        <TabPane tab={"Operacion"} key="4">
          <ABANCAOperacion
            idIdea={idea.id}
            ideaData={idea}
            userInfo={props.userInfo}
            company={props.company}
            modifiers={props.modifiers}
          />
        </TabPane>
      </Tabs>
      <Popconfirm
        placement="bottom"
        title={"Eliminar idea"}
        onConfirm={onRemoveIdea}
        okText={"Eliminar"}
        cancelText="No"
      >
        <Button
          type={"dashed"}
          style={{ color: "red", float: "right", marginRight: "25px" }}
        >
          Eliminar idea
        </Button>
      </Popconfirm>
    </>
  );
};

export default ABANCAIdeaDetails;
