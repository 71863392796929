import React from "react";
import {
  Row,
  Col,
  Form,
  Checkbox,
  Space,
  Button,
  Radio,
  Card,
  Typography,
} from "antd";
import CalculatorJson from "./calculator.json";
import {
  kControlQuestionOneList,
  kControlQuestionThreeList,
  kControlQuestionTwoList,
} from "./CalculatorCalcs/Constants";

const { Item } = Form;

const calculator = CalculatorJson.home;

const initialFormValues = {};

const Flow = ({ nextStep, resetAll }) => {
  const [form] = Form.useForm();

  const onFinish = () => {
    nextStep();
  };

  return (
    <Card
      type="inner"
      title={
        <Typography.Title level={5} className="primary-color">
          {calculator.control_title}
        </Typography.Title>
      }
    >
      <Row>
        <Form
          name="flow"
          labelCol={12}
          layout="vertical"
          size="large"
          form={form}
          onFinish={onFinish}
          requiredMark={false}
          initialValues={initialFormValues}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Item
                    label={calculator.control_question_one}
                    name="decisionTree"
                    rules={[
                      {
                        required: true,
                        message: "Campo Requerido",
                      },
                    ]}
                  >
                    <Row>
                      <Col span={24} offset={1}>
                        <Radio.Group>
                          <Space direction="vertical" size="middle">
                            {kControlQuestionOneList.map(({ name, value }) => (
                              <Radio value={value} key={name}>
                                {name}
                              </Radio>
                            ))}
                          </Space>
                        </Radio.Group>
                      </Col>
                    </Row>
                  </Item>
                </Col>
                <Col span={24}>
                  <Item
                    label={calculator.control_question_two}
                    name="exception"
                    rules={[
                      {
                        required: true,
                        message: "Campo Requerido",
                      },
                    ]}
                  >
                    <Row>
                      <Col span={24} offset={1}>
                        <Radio.Group>
                          <Space direction="vertical" size="middle">
                            {kControlQuestionTwoList.map(({ name, value }) => (
                              <Radio value={value} key={name}>
                                {name}
                              </Radio>
                            ))}
                          </Space>
                        </Radio.Group>
                      </Col>
                    </Row>
                  </Item>
                </Col>
                <Col span={24}>
                  <Item
                    label={calculator.control_question_three}
                    name="exceptionHandled"
                    rules={[
                      {
                        required: true,
                        message: "Campo Requerido",
                      },
                    ]}
                  >
                    <Row>
                      <Col span={24} offset={1}>
                        <Radio.Group>
                          <Space direction="vertical" size="middle">
                            {kControlQuestionThreeList.map(
                              ({ name, value }) => (
                                <Radio value={value} key={name}>
                                  {name}
                                </Radio>
                              )
                            )}
                          </Space>
                        </Radio.Group>
                      </Col>
                    </Row>
                  </Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={[16, 16]}>
                {[
                  "control_question_four",
                  "control_question_five",
                  "control_question_six",
                ].map((question) => (
                  <Col span={24} key={question}>
                    <Space size="middle">
                      {calculator[question]}
                      <Item valuePropName="checked" name={question}>
                        <Checkbox />
                      </Item>
                    </Space>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="end">
                <Button
                  htmlType="submit"
                  shape="round"
                  className="button-submit-secondary submit-fixed submit-secondary-spaced"
                  onClick={resetAll}
                >
                  Cancelar
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  shape="round"
                  className="button-submit submit-fixed"
                >
                  Siguiente
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Row>
    </Card>
  );
};

export default Flow;
