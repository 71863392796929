import React, { useEffect, useState } from "react";
import firebase from "../../../../../server/firebase";
import {
  Form,
  Button,
  Row,
  Col,
  message,
  Select,
  Divider,
  Card,
  Avatar,
  Popover,
  Tooltip,
  Typography,
} from "antd";
import { blue } from "@ant-design/colors";
import { updateActivitiesHEB } from "../../../../../db/firedataCalls";
import { EditOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";

const { Meta } = Card;

const WalmartAssigned = (props) => {
  const [addAsignee, setAddAsignee] = useState(false);

  useEffect(() => {}, []);

  const renderNames = (asignee) => {
    let names = "";
    let count = 0;
    asignee.forEach(({ name }) => {
      if (count === 0) {
        names += name;
      }
      count++;
    });
    if (names) {
      if (count > 1) {
        return names + ", +" + (count - 1);
      } else return names;
    } else return "";
  };

  const returnName = (user) => {
    return (
      user.firstName +
      " " +
      (user.lastName1 &&
        user.lastName1 + (user.lastName2 && " " + user.lastName2))
    );
  };

  const assigneeRender = () => {
    return (
      <Typography.Text style={{ cursor: "pointer", color: blue[5] }}>
        {renderNames(props.record.asignee).length > 35
          ? renderNames(props.record.asignee).substring(0, 35) + "..."
          : renderNames(props.record.asignee)}
      </Typography.Text>
    );
  };

  const getChildren = () => {
    let properties = null;
    if (props.record)
      properties = {
        defaultValue: props.record.asignee.map(({ uid }) => uid),
        style: { width: "100%" },
      };
    return (
      <Select
        placeholder={"Asign to someone"}
        mode="multiple"
        optionLabelProp="label"
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        {...properties}
      >
        {props.users.map((user) => (
          <Select.Option
            key={user.uid}
            value={user.uid}
            label={returnName(user)}
          >
            <Tooltip placement="right" title={user.email}>
              <Avatar style={{ backgroundColor: user.color }}>
                {user.firstName[0].toUpperCase() +
                  user.lastName1[0].toUpperCase()}
              </Avatar>
              {" " + returnName(user)}
            </Tooltip>
          </Select.Option>
        ))}
      </Select>
    );
  };

  const saveAssigned = () => (values) => {
    switch (props.origin) {
      case "WalmartActivities":
        teamChange(values);
        break;
      case "ActivitiesSummary":
        teamChangeActTable(values);
        break;
      case "WalmartMaintenance":
        teamChangeMaintenance(values);
        break;
      case "MaintenancesSummary":
        teamChangeMaintTable(values);
        break;
      default:
        console.log("no está programado jeje");
        break;
    }
  };

  const teamChangeMaintTable = async (values) => {
    if (values.asignee) {
      console.log(values);
      console.log(props);
      let user = null;
      let tempBots = props.ideas.find(
        ({ id }) => id === props.record.ideaId
      ).bots;
      let index = tempBots.findIndex(({ key }) => key === props.record.bot.key);
      let maintenanceIndex = tempBots[index].maintenance.findIndex(
        ({ id }) => id === props.record.id
      );
      let map = tempBots[index].maintenance[maintenanceIndex].asignee.map(
        (asignee) => asignee.uid
      );
      if (
        !(
          values.asignee.every((uid) => map.indexOf(uid) !== -1) &&
          map.every((uid) => values.asignee.indexOf(uid) !== -1)
        )
      ) {
        values.asignee = values.asignee.map((asignee) => {
          user = props.users.find(({ uid }) => uid === asignee);
          return {
            name: returnName(user),
            email: user.email,
            uid: user.uid,
          };
        });
        tempBots[index].maintenance[maintenanceIndex].asignee = values.asignee;
        await firebase
          .database()
          .ref()
          .child("ideas")
          .child(props.record.ideaId)
          .update({ bots: tempBots })
          .then(() => {
            message.success("Team updated successfully.");
          })
          .catch((error) => {
            message.error("There was an error updating the team.");
            console.log(error);
          });
        setAddAsignee(!addAsignee);
      }
    }
  };

  const teamChangeMaintenance = async (values) => {
    if (values.asignee) {
      let user = null;
      let tempBots = props.bots;
      let index = props.bots.findIndex(
        ({ key }) => key === props.record.botKey
      );
      let maintenanceIndex = tempBots[index].maintenance.findIndex(
        ({ id }) => id === props.record.maintenance.id
      );
      let map = tempBots[index].maintenance[maintenanceIndex].asignee.map(
        (asignee) => asignee.uid
      );
      if (
        !(
          values.asignee.every((uid) => map.indexOf(uid) !== -1) &&
          map.every((uid) => values.asignee.indexOf(uid) !== -1)
        )
      ) {
        values.asignee = values.asignee.map((asignee) => {
          user = props.users.find(({ uid }) => uid === asignee);
          return {
            name: returnName(user),
            email: user.email,
            uid: user.uid,
          };
        });
        tempBots[index].maintenance[maintenanceIndex].asignee = values.asignee;

        await firebase
          .database()
          .ref()
          .child("ideas")
          .child(props.idea.id)
          .update({ bots: tempBots })
          .then(() => {
            message.success("Team updated successfully.");
          })
          .catch((error) => {
            message.error("There was an error updating the team.");
            console.log(error);
          });
        setAddAsignee(!addAsignee);
      }
    }
  };

  const teamChangeActTable = (values) => {
    if (values.asignee) {
      let user = null;
      let idea = props.ideas.find(({ id }) => id === props.record.ideaId);
      let stages = idea.activities;
      let currentStage = stages.find(({ key }) => key === props.record.key);
      let tempActivities =
        currentStage && currentStage.activities ? currentStage.activities : [];
      let activityIndex = tempActivities.findIndex(
        ({ id }) => id === props.record.id
      );
      let map = tempActivities[activityIndex].asignee.map(
        (asignee) => asignee.uid
      );
      if (
        !(
          values.asignee.every((uid) => map.indexOf(uid) !== -1) &&
          map.every((uid) => values.asignee.indexOf(uid) !== -1)
        )
      ) {
        values.asignee = values.asignee.map((asignee) => {
          user = props.users.find(({ uid }) => uid === asignee);
          return {
            name: returnName(user),
            email: user.email,
            uid: user.uid,
          };
        });
        tempActivities[activityIndex].asignee = values.asignee;
        let index = stages.findIndex(({ key }) => key === props.record.key);
        stages[index].activities = tempActivities;
        updateActivitiesHEB(props.record.ideaId, stages)
          .then(() => {
            message.success("Team updated successfully.");
          })
          .catch((error) => {
            message.error("There was an error updating the team.");
            console.log(error);
          });
        setAddAsignee(!addAsignee);
      }
    }
  };

  const teamChange = (values) => {
    if (values.asignee) {
      let user = null;
      let currentStage = props.idea.activities.find(
        ({ key }) => key === props.stage.key
      );
      let tempActivities =
        currentStage && currentStage.activities ? currentStage.activities : [];
      let activityIndex = tempActivities.findIndex(
        ({ id }) => id === props.record.id
      );
      let map = tempActivities[activityIndex].asignee.map(
        (asignee) => asignee.uid
      );
      console.log(values.asignee.length, map.length);
      if (
        !(
          values.asignee.every((uid) => map.indexOf(uid) !== -1) &&
          map.every((uid) => values.asignee.indexOf(uid) !== -1)
        )
      ) {
        values.asignee = values.asignee.map((asignee) => {
          user = props.users.find(({ uid }) => uid === asignee);
          return {
            name: returnName(user),
            email: user.email,
            uid: user.uid,
          };
        });
        tempActivities[activityIndex].asignee = values.asignee;
        let stages = props.idea.activities;
        let index = stages.findIndex(({ key }) => key === props.stage.key);
        stages[index].activities = tempActivities;
        updateActivitiesHEB(props.idea.id, stages)
          .then(() => {
            message.success("Team updated successfully.");
          })
          .catch((error) => {
            message.error("There was an error updating the team.");
            console.log(error);
          });
        setAddAsignee(!addAsignee);
      }
    }
  };

  const Content = () => (
    <Card
      style={{ width: 350 }}
      title={"Team"}
      size="small"
      bordered={false}
      actions={
        addAsignee
          ? [
              <CloseOutlined
                key="close"
                onClick={() => {
                  setAddAsignee(!addAsignee);
                }}
              />,
              <Button
                style={{ padding: "0px 0", fontSize: "0px" }}
                type="link"
                htmlType="submit"
                icon={<CheckOutlined />}
                form="teamForm"
              />,
            ]
          : [
              <EditOutlined
                key="edit"
                onClick={() => {
                  setAddAsignee(!addAsignee);
                }}
              />,
            ]
      }
    >
      <Row>
        {props.record.asignee.map((asignee, index) => (
          <Col key={index}>
            <Meta
              avatar={
                <Avatar
                  style={{
                    backgroundColor: props.users.find(
                      ({ uid }) => asignee.uid === uid
                    ).color,
                  }}
                >
                  {asignee.name.split(" ")[0]}
                </Avatar>
              }
              title={asignee.name}
              description={asignee.email}
            />
            {index !== props.record.asignee.length - 1 && <Divider />}
          </Col>
        ))}
      </Row>

      {addAsignee && (
        <>
          <Divider>Change Team</Divider>
          <Form
            id="teamForm"
            onFinish={saveAssigned()}
            onFinishFailed={() => setAddAsignee(!addAsignee)}
          >
            <Form.Item
              name="asignee"
              rules={[
                {
                  required: true,
                  message: "Required field",
                },
              ]}
            >
              {getChildren()}
            </Form.Item>
          </Form>
        </>
      )}
    </Card>
  );

  return (
    <Popover
      content={<Content />}
      placement="right"
      trigger="click"
      onVisibleChange={() => setAddAsignee(false)}
    >
      {assigneeRender()}
    </Popover>
  );
};

export default WalmartAssigned;
