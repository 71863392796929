import React, { useEffect, useState } from "react";
import { Table, Row, Col, Drawer } from "antd";
import "../../../../../../App.css";
import moment from "moment";
import InputSearch from "../../InputSearch";
import CreateIdeaHandler from "../../../CreateIdea/CreateIdeaHandler";
import CompanyIdeaDetails from "../../../Details/CompanyIdeaDetails";
import ExcelDownload from "../../../../../CustomComponents/ABANCA/ExcelDownload";

const Search = (props) => {
  const [state, setState] = useState({
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
  });
  const [tableData, setTableData] = useState([]);
  const [showDatailsDrawer, setShowDetailsDrawer] = useState({
    idea: null,
    visible: false,
    idIdea: null,
  });

  const getStatus = (record) => {
    switch (record.status) {
      case -1:
        return "Sin procesar";
      case 2:
        return "En progreso";
      case 3:
        if (record.productionStatus) {
          return record.productionStatus === -1 ? "Detenido" : "Finalizado";
        }
        return "Aceptada";
      case 4:
        return "Rechazada";
      default:
        return "Undefined";
    }
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      render: (text, record) => (
        <span
          style={{ padding: "13px 13px 6px 6px", cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {record.name}
        </span>
      ),
      width: "40%",
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
    },
    {
      title: "Fecha creación",
      dataIndex: "createdDate",
      render: (createdDate) =>
        createdDate
          ? moment.unix(createdDate.seconds).format("DD/MM/YYYY")
          : "",
      width: "20%",
      sorter: {
        compare: (a, b) => a.createdDate.seconds - b.createdDate.seconds,
      },
    },
    {
      title: "Potencial RPA",
      dataIndex: "potencialRPA",
      sortDirections: ["descend", "ascend"],
      width: "20%",
      sorter: {
        compare: (a, b) =>
          a.priority
            ? b.priority
              ? a.priority - b.priority
              : a.priority - 0
            : b.priority
            ? 0 - b.priority
            : 0 - 0,
      },
      render: (potencial) =>
        potencial && (
          <span style={{ textAlign: "center" }}>{`${potencial}%`}</span>
        ),
    },
    {
      title: "Estatus",
      dataIndex: "status",
      render: (status, record) => {
        return getStatus(record);
      },
      width: "20%",
      sorter: {
        compare: (a, b) => a.status - b.status,
      },
      filters: [
        { text: "Sin procesar", value: "Sin procesar" },
        { text: "En progreso", value: "En progreso" },
        { text: "Detenido", value: "Detenido" },
        { text: "Finalizado", value: "Finalizado" },
        { text: "En producción", value: "En producción" },
        { text: "Rechazadas", value: "Rechazada" },
      ],
      onFilter: (value, record) => {
        return getStatus(record).indexOf(value) === 0;
      },
    },
  ];

  const handleRowClick = (row) => {
    setShowDetailsDrawer({ visible: true, idea: row, idIdea: row.id });
  };

  useEffect(() => {
    if (props.ideas) {
      let ideas = props.ideas;
      setTableData(ideas);
      setState({
        data: ideas,
      });
    }
    if (showDatailsDrawer.visible) {
      let index = props.ideas.findIndex(
        (idea) => idea.id === showDatailsDrawer.idIdea
      );
      setShowDetailsDrawer({
        visible: true,
        idea: props.ideas[index],
        idIdea: props.ideas[index].id,
      });
    }
  }, [props.ideas, showDatailsDrawer.visible, showDatailsDrawer.idIdea]);

  const { data } = state;

  const handleOnKeyChange = (key) => {
    if (!key) setState({ data: tableData });

    key = key.toLowerCase();
    const filteredData = tableData.filter(
      (entry) =>
        entry.name.toLowerCase().includes(key) ||
        (entry.idABANCA && entry.idABANCA.toString().includes(key)) ||
        entry.direccion.toLowerCase().includes(key) ||
        entry.dir_general.toLowerCase().includes(key)
    );

    setState({ data: filteredData });
  };

  const onClose = () => {
    setShowDetailsDrawer({ visible: false, idea: null, idIdea: null });
  };

  return (
    <>
      <Row>
        <Col span={6}>
          <InputSearch handleOnKeyChange={handleOnKeyChange} />
        </Col>

        <Col offset={2} span={3}>
          <CreateIdeaHandler
            userInfo={props.userInfo}
            company={props.company}
            ideas={tableData}
          />
        </Col>
        <Col span={11}></Col>
        <Col span={2}>
          <ExcelDownload data={data} name={"Peticiones"} id={"excel1"} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            bordered
            dataSource={data}
            rowKey={(idea) => idea.id}
            columns={columns}
            scroll={{ x: 1300 }}
          />

          <Drawer
            style={{ background: "white" }}
            width="100vw"
            onClose={onClose}
            visible={showDatailsDrawer.visible}
            footer={
              <div
                style={{
                  textAlign: "right",
                }}
              ></div>
            }
            destroyOnClose
          >
            <CompanyIdeaDetails
              company={props.company}
              onCloseDrawer={onClose}
              userInfo={props.userInfo}
              companyName={props.companyName}
              mappings={props.mappings}
              idea={showDatailsDrawer.idea}
            />
          </Drawer>
        </Col>
      </Row>
    </>
  );
};

export default Search;
