import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Popconfirm,
  Button,
  Drawer,
  message,
  Tooltip,
  Tag,
} from "antd";
import {
  WarningOutlined,
  ExclamationCircleOutlined,
  SendOutlined,
} from "@ant-design/icons";
import moment from "moment";
import InputSearch from "../../InputSearch";
import { onChangeApprovedABANCA } from "./InProgressValidations";
import CompanyIdeaDetails from "../../../Details/CompanyIdeaDetails";
import ExcelDownload from "../../../../../CustomComponents/ABANCA/ExcelDownload";

const InProgress = (props) => {
  const [dirFilter, setDirFilter] = useState([]);
  const [dirGenFilter, setDirGenFilter] = useState([]);
  const [tipoTareaFilter, setTipoTareaFilter] = useState([]);

  const cardIconsStyle = {
    fontWeight: "bolder",
    fontSize: "2em",
    color: "#1890ff",
  };
  const cardTextStyle = {
    fontWeight: "bolder",
    fontSize: "large",
    color: "#1890ff",
  };

  const [inProgressData, setInProgressData] = useState({
    ideas: [],
    atRisk: 0,
    total: 0,
    offTrack: 0,
  });

  const priorities = {
    VERY_HIGH: "Muy alta",
    HIGH: "Alta",
    MEDIUM: "Media",
    LOW: "Baja",
    VERY_LOW: "Muy baja",
  };
  const prioritiesColor = {
    VERY_HIGH: "#C41B24",
    HIGH: "#ccb894",
    MEDIUM: "#CECE94",
    LOW: "#97AC85",
    VERY_LOW: "#94B8CC",
  };
  const MapPriority = (priority) => {
    if (priority < 0.5) return "VERY_LOW";
    if (priority < 1.5) return "LOW";
    if (priority < 2.5) return "MEDIUM";
    if (priority < 3) return "HIGH";
    if (priority >= 3) return "VERY_HIGH";
  };

  const getStatusColor = (targetDate) => {
    let days = 0;

    if (targetDate) {
      let a = moment.unix(targetDate._seconds);
      let b = moment(new Date());
      days = moment.duration(a.diff(b)).asDays();
    } else return "#b7eb8f";

    if (days <= 0) return "#ff7875";
    if (days > 0 && days <= 14) return "#ffcc00";
    else return "#b7eb8f";
  };

  const getStatusString = (targetDate) => {
    let days = 0;

    if (targetDate) {
      let a = moment.unix(targetDate._seconds);
      let b = moment(new Date());
      days = moment.duration(a.diff(b)).asDays();
    } else return "A tiempo";

    if (days <= 0) return "Vencida";
    if (days > 0 && days <= 14) return "Próxima";
    else return "A tiempo";
  };

  const [showDatailsDrawer, setShowDetailsDrawer] = useState({
    idea: null,
    visible: false,
    idIdea: null,
  });

  const handleRowClick = (row) => {
    setShowDetailsDrawer({ visible: true, idea: row, idIdea: row.id });
  };

  const success = () => {
    message.success("Petición puesta en producción");
  };

  const error = (mensaje) => {
    message.error(mensaje.split(":")[1]);
  };

  const warning = () => {
    message.warning(
      "Existen actividades no completadas favor de completar antes de aprobar"
    );
  };

  const onChangeApproved = async (idea, value) => {
    let res = "";
    res = await onChangeApprovedABANCA(idea, value, props.userInfo);
    if (res === "true") success();
    if (res === "warning") warning();
    if (res.includes("ERROR")) error(res);
  };

  const onClose = () => {
    setShowDetailsDrawer({ idea: null, visible: false, idIdea: null });
  };

  const CircleSvg = ({ color }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill={color}
      version="1.1"
    >
      <circle cx="20" cy="20" r="10" />
    </svg>
  );

  const CircleIcon = (props) => <CircleSvg {...props} />;
  const columns = [
    {
      title: "ID",
      dataIndex: "idABANCA",
      sorter: (a, b) => parseInt(a.idABANCA) - parseInt(b.idABANCA),
      width: "5%",
    },
    {
      title: "Nombre",
      dataIndex: "name",
      render: (text, record) => (
        <span
          style={{ padding: "13px 13px 6px 6px", cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {record.name}
        </span>
      ),
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
    },
    {
      title: "Dirección General",
      dataIndex: "dir_general",
      render: (text) => {
        return text && text;
      },
      sorter: {
        compare: (a, b) => a.dir_general.localeCompare(b.dir_general),
      },
      filters: dirGenFilter,
      onFilter: (value, record) => {
        return record.dir_general.indexOf(value) === 0;
      },
    },
    {
      title: "Dirección",
      dataIndex: "direccion",
      render: (text) => {
        return text && text;
      },
      sorter: {
        compare: (a, b) => a.direccion.localeCompare(b.direccion),
      },
      filters: dirFilter,
      onFilter: (value, record) => {
        return record.direccion.indexOf(value) === 0;
      },
    },
    {
      title: "Estatus",
      dataIndex: "fechaMax",
      className: "center-column",
      render: (fechaMax) => (
        <Tooltip
          title={
            fechaMax &&
            fechaMax._seconds &&
            moment.unix(fechaMax._seconds).format("DD/MM/YYYY")
          }
        >
          <div>
            <CircleIcon color={getStatusColor(fechaMax)} />
          </div>
        </Tooltip>
      ),
      sorter: {
        compare: (a, b) =>
          getStatusString(a.fechaMax).localeCompare(
            getStatusString(b.fechaMax)
          ),
      },
      filters: [
        { text: "A tiempo", value: "A tiempo" },
        { text: "Próxima", value: "Próxima" },
        { text: "Vencida", value: "Vencida" },
      ],
      onFilter: (value, record) => {
        return getStatusString(record.fechaMax).indexOf(value) === 0;
      },
      width: "8%",
    },
    {
      title: "Prioridad",
      dataIndex: "priority",
      sortDirections: ["descend", "ascend"],
      sorter: {
        compare: (a, b) =>
          a.priority
            ? b.priority
              ? a.priority - b.priority
              : a.priority - 0
            : b.priority
            ? 0 - b.priority
            : 0 - 0,
      },
      render: (priority, record) => {
        if (priority) {
          priority = MapPriority(priority);
          let color = prioritiesColor[priority];
          return (
            <Tag color={color} key={priority}>
              {`${priorities[priority].toUpperCase()}` +
                (record.priority ? ` (${record.priority})` : "")}
            </Tag>
          );
        } else {
          return <Tag key={priority}>{"SIN ASIGNAR"}</Tag>;
        }
      },
      filters: [
        { text: "Muy baja", value: "Muy baja" },
        { text: "Baja", value: "Baja" },
        { text: "Media", value: "Media" },
        { text: "Alta", value: "Alta" },
        { text: "Muy alta", value: "Muy alta" },
        { text: "Sin asignar", value: "Sin asignar" },
      ],
      onFilter: (value, record) => {
        return record.priority
          ? priorities[MapPriority(record.priority)].indexOf(value) === 0
          : "Sin asignar".indexOf(value) === 0;
      },
    },
    {
      title: "Responsable de diseño",
      dataIndex: "assignedUser",
    },
    {
      title: "Fecha prevista",
      dataIndex: "fechaMax",
      render: (fechaMax) =>
        fechaMax &&
        fechaMax._seconds &&
        moment.unix(fechaMax._seconds).format("DD/MM/YYYY"),
      sorter: {
        compare: (a, b) =>
          a.fechaMax && a.fechaMax._seconds
            ? b.fechaMax && b.fechaMax._seconds
              ? a.fechaMax._seconds - b.fechaMax._seconds
              : a.fechaMax._seconds - 0
            : b.fechaMax && b.fechaMax._seconds
            ? 0 - b.fechaMax._seconds
            : 0 - 0,
        multiple: 2,
      },
      width: "10%",
    },
    {
      title: "Tipo de tarea",
      dataIndex: "tipoTarea",
      render: (text) => (text && text !== 0 ? text : ""),
      filters: tipoTareaFilter,
      onFilter: (value, record) => {
        return record.tipoTarea
          ? record.tipoTarea.indexOf(value) === 0
          : "Sin asignar".indexOf(value) === 0;
      },
    },
    {
      title: "Actividades completadas",
      dataIndex: "activities",
      render: (text, record) =>
        record.activities && record.activities.length
          ? `${(
              (record.activities.filter((activity) => activity.completed)
                .length /
                record.activities.length) *
              100
            ).toFixed(2)}%`
          : "Sin actividades",
    },
    {
      title: "Pase a producción",
      fixed: "right",
      width: 160,
      render: (record) => (
        <Popconfirm
          placement="bottomLeft"
          title="¿Estás seguro?"
          onConfirm={() => onChangeApproved(record, true)}
          okText="Sí"
          cancelText="No"
        >
          <Button
            style={{ width: "140px" }}
            key={record.id}
            className="ant-btn"
          >
            Pase a producción
          </Button>
        </Popconfirm>
      ),
    },
  ];

  const [tableState, setTableState] = useState({
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
  });

  const handleOnKeyChange = (key) => {
    if (!key) setTableState({ data: inProgressData.ideas });

    key = key.toLowerCase();
    const filteredData = inProgressData.ideas.filter(
      (entry) =>
        entry.name.toLowerCase().includes(key) ||
        (entry.idABANCA && entry.idABANCA.toString().includes(key)) ||
        entry.direccion.toLowerCase().includes(key) ||
        entry.dir_general.toLowerCase().includes(key) ||
        (entry.assignedUser && entry.assignedUser.toLowerCase().includes(key))
    );

    setTableState({ data: filteredData });
  };

  useEffect(() => {
    if (props.ideas) {
      let ideas = [];
      let direccion = [];
      let dir_general = [];
      props.ideas.forEach(
        (doc) =>
          doc.status === 2 &&
          ideas.push({
            ...doc,
            id: doc.id,
            assignedUser: doc.asignee ? getUserAndCode(doc.asignee) : "",
          })
      );
      let total = ideas.length;
      console.log("total: ", total);
      setTableState({ data: ideas });
      ideas.forEach((idea) => {
        if (!direccion.find((dir) => dir.value === idea.direccion))
          direccion.push({ text: idea.direccion, value: idea.direccion });
        if (
          !dir_general.find(
            (dir_general) => dir_general.value === idea.dir_general
          )
        )
          dir_general.push({ text: idea.dir_general, value: idea.dir_general });
      });
      setDirFilter(direccion);
      setDirGenFilter(dir_general);
      let atRisk = ideas.filter((idea) => {
        let days = 0;
        if (idea.fechaMax) {
          let a = moment.unix(idea.fechaMax._seconds);
          let b = moment(new Date());
          days = moment.duration(a.diff(b)).asDays();
        }

        if (days > 0 && days <= 14) return true;
        else return false;
      }).length;
      let offTrack = ideas.filter((idea) => {
        let days = 0;
        if (idea.fechaMax) {
          let a = moment.unix(idea.fechaMax._seconds);
          let b = moment(new Date());
          days = moment.duration(a.diff(b)).asDays();
        }

        if (days <= 0 && idea.fechaMax) return true;
        else return false;
      }).length;
      setInProgressData({
        total: total,
        atRisk: atRisk,
        ideas: ideas,
        offTrack: offTrack,
      });
      if (showDatailsDrawer.visible) {
        let index = props.ideas.findIndex(
          (idea) => idea.id === showDatailsDrawer.idIdea
        );
        setShowDetailsDrawer({
          visible: true,
          idea: props.ideas[index],
          idIdea: props.ideas[index].id,
        });
      }
    }
    let tipos = [];
    Object.values(props.company.tipoTarea).forEach((tipo) => {
      tipos.push({ text: tipo, value: tipo });
    });
    tipos.push({ text: "Sin asignar", value: "Sin asignar" });
    setTipoTareaFilter(tipos);
    // eslint-disable-next-line
  }, [props.ideas, showDatailsDrawer.visible, showDatailsDrawer.idIdea]);

  const getUserAndCode = (userUid) => {
    let user = Object.values(props.company.users).find(
      ({ uid }) => uid === userUid
    );
    let deletedUser = Object.values(props.company.deletedUsers).find(
      ({ uid }) => uid === userUid
    );
    return user
      ? user.firstName +
          " " +
          user.lastName1 +
          (user.lastName2 ? " " + user.lastName2 : null) +
          " (" +
          user.username +
          ")"
      : deletedUser.firstName +
          " " +
          deletedUser.lastName1 +
          (deletedUser.lastName2 ? " " + deletedUser.lastName2 : null) +
          " (" +
          deletedUser.username +
          ")";
  };

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col span={8}>
          <Card>
            <Row>
              <Col span={18}>
                <Row>
                  <span>TOTAL EN PROGRESO</span>
                </Row>
                <Row>
                  <span style={cardTextStyle}>{inProgressData.total}</span>
                </Row>
              </Col>
              <Col span={6}>
                <SendOutlined style={cardIconsStyle} />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Row>
              <Col span={18}>
                <Row>
                  <span>EN RIESGO</span>
                </Row>
                <Row>
                  <span style={cardTextStyle}>{inProgressData.atRisk}</span>
                </Row>
              </Col>
              <Col span={6}>
                <WarningOutlined
                  style={{ ...cardIconsStyle, color: "#fadb14" }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Row>
              <Col span={18}>
                <Row>
                  <span>VENCIDAS</span>
                </Row>
                <Row>
                  <span style={cardTextStyle}>{inProgressData.offTrack}</span>
                </Row>
              </Col>
              <Col span={6}>
                <ExclamationCircleOutlined
                  style={{ ...cardIconsStyle, color: "red" }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <br />
          <br />
          <div>
            <Drawer
              style={{ background: "white" }}
              theme="light"
              width="100vw"
              onClose={onClose}
              visible={showDatailsDrawer.visible}
              footer={
                <div
                  style={{
                    textAlign: "right",
                  }}
                ></div>
              }
              destroyOnClose
            >
              <CompanyIdeaDetails
                company={props.company}
                onCloseDrawer={onClose}
                userInfo={props.userInfo}
                companyName={props.companyName}
                mappings={props.mappings}
                idea={showDatailsDrawer.idea}
              />
            </Drawer>
            <Row>
              <Col span={6}>
                <InputSearch handleOnKeyChange={handleOnKeyChange} />
              </Col>
              <Col span={16}></Col>
              <Col span={2}>
                <ExcelDownload
                  data={tableState.data}
                  name={"En Progreso"}
                  id={"excel2"}
                  company={props.company}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Table
                  pagination={{
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                  }}
                  bordered
                  columns={columns}
                  dataSource={tableState.data}
                  rowKey={(record) => record.id}
                  size="small"
                  scroll={{ x: 1300 }}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default InProgress;
