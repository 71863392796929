import React, { useState } from "react";
import { Row, Col, Typography, Card } from "antd";
import QueueAnim from "rc-queue-anim";
import SignInForm from "./SignInForm";
import SignUp from "./SignUp";
import Waiting from "./Waiting";
import "./Login.css";

const Login = ({ isLogged }) => {
  const { Title, Text } = Typography;
  /* 0: SignIn, 1: SignUp */
  const [signOption, setSignOption] = useState(0);

  return (
    <>
      <Row className="main-container">
        <Col xl={10} lg={8} md={24} sm={24} xs={24}>
          <QueueAnim
            style={{ width: "100%", height: "100%" }}
            appear
            type={["left", "right"]}
            leaveReverse
          >
            <div className="box welcome" key="logo">
              <Row>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <div className="box">
                        <img
                          key="img"
                          src="/logotipo.png"
                          href="/"
                          alt="LOGO"
                          style={{ height: "50px" }}
                        />
                        <Text
                          style={{
                            color: "#fff",
                            marginLeft: 8,
                            fontFamily: "Tahoma",
                            fontSize: 45,
                          }}
                        >
                          rpa-manager
                        </Text>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="box">
                        <Title style={{ color: "#fff" }} level={5}>
                          INNOVATION SOFTTEK {new Date().getFullYear()}
                        </Title>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </QueueAnim>
        </Col>
        <Col xl={14} lg={16} md={24} sm={24} xs={24}>
          <div className="box">
            <Card
              style={{ borderRadius: 10 }}
              className="loginCard shadowCardLogin"
            >
              {isLogged ? (
                <div className="login-container" key="signin">
                  <Waiting />
                </div>
              ) : (
                <QueueAnim
                  appear
                  type={["right", "left"]}
                  leaveReverse
                  // The default anim duration is 450 milliseconds
                  // so 480 milliseconds on the first anim is just
                  // to ensure they don't overlap or show at the
                  // same time on screen making it a big card
                  delay={[480, 0]}
                >
                  {signOption === 0 ? (
                    <div className="login-container" key="signin">
                      <SignInForm setSignOption={setSignOption} />
                    </div>
                  ) : (
                    <div className="login-container" key="signup">
                      <SignUp setSignOption={setSignOption} />
                    </div>
                  )}
                </QueueAnim>
              )}
            </Card>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Login;
