import React from "react";
import CreateHEB from "./CreateHEB";
import CreateABANCA from "./CreateABANCA";
import CreatePRUEBA from "./CreatePRUEBA";
import CreateLALA from "./CreateLALA";
import CreateSocofar from "./CreateSocofar";
import CreateWalmart from "./CreateWalmart";
import CreateGeneric from "./CreateGeneric";

const CreateIdeaHandler = (props) => {
  switch (props.userInfo.companyId) {
    case "82Xt7pXfGf8YE9TcvfuN":
      return (
        <CreateABANCA
          userInfo={props.userInfo}
          updateRefresh={props.updateRefresh}
          ideas={props.ideas}
          company={props.company}
        />
      );
    case "inRd27ObTby2qbkKnUZF":
      return <CreateHEB userInfo={props.userInfo} company={props.company} />;
    case "-M_7VSTEJsr47BtGYwMd":
      return <CreateLALA userInfo={props.userInfo} company={props.company} />;
    case "-M_7W4FZAjWbxNafNrZr":
      return (
        <CreateSocofar userInfo={props.userInfo} company={props.company} />
      );
    case "-MsqQHal5rIzJDZ4QerN":
      return (
        <CreateWalmart userInfo={props.userInfo} company={props.company} />
      );
    case "-MTl9kEJK3-w7DMms9Cn":
      return (
        <CreatePRUEBA
          userInfo={props.userInfo}
          updateRefresh={props.updateRefresh}
          ideas={props.ideas}
          company={props.company}
        />
      );
    case "-MzpQYhPdDZ4_nwTbQX3":
      return (
        <CreateGeneric userInfo={props.userInfo} company={props.company} />
      );
    case "-Mx6wI9HtBohzQkC-0oc":
    case "-Mx6x17ZoGHSD8PnSjBY":
      return <div />;
    default:
      return <></>;
  }
};

export default CreateIdeaHandler;
