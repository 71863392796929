import { updateIdeaStatus } from "../../../../../../db/firedataCalls";

export const approveButton = (idea, ideas) => {
  updateIdeaStatus(idea, 3);
  return "Petición aprobada exitosamente";
};

export const rejectButton = (idea) => {
  updateIdeaStatus(idea, 4);
  return "Petición rechazada";
};
