import React from "react";
import {
  Row,
  Col,
  Form,
  Checkbox,
  Space,
  Button,
  Card,
  Typography,
} from "antd";
import CalculatorJson from "./calculator.json";

const { Item } = Form;

const calculator = CalculatorJson.home;

const environmentQuestions = [
  {
    key: "environment_subtitle1",
    question: calculator.environment_subtitle1,
    options: [
      { value: "office" },
      { value: "desktop" },
      { value: "web" },
      { value: "citrix" },
      { value: "terminal" },
      { value: "sap" },
    ],
  },
  { key: "externalSystems", question: calculator.external_system },
  { key: "requiredChanges", question: calculator.system_changes },
];

const initialFormValues = {
  externalSystems: false,
  requiredChanges: false,
  office: false,
  desktop: false,
  web: false,
  citrix: false,
  terminal: false,
  sap: false,
};

const Environment = ({ nextStep, resetAll }) => {
  const [form] = Form.useForm();

  const onFinish = () => {
    nextStep();
  };

  return (
    <Card
      type="inner"
      title={
        <Typography.Title level={5} className="primary-color">
          {calculator.environment_title}
        </Typography.Title>
      }
    >
      <Row>
        <Form
          name="environment"
          labelCol={12}
          layout="vertical"
          size="large"
          form={form}
          initialValues={initialFormValues}
          onFinish={onFinish}
        >
          <Row gutter={[16, 16]}>
            {environmentQuestions.map((question) =>
              question.options ? (
                <Col span={24} key={question.key}>
                  <Row gutter={[16, 0]}>
                    <Col span={24}>{question.question}</Col>
                    {question.options.map(({ value }) => (
                      <Col span={24} offset={1} key={value}>
                        <Item valuePropName="checked" name={value}>
                          <Checkbox>{calculator[value]}</Checkbox>
                        </Item>
                      </Col>
                    ))}
                  </Row>
                </Col>
              ) : (
                <Col span={24} key={question.key}>
                  <Space size={"middle"}>
                    {question.question}
                    <Item name={question.key} valuePropName="checked">
                      <Checkbox />
                    </Item>
                  </Space>
                </Col>
              )
            )}
            <Col span={24}>
              <Row justify="end">
                <Button
                  htmlType="submit"
                  shape="round"
                  className="button-submit-secondary submit-fixed submit-secondary-spaced"
                  onClick={resetAll}
                >
                  Cancelar
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  shape="round"
                  className="button-submit submit-fixed"
                >
                  Siguiente
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Row>
    </Card>
  );
};

export default Environment;
