import React, { useState, useEffect } from "react";
import { Row, Col, Button, Tag, Divider, Table, Typography } from "antd";
import "../../../../../App.css";
import BotsModal from "./BotsModal";

const LALABots = (props) => {
  const [botStatus, setBotStatus] = useState([]);
  const [bots, setBots] = useState([]);
  const [botDrawer, setBotDrawer] = useState({
    visible: false,
    botKey: null,
    editing: false,
  });

  useEffect(() => {
    function selectStage(activities) {
      let stages = [];
      Object.values(props.company.activities).forEach((activity) => {
        stages.push({
          label: activity,
          value: activity,
        });
      });
      for (let i = stages.length - 1; i >= 0; i--) {
        if (activities.find((activity) => activity.stage === stages[i].value)) {
          return stages[i].value;
        }
      }
    }

    setBotStatus(props.company.botStatus);
    let tempBots = props.idea.bots ? props.idea.bots : [];
    let stageActivities = [];
    let activities = props.idea.activities ? props.idea.activities : [];
    tempBots.forEach((element, index) => {
      stageActivities = activities.filter(
        (activity) => activity.bot === element.key
      );
      let stage = "";
      if (stageActivities.length === 0) {
        stage = "Nothing";
      } else if (stageActivities.find(({ status }) => status === 1)) {
        stage = selectStage(
          stageActivities.filter(({ status }) => status === 1)
        );
      } else if (stageActivities.find(({ status }) => status === 2)) {
        stage = selectStage(
          stageActivities.filter(({ status }) => status === 2)
        );
      }
      tempBots[index].stage = stage;
    });
    setBots(tempBots);
  }, [props.idea, props.company, props.idea.bots, props.userInfo]);

  const botsTableCols = [
    {
      title: "Bot",
      dataIndex: "name",
      render: (text, record) => {
        return (
          <Typography.Paragraph
            style={{ padding: "13px 13px 6px 6px", cursor: "pointer" }}
            onClick={() => {
              setBotDrawer({
                visible: true,
                botKey: record.key,
                editing: true,
              });
            }}
          >
            <Typography.Link>{record.name}</Typography.Link>
          </Typography.Paragraph>
        );
      },
      fixed: "left",
      width: 200,
    },
    {
      title: "Stage",
      dataIndex: "stage",
      render: (text) => {
        return (
          <Tag color="default" style={{ width: "90%", textAlign: "center" }}>
            {text.toUpperCase()}
          </Tag>
        );
      },
      width: 120,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => {
        let color;
        color =
          text === 0
            ? "processing"
            : text === 1
            ? "success"
            : text === 3
            ? "red"
            : "default";
        return (
          botStatus && (
            <>
              <Tag color={color} style={{ width: "90%", textAlign: "center" }}>
                {botStatus[text].toUpperCase()}
              </Tag>
            </>
          )
        );
      },
      width: 130,
    },
    {
      title: "ID Bot",
      dataIndex: "idBot",
      width: 150,
    },
    {
      title: "Process ID",
      dataIndex: "idProcess",
      width: 100,
    },
    {
      title: "RPA ID",
      dataIndex: "idRPA",
      width: 80,
    },
    {
      title: "Tool",
      dataIndex: "tool",
      render: (text, record) => {
        let string = "";
        let tools = text.length;
        let counter = 1;
        text.forEach((t) => {
          let tool = props.company.botTools.find(({ id }) => id === t);
          if (counter < tools) {
            string += tool.name + " - ";
            counter++;
          } else string += tool.name;
        });
        return string;
      },
      width: 150,
    },
    {
      title: "Mon",
      dataIndex: "monday",
      width: 40,
    },
    {
      title: "Tue",
      dataIndex: "tuesday",
      width: 40,
    },
    {
      title: "Wed",
      dataIndex: "wednesday",
      width: 40,
    },
    {
      title: "Thu",
      dataIndex: "thursday",
      width: 40,
    },
    {
      title: "Fri",
      dataIndex: "friday",
      width: 40,
    },
    {
      title: "Sat",
      dataIndex: "saturday",
      width: 40,
    },
    {
      title: "Sun",
      dataIndex: "sunday",
      width: 40,
    },
    {
      title: "Month days",
      dataIndex: "month",
      width: 50,
    },
    {
      title: "Execution Type",
      dataIndex: "execution",
      render: (text, record) => {
        let exe = props.company.botExecution.find(({ id }) => id === text);
        return exe.name;
      },
      width: 130,
    },
    {
      title: "In Use License",
      dataIndex: "license",
      render: (text, record) => {
        return text
          ? text === 1
            ? "Licencia de ejecución 1"
            : text === 2
            ? "Licencia de ejecución 2"
            : "N/A"
          : "N/A";
      },
      width: 180,
    },
    {
      title: "(DEV) Complexity",
      dataIndex: "devComp",
      render: (text, record) => {
        let color =
          text === 1 ? "processing" : text === 2 ? "warning" : "error";
        return (
          <Tag color={color} style={{ width: "80%", textAlign: "center" }}>
            {text === 1 ? "Baja" : text === 2 ? "Media" : "Alta"}
          </Tag>
        );
      },
      width: 150,
    },
    {
      title: "(EXE) Complexity",
      dataIndex: "exeComp",
      render: (text, record) => {
        let color =
          text === 1 ? "processing" : text === 2 ? "warning" : "error";
        return (
          <Tag color={color} style={{ width: "80%", textAlign: "center" }}>
            {text === 1 ? "Baja" : text === 2 ? "Media" : "Alta"}
          </Tag>
        );
      },
      width: 150,
    },
    {
      title: "Execution time (min)",
      dataIndex: "duration",
      width: 80,
    },
    {
      title: "Start & End Alert",
      dataIndex: "alertSE",
      width: 140,
    },
    {
      title: "Incidence Alert",
      dataIndex: "alert",
      render: (text, record) => {
        return text ? "Sí" : "No";
      },
      width: 130,
    },
  ];

  return (
    <Row gutter={[10, 20]}>
      <Col span={24}>
        {props.idea.bots && bots && (
          <>
            <Divider>Bot List</Divider>
            <Table
              columns={botsTableCols}
              dataSource={bots}
              bordered
              scroll={{ x: "max-content" }}
            />
          </>
        )}
        <Divider>
          <Button
            onClick={() => {
              setBotDrawer({ visible: true, botKey: null, editing: false });
            }}
          >
            Add New Bot
          </Button>
        </Divider>

        <BotsModal
          bots={bots}
          idea={props.idea}
          botDrawer={botDrawer}
          setBotDrawer={setBotDrawer}
          company={props.company}
        />
      </Col>
    </Row>
  );
};
export default LALABots;
