import React from "react";
import { Form, Input, Button,  Typography, Space } from "antd";
import { useTranslation } from "react-i18next";
import { loginUser, loginUserMicrosoft } from "../../db/firedataCalls";
import { UserOutlined, WindowsOutlined } from "@ant-design/icons";
import "./Login.css";

const SignInForm = ({ setSignOption }) => {
  const [loginForm] = Form.useForm();
  const { t } = useTranslation();
  const { Title } = Typography;

  const onFinish = (values) => {
    loginUser(values).catch((error) => console.error("Error"));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const loginLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Title level={2}>Sign In</Title>
      <Form
        form={loginForm}
        {...loginLayout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        requiredMark={false}
      >
        <Space direction="vertical" style={{ width: "100%" }} size="middle">
          <Form.Item
            label={t("login.form.username.label")}
            name="username"
            rules={[
              {
                required: true,
                message: t("login.form.username.message"),
              },
            ]}
          >
            <Input autoComplete="" prefix={<UserOutlined />} size="large" />
          </Form.Item>
          <Form.Item
            label={t("login.form.password.label")}
            name="password"
            rules={[
              {
                required: true,
                message: t("login.form.password.message"),
              },
            ]}
          >
            <Input.Password autoComplete="" size="large" />
          </Form.Item>
          <Form.Item
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <Button
              className="button-submit"
              shape="round"
              size="large"
              type="primary"
              htmlType="submit"
            >
              Log in
            </Button>
          </Form.Item>
          <Form.Item
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <Button
              className="button-submit"
              shape="round"
              size="large"
              type="primary"
              onClick={() => loginUserMicrosoft(false)}
            >
              <span>
                <WindowsOutlined
                  style={{
                    verticalAlign: "middle",
                    marginRight: 8,
                  }}
                />
                Log in with Microsoft
              </span>
            </Button>
          </Form.Item>
          <Form.Item
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <Button
              className="button-sign-up"
              size="large"
              type="link"
              onClick={() => setSignOption(1)}
            >
              <span>Request Access</span>
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </Space>
  );
};

export default SignInForm;
