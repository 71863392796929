import React, { useState, useEffect } from "react";
import { Row, Col, Table, Drawer } from "antd";
import moment from "moment";
import CompanyIdeaDetails from "../../../Details/CompanyIdeaDetails";
import InputSearch from "../../InputSearch";

const Deployed = (props) => {
  const [deployedData, setDeployedData] = useState({
    ideas: [],
    deployedBenefit: 0,
    totalIdeas: 0,
    failedExecution: 0,
    failedExecutionCost: 0,
    deployedData: { x: "", y: 0 },
  });

  const [showDatailsDrawer, setShowDetailsDrawer] = useState({
    idea: null,
    visible: false,
    idIdea: null,
  });

  const handleRowClick = (row) => {
    setShowDetailsDrawer({ visible: true, idea: row, idIdea: row.id });
  };

  const onClose = () => {
    setShowDetailsDrawer({ idea: null, visible: false, idIdea: null });
  };

  const [tableState, setTableState] = useState({
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
  });

  const handleOnKeyChange = (key) => {
    if (!key) setTableState({ data: deployedData.ideas });

    key = key.toLowerCase();
    const filteredData = deployedData.ideas.filter(
      (entry) =>
        entry.name.toLowerCase().includes(key) ||
        (entry.id && entry.id.toString().includes(key)) ||
        (entry.workCategory && entry.workCategory.toLowerCase().includes(key))
    );

    setTableState({ data: filteredData });
  };

  const columns = [
    /*{
      title: "ID",
      dataIndex: "id",
    },*/
    {
      title: "Name",
      dataIndex: "name",
      render: (name, record) => (
        <span
          style={{ padding: "13px 13px 6px 6px", cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {name}
        </span>
      ),
    },
    {
      title: "Area",
      dataIndex: "workCategory",
    },
    {
      title: "Estimated Benefit",
      /* dataIndex: "estimatedBenefit", */
      /* dataIndex: "ahorroExterno", */
      dataIndex: "monthlyBenefit",
      render: (text, record) =>
        text
          ? "$ " + record.benefit.toFixed(2)
          : record.executionTime.toFixed(2) + " hours",
    },
  ];

  useEffect(() => {
    if (props.ideas) {
      let ideas = [];
      props.ideas.forEach(
        (doc) =>
          doc.status === 2 &&
          ideas.push({
            ...doc,
          })
      );
      let benefit = ideas.reduce((acc, idea) => acc + idea.estimatedBenefit, 0);

      let filteredIdeas = ideas.filter(
        (idea) =>
          idea.createdDate.seconds >= moment().subtract(3, "months").unix()
      );
      let months = new Map();
      filteredIdeas.forEach((idea) =>
        months.set(
          moment.unix(idea.createdDate.seconds).month(),
          moment.unix(idea.createdDate.seconds).format("MMMM")
        )
      );

      let chartData = [];

      for (const [key, value] of months.entries()) {
        chartData.push({
          x: value,
          y: filteredIdeas
            .filter(
              (idea) => moment.unix(idea.createdDate.seconds).month() === key
            )
            .reduce((acc, idea) => acc + idea.estimatedBenefit, 0),
        });
      }

      ideas = ideas.filter((i) => i.productionStatus !== -1);
      setTableState({ data: ideas });
      setDeployedData({
        ideas: ideas,
        deployedBenefit: "€" + benefit,
        totalIdeas: ((ideas.length / props.ideas.length) * 100).toFixed(),
        failedExecution: 0,
        failedExecutionCost: 0,
        chartData: chartData,
      });
      if (showDatailsDrawer.visible) {
        let index = props.ideas.findIndex(
          (idea) => idea.id === showDatailsDrawer.idIdea
        );
        setShowDetailsDrawer({
          visible: true,
          idea: props.ideas[index],
          idIdea: props.ideas[index].id,
        });
      }
    }
  }, [props.ideas, showDatailsDrawer.idIdea, showDatailsDrawer.visible]);

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col /* span={14} */>
          <div>
            <Drawer
              style={{ background: "white" }}
              theme="light"
              width="100vw"
              onClose={onClose}
              visible={showDatailsDrawer.visible}
              footer={
                <div
                  style={{
                    textAlign: "right",
                  }}
                ></div>
              }
              destroyOnClose
            >
              <CompanyIdeaDetails
                company={props.company}
                userInfo={props.userInfo}
                onCloseDrawer={onClose}
                companyName={props.companyName}
                mappings={props.mappings}
                idea={showDatailsDrawer.idea}
              />
            </Drawer>
            <Row>
              <Col span={6}>
                <InputSearch handleOnKeyChange={handleOnKeyChange} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Table
                  pagination={{
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                  }}
                  bordered
                  columns={columns}
                  dataSource={tableState.data}
                  rowKey={(record) => record.id}
                  size="small"
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row></Row>
      <br />
      <br />
      <br />
    </>
  );
};

export default Deployed;
