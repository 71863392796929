import React, { useState, useEffect } from "react";
import { Input } from "antd";
import { useTranslation } from "react-i18next";

const { Search } = Input;

const InputSearch = (props) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState("");

  useEffect(() => {
    props.handleOnKeyChange(query);
    // eslint-disable-next-line
  }, [query]);

  return (
    <>
      <Search
        placeholder={t("buttons.search")}
        size="large"
        onChange={(event) => setQuery(event.target.value)}
      />
      <br />
      <br />
    </>
  );
};

export default InputSearch;
