import { updateIdeaStatus } from "../../../../../../db/firedataCalls";
import firebase from "../../../../../../server/firebase";

export const approveButton = (idea, ideas) => {
  if (idea.priority >= 0 && idea.priority <= 5) {
    if (idea.peticionCER) {
      if (idea.asignee) {
        updateIdeaStatus(idea, 2);
        setIdABANCA(idea, ideas);
        return "IdeaApproved";
      } else {
        return "CERNoAssigned";
      }
    } else {
      updateIdeaStatus(idea, 2);
      setIdABANCA(idea, ideas);
      return "IdeaApproved";
    }
  } else return "NoPriorityJustifyIdea";
};

const setIdABANCA = async (idea, ideas) => {
  if (!idea.idABANCA) {
    if (idea.companyId === "82Xt7pXfGf8YE9TcvfuN") {
      firebase
        .database()
        .ref()
        .child("ideas")
        .orderByChild("idABANCA")
        .limitToLast(1)
        .once("child_added")
        .then((doc) => {
          firebase
            .database()
            .ref()
            .child("ideas")
            .child(idea.id)
            .update({
              idABANCA: Number(doc.val().idABANCA) + 1,
            });
        });
    } else {
      firebase
        .database()
        .ref()
        .child("ideas")
        .child(idea.id)
        .update({
          idABANCA:
            Number(
              ideas.reduce(
                (max, idea) => (idea.idABANCA > max ? idea.idABANCA : max),
                0
              )
            ) + 1,
        });
    }
  }
};
