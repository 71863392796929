import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Radio,
  Select,
  Button,
  DatePicker,
  InputNumber,
  message,
} from "antd";
import { useTranslation } from "react-i18next";
import { createIdea } from "../../../../../db/firedataCalls";
import firebase from "firebase/app";
import moment from "moment";
const { Option } = Select;

const JustificacionIdea = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [showAlternatives, setShowAlternatives] = useState(
    props.ideaData ? props.ideaData.tiempoDedicado : false
  );
  const [showFechaMaxima, setShowFechaMaxima] = useState(
    props.ideaData ? props.ideaData.fechaMax : false
  );
  const [format] = useState("DD/MM/YYYY");

  useEffect(() => {
    if (props.ideaData) {
      form.setFieldsValue({ ...props.ideaData });
      if (props.ideaData.fechaMax) {
        form.setFieldsValue({ hasFechaMax: true });
        form.setFieldsValue({ fechaMax: moment(getDateString(), format) });
      } else {
        form.setFieldsValue({ hasFechaMax: false });
      }
    }
    // eslint-disable-next-line
  }, [props.ideaData]);

  const successUpdate = () => {
    message.success(t("Información guardada correctamente"));
  };

  const errorUpdate = () => {
    message.error(t("Hubo un error al guardar la información"));
  };

  const getDateString = () => {
    return new Date(
      props.ideaData.fechaMax._seconds * 1000 +
        props.ideaData.fechaMax._nanoseconds / 1000000
    ).toLocaleDateString("en-GB");
  };

  const onFinish = async (values) => {
    let prioridad = [];
    let volumetria, tiempo, periodicidad;
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    if (showAlternatives) {
      values.tiempoTarea = null;
    } else {
      values.tiempoTareaVolumen = null;
      values.volumenEstimado = null;
    }

    if (!showFechaMaxima) {
      values.fechaMax = null;
    } else {
      values.fechaMax = {
        _nanoseconds: firebase.firestore.Timestamp.fromDate(
          values.fechaMax.toDate()
        ).nanoseconds,
        _seconds: firebase.firestore.Timestamp.fromDate(
          values.fechaMax.toDate()
        ).seconds,
      };
    }

    /*
        PRIORIDAD
        ARRAY[
            0 -> Ahorro de Costes: 0 o 5 dependiendo si la tarea la realiza una empresa externa, si la realiza el usuario interno o una empresa externa 
            1 -> Riesgo Operacional: 0 o 5 dependiendo si la tarea tiene impacto en riesgo operacional 
            2 -> Liberación de Carga Administrativa: siendo 5 (150h al mes)
            3 -> Fecha máxima: si tiene fecha máxima un 5 y sino un 0 
            4 -> Tiempo de Parametrización: lo pondríamos nosotros siendo 5 muy fácil y 0 muy difícil 
        ]
        */
    console.log("Success:", values);
    if (values.ahorroCostes) prioridad[0] = 0;
    else prioridad[0] = 5;
    if (values.riesgoOperacion) prioridad[1] = 5;
    else prioridad[1] = 0;
    if (values.fechaMax) prioridad[3] = 5;
    else prioridad[3] = 0;
    prioridad[4] = values.parametrizacion;
    volumetria =
      !values.volumenEstimado || values.volumenEstimado < 1
        ? 1
        : values.volumenEstimado;
    tiempo = values.tiempoTarea
      ? values.tiempoTarea
      : values.tiempoTareaVolumen;
    switch (values.periodicidad) {
      case "diaria":
        periodicidad = 1;
        break;
      case "cada hora":
        periodicidad = 24;
        break;
      case "cada 12 horas":
        periodicidad = 2;
        break;
      case "cada 18 horas":
        periodicidad = 24 / 18;
        break;
      case "semanal":
        periodicidad = 1 / 4;
        break;
      case "cada 2 semanas":
        periodicidad = 1 / 8;
        break;
      case "mensual":
        periodicidad = 1 / 22;
        break;
      case "trimestral":
        periodicidad = 1 / 66;
        break;
      default:
        periodicidad = 0;
        break;
    }
    let total = Number(
      ((volumetria * (tiempo / 60) * periodicidad * 22 * 5) / 150).toFixed(2)
    );
    prioridad[2] = total > 5 ? 5 : total;
    let priority = (prioridad.reduce(reducer) / 5).toFixed(2);
    values["priority"] = Number(priority);
    values["id"] = props.ideaData.id;
    console.log(
      `${volumetria} * (${tiempo} / 60) * ${periodicidad} * 22) * 5 / 150 = ${prioridad[2]}\n Prioridad: ${priority}`,
      prioridad
    );
    delete values["hasFechaMax"];
    createIdea(values)
      .then(() => {
        successUpdate();
      })
      .catch((error) => {
        errorUpdate();
        console.error(error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    errorUpdate();
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      form={form}
      name="justificacion"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row gutter={[40, 20]}>
        <Col span={24}>
          <Form.Item
            colon={false}
            name="periodicidad"
            labelAlign="left"
            label="La tarea la realizas"
            rules={[
              {
                required: true,
                message: t("Campo requerido"),
              },
            ]}
          >
            <Select style={{ width: 300 }}>
              <Option key="0" value="diaria">
                {"Diariamente"}
              </Option>
              <Option key="1" value="cada hora">
                {"Cada hora"}
              </Option>
              <Option key="2" value="cada 12 horas">
                {"Cada 12 horas"}
              </Option>
              <Option key="3" value="cada 18 horas">
                {"Cada 18 horas"}
              </Option>
              <Option key="4" value="semanal">
                {"Semanal"}
              </Option>
              <Option key="5" value="cada 2 semanas">
                {"Cada 2 semanas"}
              </Option>
              <Option key="6" value="mensual">
                {"Mensual"}
              </Option>
              <Option key="7" value="trimestral">
                {"Trimestral"}
              </Option>
              <Option key="8" value="cada 6 meses">
                {"Cada 6 meses"}
              </Option>
              <Option key="9" value="anual">
                {"Anual"}
              </Option>
              <Option key="10" value="puntual">
                {"Puntual"}
              </Option>
              <Option key="11" value="one-off">
                {"One-Off"}
              </Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[40, 20]}>
        <Col span={24}>
          <Form.Item
            colon={false}
            name="tiempoDedicado"
            labelAlign="left"
            label="¿El tiempo que dedicas a la tarea es variable dependiendo del volumen archivos/correos/expedientes/documentos, etc que procesas en cada ejecución?"
            rules={[
              {
                required: true,
                message: t("Campo requerido"),
              },
            ]}
          >
            <Radio.Group
              style={{ width: 300 }}
              onChange={(e) => setShowAlternatives(e.target.value)}
            >
              <Radio.Button key="si" value={true}>
                Sí
              </Radio.Button>
              <Radio.Button key="no" value={false}>
                No
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[40, 20]} hidden={!showAlternatives}>
        <Col span={24}>
          <Form.Item
            colon={false}
            name="volumenEstimado"
            labelAlign="left"
            label="¿Cuál es el volumen estimado de archivos/correos/expedientes/documentos/etc cada vez que realizas la tarea?"
            rules={[
              {
                required: !showAlternatives ? false : true,
              },
            ]}
          >
            <InputNumber placeholder="Unidades" style={{ width: 300 }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[40, 20]} hidden={showAlternatives}>
        <Col span={24}>
          <Form.Item
            colon={false}
            name="tiempoTarea"
            labelAlign="left"
            label="¿Cuánto tiempo te lleva realizar la tarea?"
            rules={[
              {
                required: showAlternatives ? false : true,
                message: t("Campo requerido"),
              },
            ]}
          >
            <InputNumber placeholder="Minutos" style={{ width: 300 }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[40, 20]} hidden={!showAlternatives}>
        <Col span={24}>
          <Form.Item
            colon={false}
            name="tiempoTareaVolumen"
            labelAlign="left"
            label="¿Cuánto tiempo te lleva realizar la tarea por cada archivo/correo/expediente/documento/etc?"
            rules={[
              {
                required: !showAlternatives ? false : true,
                message: t("Campo requerido"),
              },
            ]}
          >
            <InputNumber placeholder="Minutos" style={{ width: 300 }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[40, 20]}>
        <Col span={24}>
          <Form.Item
            name="riesgoOperacion"
            colon={false}
            labelAlign="left"
            label="¿Un error en la ejecución de la tarea o en la no ejecución de la misma puede llevar a un posible Riesgo Operacional?"
            rules={[
              {
                required: true,
                message: t("Campo requerido"),
              },
            ]}
          >
            <Radio.Group style={{ width: 300 }}>
              <Radio.Button value={true}>Sí</Radio.Button>
              <Radio.Button value={false}>No</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[40, 20]}>
        <Col span={24}>
          <Form.Item
            name="ahorroCostes"
            labelAlign="left"
            colon={false}
            label="¿La tarea robotizada la realizas tú o esta soporta en una empresa externa?"
            rules={[
              {
                required: true,
                message: t("Campo requerido"),
              },
            ]}
          >
            <Radio.Group style={{ width: 300 }}>
              <Radio.Button value={true}>Dentro de la empresa</Radio.Button>
              <Radio.Button value={false}>En una empresa externa</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[40, 20]}>
        <Col span={24}>
          <Form.Item
            name="hasFechaMax"
            labelAlign="left"
            colon={false}
            label="¿La tarea tiene fecha límite?"
            rules={[
              {
                required: true,
                message: t("Campo requerido"),
              },
            ]}
          >
            <Radio.Group
              onChange={(e) => {
                setShowFechaMaxima(e.target.value);
              }}
              style={{ width: 300 }}
            >
              <Radio.Button value={true}>Sí</Radio.Button>
              <Radio.Button value={false}>No</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[40, 20]} hidden={!showFechaMaxima}>
        <Col span={24}>
          <Form.Item
            name="fechaMax"
            labelAlign="left"
            colon={false}
            label="Fecha Límite:"
            rules={[
              {
                required: showFechaMaxima,
                message: t("Campo requerido"),
              },
            ]}
          >
            <DatePicker format={format} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[40, 20]}>
        <Col span={24}>
          <Form.Item
            name="parametrizacion"
            labelAlign="left"
            colon={false}
            label="Parametrización, la petición se considera:"
            rules={[
              {
                required: true,
                message: t("Campo requerido"),
              },
            ]}
          >
            <Select style={{ width: 300 }}>
              <Option key="0" value={5}>
                Muy Fácil
              </Option>
              <Option key="1" value={4}>
                Fácil
              </Option>
              <Option key="2" value={3}>
                Regular
              </Option>
              <Option key="3" value={2}>
                Por encima de lo normal
              </Option>
              <Option key="4" value={1}>
                Difícil
              </Option>
              <Option key="5" value={0}>
                Muy Difícil
              </Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[40, 20]}>
        <Col span={24}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t("Guardar")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default JustificacionIdea;
