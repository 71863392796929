import React, { useState, useEffect } from "react";
// import firebase from "../../../../../server/firebase";
import moment from "moment";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Divider,
  Table,
  Select,
  DatePicker,
  Empty,
  Popconfirm,
  Tooltip,
  Avatar,
  Tag,
  InputNumber,
  Space,
  Upload,
  message,
  Modal,
  List,
} from "antd";
import {
  InboxOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import GenericAssigned from "./GenericAssigned";
import GenericComments from "./GenericComments";
import "../../../../../App.css";
import { updateGenericIdea } from "../../../../../db/genericFiredataCalls";
import {
  uploadFile,
  removeFile,
  getDownloadURL,
} from "../../../../../db/firedataCalls";
import { presetPalettes } from "@ant-design/colors";
const clone = require("rfdc")();
const { Dragger } = Upload;

export const statusColor = {
  0: "default",
  1: "warning",
  2: "success",
  3: "purple",
  4: "geekblue",
};

export const statusText = {
  0: "PENDING",
  1: "IN PROGRESS",
  2: "COMPLETED",
  3: "ON HOLD",
  4: "UAT",
};

const GenericMaintenance = (props) => {
  const [bots, setBots] = useState([]);
  const [maintenanceList, setMaintenanceList] = useState([]);
  const [botFilterList, setBotFilterList] = useState([]);
  const [tableChange, setTableChange] = useState(false);
  const dateFormat = "DD/MM/YYYY";
  const [form] = Form.useForm();
  const [deliveredBots, setDeliveredBots] = useState([]);
  const [users, setUsers] = useState([]);
  const [editStatus, setEditStatus] = useState({ id: -1, bot: -1 });
  const [UATMaintenances, setUATMaintenances] = useState([]);
  const [filesList, setFilesList] = useState([]);
  const [showFiles, setShowFiles] = useState({
    visible: false,
    maintenance: null,
  });

  useEffect(() => {
    let cloneBots = [];
    let tempList = [];
    let tempBotFilterList = [];
    let colors = [];
    let users = [];
    if (props.company.users) users = Object.values(props.company.users);
    if (props.idea.bots) {
      cloneBots = clone(props.idea.bots);
      setBots(cloneBots);
      setDeliveredBots(cloneBots.filter((bot) => bot.status === 1));
      cloneBots.forEach((bot) => {
        if (bot.maintenance) {
          tempBotFilterList.push({ text: bot.name, value: bot.key });
          bot.maintenance.forEach((maintenance) => {
            tempList.push({
              id: maintenance.id,
              botKey: bot.key,
              botName: bot.name,
              startDate: maintenance.startDate,
              endDate: maintenance.endDate,
              realStartDate: maintenance.realStartDate
                ? maintenance.realStartDate
                : null,
              realEndDate: maintenance.realEndDate
                ? maintenance.realEndDate
                : null,
              type: maintenance.type,
              description: maintenance.description,
              maintenance: maintenance,
              asignee: maintenance.asignee,
              comments: maintenance.comments,
              priority: maintenance.priority,
              status: maintenance.status,
              createdDate: maintenance.createdDate,
              createdBy: maintenance.createdBy,
              schdStartDate: maintenance.schdStartDate
                ? maintenance.schdStartDate
                : null,
              schdEndDate: maintenance.schdEndDate
                ? maintenance.schdEndDate
                : null,
              attachedFiles: maintenance.attachedFiles,
            });
          });
        }
      });
      Object.values(presetPalettes).forEach((colorSet) => {
        colors.push(colorSet.primary);
      });
      let res = users.length / colors.length;
      if (res > 1) {
        for (let a = 0; a < Math.round(res); a++) {
          colors.push(...colors);
        }
      }
      /* users = [
        {
          companyId: "QrEN5Mn25tnNhdPET601",
          email: "juane.soto@softtek.com",
          firstName: "Juan",
          lastName1: "Soto",
          lastName2: "Luévano",
          rol: "User",
          status: "Active",
          uid: "7FVuUhLFfBUVXOfDeEo3UQvax7k1",
          username: "juane.soto@softtek.com",
        },
        {
          companyId: "QrEN5Mn25tnNhdPET601",
          email: "luis.luna@softtek.com",
          firstName: "Luis",
          lastName1: "de Luna",
          lastName2: "Saucedo",
          rol: "User",
          status: "Active",
          uid: "7FVuUFfBUVXOfDeEo3UQvax7k1",
          username: "luis.luna@softtek.com",
        },
        {
          companyId: "QrEN5Mn25tnNhdPET601",
          email: "javier.gutierrez@softtek.com",
          firstName: "Javier",
          lastName1: "Gutiérrez",
          lastName2: "Pérez",
          rol: "User",
          status: "Active",
          uid: "7FVuUhLFfVXOfDeEo3UQvax7k1",
          username: "javier.gutierrez@softtek.com",
        },
        {
          companyId: "QrEN5Mn25tnNhdPET601",
          email: "jorge.bohorquez@softtek.com",
          firstName: "Jorge",
          lastName1: "Bohorquez",
          lastName2: "Ibarra",
          rol: "User",
          status: "Active",
          uid: "7FVuUhLFfBUVXOfDeEovax7k1",
          username: "jorge.bohorquez@softtek.com",
        },
      ]; */
      users = users.map((user, index) => ({ ...user, color: colors[index] }));
      setUsers(users);
      setBotFilterList(tempBotFilterList);
      setMaintenanceList(tempList);
    }
  }, [props.idea.bots, props.company.users]);

  const onFinish = async (values) => {
    let user = null;
    let tempBots = props.idea.bots;
    let index = bots.findIndex(({ key }) => key === values.bot);
    let maintenanceFiles = [];
    let maintenanceIndex = 1;
    message.loading({
      content: "Creating Maintenance...",
      key: "updateCreateMsg",
      duration: 0,
    });
    values.asignee = values.asignee.map((asignee) => {
      user = users.find(({ uid }) => uid === asignee);
      return {
        name: returnName(user),
        email: user.email,
        uid: user.uid,
      };
    });
    let creator = {
      name: returnName(props.userInfo),
      email: props.userInfo.email,
      uid: props.userInfo.uid,
    };
    if (tempBots[index].maintenance)
      maintenanceIndex = tempBots[index].maintenance.push({
        createdBy: creator,
        createdDate: { seconds: moment().unix(), nanoseconds: 0 },
        schdStartDate: { seconds: values.date[0].unix(), nanoseconds: 0 },
        schdEndDate: { seconds: values.date[1].unix(), nanoseconds: 0 },
        endDate: { seconds: values.date[1].unix(), nanoseconds: 0 },
        description: values.description,
        id:
          Number(
            tempBots[index].maintenance.reduce(
              (max, maintenance) =>
                maintenance.id > max ? maintenance.id : max,
              0
            )
          ) + 1,
        asignee: values.asignee,
        type: values.type,
        priority: values.priority,
        status: 0,
      });
    else
      tempBots[index].maintenance = [
        {
          createdBy: creator,
          createdDate: { seconds: moment().unix(), nanoseconds: 0 },
          schdStartDate: { seconds: values.date[0].unix(), nanoseconds: 0 },
          schdEndDate: { seconds: values.date[1].unix(), nanoseconds: 0 },
          description: values.description,
          id: 1,
          asignee: values.asignee,
          priority: values.priority,
          status: 0,
          type: values.type,
        },
      ];
    for (let i = 0; i < filesList.length; i++) {
      await uploadFile(
        "generic_maintenances/" +
          props.idea.id +
          "/" +
          tempBots[index].key +
          "/" +
          tempBots[index].maintenance[maintenanceIndex - 1].id,
        filesList[i].originFileObj
      )
        .then((uploadResponse) => {
          if (uploadResponse === null) {
            console.error("no se pudo subir el archivo");
            message.error(
              `File ${filesList[i].originFileObj.name} failed to upload.`
            );
          } else {
            maintenanceFiles.push({ name: uploadResponse.name });
          }
        })
        .catch((error) => {
          console.error(error);
          message.error(`File upload failed.`);
        });
    }
    tempBots[index].maintenance[maintenanceIndex - 1].attachedFiles =
      maintenanceFiles;
    await updateGenericIdea(props.idea.id, { bots: tempBots })
      .then(async () => {
        await sendMail(tempBots[index].name, values.type, values.asignee);
        message
          .success({
            content: "Maintenance Created!",
            key: "updateCreateMsg",
            duration: 3,
          })
          .then(() => message.info("Email sent to Assigned Team", 2.5));
        form.resetFields();
        setBots(tempBots);
        setFilesList([]);
        setTableChange(false);
      })
      .catch((error) => {
        message.error({
          content: "There was an error creating the maintenance.",
          key: "updateCreateFail",
          duration: 3,
        });
        console.log(error);
      });
    // await firebase
    //   .database()
    //   .ref()
    //   .child("ideas")
    //   .child(props.idea.id)
    //   .update({ bots: tempBots });
    // message
    //   .success({
    //     content: "Maintenance Created!",
    //     key: "updateCreateMsg",
    //     duration: 3,
    //   })
    //   .then(() => message.info("Email sent to Assigned Team", 2.5));
    // form.resetFields();
    // setBots(tempBots);
    // setFilesList([]);
    // setTableChange(false);
  };

  const updateBots = async () => {
    message.loading({
      content: "Updating Maintenances...",
      key: "updateSaveMsg",
      duration: 0,
    });
    await updateGenericIdea(props.idea.id, { bots: bots })
      .then(async () => {
        await sendUATMail();
        setTableChange(false);
        await message
          .success({
            content: "Maintenances Updated!",
            key: "updateSaveMsg",
            duration: 2.5,
          })
          .then(() => {
            if (UATMaintenances.length > 0)
              message.info("Email sent to UAT Maintenance creator", 2.5);
          });
        setUATMaintenances([]);
      })
      .catch((error) => {
        message.error({
          content: "There was an error updating the activity.",
          key: "updateSaveFail",
          duration: 3,
        });
        console.log(error);
      });
    // await sendUATMail();
    // await firebase
    //   .database()
    //   .ref()
    //   .child("ideas")
    //   .child(props.idea.id)
    //   .update({ bots: bots });
    // setTableChange(false);
    // await message
    //   .success({
    //     content: "Maintenances Updated!",
    //     key: "updateSaveMsg",
    //     duration: 2.5,
    //   })
    //   .then(() => {
    //     if (UATMaintenances.length > 0)
    //       message.info("Email sent to UAT Maintenance creator", 2.5);
    //   });
    // setUATMaintenances([]);
  };

  const sendUATMail = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    UATMaintenances.forEach(async (maintenance) => {
      if (maintenance.createdBy) {
        var raw =
          '{\n SenderName: "RPA-MANAGER",\n SenderMail:"noreply@rpamanager.online",\n To: ["' +
          maintenance.createdBy.email +
          '"],\n Subject:"Mantenimiento pasa a UAT"' +
          ' ,\n isHTML: "true",\n HTMLBody:"<h3>Le informamos que su mantenimiento creado del tipo ' +
          maintenance.type +
          ", del bot: " +
          maintenance.botName +
          ", pertenece al Proceso " +
          props.idea.name +
          ", ahora cuenta con el estatus de UAT." +
          "</h3>\n" +
          '",\n}';

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        await fetch(
          "https://automationplatform.azurewebsites.net/api/mailnotification",
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => console.log(result))
          .catch((error) => console.log("error", error));
      }
    });
  };

  const sendMail = async (botName, maintenanceType, maintenanceAsignees) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    maintenanceAsignees.forEach(async (receiver) => {
      var raw =
        '{\n SenderName: "RPA-MANAGER",\n SenderMail:"noreply@rpamanager.online",\n To: ["' +
        receiver.email +
        '"],\n Subject:"Nuevo Mantenimiento Asignado"' +
        ' ,\n isHTML: "true",\n HTMLBody:"<h3>Le informamos que el bot: ' +
        botName +
        " tiene un nuevo mantenimiento del tipo " +
        maintenanceType +
        ", mismo que le ha sido asignado." +
        "</h3>\n" +
        '",\n}';

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      await fetch(
        "https://automationplatform.azurewebsites.net/api/mailnotification",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    });
  };

  const onDateChange = (obj, value, type) => {
    let changeType = "";
    switch (type) {
      case 0:
        changeType = "schdStartDate";
        break;
      case 1:
        changeType = "schdEndDate";
        break;
      default:
        changeType = "realEndDate";
        break;
    }
    let tempBots = bots;
    let botIndex = tempBots.findIndex(({ key }) => key === obj.botKey);
    let mainIndex = tempBots[botIndex].maintenance.findIndex(
      ({ id }) => id === obj.id
    );
    if (value) {
      obj[changeType] = {
        seconds: value.unix(),
        nanoseconds: 0,
      };
      tempBots[botIndex].maintenance[mainIndex][changeType] = obj[changeType];
    } else {
      tempBots[botIndex].maintenance[mainIndex][changeType] = null;
      obj[changeType] = null;
    }
    setTableChange(true);
    setBots(tempBots);
  };

  const onPriorityChange = (obj, value) => {
    let tempBots = bots;
    let botIndex = tempBots.findIndex(({ key }) => key === obj.botKey);
    let mainIndex = tempBots[botIndex].maintenance.findIndex(
      ({ id }) => id === obj.id
    );
    if (value) {
      tempBots[botIndex].maintenance[mainIndex].priority = value;
    }
    setTableChange(true);
    setBots(tempBots);
  };

  const statusClick = (obj) => {
    setEditStatus({ id: obj.id, bot: obj.botKey });
  };

  const onStatusChange = (nextStatus, obj, e) => {
    e.preventDefault();
    let tempBots = bots;
    let botIndex = tempBots.findIndex(({ key }) => key === obj.botKey);
    let mainIndex = tempBots[botIndex].maintenance.findIndex(
      ({ id }) => id === obj.id
    );
    let tempUAT = UATMaintenances;
    tempBots[botIndex].maintenance[mainIndex].status = nextStatus;
    obj.status = nextStatus;
    if (nextStatus === 4) {
      if (
        !UATMaintenances.includes((maintenance) => maintenance.id === obj.id)
      ) {
        tempUAT.push({
          id: obj.id,
          botName: obj.botName,
          createdBy: obj.createdBy,
          type: obj.type,
        });
      }
    } else {
      tempUAT = tempUAT.filter((maintenance) => maintenance.id !== obj.id);
    }
    if (nextStatus === 2 || nextStatus === 4) {
      if (!tempBots[botIndex].maintenance[mainIndex].realEndDate) {
        tempBots[botIndex].maintenance[mainIndex].realEndDate = {
          seconds: moment().unix(),
          nanoseconds: 0,
        };
        obj.realEndDate = { seconds: moment().unix(), nanoseconds: 0 };
      }
    } else {
      if (tempBots[botIndex].maintenance[mainIndex].realEndDate) {
        tempBots[botIndex].maintenance[mainIndex].realEndDate = null;
        obj.realEndDate = null;
      }
    }

    setBots(tempBots);
    setEditStatus({ id: -1, bot: -1 });
    setUATMaintenances(tempUAT);
    setTableChange(true);
  };

  const deleteMaintenance = async (record) => {
    let tempBots = bots;
    let index = bots.findIndex(({ key }) => key === record.botKey);
    let maintenance = tempBots[index].maintenance.filter(
      ({ id }) => id !== record.maintenance.id
    );
    message.loading({
      content: "Deleting Maintenance...",
      key: "updateDelManMsg",
      duration: 0,
    });
    for (
      let i = 0;
      i < record.attachedFiles ? record.attachedFiles.length : 0;
      i++
    ) {
      let response = await removeFile(
        "generic_maintenances/" +
          props.idea.id +
          "/" +
          record.botKey +
          "/" +
          record.id,
        record.attachedFiles[i].name
      );
      if (!response) console.log("Error al eliminar un archivo");
    }
    tempBots[index].maintenance = maintenance;
    setBots(tempBots);
    setTableChange(false);
    await message.success({
      content: "Maintenance Successfully Deleted!",
      key: "updateDelManMsg",
      duration: 2.5,
    });
    updateBots();
  };

  const returnName = (user) => {
    return (
      user.firstName +
      " " +
      (user.lastName1 &&
        user.lastName1 + (user.lastName2 && " " + user.lastName2))
    );
  };

  const getChildren = () => {
    return (
      <Select
        placeholder={"Asign to someone"}
        mode="multiple"
        optionLabelProp="label"
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {users.map((user) => (
          <Select.Option
            key={user.uid}
            value={user.uid}
            label={returnName(user)}
          >
            <Tooltip placement="right" title={user.email}>
              <Avatar style={{ backgroundColor: user.color }}>
                {user.firstName[0].toUpperCase() +
                  user.lastName1[0].toUpperCase()}
              </Avatar>
              {" " + returnName(user)}
            </Tooltip>
          </Select.Option>
        ))}
      </Select>
    );
  };

  const columns = [
    {
      title: "Maintenance Type",
      dataIndex: "type",
      onFilter: (value, record) => (record.type = value),
      filters: props.company.maintenanceType.map((type) => {
        return { text: type, value: type };
      }),
      fixed: "left",
      width: 180,
    },
    {
      title: "Bot Name",
      dataIndex: "botName",
      filters: [...botFilterList],
      filterMultiple: false,
      onFilter: (value, record) => record.botKey === value,
      fixed: "left",
      width: 180,
    },
    {
      title: "Comments",
      render: (text, record) => {
        return (
          <GenericComments
            record={record}
            setPopoverRecord={() => false}
            users={users}
            userInfo={props.userInfo}
            idea={props.idea}
            bots={bots}
            origin={"GenericMaintenance"}
          />
        );
      },
      width: 210,
    },
    {
      title: "Team",
      render: (text, record) => {
        return (
          <GenericAssigned
            record={record}
            setPopoverRecord={() => false}
            users={users}
            userInfo={props.userInfo}
            idea={props.idea}
            bots={bots}
            origin={"GenericMaintenance"}
          />
        );
      },
      width: 150,
    },
    {
      title: "Schedule Start Date",
      dataIndex: "schdStartDate",
      render: (text, record) => {
        return (
          <DatePicker
            defaultValue={text && moment(moment.unix(text.seconds), dateFormat)}
            format={dateFormat}
            onChange={(date) => onDateChange(record, date, 0)}
            bordered={false}
            suffixIcon={null}
          />
        );
      },
      sorter: {
        compare: (a, b) =>
          a.schdStartDate
            ? b.schdStartDate
              ? a.schdStartDate.seconds - b.schdStartDate.seconds
              : a.schdStartDate.seconds - 0
            : b.schdStartDate
            ? 0 - b.schdStartDate.seconds
            : 0 - 0,
        multiple: 2,
      },
      width: 130,
    },
    {
      title: "Schedule End Date",
      dataIndex: "schdEndDate",
      render: (text, record) => {
        return (
          <DatePicker
            defaultValue={text && moment(moment.unix(text.seconds), dateFormat)}
            format={dateFormat}
            onChange={(date) => onDateChange(record, date, 1)}
            bordered={false}
            suffixIcon={null}
            allowClear={false}
          />
        );
      },
      sorter: {
        compare: (a, b) =>
          a.schdEndDate
            ? b.schdEndDate
              ? a.schdEndDate.seconds - b.schdEndDate.seconds
              : a.schdEndDate.seconds - 0
            : b.schdEndDate
            ? 0 - b.schdEndDate.seconds
            : 0 - 0,
        multiple: 2,
      },
      width: 130,
    },
    {
      title: "End Date",
      dataIndex: "realEndDate",
      render: (text, record) => {
        return record.status === 2 || record.status === 4 ? (
          <DatePicker
            defaultValue={text && moment(moment.unix(text.seconds), dateFormat)}
            format={dateFormat}
            onChange={(date) => onDateChange(record, date, 3)}
            bordered={false}
            suffixIcon={null}
            allowClear={false}
          />
        ) : (
          <span style={{ textAlign: "center", paddingLeft: "12px" }}>
            Not finished
          </span>
        );
      },
      sorter: {
        compare: (a, b) =>
          a.realEndDate
            ? b.realEndDate
              ? a.realEndDate.seconds - b.realEndDate.seconds
              : a.realEndDate.seconds - 0
            : b.realEndDate
            ? 0 - b.realEndDate.seconds
            : 0 - 0,
        multiple: 2,
      },
      width: 130,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => {
        return editStatus.id === record.id &&
          editStatus.bot === record.botKey ? (
          <>
            <Space direction="vertical">
              <Tag
                color={statusColor[0]}
                style={{ cursor: "pointer", width: "90%", textAlign: "center" }}
                onClick={(e) => onStatusChange(0, record, e)}
              >
                {statusText[0]}
              </Tag>
              <Tag
                color={statusColor[1]}
                style={{ cursor: "pointer", width: "90%", textAlign: "center" }}
                onClick={(e) => onStatusChange(1, record, e)}
              >
                {statusText[1]}
              </Tag>
              <Tag
                color={statusColor[3]}
                style={{ cursor: "pointer", width: "90%", textAlign: "center" }}
                onClick={(e) => onStatusChange(3, record, e)}
              >
                {statusText[3]}
              </Tag>
              <Tag
                color={statusColor[4]}
                style={{ cursor: "pointer", width: "90%", textAlign: "center" }}
                onClick={(e) => onStatusChange(4, record, e)}
              >
                {statusText[4]}
              </Tag>
              <Tag
                color={statusColor[2]}
                style={{ cursor: "pointer", width: "90%", textAlign: "center" }}
                onClick={(e) => onStatusChange(2, record, e)}
              >
                {statusText[2]}
              </Tag>
            </Space>
          </>
        ) : (
          <>
            {text === 2 ? (
              <Tag color={statusColor[text]} style={{ textAlign: "center" }}>
                {statusText[text]}
              </Tag>
            ) : (
              <Tag
                color={statusColor[text]}
                onClick={() => statusClick(record)}
                style={{ cursor: "pointer", width: "90%", textAlign: "center" }}
              >
                {statusText[text]}
              </Tag>
            )}
          </>
        );
      },
      onFilter: (value, record) => record.status === value,
      filters: [
        { text: "PENDING", value: 0 },
        { text: "IN PROGRESS", value: 1 },
        { text: "UAT", value: 4 },
        { text: "ON HOLD", value: 3 },
        { text: "COMPLETED", value: 2 },
      ],
      width: 120,
    },
    {
      title: "Priority",
      dataIndex: "priority",
      render: (text, record) => {
        return (
          <InputNumber
            style={{ width: "80%" }}
            bordered={false}
            defaultValue={text}
            min={1}
            max={10}
            onChange={(number) => onPriorityChange(record, number)}
          />
        );
      },
      sorter: (a, b) => a.priority - b.priority,
      onFilter: (value, record) => record.priority === value,
      filters: [
        { text: "1", value: 1 },
        { text: "2", value: 2 },
        { text: "3", value: 3 },
        { text: "4", value: 4 },
        { text: "5", value: 5 },
        { text: "6", value: 6 },
        { text: "7", value: 7 },
        { text: "8", value: 8 },
        { text: "9", value: 9 },
        { text: "10", value: 10 },
      ],
      width: 100,
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      render: (text, record) =>
        text ? `${moment.unix(text.seconds).format(dateFormat)}` : "",
      width: 130,
      sorter: {
        compare: (a, b) =>
          a.createdDate
            ? b.createdDate
              ? a.createdDate.seconds - b.createdDate.seconds
              : a.createdDate.seconds - 0
            : b.createdDate
            ? 0 - b.createdDate.seconds
            : 0 - 0,
        multiple: 2,
      },
    },
    { title: "Description", dataIndex: "description", width: 600 },
    {
      title: "Files",
      dataIndex: "attachedFiles",
      render: (text, record) => {
        return (
          <Button
            type="primary"
            ghost
            style={{ width: "100%" }}
            disabled={!text}
            onClick={() => setShowFiles({ visible: true, maintenance: record })}
          >
            View Files
          </Button>
        );
      },
      fixed: "right",
      width: 100,
    },
    {
      title: "Actions",
      render: (text, record) => {
        return (
          <Popconfirm
            placement="bottom"
            title={"Delete maintenance?"}
            okText={"Yes"}
            cancelText="No"
            onConfirm={() => deleteMaintenance(record)}
          >
            <Button
              type="link"
              style={{ width: "50px", borderLeft: "0px", paddingLeft: "0px" }}
            >
              {"Delete"}
            </Button>
          </Popconfirm>
        );
      },
      fixed: "right",
      width: 100,
    },
  ];

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const closeModal = () => {
    setShowFiles({ visible: false, maintenance: null });
  };

  const downloadAttachedFile = async (fileName) => {
    let url = await getDownloadURL(
      "generic_maintenances/" +
        props.idea.id +
        "/" +
        showFiles.maintenance.botKey +
        "/" +
        showFiles.maintenance.id,
      fileName
    );
    window.open(url, "_blank");
  };

  const deleteAttachedFile = async (fileName) => {
    let tempBots = bots;
    let botIndex = bots.findIndex(
      ({ key }) => key === showFiles.maintenance.botKey
    );
    let mainIndex = tempBots[botIndex].maintenance.findIndex(
      ({ id }) => id === showFiles.maintenance.id
    );
    message.loading({
      content: "Deleting File...",
      key: "updateDeleteMsg",
      duration: 0,
    });
    let response = await removeFile(
      "generic_maintenances/" +
        props.idea.id +
        "/" +
        showFiles.maintenance.botKey +
        "/" +
        showFiles.maintenance.id,
      fileName
    );
    if (response) {
      let index = showFiles.maintenance.attachedFiles.findIndex(
        (file) => file.name === fileName
      );
      tempBots[botIndex].maintenance[mainIndex].attachedFiles.splice(index, 1);
      let tempRecord = showFiles.maintenance;
      tempRecord.attachedFiles =
        tempBots[botIndex].maintenance[mainIndex].attachedFiles;
      setBots(tempBots);
      setShowFiles({ visible: true, maintenance: tempRecord });
      await message.success({
        content: "File successfully deleted!",
        key: "updateDeleteMsg",
        duration: 1.5,
      });
      updateBots();
    } else message.error("No se pudo eliminar el archivo");
  };

  const uploadSingleFile = async (file) => {
    let tempBots = bots;
    let botIndex = bots.findIndex(
      ({ key }) => key === showFiles.maintenance.botKey
    );
    let mainIndex = tempBots[botIndex].maintenance.findIndex(
      ({ id }) => id === showFiles.maintenance.id
    );
    message.loading({
      content: "Uploading File...",
      key: "updateUploadMsg",
      duration: 0,
    });
    await uploadFile(
      "generic_maintenances/" +
        props.idea.id +
        "/" +
        showFiles.maintenance.botKey +
        "/" +
        showFiles.maintenance.id,
      file
    )
      .then(async (uploadResponse) => {
        if (uploadResponse === null) {
          console.error("no se pudo subir el archivo");
          message.error(`File upload failed.`);
        } else {
          if (tempBots[botIndex].maintenance[mainIndex].attachedFiles)
            tempBots[botIndex].maintenance[mainIndex].attachedFiles.push({
              name: uploadResponse.name,
            });
          else
            tempBots[botIndex].maintenance[mainIndex].attachedFiles = [
              { name: uploadResponse.name },
            ];
          let tempRecord = showFiles.maintenance;
          tempRecord.attachedFiles =
            tempBots[botIndex].maintenance[mainIndex].attachedFiles;
          setBots(tempBots);
          setShowFiles({ visible: true, maintenance: tempRecord });
          await message.success({
            content: "File successfully uploaded!",
            key: "updateUploadMsg",
            duration: 1.5,
          });
          updateBots();
        }
      })
      .catch((error) => {
        console.error(error);
        message.error(`File upload failed.`);
      });
  };

  return props.idea.bots ? (
    <>
      {props.idea.bots && bots && (
        <>
          <Modal
            title="Maintenance Files"
            visible={showFiles.visible}
            onCancel={closeModal}
            style={{ overflow: "auto" }}
            width={700}
            footer={null}
          >
            {showFiles.maintenance && (
              <>
                <List
                  itemLayout="horizontal"
                  dataSource={
                    showFiles.maintenance.attachedFiles
                      ? showFiles.maintenance.attachedFiles
                      : []
                  }
                  pagination={{ pageSize: 5 }}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        title={
                          <Button
                            type="link"
                            onClick={() => downloadAttachedFile(item.name)}
                          >
                            {item.name}
                          </Button>
                        }
                      />
                      <List.Item.Meta
                        title={
                          <Popconfirm
                            placement="bottom"
                            title={"Delete file?"}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => deleteAttachedFile(item.name)}
                          >
                            <Button
                              type="link"
                              danger
                              icon={<DeleteOutlined />}
                              size="large"
                            />
                          </Popconfirm>
                        }
                      />
                    </List.Item>
                  )}
                ></List>
                <Upload
                  name="file"
                  listType="text"
                  action={(file) => uploadSingleFile(file)}
                  customRequest={dummyRequest}
                  fileList={[]}
                >
                  <Button type="primary">
                    <UploadOutlined /> Upload File
                  </Button>
                </Upload>
              </>
            )}
          </Modal>
          <Row gutter={[40, 20]}>
            <Divider>Maintenance List</Divider>
          </Row>
          <Row gutter={[40, 20]}>
            <Table
              columns={columns}
              dataSource={maintenanceList}
              style={{ width: "100%" }}
              rowKey={(record) =>
                record.maintenance.id +
                "_" +
                record.botKey +
                (record.realEndDate ? record.realEndDate.seconds : null)
              }
              pagination={{ pageSize: 7 }}
              scroll={{ x: "max-content" }}
            />
          </Row>
          <Row gutter={[40, 20]} justify={"end"}>
            <Popconfirm
              placement="bottomLeft"
              title={"Save Changes?"}
              onConfirm={updateBots}
              okText={"Yes"}
              cancelText={"Cancel"}
              disabled={!tableChange}
            >
              <Button disabled={!tableChange} type="primary">
                {"Save Changes"}
              </Button>
            </Popconfirm>
          </Row>
        </>
      )}
      <Divider>New Maintenance</Divider>

      <Form
        onFinish={onFinish}
        form={form}
        name="New Bot"
        initialValues={{ priority: 1 }}
      >
        <Row gutter={[10, 20]} justify="center">
          <Col span={10}>
            <Row gutter={[10, 20]}>
              <Col span={12}>
                <Form.Item
                  name={"type"}
                  label={"Type"}
                  labelAlign={"left"}
                  rules={[
                    {
                      required: true,
                      message: "Required field",
                    },
                  ]}
                >
                  <Select placeholder={"Select a type of maintenance"}>
                    {props.company.maintenanceType.map((type) => {
                      return (
                        <Select.Option key={type} value={type}>
                          {type}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={"priority"}
                  label={"Priority"}
                  labelAlign={"left"}
                  rules={[
                    {
                      required: true,
                      message: "Required field",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} min={1} max={10} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={"bot"}
                  label={"Bot name"}
                  labelAlign={"left"}
                  rules={[
                    {
                      required: true,
                      message: "Required field",
                    },
                  ]}
                >
                  <Select placeholder={"Select a bot"}>
                    {deliveredBots.map((bot) => {
                      return (
                        <Select.Option key={bot.key} value={bot.key}>
                          {bot.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={"date"}
                  label={"Schedule date"}
                  labelAlign={"left"}
                  rules={[
                    {
                      required: true,
                      message: "Required field",
                    },
                  ]}
                >
                  <DatePicker.RangePicker
                    format={dateFormat}
                    defaultValue={[moment()]}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={"asignee"}
                  label={"Team"}
                  labelAlign={"left"}
                  rules={[
                    {
                      required: true,
                      message: "Required field",
                    },
                  ]}
                >
                  {getChildren()}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={"description"}
                  label={"Description"}
                  labelAlign={"left"}
                  rules={[{ required: true, message: "Required field" }]}
                >
                  <Input.TextArea
                    placeholder={"Maintenance description"}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name={"files"} label={"Files"} labelAlign={"left"}>
                  <Dragger
                    name="files"
                    multiple={true}
                    listType="text"
                    customRequest={dummyRequest}
                    fileList={filesList}
                    onChange={(info) => {
                      const { status } = info.file;
                      if (status !== "uploading") {
                        //console.log(info.file, info.fileList);
                      }
                      if (status === "done") {
                        setFilesList(info.fileList);
                      } else if (status === "error") {
                        message.error(`${info.file.name} file upload failed.`);
                      } else if (status === "removed") {
                        setFilesList(info.fileList);
                      }
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint">
                      Files attached to this Maintenance
                    </p>
                  </Dragger>
                </Form.Item>
              </Col>
              <Form.Item>
                <Button
                  type={"primary"}
                  style={{ marginLeft: "6px" }}
                  htmlType="submit"
                >
                  Add Maintenance
                </Button>
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  ) : (
    <Empty description={"No Bots"} />
  );
};

export default GenericMaintenance;
