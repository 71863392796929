import React, { useEffect, useState } from "react";
import firebase from "../../../../../server/firebase";
import {
  Form,
  Button,
  Input,
  Card,
  Avatar,
  Popover,
  Typography,
  List,
  Comment,
  Col,
} from "antd";
import { EditOutlined, SendOutlined } from "@ant-design/icons";
import { blue } from "@ant-design/colors";
import { updateActivitiesHEB } from "../../../../../db/firedataCalls";
import moment from "moment";
import "../../../../../App.css";
const dateFormat = "DD/MM/YYYY";
const { TextArea } = Input;

const WalmartComments = (props) => {
  const [comments, setComments] = useState([]);
  const [lastComments, setLastComments] = useState([]);
  const [editComment, setEditComment] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    if (props.record.comments) {
      let tempComments = props.record.comments;
      tempComments = tempComments.map((comment, index) => {
        let user = props.users.find(({ uid }) => comment.userId === uid);
        return {
          author: comment.userName,
          content: <p>{comment.comment}</p>,
          datetime: moment(moment.unix(comment.date.seconds))
            .format(dateFormat)
            .toLocaleString(),
          avatar: (
            <Avatar
              style={{
                backgroundColor: props.users.find(({ uid }) => user.uid === uid)
                  .color,
              }}
            >
              {user.firstName[0].toUpperCase() +
                user.lastName1[0].toUpperCase()}
            </Avatar>
          ),
          text: comment.comment,
          index: index,
          userId: comment.userId,
        };
      });
      let spliced = tempComments;
      setComments(tempComments);
      setLastComments(spliced.slice(Math.max(spliced.length - 2, 0)));
    } else {
      setComments([]);
      setLastComments([]);
    }
  }, [props.record, props.idea, props.userInfo, props.users, props.stage]);

  const saveComment = (values) => {
    switch (props.origin) {
      case "WalmartActivities":
        addComment(values);
        break;
      case "ActivitiesSummary":
        addCommentActTable(values);
        break;
      case "WalmartMaintenance":
        addCommentMaintenance(values);
        break;
      case "MaintenancesSummary":
        addCommentMaintTable(values);
        break;
      default:
        console.log("no programado jeje");
        break;
    }
  };

  const addCommentMaintTable = async (values) => {
    const comment = {
      comment: values.comment,
      userId: props.userInfo.uid,
      userName: returnName(props.userInfo),
      date: { seconds: moment.utc().unix(), nanoseconds: 0 },
    };
    let tempBots = props.ideas.find(
      ({ id }) => id === props.record.ideaId
    ).bots;
    let index = tempBots.findIndex(({ key }) => key === props.record.bot.key);
    let maintenanceIndex = tempBots[index].maintenance.findIndex(
      ({ id }) => id === props.record.id
    );
    if (editComment) {
      let tempComments = props.record.comments;
      tempComments[editComment.index].comment = values.comment;
      tempBots[index].maintenance[maintenanceIndex].comments = tempComments;
      setEditComment(null);
    } else {
      if (!tempBots[index].maintenance[maintenanceIndex].comments)
        tempBots[index].maintenance[maintenanceIndex].comments = [];
      tempBots[index].maintenance[maintenanceIndex].comments.push(comment);
    }

    await firebase
      .database()
      .ref()
      .child("ideas")
      .child(props.record.ideaId)
      .update({ bots: tempBots });
    form.resetFields();
  };

  const addCommentMaintenance = async (values) => {
    const comment = {
      comment: values.comment,
      userId: props.userInfo.uid,
      userName: returnName(props.userInfo),
      date: { seconds: moment.utc().unix(), nanoseconds: 0 },
    };
    let tempBots = props.bots;
    let index = props.bots.findIndex(({ key }) => key === props.record.botKey);
    let maintenanceIndex = tempBots[index].maintenance.findIndex(
      ({ id }) => id === props.record.maintenance.id
    );
    if (editComment) {
      let tempComments = props.record.comments;
      tempComments[editComment.index].comment = values.comment;
      tempBots[index].maintenance[maintenanceIndex].comments = tempComments;
      setEditComment(null);
    } else {
      if (!tempBots[index].maintenance[maintenanceIndex].comments)
        tempBots[index].maintenance[maintenanceIndex].comments = [];
      tempBots[index].maintenance[maintenanceIndex].comments.push(comment);
    }

    await firebase
      .database()
      .ref()
      .child("ideas")
      .child(props.idea.id)
      .update({ bots: tempBots });
    form.resetFields();
  };

  const addCommentActTable = (values) => {
    const comment = {
      comment: values.comment,
      userId: props.userInfo.uid,
      userName: returnName(props.userInfo),
      date: { seconds: moment.utc().unix(), nanoseconds: 0 },
    };
    let idea = props.ideas.find(({ id }) => id === props.record.ideaId);
    let stages = idea.activities;
    let index = stages.findIndex(({ key }) => key === props.record.key);
    let tempActivities = stages[index].activities;
    let activityIndex = tempActivities.findIndex(
      ({ id }) => id === props.record.id
    );
    if (editComment) {
      let tempComments = props.record.comments;
      tempComments[editComment.index].comment = values.comment;
      tempActivities[activityIndex].comments = tempComments;
      setEditComment(null);
    } else {
      if (!tempActivities[activityIndex].comments)
        tempActivities[activityIndex].comments = [];
      tempActivities[activityIndex].comments.push(comment);
    }

    stages[index].activities = tempActivities;
    updateActivitiesHEB(props.record.ideaId, stages);
    form.resetFields();
  };

  const addComment = (values) => {
    const comment = {
      comment: values.comment,
      userId: props.userInfo.uid,
      userName: returnName(props.userInfo),
      date: { seconds: moment.utc().unix(), nanoseconds: 0 },
    };
    let stages = props.idea.activities;
    let index = stages.findIndex(({ key }) => key === props.stage.key);
    let tempActivities = stages[index].activities;
    let activityIndex = tempActivities.findIndex(
      ({ id }) => id === props.record.id
    );
    if (editComment) {
      let tempComments = props.record.comments;
      tempComments[editComment.index].comment = values.comment;
      tempActivities[activityIndex].comments = tempComments;
      setEditComment(null);
    } else {
      if (!tempActivities[activityIndex].comments)
        tempActivities[activityIndex].comments = [];
      tempActivities[activityIndex].comments.push(comment);
    }

    stages[index].activities = tempActivities;
    updateActivitiesHEB(props.idea.id, stages);
    form.resetFields();
  };

  const returnName = (user) => {
    return (
      user.firstName +
      " " +
      (user.lastName1 &&
        user.lastName1 + (user.lastName2 && " " + user.lastName2))
    );
  };

  const Content = () => (
    <Card
      style={{ width: 350 }}
      size="small"
      bordered={false}
      bodyStyle={{ maxHeight: "400px", overflow: "auto" }}
    >
      {comments && (
        <List
          dataSource={comments}
          header={
            <Col>
              {comments && comments.length > 1
                ? `${comments.length} replies`
                : "1 Reply"}
              {comments.length > 2 &&
                comments !== lastComments &&
                !editComment && (
                  <Button type="link" onClick={() => setLastComments(comments)}>
                    load others...
                  </Button>
                )}
            </Col>
          }
          itemLayout="horizontal"
          renderItem={(item) => {
            if (!editComment && lastComments.includes(item))
              return (
                <List.Item
                  actions={
                    props.userInfo.uid === item.userId && [
                      <EditOutlined
                        onClick={() => {
                          setEditComment(comments[item.index]);
                          form.setFieldsValue({ comment: item.text });
                        }}
                      />,
                    ]
                  }
                >
                  <Comment
                    author={item.author}
                    avatar={item.avatar}
                    content={item.content}
                    datetime={item.datetime}
                  />
                </List.Item>
              );
            else return null;
          }}
        />
      )}

      <Comment
        avatar={
          <Avatar
            style={{
              backgroundColor: props.users.find(
                ({ uid }) => props.userInfo.uid === uid
              ).color,
            }}
          >
            {props.userInfo.firstName[0].toUpperCase() +
              props.userInfo.lastName1[0].toUpperCase()}
          </Avatar>
        }
        content={
          <Form
            layout="inline"
            form={form}
            onFinish={(values) => saveComment(values)}
          >
            <Form.Item
              name="comment"
              rules={[
                {
                  required: true,
                  message: "Required field",
                },
              ]}
              style={{ width: "80%" }}
            >
              <TextArea
                style={{ borderRadius: "15px 30px 30px 5px" }}
                rows={2}
                onKeyDown={(e) =>
                  e.keyCode === 13 && saveComment(form.getFieldsValue())
                }
              />
            </Form.Item>
            <Form.Item>
              <Button
                icon={<SendOutlined />}
                htmlType="submit"
                type="link"
                shape="circle"
              />
            </Form.Item>
          </Form>
        }
      />
    </Card>
  );

  const commentsRender = () => (
    <Typography.Paragraph
      style={{ cursor: "pointer", color: blue[5] }}
      ellipsis={{ rows: 3, expandable: false }}
    >
      {comments.length > 0
        ? comments[comments.length - 1].text
        : "Be the first one to comment"}
    </Typography.Paragraph>
  );

  return (
    <Popover
      content={<Content />}
      placement="right"
      trigger="click"
      onVisibleChange={() => {
        setEditComment(null);
        form.resetFields();
      }}
    >
      {commentsRender()}
    </Popover>
  );
};

export default WalmartComments;
