import React, { useState } from "react";
import { Button } from "antd";
import ReactExport from "react-data-export";
import moment from "moment";

const ExcelDownload = (props) => {
  var [arrayExcel, setArrayExcel] = useState([]);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const getStatus = (idea) => {
    switch (idea.status) {
      case -1:
        return "Sin procesar";
      case 2:
        return "En progreso";
      case 3:
        if (idea.productionStatus) {
          return idea.productionStatus === -1 ? "Detenido" : "Finalizado";
        }
        return "En producción";
      case 4:
        return "Rechazada";
      default:
        return "Undefined";
    }
  };

  const getUserAndCode = (userUid) => {
    let user = Object.values(props.company.users).find(
      ({ uid }) => uid === userUid
    );
    let deletedUser = Object.values(props.company.deletedUsers).find(
      ({ uid }) => uid === userUid
    );
    return user
      ? user.firstName +
          " " +
          user.lastName1 +
          (user.lastName2 ? " " + user.lastName2 : "") +
          " (" +
          user.username +
          ")"
      : deletedUser
      ? deletedUser.firstName +
        " " +
        deletedUser.lastName1 +
        (deletedUser.lastName2 ? " " + deletedUser.lastName2 : "") +
        " (" +
        deletedUser.username +
        ")"
      : "";
  };

  const getPeriodicidad = (periodicidad) => {
    switch (periodicidad) {
      case "diaria":
        return "Diariamente";
      case "cada hora":
        return "Cada hora";
      case "cada 12 horas":
        return "Cada 12 horas";
      case "cada 18 horas":
        return "Cada 18 horas";
      case "semanal":
        return "Semanal";
      case "cada 2 semanas":
        return "Cada 2 semanas";
      case "mensual":
        return "Mensual";
      case "trimestral":
        return "Trimestral";
      case "cada 6 meses":
        return "Cada 6 meses";
      case "anual":
        return "Anual";
      case "puntual":
        return "Puntual";
      case "one-off":
        return "One-Off";
      default:
        return "Undefined";
    }
  };

  const getParametrizacion = (parametrizacion) => {
    switch (parametrizacion) {
      case 0:
        return "Muy Difícil";
      case 1:
        return "Difícil";
      case 2:
        return "Por encima de lo normal";
      case 3:
        return "Regular";
      case 4:
        return "Fácil";
      case 5:
        return "Muy Fácil";
      default:
        return "Undefined";
    }
  };

  const downloadData = async () => {
    var arrayExcel = [];
    props.data.forEach((idea) => {
      arrayExcel.push({
        idABANCA: idea.idABANCA,
        name: idea.name,
        dir_general: idea.dir_general,
        direccion: idea.direccion,
        status: getStatus(idea),
        description: idea.description,
        productionDate: idea.paseProduccion
          ? moment.unix(idea.paseProduccion.seconds).format("DD/MM/YYYY")
          : "",
        creator: ` ${idea.createdBy.firstName || "No capturado"} ${
          idea.createdBy.lastName1 || ""
        } ${idea.createdBy.lastName2 || ""} ${
          idea.createdBy.username ? " (" + idea.createdBy.username + ")" : ""
        }`,
        designer: idea.asignee
          ? getUserAndCode(idea.asignee)
          : "Sin responsable de diseño",
        operator: idea.operator
          ? getUserAndCode(idea.operator)
          : "Sin responsable de operación",
        taskType: idea.tipoTarea ? idea.tipoTarea : "",
        area: idea.area ? idea.area : "",
        periodicity: idea.periodicidad
          ? getPeriodicidad(idea.periodicidad)
          : "",
        time: idea.tiempoDedicado ? "Si" : "No",
        volume: idea.tiempoDedicado
          ? idea.volumenEstimado
            ? idea.volumenEstimado < 1
              ? 1
              : idea.volumenEstimado
            : 1
          : "N/A",
        volumeTime: idea.tiempoDedicado
          ? idea.tiempoTareaVolumen
            ? idea.tiempoTareaVolumen < 1
              ? 1
              : idea.tiempoTareaVolumen
            : 1
          : "N/A",
        duration: idea.tiempoDedicado
          ? "N/A"
          : idea.tiempoTarea
          ? idea.tiempoTarea < 1
            ? 1
            : idea.tiempoTarea
          : 1,
        risk: idea.riesgoOperacion ? "Si" : "No",
        realization: idea.ahorroCostes
          ? "Dentro de la empresa"
          : "En una empresa externa",
        limitDate: idea.fechaMax ? "Si" : "No",
        /*currentTask: idea.hasOwnProperty("tareaActualmente")
          ? idea.tareaActualmente
            ? "Si"
            : "No"
          : "-",*/
        parameter: idea.parametrizacion
          ? getParametrizacion(idea.parametrizacion)
          : "",
      });
    });
    setArrayExcel(arrayExcel);
    setTimeout(() => {
      var link = document.getElementById(props.id);
      link.click();
    }, 1000);
  };

  return (
    <>
      <Button
        onClick={() => {
          downloadData();
        }}
        style={{ color: "white", backgroundColor: "#009900" }}
      >
        Excel
      </Button>
      <ExcelFile
        element={
          <button id={props.id} style={{ display: "none" }}>
            Download Data
          </button>
        }
        filename={props.name}
      >
        <ExcelSheet key={"0"} data={arrayExcel} name={"Datos"}>
          <ExcelColumn label="ID" value="idABANCA" key={0} />
          <ExcelColumn label="Nombre" value="name" key={1} />
          <ExcelColumn label="Dirección General" value="dir_general" key={2} />
          <ExcelColumn label="Dirección" value="direccion" key={3} />
          <ExcelColumn label="Estatus" value="status" key={4} />
          <ExcelColumn label="Descripción" value="description" key={5} />
          <ExcelColumn
            label="Fecha Pase Producción"
            value="productionDate"
            key={6}
          />
          <ExcelColumn
            label="Responsable de creación"
            value="creator"
            key={7}
          />
          <ExcelColumn label="Responsable de diseño" value="designer" key={8} />
          <ExcelColumn
            label="Responsable de operación"
            value="operator"
            key={9}
          />
          <ExcelColumn label="Tipo de Tarea" value="taskType" key={10} />
          <ExcelColumn label="Área" value="area" key={11} />
          <ExcelColumn label="Periodicidad" value="periodicity" key={12} />
          <ExcelColumn label="Tiempo Variable" value="time" key={13} />
          <ExcelColumn
            label="Volumen estimado de archivos/correos/expedientes/documentos/etc cada vez que realizas la tarea"
            value="volume"
            key={14}
          />
          <ExcelColumn
            label="Tiempo de realización por cada archivo/correo/expediente/documento/etc"
            value="volumeTime"
            key={15}
          />
          <ExcelColumn
            label="Duración de la realización manual de la tarea"
            value="duration"
            key={16}
          />
          <ExcelColumn label="Riesgo Operacional" value="risk" key={17} />
          <ExcelColumn
            label="Realización interna o por empresa externa"
            value="realization"
            key={18}
          />
          <ExcelColumn
            label="¿La tarea tiene fecha de límite?"
            value="limitDate"
            key={19}
          />
          {/*<ExcelColumn
            label="¿Es una tarea que se realiza actualmente?"
            value="currentTask"
            key={20}
      />*/}
          <ExcelColumn label="Parametrización" value="parameter" key={21} />
        </ExcelSheet>
      </ExcelFile>
    </>
  );
};

export default ExcelDownload;
