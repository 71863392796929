import React, { useEffect } from "react";
import { ColumnLine } from "@antv/g2plot";
import moment from "moment";
import { useTranslation } from "react-i18next";

const IdeaVelocityChart = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    let results = props.chartData;
    let graphData = results.map((element) => {
      let date = moment.unix(element.createdDate.seconds);
      let period = `${date.format("MMMM")}-${date.year()}`;
      return { period: period, ideasBenefit: element.estimatedBenefit };
    });

    let formattedData = {};

    graphData.forEach((element) => {
      if (!formattedData[element.period]) {
        formattedData[element.period] = {
          count: 1,
          ideasBenefit: element.ideasBenefit,
        };
      } else {
        let count = formattedData[element.period].count;
        let ideasBenefit = formattedData[element.period].ideasBenefit;

        formattedData[element.period] = {
          count: count + 1,
          ideasBenefit:
            (element.ideasBenefit ? element.ideasBenefit : 0) + ideasBenefit,
        };
      }
    });

    const uvData = [];
    const transformData = [];

    Object.entries(formattedData).forEach((data) => {
      uvData.push({ time: data[0], ideasSubmitted: data[1]["count"] });
      transformData.push({
        time: data[0],
        totalSavings: data[1]["ideasBenefit"]?data[1]["ideasBenefit"]:0,
        name: t("dashboard.charts.labels.total_benefit"),
      });
    });
    console.log(transformData);

    const columnLine = new ColumnLine(
      document.getElementById("idea_velocity_chart"),
      {
        title: {
          visible: false,
          text: "Algun Titulo",
        },
        description: {
          visible: true,
          text: t("Últimos 6 meses"),
        },
        data: [uvData, transformData],
        xField: "time",
        yField: ["ideasSubmitted", "totalSavings"],
        lineSeriesField: "name",
        columnConfig: {
          label: {
            visible: true,
            formatter: (number) => number.toFixed(2),
          },
        },
        lineConfig: {
          point: {
            visible: true,
          },
          label: {
            visible: true,
          },
        },
        meta: {
          ideasSubmitted: {
            alias: t("dashboard.charts.labels.ideas_received"),
          },
          totalSavings: {
            alias: t("dashboard.charts.labels.total_benefit"),
          },
        },
      }
    );
    columnLine.render();
    //eslint-disable-next-line
  }, [props.chartData]);

  return <div id="idea_velocity_chart"></div>;
};

export default IdeaVelocityChart;
