import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Drawer } from "antd";
import moment from "moment";
import { Table, Tag, Badge } from "antd";
import "../../../../../../App.css";
import CompanyIdeaDetails from "../../../Details/CompanyIdeaDetails";
import { Resizable } from "react-resizable";
import {
  CloseCircleFilled,
  CheckCircleFilled,
  ScheduleTwoTone,
} from "@ant-design/icons";
import InputSearch from "../../InputSearch";
import { KanbanLayout } from "../../../../../CustomComponents/ABANCA/ActivitiesPanel";
import ExcelDownload from "../../../../../CustomComponents/ABANCA/ExcelDownload";

const MyAssigments = (props) => {
  const [inboxData, setInboxData] = useState(null);
  const [dirFilter, setDirFilter] = useState([]);
  const [dirGenFilter, setDirGenFilter] = useState([]);
  const [badgeNumber, setBadgeNumber] = useState(0);
  const priorities = {
    VERY_HIGH: "Muy alta",
    HIGH: "Alta",
    MEDIUM: "Media",
    LOW: "Baja",
    VERY_LOW: "Muy baja",
  };
  const [activitiesPanel, setActivitiesPanel] = useState(false);
  const [showDatailsDrawer, setShowDetailsDrawer] = useState({
    id: null,
    visible: false,
  });
  const [tipoTareaFilter, setTipoTareaFilter] = useState([]);

  useEffect(() => {
    var highlighted = [];
    if (props.company.todoConfig.boards) {
      props.company.todoConfig.boards.forEach((board) => {
        if (board.highlighted) {
          highlighted.push(board.value);
        }
      });
    }
    if (props.ideas) {
      let ideas = [];
      let direccion = [];
      let dir_general = [];
      let count = 0;
      props.ideas.forEach((doc) => {
        doc.status === 2 &&
          doc.asignee === props.userInfo.uid &&
          ideas.push({
            ...doc,
            id: doc.id,
          });
        doc.activities &&
          doc.activities.forEach((activity) => {
            if (activity.assignee === props.userInfo.uid) {
              if (highlighted.includes(activity.status)) {
                count++;
              }
            }
          });
      });
      props.userInfo.activities &&
        props.userInfo.activities.forEach((activity) => {
          if (highlighted.includes(activity.status)) {
            count++;
          }
        });
      setBadgeNumber(count);
      setInboxData(ideas);
      setTableState({ data: ideas });
      ideas.forEach((idea) => {
        if (!direccion.find((dir) => dir.value === idea.direccion))
          direccion.push({ text: idea.direccion, value: idea.direccion });
        if (
          !dir_general.find(
            (dir_general) => dir_general.value === idea.dir_general
          )
        )
          dir_general.push({ text: idea.dir_general, value: idea.dir_general });
      });
      setDirFilter(direccion);
      setDirGenFilter(dir_general);
      if (showDatailsDrawer.visible) {
        let index = props.ideas.findIndex(
          (idea) => idea.id === showDatailsDrawer.idIdea
        );
        setShowDetailsDrawer({
          visible: true,
          idea: props.ideas[index],
          idIdea: props.ideas[index].id,
        });
      }
    }
    let tipos = [];
    Object.values(props.company.tipoTarea).forEach((tipo) => {
      tipos.push({ text: tipo, value: tipo });
    });
    tipos.push({ text: "Sin asignar", value: "Sin asignar" });
    setTipoTareaFilter(tipos);
  }, [
    props.ideas,
    props.userInfo,
    props.company,
    showDatailsDrawer.visible,
    showDatailsDrawer.idIdea,
  ]);

  useEffect(() => {
    if (props.ideas) {
      let ideas = [];
      let direccion = [];
      let dir_general = [];
      props.ideas.forEach(
        (doc) =>
          doc.status === 2 &&
          doc.asignee === props.userInfo.uid &&
          ideas.push({
            ...doc,
            id: doc.id,
          })
      );
      setInboxData(ideas);
      setTableState({ data: ideas });

      ideas.forEach((idea) => {
        if (!direccion.find((dir) => dir.value === idea.direccion))
          direccion.push({ text: idea.direccion, value: idea.direccion });
        if (
          !dir_general.find(
            (dir_general) => dir_general.value === idea.dir_general
          )
        )
          dir_general.push({ text: idea.dir_general, value: idea.dir_general });
      });
      setDirFilter(direccion);
      setDirGenFilter(dir_general);
      if (showDatailsDrawer.visible) {
        let index = props.ideas.findIndex(
          (idea) => idea.id === showDatailsDrawer.idIdea
        );
        setShowDetailsDrawer({
          visible: true,
          idea: props.ideas[index],
          idIdea: props.ideas[index].id,
        });
      }
    }
  }, [
    props.ideas,
    props.userInfo.uid,
    showDatailsDrawer.visible,
    showDatailsDrawer.idIdea,
  ]);

  const statuses = new Map([
    [-1, "Sin procesar"],
    [0, "Sin procesar"],
    [1, "Procesando"],
    [2, "En progreso"],
    [3, "En producción"],
  ]);
  const statusColor = {
    4: "#C41B24",
    3: "#ccb894",
    2: "#CECE94",
    1: "#97AC85",
    0: "#94B8CC",
  };

  const prioritiesColor = {
    VERY_HIGH: "#C41B24",
    HIGH: "#ccb894",
    MEDIUM: "#CECE94",
    LOW: "#97AC85",
    VERY_LOW: "#94B8CC",
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "idABANCA",
      width: 50,
      sorter: (a, b) => parseInt(a.idABANCA) - parseInt(b.idABANCA),
    },
    {
      title: "Nombre",
      dataIndex: "name",
      width: 300,
      className: "clickable-col",
      render: (name, record) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {name}
        </span>
      ),
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
    },
    {
      title: "Fecha prevista",
      dataIndex: "fechaMax",
      with: 80,
      render: (fechaMax) =>
        fechaMax &&
        fechaMax._seconds &&
        moment.unix(fechaMax._seconds).format("DD/MM/YYYY"),
      sorter: {
        compare: (a, b) =>
          a.fechaMax && a.fechaMax._seconds
            ? b.fechaMax && b.fechaMax._seconds
              ? a.fechaMax._seconds - b.fechaMax._seconds
              : a.fechaMax._seconds - 0
            : b.fechaMax && b.fechaMax._seconds
            ? 0 - b.fechaMax._seconds
            : 0 - 0,
        multiple: 2,
      },
    },
    {
      title: "Prioridad",
      dataIndex: "priority",
      width: 80,
      sortDirections: ["descend", "ascend"],
      sorter: {
        compare: (a, b) =>
          a.priority
            ? b.priority
              ? a.priority - b.priority
              : a.priority - 0
            : b.priority
            ? 0 - b.priority
            : 0 - 0,
      },
      render: (priority, record) => {
        if (priority) {
          priority = MapPriority(priority);
          let color = prioritiesColor[priority];
          return (
            <Tag color={color} key={priority}>
              {`${priorities[priority].toUpperCase()}` +
                (record.priority ? ` (${record.priority})` : "")}
            </Tag>
          );
        } else {
          return <Tag key={priority}>{"SIN ASIGNAR"}</Tag>;
        }
      },
      filters: [
        { text: "Muy baja", value: "Muy baja" },
        { text: "Baja", value: "Baja" },
        { text: "Media", value: "Media" },
        { text: "Alta", value: "Alta" },
        { text: "Muy alta", value: "Muy alta" },
        { text: "Sin asignar", value: "Sin asignar" },
      ],
      onFilter: (value, record) => {
        return record.priority
          ? priorities[MapPriority(record.priority)].indexOf(value) === 0
          : "Sin asignar".indexOf(value) === 0;
      },
    },
    {
      title: "Dirección General",
      dataIndex: "dir_general",
      width: 80,
      render: (text) => {
        return text && text;
      },
      sorter: {
        compare: (a, b) => a.dir_general.localeCompare(b.dir_general),
      },
      filters: dirGenFilter,
      onFilter: (value, record) => {
        return record.dir_general.indexOf(value) === 0;
      },
    },
    {
      title: "Dirección",
      dataIndex: "direccion",
      width: 80,
      render: (text) => {
        return text && text;
      },
      sorter: {
        compare: (a, b) => a.direccion.localeCompare(b.direccion),
      },
      filters: dirFilter,
      onFilter: (value, record) => {
        return record.direccion.indexOf(value) === 0;
      },
    },
    {
      title: "Actividades completadas",
      dataIndex: "activities",
      width: 80,
      render: (text, record) =>
        record.activities && record.activities.length
          ? `${(
              (record.activities.filter((activity) => activity.completed)
                .length /
                record.activities.length) *
              100
            ).toFixed(2)}%`
          : "Sin actividades",
    },
    {
      title: "Tipo de tarea",
      dataIndex: "tipoTarea",
      render: (text) => (text && text !== 0 ? text : ""),
      filters: tipoTareaFilter,
      onFilter: (value, record) => {
        return record.tipoTarea
          ? record.tipoTarea.indexOf(value) === 0
          : "Sin asignar".indexOf(value) === 0;
      },
    },
    {
      title: "Petición CER",
      className: "center-column",
      dataIndex: "peticionCER",
      width: 100,
      render: (peticionCER) =>
        peticionCER ? (
          <CheckCircleFilled
            style={{
              fontWeight: "bolder",
              fontSize: "1.5em",
              color: "#b7eb8f",
            }}
          />
        ) : (
          <CloseCircleFilled
            style={{
              fontWeight: "bolder",
              fontSize: "1.5em",
              color: "#ffa39e",
            }}
          />
        ),
      onFilter: (value, record) => {
        return record.peticionCER === value;
      },
      filters: [
        { text: "Peticion CER", value: true },
        { text: "No peticion CER", value: false },
      ],
    },
    {
      title: "Estatus",
      dataIndex: "status",
      width: 80,
      render: (status) => {
        let color = statusColor[status];
        return (
          <Tag color={color} key={status}>
            {statuses.get(status).toUpperCase()}
          </Tag>
        );
      },
    },
  ];
  const MapPriority = (priority) => {
    if (priority < 0.5) return "VERY_LOW";
    if (priority < 1.5) return "LOW";
    if (priority < 2.5) return "MEDIUM";
    if (priority < 3) return "HIGH";
    if (priority >= 3) return "VERY_HIGH";
  };

  const Loader = () => (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        textAlign: "center",
        marginTop: "25%",
      }}
    >
      <Spin size="large" spinning={true}></Spin>
    </div>
  );

  const ResizableTitle = (props) => {
    const { onResize, width, ...restProps } = props;

    if (!width) {
      return <th {...restProps} />;
    }

    return (
      <Resizable
        width={width}
        height={0}
        handle={
          <span
            className="react-resizable-handle"
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        }
        onResize={onResize}
        draggableOpts={{ enableUserSelectHack: false }}
      >
        <th {...restProps}></th>
      </Resizable>
    );
  };

  const onClose = () => {
    setShowDetailsDrawer({ visible: false, id: null });
  };

  const handleRowClick = (row) => {
    setShowDetailsDrawer({ visible: true, idea: row, idIdea: row.id });
  };

  const tableComponents = {
    header: {
      cell: ResizableTitle,
    },
  };

  const [tableState, setTableState] = useState({
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
  });

  const handleOnKeyChange = (key) => {
    if (!key) setTableState({ data: inboxData });

    key = key.toLowerCase();
    const filteredData = inboxData.filter(
      (entry) =>
        entry.name.toLowerCase().includes(key) ||
        (entry.idABANCA && entry.idABANCA.toString().includes(key)) ||
        entry.direccion.toLowerCase().includes(key) ||
        entry.dir_general.toLowerCase().includes(key)
    );

    setTableState({ data: filteredData });
  };

  return inboxData ? (
    <div style={{ padding: 10 }}>
      <Drawer
        style={{ background: "white" }}
        theme="light"
        width="100vw"
        onClose={onClose}
        visible={showDatailsDrawer.visible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          ></div>
        }
        destroyOnClose
      >
        <CompanyIdeaDetails
          company={props.company}
          onCloseDrawer={onClose}
          userInfo={props.userInfo}
          companyName={props.companyName}
          mappings={props.mappings}
          idea={showDatailsDrawer.idea}
        />
      </Drawer>
      <Row gutter={[20, 30]}>
        <Col span={24}>
          <Row>
            <Col span={6}>
              <InputSearch handleOnKeyChange={handleOnKeyChange} />
            </Col>
            <Col span={14}></Col>
            <Col span={2}>
              <Badge count={badgeNumber}>
                <ScheduleTwoTone
                  twoToneColor="#ff7a45"
                  style={{ fontSize: "32px", cursor: "pointer" }}
                  onClick={() => setActivitiesPanel(true)}
                />
              </Badge>
              <KanbanLayout
                company={props.company}
                ideas={props.ideas}
                visible={activitiesPanel}
                onClose={setActivitiesPanel}
                userInfo={props.userInfo}
              />
            </Col>
            <Col span={2}>
              <ExcelDownload
                data={tableState.data}
                name={"Mis Asignaciones"}
                id={"excel5"}
                company={props.company}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Table
                pagination={{
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }}
                bordered
                components={tableComponents}
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={tableState.data}
                scroll={{ x: "max-content" }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  ) : (
    <Loader />
  );
};

export default MyAssigments;
