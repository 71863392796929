import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Row,
  Col,
  Upload,
  message,
  DatePicker,
  Card,
  InputNumber,
} from "antd";
import {
  createIdea,
  updateIdea,
  uploadFile,
} from "../../../../../db/firedataCalls";
import { UploadOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  departaments,
  tipoTarea,
} from "../../../../Constants/Desigual/DetailsQuestionary";
const areas = require("../../../../../centros.json");

const { Option } = Select;

const DetailsForm = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [workCategories, setWorkCategories] = useState([]);
  const [tiposTarea, setTiposTarea] = useState([]);
  const dateFormat = "DD/MM/YYYY";

  const successUpdate = () => {
    message.success("Información guardada correctamente");
  };

  const errorUpdate = () => {
    message.error("Hubo un error al guardar la información");
  };

  const successFiles = () => {
    message.success("Archivos subidos correctamente");
  };

  const errorFiles = () => {
    message.error("Error al subir archivos");
  };

  const onFinish = async (values) => {
    setLoading(true);
    Object.entries(values).forEach((k) => {
      if (k[1] === undefined) delete values[k[0]];
    });
    await updateIdea(props.ideaData.id, values)
      .then(() => {
        successUpdate();
        setLoading(false);
      })
      .catch((error) => {
        errorUpdate();
        console.error(error);
        setLoading(false);
      });
  };

  const handleUploadFile = (file) => {
    setLoadingFiles(true);
    uploadFile(props.ideaData.id, file)
      .then((uploadResponse) => {
        let uploads = [];

        if (uploadResponse === null) {
          console.error("no se pudo subir el archivo");
        }

        if (!form.getFieldValue("uploads")) {
          form.setFieldsValue({ uploads: uploads });
        }

        let currUploads = form.getFieldValue("uploads");

        if (Array.isArray(currUploads)) {
          //si no existe lo agrega
          if (
            currUploads.findIndex(
              (upload) => upload.name === uploadResponse.name
            ) === -1
          )
            currUploads.push({
              name: uploadResponse.name,
            });
        } else {
          currUploads = [{ name: uploadResponse.name }];
        }

        form.setFieldsValue({ uploads: currUploads });
        successFiles();
        setLoadingFiles(false);
      })
      .catch((error) => {
        console.error(error);
        errorFiles();
      });
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  useEffect(() => {
    if (props.company && props.ideaData) {
      form.setFieldsValue({ ...props.ideaData });
      areas.departments.forEach((area) => {
        setWorkCategories((workCategories) => [
          ...workCategories,
          {
            value: area.value,
            name: area.title,
            key: area.value,
          },
        ]);
      });
      let tipos = [];
      Object.values(props.company.tipoTarea).forEach((tt) => {
        tipos.push({ label: tt, value: tt });
      });
      setTiposTarea(tipos);
    }
    // eslint-disable-next-line
  }, [props.ideaData, props.company]);

  if (props.ideaData)
    return (
      <div>
        <Form
          form={form}
          name="datails-form"
          onFinish={onFinish}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          layout="vertical"
          size="medium"
          labelAlign="right"
        >
          <Row gutter={[10, 20]}>
            <Col span={12}>
              <Form.Item
                labelCol={8}
                wrapperCol={14}
                name="name"
                label={"Nombre"}
                rules={[
                  {
                    required: true,
                    message: "Campo requerido",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={8}
                wrapperCol={14}
                label="Responsable de creación"
              >
                <Input
                  disabled
                  value={` ${
                    props.ideaData.createdBy.firstName || "No capturado"
                  } ${props.ideaData.createdBy.lastName1 || ""} ${
                    props.ideaData.createdBy.lastName2 || ""
                  }
              ${
                props.ideaData.createdBy.username &&
                " (" + props.ideaData.createdBy.username + ")"
              }`}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="departamento"
                label={"Departamento"}
                labelCol={8}
                wrapperCol={14}
                rules={[
                  {
                    required: true,
                    message: "Campo requerido",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder={"Departamento"}
                  optionFilterProp="children"
                >
                  {departaments.map(({ key, value }) => (
                    <Option key={key} value={value} children={value} />
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                colon={false}
                labelCol={8}
                wrapperCol={14}
                labelAlign="left"
                label={"Área"}
                name="area"
                rules={[
                  {
                    required: true,
                    message: "Campo requerido",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="potencialRPA"
                label={"Potencial RPA"}
                labelCol={8}
                wrapperCol={14}
                style={{ width: "100%", textAlign: "right" }}
                rules={[
                  {
                    required: true,
                    message: "Campo requerido",
                  },
                ]}
              >
                <InputNumber
                  formatter={(value) => `${value}%`}
                  style={{ width: "100%", textAlign: "right" }}
                  min={0}
                  max={100}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="tipoTarea"
                label={"Tipo de tarea"}
                labelCol={8}
                wrapperCol={14}
                rules={[
                  {
                    required: true,
                    message: "Campo requerido",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder={"Tipo de tarea"}
                  optionFilterProp="children"
                  options={tipoTarea.map(({ value }) => ({
                    value: value,
                    label: value,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={8}
                wrapperCol={14}
                name="description"
                label={"Descripción"}
              >
                <Input.TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={8}
                wrapperCol={14}
                name="comments"
                label={"Comentarios"}
              >
                <Input.TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={8}
                wrapperCol={14}
                name="responsable"
                label={"Responsables"}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <div className="ant-col ant-form-item-label">
                <label title="Files">{"Archivos"}</label>
              </div>
              <br />
              <Upload
                name="files"
                listType="text"
                action={(file) => handleUploadFile(file)}
                customRequest={dummyRequest}
              >
                <Button>
                  <UploadOutlined /> Subir archivo
                </Button>
              </Upload>
            </Col>
            <Form.Item
              style={{
                textAlign: "center",
                position: "absolute",
                right: 50,
                bottom: 20,
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                block={loadingFiles}
                loading={loading}
                shape="round"
                size="large"
                style={{
                  fontWeight: "bold",
                  fontsize: "15px",
                  backgroundColor: "#f09819",
                  borderColor: "#f09819",
                  boxShadow:
                    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                }}
              >
                Guardar
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </div>
    );
  else return null;
};

export default DetailsForm;
