import React, { useState, useEffect } from "react";
import { Table, Tag, Badge } from "antd";
import moment from "moment";
const dateFormat = "DD/MM/YYYY";

const InfoTable = ({ type, botStages, maintenances }) => {
  const [tableType, setTableType] = useState();

  useEffect(() => {
    if (type) {
      if (type === "BotStages") {
        setTableType(type);
      }
      if (type === "Maintenances") {
        setTableType(type);
      }
    }
    return () => {};
  }, [botStages, maintenances, type]);

  const monthFilters = [
    { text: "January", value: 0 },
    { text: "February", value: 1 },
    { text: "March", value: 2 },
    { text: "April", value: 3 },
    { text: "May", value: 4 },
    { text: "June", value: 5 },
    { text: "July", value: 6 },
    { text: "August", value: 7 },
    { text: "September", value: 8 },
    { text: "October", value: 9 },
    { text: "November", value: 10 },
    { text: "December", value: 11 },
  ];

  const OTDFilters = [
    { text: <Badge color="green"></Badge>, value: "green" },
    { text: <Badge color="red"></Badge>, value: "red" },
    { text: <Badge color="cyan"></Badge>, value: "cyan" },
    { text: <Badge color="orange"></Badge>, value: "orange" },
  ];

  const botStagesColumns = [
    {
      title: "Bot Name",
      dataIndex: "botName",
      width: 200,
    },
    {
      title: "Stage",
      dataIndex: "stage",
      render: (record) => {
        return (
          <Tag color="default" style={{ width: "90%", textAlign: "center" }}>
            {record ? record.toUpperCase() : "NOTHING"}
          </Tag>
        );
      },
      /* onFilter: (value, record) => {
        return record && record.stage
          ? record.stage.toString().indexOf(value) === 0
          : false;
      },
      filters: [...stageFilter], */
      width: 150,
    },
    {
      title: "Expected Start Date",
      dataIndex: "startDate",
      render: (text, record) =>
        text ? `${moment.unix(text.seconds).format(dateFormat)}` : "",
      width: 100,
    },
    {
      title: "Expected End Date",
      dataIndex: "endDate",
      render: (text, record) =>
        text ? `${moment.unix(text.seconds).format(dateFormat)}` : "",
      filters: monthFilters,
      onFilter: (value, record) =>
        record.endDate
          ? moment.unix(record.endDate.seconds).month() === value
          : false,
      width: 100,
    },
    {
      title: "Real Start Date",
      dataIndex: "realStartDate",
      render: (text, record) =>
        text ? `${moment.unix(text.seconds).format(dateFormat)}` : "",
      width: 100,
    },
    {
      title: "Real End Date",
      dataIndex: "realEndDate",
      render: (text, record) =>
        text ? `${moment.unix(text.seconds).format(dateFormat)}` : "",
      filters: monthFilters,
      onFilter: (value, record) =>
        record.realEndDate
          ? moment.unix(record.realEndDate.seconds).month() === value
          : false,
      width: 100,
    },
    {
      title: "",
      dataIndex: "color",
      render: (text, record) => {
        return text && <Badge color={text} />;
      },
      filters: OTDFilters,
      onFilter: (value, record) =>
        record.color ? record.color === value : false,
      fixed: "right",
    },
    /* {
      title: "Status",
      dataIndex: "status",
      render: (record) => {
        let color;
        color =
          record === 0
            ? "processing"
            : record === 1
            ? "success"
            : record === 3
            ? "red"
            : "default";
        return (
          botStatus && (
            <>
              <Tag color={color} style={{ width: "90%", textAlign: "center" }}>
                {botStatus[record].toUpperCase()}
              </Tag>
            </>
          )
        );
      },
      onFilter: (value, record) => {
        return record && record.status
          ? botStatus[record.status].indexOf(value) === 0
          : false;
      },
      filters: [...statusFilter],
      width: 140,
    }, */
    /* {
      title: "Due date",
      dataIndex: "dueDateDiff",
      render: (text, record) => {
        if (text === -1 || !record.dueDate)
          return (
            <Tag color="default" style={{ width: "90%", textAlign: "center" }}>
              NOT SET
            </Tag>
          );
        else
          return (
            <Tooltip
              title={`${
                record.stageInfo.realEndDate ? "Completed on" : ""
              } ${moment
                .unix(record.dueDate.seconds)
                .format("dddd, MMMM Do YYYY")}`}
            >
              {text === 5 ? (
                <Tag
                  color="green"
                  style={{ width: "90%", textAlign: "center" }}
                >
                  COMPLETED
                </Tag>
              ) : text === 1 ? (
                <Tag color="red" style={{ width: "90%", textAlign: "center" }}>
                  OVERDUE
                </Tag>
              ) : text === 2 ? (
                <Tag
                  color="orange"
                  style={{ width: "90%", textAlign: "center" }}
                >
                  THIS WEEK
                </Tag>
              ) : text === 3 ? (
                <Tag color="lime" style={{ width: "90%", textAlign: "center" }}>
                  THIS MONTH
                </Tag>
              ) : (
                <Tag color="blue" style={{ width: "90%", textAlign: "center" }}>
                  ON TIME
                </Tag>
              )}
            </Tooltip>
          );
      },
      onFilter: (value, record) => record.dueDateDiff === value,
      filters: [
        { text: "OVERDUE", value: 1 },
        { text: "THIS WEEK", value: 2 },
        { text: "THIS MONTH", value: 3 },
        { text: "ON TIME", value: 4 },
        { text: "NOT SET", value: -1 },
        { text: "COMPLETED", value: 5 },
      ],
      width: 140,
    }, */
  ];

  const MaintenancesColumns = [
    {
      title: "Bot Name",
      dataIndex: "botName",
      width: 200,
    },
    {
      title: "Maintenance Type",
      dataIndex: "type",
      width: 160,
    },
    {
      title: "Expected Start Date",
      dataIndex: "schdStartDate",
      render: (text, record) =>
        text ? `${moment.unix(text.seconds).format(dateFormat)}` : "",
      width: 100,
    },
    {
      title: "Expected End Date",
      dataIndex: "schdEndDate",
      render: (text, record) =>
        text ? `${moment.unix(text.seconds).format(dateFormat)}` : "",
      filters: monthFilters,
      onFilter: (value, record) =>
        record.schdEndDate
          ? moment.unix(record.schdEndDate.seconds).month() === value
          : false,
      width: 100,
    },
    {
      title: "Real End Date",
      dataIndex: "realEndDate",
      render: (text, record) =>
        text ? `${moment.unix(text.seconds).format(dateFormat)}` : "",
      filters: monthFilters,
      onFilter: (value, record) =>
        record.realEndDate
          ? moment.unix(record.realEndDate.seconds).month() === value
          : false,
      width: 100,
    },
    {
      title: "",
      dataIndex: "color",
      render: (text, record) => {
        return text && <Badge color={text} />;
      },
      filters: OTDFilters,
      onFilter: (value, record) =>
        record.color ? record.color === value : false,
      fixed: "right",
    },
  ];

  if (tableType === "BotStages")
    return (
      <Table
        pagination={{
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          pageSize: 7,
        }}
        bordered
        size="small"
        rowKey={(record) =>
          String(record.bot) + "_" + record.ideaId + "_" + String(record.stage)
        }
        columns={botStagesColumns}
        dataSource={botStages}
        scroll={{ x: "max-content", y: 320 }}
      />
    );
  else if (tableType === "Maintenances")
    return (
      <Table
        pagination={{
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          pageSize: 7,
        }}
        bordered
        size="small"
        rowKey={(record) =>
          String(record.bot) + "_" + record.ideaId + "_" + String(record.id)
        }
        columns={MaintenancesColumns}
        dataSource={maintenances}
        scroll={{ x: "max-content", y: 320 }}
      />
    );
  else return null;
};

export default InfoTable;
