import moment from "moment";

export const priorityMap = [
  {
    label: "Urgente",
    value: 4,
    color: "#f5222d",
  },
  {
    label: "Alta",
    value: 3,
    color: "#fa8c16",
  },
  {
    label: "Media",
    value: 2,
    color: "#52c41a",
  },
  {
    label: "Baja",
    value: 1,
    color: "#1890ff",
  },
];

export const personalMap = [
  { label: "showpersonal", value: 1 },
  { label: "hidepersonal", value: 2 },
  { label: "assignedToMe", value: 3 },
  { label: "assignedToOther", value: 4 },
];

export const completedFilter = (activities) => {
  return activities.filter(
    (activity) =>
      (activity.status !== 4 && !activity.completed) ||
      (activity.completed &&
        activity.status === 4 &&
        (!activity.completedDate ||
          (activity.completedDate &&
            moment().diff(moment.unix(activity.completedDate.seconds), "days") <
              30)))
  );
};

export const prioritySorter = [
  { label: "ascpriority", value: 1 },
  { label: "descpriority", value: 2 },
];

export const dateSorter = [
  { label: "ascdate", value: 3 },
  { label: "descdate", value: 6 },
];

export const applyFilters = (
  activities,
  filters,
  userInfo,
  users,
  text,
  deletedUsers,
  userKey
) => {
  let priorities = [];
  let sorters = 0;
  let personalFilterCount = 0;
  filters.forEach((filter) => {
    if (personalMap.find(({ label }) => label === filter))
      personalFilterCount++;
  });
  filters.forEach((filter) => {
    if (personalFilterCount === 2) {
      activities = doublePersonalFilter(
        activities,
        filters.includes(personalMap[0].label),
        userInfo
      );
    } else if (personalMap.find(({ label }) => filter === label)) {
      activities = personalFilter(activities, filter, userInfo, userKey);
    }
    if (priorityMap.find(({ label }) => filter === label))
      priorities.push(priorityMap.find(({ label }) => filter === label).value);
    if (prioritySorter.find(({ label }) => filter === label)) {
      sorters += prioritySorter.find(({ label }) => filter === label).value;
    }
    if (dateSorter.find(({ label }) => filter === label)) {
      sorters += dateSorter.find(({ label }) => filter === label).value;
    }
  });
  if (priorities.length > 0)
    activities = priorityFilter(activities, priorities);
  if (sorters > 0) activities = activitiesSorter(activities, sorters);
  if (filters.includes("dateless")) {
    activities = activities.filter(
      (activity) => !(activity.startDate && activity.endDate)
    );
  }
  if (filters.includes("textsearch"))
    activities = textFilter(activities, users, text, deletedUsers);
  return activities;
};

export const onChangeSelected = (changed, selected, userKey) => {
  let tempSelected = selected;
  let tempFilters = [];
  if (tempSelected.includes(changed.key)) {
    if (changed.key === "textsearch") {
      if (changed.text.length === 0)
        tempSelected = tempSelected.filter((key) => !(key === changed.key));
    } else if (changed.key !== "assignedToOther")
      tempSelected = tempSelected.filter((key) => !(key === changed.key));
  } else if (personalMap.find(({ label }) => label === changed.key)) {
    if (changed.key === personalMap[3].label) {
      tempSelected = tempSelected.filter(
        (key) => !personalMap.find(({ label }) => label === key)
      );
    } else {
      tempSelected = tempSelected.filter(
        (key) => !(key === personalMap[3].label)
      );
    }
    if (changed.key === personalMap[0].label) {
      tempSelected = tempSelected.filter(
        (key) => !(key === personalMap[1].label)
      );
    } else if (changed.key === personalMap[1].label) {
      tempSelected = tempSelected.filter(
        (key) => !(key === personalMap[0].label)
      );
    }
    tempSelected.push(changed.key);
  } else if (priorityMap.find(({ label }) => label === changed.key)) {
    tempSelected = tempSelected.filter((key) =>
      priorityMap.find(({ label }) => !(key === label))
    );
    tempSelected.push(changed.key);
  } else if (prioritySorter.find(({ label }) => label === changed.key)) {
    tempSelected = tempSelected.filter(
      (key) => !prioritySorter.find(({ label }) => label === key)
    );
    tempSelected.push(changed.key);
  } else if (dateSorter.find(({ label }) => label === changed.key)) {
    tempSelected = tempSelected.filter(
      (key) => !dateSorter.find(({ label }) => label === key)
    );
    tempSelected.push(changed.key);
  } else if (changed.key === "reset") {
    tempSelected = [];
  } else {
    if (changed.key === "textsearch") {
      if (changed.text.length > 0) tempSelected.push(changed.key);
    } else tempSelected.push(changed.key);
  }
  tempSelected.forEach((key) => {
    personalMap.find(({ label }) => label === key) &&
      tempFilters.push({ personal: key });
    priorityMap.find(({ label }) => key === label) &&
      tempFilters.push({ priority: key });
  });

  return [[...tempFilters], [...tempSelected]];
};

const personalFilter = (activities, filter, userInfo, userKey) => {
  if (personalMap.find(({ label }) => filter === label).value === 1)
    return activities.filter((activity) => activity.personal);
  else if (personalMap.find(({ label }) => filter === label).value === 2)
    return activities.filter((activity) => !activity.personal);
  else if (personalMap.find(({ label }) => filter === label).value === 3)
    return activities.filter((activity) => activity.assignee === userInfo.uid);
  else if (personalMap.find(({ label }) => filter === label).value === 4)
    return activities.filter((activity) => activity.assignee === userKey);
};

const doublePersonalFilter = (activities, flag, userInfo) => {
  if (flag)
    return activities.filter(
      (activity) => activity.personal || activity.assignee === userInfo.uid
    );
  else
    return activities.filter((activity) => activity.assignee === userInfo.uid);
};

const priorityFilter = (activities, priorities) => {
  return activities.filter(({ priority }) =>
    priorities.find((value) => priority === value)
  );
};

const activitiesSorter = (activities, sorters) => {
  let tempActivities = [];
  let priorityActivities = [];
  let priority = 0;
  switch (sorters) {
    case 1:
      activities.sort((a, b) => a.priority - b.priority);
      break;
    case 2:
      activities.sort((a, b) => b.priority - a.priority);
      break;
    case 3:
      activities.sort((a, b) =>
        a.endDate && a.endDate.seconds
          ? b.endDate && b.endDate.seconds
            ? a.endDate.seconds - b.endDate.seconds
            : a.endDate.seconds - 0
          : b.endDate && b.endDate.seconds
          ? 0 - b.endDate.seconds
          : 0 - 0
      );
      break;
    case 6:
      activities.sort((a, b) =>
        b.endDate && b.endDate.seconds
          ? a.endDate && a.endDate.seconds
            ? b.endDate.seconds - a.endDate.seconds
            : b.endDate.seconds - 0
          : a.endDate && a.endDate.seconds
          ? 0 - a.endDate.seconds
          : 0 - 0
      );
      break;
    case 4:
      activities.sort((a, b) => a.priority - b.priority);
      priority = activities[0].priority;
      activities.forEach((activity) => {
        if (priority === activity.priority) priorityActivities.push(activity);
        else {
          priorityActivities.sort((a, b) =>
            a.endDate && a.endDate.seconds
              ? b.endDate && b.endDate.seconds
                ? a.endDate.seconds - b.endDate.seconds
                : a.endDate.seconds - 0
              : b.endDate && b.endDate.seconds
              ? 0 - b.endDate.seconds
              : 0 - 0
          );
          priorityActivities.forEach((newActivity) =>
            tempActivities.push(newActivity)
          );
          priorityActivities = [];
          priority = activity.priority;
          priorityActivities.push(activity);
        }
      });
      priorityActivities.forEach((newActivity) =>
        tempActivities.push(newActivity)
      );
      activities = tempActivities;
      break;
    case 7:
      activities.sort((a, b) => a.priority - b.priority);
      priority = activities[0].priority;
      activities.forEach((activity) => {
        if (priority === activity.priority) priorityActivities.push(activity);
        else {
          priorityActivities.sort((a, b) =>
            b.endDate && b.endDate.seconds
              ? a.endDate && a.endDate.seconds
                ? b.endDate.seconds - a.endDate.seconds
                : b.endDate.seconds - 0
              : a.endDate && a.endDate.seconds
              ? 0 - a.endDate.seconds
              : 0 - 0
          );
          priorityActivities.forEach((newActivity) =>
            tempActivities.push(newActivity)
          );
          priorityActivities = [];
          priority = activity.priority;
          priorityActivities.push(activity);
        }
      });
      priorityActivities.forEach((newActivity) =>
        tempActivities.push(newActivity)
      );
      activities = tempActivities;
      break;
    case 5:
      activities.sort((a, b) => b.priority - a.priority);
      priority = activities[0].priority;
      activities.forEach((activity) => {
        if (priority === activity.priority) priorityActivities.push(activity);
        else {
          priorityActivities.sort((a, b) =>
            a.endDate && a.endDate.seconds
              ? b.endDate && b.endDate.seconds
                ? a.endDate.seconds - b.endDate.seconds
                : a.endDate.seconds - 0
              : b.endDate && b.endDate.seconds
              ? 0 - b.endDate.seconds
              : 0 - 0
          );
          priorityActivities.forEach((newActivity) =>
            tempActivities.push(newActivity)
          );
          priorityActivities = [];
          priority = activity.priority;
          priorityActivities.push(activity);
        }
      });
      priorityActivities.forEach((newActivity) =>
        tempActivities.push(newActivity)
      );
      activities = tempActivities;
      break;
    case 8:
      activities.sort((a, b) => b.priority - a.priority);
      priority = activities[0].priority;
      activities.forEach((activity) => {
        if (priority === activity.priority) priorityActivities.push(activity);
        else {
          priorityActivities.sort((a, b) =>
            b.endDate && b.endDate.seconds
              ? a.endDate && a.endDate.seconds
                ? b.endDate.seconds - a.endDate.seconds
                : b.endDate.seconds - 0
              : a.endDate && a.endDate.seconds
              ? 0 - a.endDate.seconds
              : 0 - 0
          );
          priorityActivities.forEach((newActivity) =>
            tempActivities.push(newActivity)
          );
          priorityActivities = [];
          priority = activity.priority;
          priorityActivities.push(activity);
        }
      });
      priorityActivities.forEach((newActivity) =>
        tempActivities.push(newActivity)
      );
      activities = tempActivities;
      break;
    default:
      break;
  }
  return activities;
};

const textFilter = (activities, users, text, deletedUsers) => {
  let usersUID = [];
  let key = text.toLowerCase();
  Object.values(users).forEach((user) => {
    let name = user.firstName && user.firstName;
    name += user.lastName1 && " " + user.lastName1;
    name += user.lastName2 && " " + user.lastName2;
    name += user.username && " " + user.username;
    name = name.toLowerCase();
    name.includes(key) && usersUID.push(user.uid);
  });
  Object.values(deletedUsers).forEach((user) => {
    let name = user.firstName && user.firstName;
    name += user.lastName1 && " " + user.lastName1;
    name += user.lastName2 && " " + user.lastName2;
    name += user.username && " " + user.username;
    name = name.toLowerCase();
    name.includes(key) && usersUID.push(user.uid);
  });
  return activities.filter((activity) => usersUID.includes(activity.assignee));
};
