import React from "react";
import { message, Button, Row, Col, Card, List, Empty } from "antd";
import DetailsForm from "./DetailsForm";
import JustificacionIdea from "./JustificacionIdea";
import firebase from "../../../../../server/firebase";
import { getDownloadURL, removeFile } from "../../../../../db/firedataCalls";

const Details = ({ userInfo, idea, company }) => {
  const downloadFile = async (fileName) => {
    let url = await getDownloadURL(idea.id, fileName);
    window.open(url, "_blank");
  };
  const deleteFile = async (fileName) => {
    let response = await removeFile(idea.id, fileName);
    if (response) {
      let index = idea.uploads.findIndex((upload) => upload.name === fileName);
      let uploads = idea.uploads;
      uploads.splice(index, 1);
      firebase
        .database()
        .ref()
        .child("ideas")
        .child(idea.id)
        .update({ uploads });
    } else message.error("No se eliminó el archivo");
  };

  return (
    <Row gutter={[10, 20]}>
      <Col span={24}>
        <Card
          bodyStyle={{ background: "white" }}
          type="inner"
          title={"Datos de la idea"}
          style={{ marginTop: 20 }}
        >
          <DetailsForm userInfo={userInfo} ideaData={idea} company={company} />
        </Card>
        {idea.uploads && (
          <Card
            bodyStyle={{ background: "white" }}
            type="inner"
            title={"Archivos"}
            style={{ marginTop: 20 }}
          >
            <List
              itemLayout="horizontal"
              dataSource={idea.uploads}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={
                      <Button
                        type="link"
                        onClick={() => downloadFile(item.name)}
                      >
                        {item.name}
                      </Button>
                    }
                  />
                  <List.Item.Meta
                    title={
                      <Button
                        type="primary"
                        onClick={() => deleteFile(item.name)}
                      >
                        Eliminar archivo
                      </Button>
                    }
                  />
                </List.Item>
              )}
            />
          </Card>
        )}
        <Card
          bodyStyle={{ background: "white" }}
          type="inner"
          title={"Archivos Innotek"}
          style={{ marginTop: 20 }}
        >
          {idea.innotekAttachments ? (
            <>
              <List
                itemLayout="horizontal"
                dataSource={idea.innotekAttachments}
                split={false}
                renderItem={(item) => (
                  <List.Item style={{ paddingLeft: "10px" }}>
                    {<a href={item.url}>{item.name}</a>}
                  </List.Item>
                )}
              />
            </>
          ) : (
            <>
              <Empty
                description={"Sin archivos"}
                style={{ height: "100px" }}
                imageStyle={{ height: "75%" }}
              />
            </>
          )}
        </Card>
        <Card
          bodyStyle={{ background: "white" }}
          type="inner"
          title={"Vialidad"}
          style={{ marginTop: 20 }}
        >
          <JustificacionIdea idIdea={idea.id} ideaData={idea} />
        </Card>
      </Col>
    </Row>
  );
};

export default Details;
