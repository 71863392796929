import React from "react";
import { Col, Row } from "antd";
import { Radar } from "@ant-design/plots";
import "./style.css";
import CalculatorJson from "../calculator.json";

const RadarChart = ({ idea }) => {
  const calculator = CalculatorJson.home;

  const newResultsArray = idea.viabilityCalculator.brainArray;
  const config = {
    data: newResultsArray.map((element, index) => ({
      ...element,
      name: (index + 1).toString(),
    })),
    xField: "name",
    yField: "value",
    appendPadding: [0, 10, 0, 10],
    meta: {
      star: {
        alias: "",
        min: 0,
        nice: true,
      },
    },
    xAxis: {
      tickLine: null,
    },
    yAxis: {
      label: false,
      grid: {
        alternateColor: "rgba(0, 0, 0, 0.04)",
      },
    },
    point: {
      size: 4,
    },
    area: {},
  };

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col span={14}>
          <Radar {...config} />
        </Col>
        <Col span={8}>
          <Row gutter={[8, 24]}>
            <Col span={24} key={"Unique"}></Col>
            {newResultsArray.map((element, index) => (
              <Col span={24} key={element.name}>
                {`${(index + 1).toString()}. ${calculator[
                  element.name
                ].replaceAll(":", "")}`}
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default RadarChart;
