import React, { useState, useEffect } from "react";
import { Drawer, Layout, Row, Col, Card, DatePicker } from "antd";
import InfoTable from "./InfoTable";
import moment from "moment";
import LineChart from "./LineChart";

const { Header, Content } = Layout;

const customFormat = (value) => `Select a year: ${value.format("YYYY")}`;

const OTDLayout = ({ visible, setVisible, botStages, maintenances }) => {
  const [year, setYear] = useState(moment());
  const [yearBotStages, setYearBotStages] = useState([]);
  const [yearMaintenances, setYearMaintenances] = useState([]);
  const [botStagesOTD, setBotStagesOTD] = useState([]);
  const [maintenancesOTD, setMaintenancesOTD] = useState([]);

  useEffect(() => {
    let totalBotStages = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let fulfilledBotStages = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let totalMaintenances = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let fulfilledMaintenances = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let currentBotStagesOTD = [
      { mes: "January", OTD: 0 },
      { mes: "February", OTD: 0 },
      { mes: "March", OTD: 0 },
      { mes: "April", OTD: 0 },
      { mes: "May", OTD: 0 },
      { mes: "June", OTD: 0 },
      { mes: "July", OTD: 0 },
      { mes: "August", OTD: 0 },
      { mes: "September", OTD: 0 },
      { mes: "October", OTD: 0 },
      { mes: "November", OTD: 0 },
      { mes: "December", OTD: 0 },
    ];
    let currentMaintenancesOTD = [
      { mes: "January", OTD: 0 },
      { mes: "February", OTD: 0 },
      { mes: "March", OTD: 0 },
      { mes: "April", OTD: 0 },
      { mes: "May", OTD: 0 },
      { mes: "June", OTD: 0 },
      { mes: "July", OTD: 0 },
      { mes: "August", OTD: 0 },
      { mes: "September", OTD: 0 },
      { mes: "October", OTD: 0 },
      { mes: "November", OTD: 0 },
      { mes: "December", OTD: 0 },
    ];
    if (botStages) {
      let currentBotStages = botStages.filter((stage) => {
        if (stage.realEndDate) {
          return moment.unix(stage.realEndDate.seconds).isSame(year, "year");
        } else if (stage.endDate) {
          return moment.unix(stage.endDate.seconds).isSame(year, "year");
        } else return false;
      });
      currentBotStages.forEach((stage) => {
        if (stage.realEndDate) {
          if (stage.endDate) {
            if (
              Number(
                moment
                  .unix(stage.endDate.seconds)
                  .diff(moment.unix(stage.realEndDate.seconds), "days")
              ) >= 0
            ) {
              stage.color = "green";
              totalBotStages[moment.unix(stage.realEndDate.seconds).month()]++;
              fulfilledBotStages[
                moment.unix(stage.realEndDate.seconds).month()
              ]++;
            } else {
              stage.color = "red";
              totalBotStages[moment.unix(stage.realEndDate.seconds).month()]++;
            }
          }
        } else {
          if (stage.endDate) {
            if (
              Number(
                moment
                  .unix(stage.endDate.seconds)
                  .diff(moment().startOf("day"), "days")
              ) > 0
            ) {
              stage.color = "cyan";
            } else {
              stage.color = "orange";
            }
          }
        }
      });
      for (let index = 0; index < 12; index++) {
        if (totalBotStages[index] > 0) {
          currentBotStagesOTD[index].OTD = Number(
            ((fulfilledBotStages[index] / totalBotStages[index]) * 100).toFixed(
              2
            )
          );
        } else {
          currentBotStagesOTD[index].OTD = 100;
        }
      }
      setYearBotStages(currentBotStages);
      setBotStagesOTD(currentBotStagesOTD);
    }
    if (maintenances) {
      let currentMaintenances = maintenances.filter((maintenance) => {
        if (maintenance.realEndDate) {
          return moment
            .unix(maintenance.realEndDate.seconds)
            .isSame(year, "year");
        } else if (maintenance.schdEndDate) {
          return moment
            .unix(maintenance.schdEndDate.seconds)
            .isSame(year, "year");
        } else return false;
      });
      currentMaintenances.forEach((maintenance) => {
        if (maintenance.realEndDate) {
          if (maintenance.schdEndDate) {
            if (
              Number(
                moment
                  .unix(maintenance.schdEndDate.seconds)
                  .diff(moment.unix(maintenance.realEndDate.seconds), "days")
              ) >= 0
            ) {
              maintenance.color = "green";
              totalMaintenances[
                moment.unix(maintenance.realEndDate.seconds).month()
              ]++;
              fulfilledMaintenances[
                moment.unix(maintenance.realEndDate.seconds).month()
              ]++;
            } else {
              maintenance.color = "red";
              totalMaintenances[
                moment.unix(maintenance.realEndDate.seconds).month()
              ]++;
            }
          }
        } else {
          if (maintenance.schdEndDate) {
            if (
              Number(
                moment
                  .unix(maintenance.schdEndDate.seconds)
                  .diff(moment().startOf("day"), "days")
              ) > 0
            ) {
              maintenance.color = "cyan";
            } else {
              maintenance.color = "orange";
            }
          }
        }
      });
      for (let index = 0; index < 12; index++) {
        if (totalMaintenances[index] > 0) {
          currentMaintenancesOTD[index].OTD = Number(
            (
              (fulfilledMaintenances[index] / totalMaintenances[index]) *
              100
            ).toFixed(2)
          );
        } else {
          currentMaintenancesOTD[index].OTD = 100;
        }
      }
      setYearMaintenances(currentMaintenances);
      setMaintenancesOTD(currentMaintenancesOTD);
    }
  }, [botStages, maintenances, year]);

  const ContentInfo = () => {
    return (
      <>
        <Row gutter={[10, 10]}>
          <Col>
            <DatePicker
              picker="year"
              defaultValue={moment()}
              onChange={(date) => setYear(date)}
              format={customFormat}
            />
          </Col>
        </Row>
        <br></br>
        <Row style={{ width: "100%" }} gutter={[10, 10]}>
          <Col span={12}>
            <Card title="Bot Stages" bordered={false} style={{ height: 500 }}>
              <InfoTable
                type="BotStages"
                year={year}
                botStages={yearBotStages}
                maintenances={yearMaintenances}
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card
              title="Bot Stages Chart"
              bordered={false}
              style={{ height: 500 }}
            >
              <LineChart chartData={botStagesOTD}></LineChart>
            </Card>
          </Col>
        </Row>
        <br></br>
        <Row style={{ width: "100%" }} gutter={[10, 10]}>
          <Col span={12}>
            <Card title="Maintenances" bordered={false} style={{ height: 500 }}>
              <InfoTable
                type="Maintenances"
                year={year}
                botStages={yearBotStages}
                maintenances={yearMaintenances}
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card
              title="Maintenances Chart"
              bordered={false}
              style={{ height: 500 }}
            >
              <LineChart chartData={maintenancesOTD}></LineChart>
            </Card>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Drawer
      style={{ margin: 0, border: 0, padding: 0 }}
      bodyStyle={{ margin: 0, border: 0, padding: 0 }}
      width="100vw"
      onClose={() => setVisible(false)}
      visible={visible}
      footer={null}
      destroyOnClose
    >
      <Layout style={{ width: "100vw", height: "100vh" }}>
        <Header
          style={{
            paddingLeft: 25,
            background:
              "url('https://firebasestorage.googleapis.com/v0/b/rpa-manager.appspot.com/o/iconos%2FRPA_Banner_72dpi-01.png?alt=media&token=b44f001b-907b-46d4-8200-6b5524e0eff0')",
            borderBottom: "1px solid lightgray",
            backgroundSize: "auto",
          }}
        >
          <img
            key="img"
            src="https://firebasestorage.googleapis.com/v0/b/rpa-manager.appspot.com/o/iconos%2Frpa-manager.png?alt=media&token=87ae54b7-1c1f-4f2f-894d-5540d4ae5076"
            href="/"
            alt="RPA MANAGER"
            style={{ height: "24px" }}
          />
        </Header>
        <Layout>
          <Content style={{ overflow: "auto" }}>{ContentInfo()}</Content>
        </Layout>
      </Layout>
    </Drawer>
  );
};

export default OTDLayout;
