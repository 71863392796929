import React from 'react';

const BenefitInProgressStatsCard = (props) => {
  return (
    <>
      <span>{`$${new Intl.NumberFormat('es-MX', { maximumSignificantDigits: 3 }).format(props.benefitInProgress)}`}</span>
    </>
  );
};

export default BenefitInProgressStatsCard;