// Definicion de Clases a Ocupar
export class DefinitionModel {
  constructor(definition, scale, effort) {
    this.definition = definition; // String
    this.scale = scale; //String
    this.effort = effort; //String
  }
}

var defModel = new DefinitionModel("", "", "");

export class ComplexityModel {
  constructor(amount, complexity, complexityLevel, complexityName, definition) {
    this.amount = amount; // Double
    this.complexity = complexity; // Int
    this.complexityLevel = complexityLevel; // Double
    this.complexityName = complexityName; // String
    this.definition = definition; // DefinitionModel
  }
}

var comModel = new ComplexityModel(0.0, 0, 0.0, "", defModel);

export class RadioModel {
  constructor(name, value) {
    this.name = name; // String
    this.value = value; // Integer
  }
}

var radModel = new RadioModel("", 0);

export class Question {
  constructor(questionTitle, questionText, questionAnswer) {
    this.questionTitle = questionTitle; // String
    this.questionText = questionText; // String
    this.questionAnswer = questionAnswer; // String
  }
}

var quest = new Question("", "", "");
