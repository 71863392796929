import {
  setNewABANCAid,
  updateIdeaStatus,
} from "../../../../../../db/firedataCalls";

export const approveButton = (idea, ideas) => {
  setIdABANCA(idea);
  return "Petición aprobada exitosamente";
};

export const rejectButton = (idea) => {
  updateIdeaStatus(idea, 4);
  return "Petición rechazada";
};

const setIdABANCA = async (idea) => {
  if (!idea.idABANCA) {
    setNewABANCAid(idea.companyId, idea.id);
  } else await updateIdeaStatus(idea, 2);
};
