import React, { useEffect, useState } from "react";
import { Table, Row, Col, Drawer, Button } from "antd";
import "../../../../../../App.css";
import moment from "moment";
import InputSearch from "../../InputSearch";
import CreateIdeaHandler from "../../../CreateIdea/CreateIdeaHandler";
import CompanyIdeaDetails from "../../../Details/CompanyIdeaDetails";
import { PanelRender } from "../../../../../CustomComponents/HEB/OTDPanel";
import ReactExport from "react-data-export";
import { useTranslation } from "react-i18next";

const Search = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
  });

  const [tableData, setTableData] = useState([]);
  const [showDatailsDrawer, setShowDetailsDrawer] = useState({
    idea: null,
    visible: false,
    idIdea: null,
  });
  const [arrayExcel, setArrayExcel] = useState([]);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const columns = [
    /*{
      title: "ID",
      dataIndex: "id",
    },*/
    {
      title: t("Generic.SearchTab.Table.ColumnTitles.Name"),
      dataIndex: "name",
      render: (text, record) => (
        <span
          style={{ padding: "13px 13px 6px 6px", cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {record.name}
        </span>
      ),
    },
    {
      title: t("Generic.SearchTab.Table.ColumnTitles.CreatedDate"),
      dataIndex: "createdDate",
      render: (createdDate) =>
        createdDate
          ? moment.unix(createdDate.seconds).format("DD/MM/YYYY")
          : "",
      sorter: {
        compare: (a, b) =>
          a.createdDate
            ? b.createdDate
              ? a.createdDate.seconds - b.createdDate.seconds
              : a.createdDate.seconds - 0
            : b.createdDate
            ? 0 - b.createdDate.seconds
            : 0 - 0,
        multiple: 2,
      },
    },
    {
      title: t("Generic.SearchTab.Table.ColumnTitles.Priority"),
      dataIndex: "priority",
      sortDirections: ["descend", "ascend"],
      sorter: {
        compare: (a, b) => a.priority - b.priority,
      },
    },
    {
      title: t("Generic.SearchTab.Table.ColumnTitles.WorkCategory"),
      dataIndex: "workCategory",
      render: (text, record) => {
        return t("Generic.ProcessDetails.Text.Value.WorkCategory." + text);
      },
    },
    {
      title: t("Generic.SearchTab.Table.ColumnTitles.Status"),
      dataIndex: "status",
      render: (status, record) => {
        return t("Generic.SearchTab.Table.Values.Status." + status);
      },
      sorter: {
        compare: (a, b) => a.status - b.status,
        multiple: 2,
      },
      filters: [
        { text: t("Generic.SearchTab.Table.Values.Status.-1"), value: -1 },
        { text: t("Generic.SearchTab.Table.Values.Status.1"), value: 1 },
        { text: t("Generic.SearchTab.Table.Values.Status.2"), value: 2 },
        { text: t("Generic.SearchTab.Table.Values.Status.3"), value: 3 },
      ],
      onFilter: (value, record) => {
        return record.status === value;
      },
    },
  ];

  const handleRowClick = (row) => {
    setShowDetailsDrawer({ visible: true, idea: row, idIdea: row.id });
  };

  useEffect(() => {
    if (props.ideas) {
      let ideas = props.ideas;
      setTableData(ideas);
      setState({
        data: ideas,
      });
      if (showDatailsDrawer.visible) {
        let index = props.ideas.findIndex(
          (idea) => idea.id === showDatailsDrawer.idIdea
        );
        setShowDetailsDrawer({
          visible: true,
          idea: props.ideas[index],
          idIdea: props.ideas[index].id,
        });
      }
    }
  }, [props.ideas, showDatailsDrawer.idIdea, showDatailsDrawer.visible]);

  const { data } = state;

  const handleOnKeyChange = (key) => {
    if (!key) setState({ data: tableData });

    key = key.toLowerCase();

    const filteredData = tableData.filter(
      (entry) =>
        entry.name.toLowerCase().includes(key) ||
        (entry.id && entry.id.toString().includes(key)) ||
        (entry.workCategory && entry.workCategory.toLowerCase().includes(key))
    );

    setState({ data: filteredData });
  };

  const onClose = () => {
    setShowDetailsDrawer({ visible: false, idea: null, idIdea: null });
  };

  const downloadData = async () => {
    var arrayData = [];
    arrayData = tableData.map((data) => {
      var dataEntry = {
        name: data.name,
        createdDate: data.createdDate
          ? moment.unix(data.createdDate.seconds).format("DD/MM/YYYY")
          : "-",
        priority: data.priority,
        workCategory: t(
          "Generic.ProcessDetails.Text.Value.WorkCategory." + data.workCategory
        ),
        status: t("Generic.SearchTab.Table.Values.Status." + data.status),
      };
      return dataEntry;
    });
    setArrayExcel(arrayData);
    setTimeout(() => {
      var link = document.getElementById("excelBacklog");
      link.click();
    }, 1000);
  };

  return (
    <>
      <Row>
        <Col span={6}>
          <InputSearch handleOnKeyChange={handleOnKeyChange} />
        </Col>

        <Col offset={2} span={3}>
          <CreateIdeaHandler
            userInfo={props.userInfo}
            company={props.company}
            ideas={tableData}
          />
        </Col>
        <Col span={8}></Col>
        <Col span={3}>
          <PanelRender ideas={data} userInfo={props.userInfo} />
        </Col>
        <Col span={2}>
          <Button
            onClick={() => {
              downloadData();
            }}
            style={{ color: "white", backgroundColor: "#009900" }}
          >
            Excel
          </Button>
          <ExcelFile
            element={
              <button id={"excelBacklog"} style={{ display: "none" }}>
                Download Data
              </button>
            }
            filename={t("Generic.TabsTitle.Search")}
          >
            <ExcelSheet key={"0"} data={arrayExcel} name={"Data"}>
              <ExcelColumn
                label={t("Generic.SearchTab.Table.ColumnTitles.Name")}
                value="name"
                key={0}
              />
              <ExcelColumn
                label={t("Generic.SearchTab.Table.ColumnTitles.CreatedDate")}
                value="createdDate"
                key={1}
              />
              <ExcelColumn
                label={t("Generic.SearchTab.Table.ColumnTitles.Priority")}
                value="priority"
                key={2}
              />
              <ExcelColumn
                label={t("Generic.SearchTab.Table.ColumnTitles.WorkCategory")}
                value="workCategory"
                key={3}
              />
              <ExcelColumn
                label={t("Generic.SearchTab.Table.ColumnTitles.Status")}
                value="status"
                key={4}
              />
            </ExcelSheet>
          </ExcelFile>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            bordered
            dataSource={data}
            rowKey={(idea) => idea.id}
            columns={columns}
            scroll={{ x: 1300 }}
          />

          <Drawer
            style={{ background: "white" }}
            width="100vw"
            onClose={onClose}
            visible={showDatailsDrawer.visible}
            footer={
              <div
                style={{
                  textAlign: "right",
                }}
              ></div>
            }
            destroyOnClose
          >
            <CompanyIdeaDetails
              company={props.company}
              onCloseDrawer={onClose}
              userInfo={props.userInfo}
              companyName={props.companyName}
              mappings={props.mappings}
              idea={showDatailsDrawer.idea}
            />
          </Drawer>
        </Col>
      </Row>
    </>
  );
};

export default Search;
