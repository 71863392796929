import React from "react";
import { message } from "antd";
import ABANCATabs from "./ABANCA/ABANCATabs";
import HEBTabs from "./HEB/HEBTabs";
import PRUEBATabs from "./PRUEBA/PRUEBATabs";
import LALATabs from "./LALA/LALATabs";
import SOCOFARTabs from "./SOCOFAR/SOCOFARTabs";
import WalmartTabs from "./Walmart/WalmartTabs";
import DesigualTabs from "./DESIGUAL/DesigualTabs";
import GenericTabs from "./GENERIC/GenericTabs";

const CompanyTabs = (props) => {
  if (props.companyName && props.userInfo && props.mappings && props.ideas)
    switch (props.companyName) {
      case "HEB":
        return (
          <HEBTabs
            companyName={props.companyName}
            company={props.company}
            userInfo={props.userInfo}
            mappings={props.mappings}
            ideas={props.ideas}
            areas={props.areas}
          />
        );

      case "ABANCA":
        return (
          <ABANCATabs
            companyName={props.companyName}
            company={props.company}
            userInfo={props.userInfo}
            mappings={props.mappings}
            ideas={props.ideas}
          />
        );

      case "LALA":
        return (
          <LALATabs
            companyName={props.companyName}
            company={props.company}
            userInfo={props.userInfo}
            mappings={props.mappings}
            ideas={props.ideas}
            areas={props.areas}
          />
        );

      case "Socofar":
        return (
          <SOCOFARTabs
            companyName={props.companyName}
            company={props.company}
            userInfo={props.userInfo}
            mappings={props.mappings}
            ideas={props.ideas}
            areas={props.areas}
          />
        );

      case "Walmart":
        return (
          <WalmartTabs
            companyName={props.companyName}
            company={props.company}
            userInfo={props.userInfo}
            mappings={props.mappings}
            ideas={props.ideas}
            areas={props.areas}
          />
        );

      case "PRUEBA":
        return (
          <PRUEBATabs
            companyName={props.companyName}
            company={props.company}
            userInfo={props.userInfo}
            mappings={props.mappings}
            ideas={props.ideas}
          />
        );
      case "Desigual":
        return (
          <DesigualTabs
            companyName={props.companyName}
            company={props.company}
            userInfo={props.userInfo}
            mappings={props.mappings}
            ideas={props.ideas}
          />
        );

      case "Generic":
        return (
          <GenericTabs
            companyName={props.companyName}
            company={props.company}
            userInfo={props.userInfo}
            mappings={props.mappings}
            ideas={props.ideas}
            areas={props.areas}
          />
        );

      default:
        return (
          <HEBTabs
            companyName={props.companyName}
            company={props.company}
            userInfo={props.userInfo}
            mappings={props.mappings}
            ideas={props.ideas}
          />
        );
    }
  else {
    message.error("");
    return <></>;
  }
};

export default CompanyTabs;
