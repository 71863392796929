import React, { useState, useEffect } from "react";
import firebase from "../../../../../server/firebase";
import moment from "moment";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Divider,
  Table,
  Select,
  DatePicker,
  Empty,
  Popconfirm,
  Tooltip,
  Avatar,
  Tag,
  InputNumber,
  Space,
} from "antd";
import SOCOFARAssigned from "./SOCOFARAssigned";
import SOCOFARComments from "./SOCOFARComments";
import "../../../../../App.css";
import { presetPalettes } from "@ant-design/colors";
const clone = require("rfdc")();

const SOCOFARMaintenance = (props) => {
  const [bots, setBots] = useState([]);
  const [maintenanceList, setMaintenanceList] = useState([]);
  const [botFilterList, setBotFilterList] = useState([]);
  const [tableChange, setTableChange] = useState(false);
  const dateFormat = "DD/MM/YYYY";
  const [form] = Form.useForm();
  const [deliveredBots, setDeliveredBots] = useState([]);
  const [users, setUsers] = useState([]);
  const [editStatus, setEditStatus] = useState({ id: -1, bot: -1 });

  const statusColor = {
    0: "default",
    1: "warning",
    2: "success",
    3: "purple",
  };

  const statusText = {
    0: "PENDING",
    1: "IN PROGRESS",
    2: "COMPLETED",
    3: "ON HOLD",
  };

  useEffect(() => {
    let cloneBots = [];
    let tempList = [];
    let tempBotFilterList = [];
    let colors = [];
    let users = [];
    if (props.company.users) users = Object.values(props.company.users);
    if (props.idea.bots) {
      cloneBots = clone(props.idea.bots);
      setBots(cloneBots);
      setDeliveredBots(cloneBots.filter((bot) => bot.status === 1));
      cloneBots.forEach((bot) => {
        if (bot.maintenance) {
          tempBotFilterList.push({ text: bot.name, value: bot.key });
          bot.maintenance.forEach((maintenance) => {
            tempList.push({
              id: maintenance.id,
              botKey: bot.key,
              botName: bot.name,
              startDate: maintenance.startDate,
              endDate: maintenance.endDate,
              realStartDate: maintenance.realStartDate
                ? maintenance.realStartDate
                : null,
              realEndDate: maintenance.realEndDate
                ? maintenance.realEndDate
                : null,
              type: maintenance.type,
              description: maintenance.description,
              maintenance: maintenance,
              asignee: maintenance.asignee,
              comments: maintenance.comments,
              priority: maintenance.priority,
              status: maintenance.status,
              createdDate: maintenance.createdDate,
              schdStartDate: maintenance.schdStartDate
                ? maintenance.schdStartDate
                : null,
              schdEndDate: maintenance.schdEndDate
                ? maintenance.schdEndDate
                : null,
            });
          });
        }
      });
      Object.values(presetPalettes).forEach((colorSet) => {
        colors.push(colorSet.primary);
      });
      let res = users.length / colors.length;
      if (res > 1) {
        for (let a = 0; a < Math.round(res); a++) {
          colors.push(...colors);
        }
      }
      /* users = [
        {
          companyId: "QrEN5Mn25tnNhdPET601",
          email: "juane.soto@softtek.com",
          firstName: "Juan",
          lastName1: "Soto",
          lastName2: "Luévano",
          rol: "User",
          status: "Active",
          uid: "7FVuUhLFfBUVXOfDeEo3UQvax7k1",
          username: "juane.soto@softtek.com",
        },
        {
          companyId: "QrEN5Mn25tnNhdPET601",
          email: "luis.luna@softtek.com",
          firstName: "Luis",
          lastName1: "de Luna",
          lastName2: "Saucedo",
          rol: "User",
          status: "Active",
          uid: "7FVuUFfBUVXOfDeEo3UQvax7k1",
          username: "luis.luna@softtek.com",
        },
        {
          companyId: "QrEN5Mn25tnNhdPET601",
          email: "javier.gutierrez@softtek.com",
          firstName: "Javier",
          lastName1: "Gutiérrez",
          lastName2: "Pérez",
          rol: "User",
          status: "Active",
          uid: "7FVuUhLFfVXOfDeEo3UQvax7k1",
          username: "javier.gutierrez@softtek.com",
        },
        {
          companyId: "QrEN5Mn25tnNhdPET601",
          email: "jorge.bohorquez@softtek.com",
          firstName: "Jorge",
          lastName1: "Bohorquez",
          lastName2: "Ibarra",
          rol: "User",
          status: "Active",
          uid: "7FVuUhLFfBUVXOfDeEovax7k1",
          username: "jorge.bohorquez@softtek.com",
        },
      ]; */
      users = users.map((user, index) => ({ ...user, color: colors[index] }));
      setUsers(users);
      setBotFilterList(tempBotFilterList);
      setMaintenanceList(tempList);
    }
  }, [props.idea.bots, props.company.users]);

  const onFinish = async (values) => {
    let user = null;
    let tempBots = props.idea.bots;
    let index = bots.findIndex(({ key }) => key === values.bot);
    values.asignee = values.asignee.map((asignee) => {
      user = users.find(({ uid }) => uid === asignee);
      return {
        name: returnName(user),
        email: user.email,
        uid: user.uid,
      };
    });
    if (tempBots[index].maintenance)
      tempBots[index].maintenance.push({
        createdDate: { seconds: moment().unix(), nanoseconds: 0 },
        schdStartDate: { seconds: values.date[0].unix(), nanoseconds: 0 },
        schdEndDate: { seconds: values.date[1].unix(), nanoseconds: 0 },
        endDate: { seconds: values.date[1].unix(), nanoseconds: 0 },
        description: values.description,
        id:
          Number(
            tempBots[index].maintenance.reduce(
              (max, maintenance) =>
                maintenance.id > max ? maintenance.id : max,
              0
            )
          ) + 1,
        asignee: values.asignee,
        type: values.type,
        priority: values.priority,
        status: 0,
      });
    else
      tempBots[index].maintenance = [
        {
          createdDate: { seconds: moment().unix(), nanoseconds: 0 },
          schdStartDate: { seconds: values.date[0].unix(), nanoseconds: 0 },
          schdEndDate: { seconds: values.date[1].unix(), nanoseconds: 0 },
          description: values.description,
          id: 1,
          asignee: values.asignee,
          priority: values.priority,
          status: 0,
          type: values.type,
        },
      ];
    await firebase
      .database()
      .ref()
      .child("ideas")
      .child(props.idea.id)
      .update({ bots: tempBots });
    form.resetFields();
    setTableChange(false);
  };

  const updateBots = async () => {
    await firebase
      .database()
      .ref()
      .child("ideas")
      .child(props.idea.id)
      .update({ bots: bots });
    setTableChange(false);
  };

  const onDateChange = (obj, value, type) => {
    let changeType = "";
    if (type === 0) changeType = "schdStartDate";
    else changeType = "schdEndDate";
    let tempBots = bots;
    let botIndex = tempBots.findIndex(({ key }) => key === obj.botKey);
    let mainIndex = tempBots[botIndex].maintenance.findIndex(
      ({ id }) => id === obj.id
    );
    if (value) {
      obj[changeType] = {
        seconds: value.unix(),
        nanoseconds: 0,
      };
      tempBots[botIndex].maintenance[mainIndex][changeType] = obj[changeType];
    } else {
      tempBots[botIndex].maintenance[mainIndex][changeType] = null;
      obj[changeType] = null;
    }
    setTableChange(true);
    setBots(tempBots);
  };

  const onPriorityChange = (obj, value) => {
    let tempBots = bots;
    let botIndex = tempBots.findIndex(({ key }) => key === obj.botKey);
    let mainIndex = tempBots[botIndex].maintenance.findIndex(
      ({ id }) => id === obj.id
    );
    if (value) {
      tempBots[botIndex].maintenance[mainIndex].priority = value;
    }
    setTableChange(true);
    setBots(tempBots);
  };

  const statusClick = (obj) => {
    setEditStatus({ id: obj.id, bot: obj.botKey });
  };

  const onStatusChange = (nextStatus, obj, e) => {
    e.preventDefault();
    let tempBots = bots;
    let botIndex = tempBots.findIndex(({ key }) => key === obj.botKey);
    let mainIndex = tempBots[botIndex].maintenance.findIndex(
      ({ id }) => id === obj.id
    );
    tempBots[botIndex].maintenance[mainIndex].status = nextStatus;
    obj.status = nextStatus;
    if (nextStatus === 2) {
      tempBots[botIndex].maintenance[mainIndex].realEndDate = {
        seconds: moment().unix(),
        nanoseconds: 0,
      };
      obj.realEndDate = { seconds: moment().unix(), nanoseconds: 0 };
    }

    setBots(tempBots);
    setEditStatus({ id: -1, bot: -1 });
    setTableChange(true);
  };

  const deleteMaintenance = (record) => {
    let tempBots = bots;
    let index = bots.findIndex(({ key }) => key === record.botKey);
    let maintenance = tempBots[index].maintenance.filter(
      ({ id }) => id !== record.maintenance.id
    );
    tempBots[index].maintenance = maintenance;
    setBots(tempBots);
    updateBots();
    setTableChange(false);
  };

  const returnName = (user) => {
    return (
      user.firstName +
      " " +
      (user.lastName1 &&
        user.lastName1 + (user.lastName2 && " " + user.lastName2))
    );
  };

  const getChildren = () => {
    return (
      <Select
        placeholder={"Asign to someone"}
        mode="multiple"
        optionLabelProp="label"
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {users.map((user) => (
          <Select.Option
            key={user.uid}
            value={user.uid}
            label={returnName(user)}
          >
            <Tooltip placement="right" title={user.email}>
              <Avatar style={{ backgroundColor: user.color }}>
                {user.firstName[0].toUpperCase() +
                  user.lastName1[0].toUpperCase()}
              </Avatar>
              {" " + returnName(user)}
            </Tooltip>
          </Select.Option>
        ))}
      </Select>
    );
  };

  const columns = [
    {
      title: "Maintenance Type",
      dataIndex: "type",
      /* filters: [...botFilterList],
      filterMultiple: false,
      onFilter: (value, record) => record.botKey === value, */
      fixed: "left",
      width: 180,
    },
    {
      title: "Bot Name",
      dataIndex: "botName",
      filters: [...botFilterList],
      filterMultiple: false,
      onFilter: (value, record) => record.botKey === value,
      fixed: "left",
      width: 180,
    },
    {
      title: "Comments",
      render: (text, record) => {
        return (
          <SOCOFARComments
            record={record}
            setPopoverRecord={() => false}
            users={users}
            userInfo={props.userInfo}
            idea={props.idea}
            bots={bots}
            origin={"SOCOFARMaintenance"}
          />
        );
      },
      width: 210,
    },
    {
      title: "Team",
      render: (text, record) => {
        return (
          <SOCOFARAssigned
            record={record}
            setPopoverRecord={() => false}
            users={users}
            userInfo={props.userInfo}
            idea={props.idea}
            bots={bots}
            origin={"SOCOFARMaintenance"}
          />
        );
      },
      width: 150,
    },
    {
      title: "Schedule Start Date",
      dataIndex: "schdStartDate",
      render: (text, record) => {
        return (
          <DatePicker
            defaultValue={text && moment(moment.unix(text.seconds), dateFormat)}
            format={dateFormat}
            onChange={(date) => onDateChange(record, date, 0)}
            bordered={false}
            suffixIcon={null}
          />
        );
      },
      width: 130,
    },
    {
      title: "Schedule End Date",
      dataIndex: "schdEndDate",
      render: (text, record) => {
        return (
          <DatePicker
            defaultValue={text && moment(moment.unix(text.seconds), dateFormat)}
            format={dateFormat}
            onChange={(date) => onDateChange(record, date, 1)}
            bordered={false}
            suffixIcon={null}
            allowClear={false}
          />
        );
      },
      width: 130,
    },
    {
      title: "End Date",
      dataIndex: "realEndDate",
      render: (text, record) => {
        return record.status === 2 ? (
          <DatePicker
            defaultValue={text && moment(moment.unix(text.seconds), dateFormat)}
            format={dateFormat}
            onChange={(date) => onDateChange(record, date, 1)}
            bordered={false}
            suffixIcon={null}
            allowClear={false}
          />
        ) : (
          <span style={{ textAlign: "center", paddingLeft: "12px" }}>
            Not finished
          </span>
        );
      },
      width: 130,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => {
        return editStatus.id === record.id &&
          editStatus.bot === record.botKey ? (
          <>
            <Space direction="vertical">
              <Tag
                color={statusColor[0]}
                style={{ cursor: "pointer", width: "90%", textAlign: "center" }}
                onClick={(e) => onStatusChange(0, record, e)}
              >
                {statusText[0]}
              </Tag>
              <Tag
                color={statusColor[1]}
                style={{ cursor: "pointer", width: "90%", textAlign: "center" }}
                onClick={(e) => onStatusChange(1, record, e)}
              >
                {statusText[1]}
              </Tag>
              <Tag
                color={statusColor[3]}
                style={{ cursor: "pointer", width: "90%", textAlign: "center" }}
                onClick={(e) => onStatusChange(3, record, e)}
              >
                {statusText[3]}
              </Tag>
              <Tag
                color={statusColor[2]}
                style={{ cursor: "pointer", width: "90%", textAlign: "center" }}
                onClick={(e) => onStatusChange(2, record, e)}
              >
                {statusText[2]}
              </Tag>
            </Space>
          </>
        ) : (
          <>
            {text === 2 ? (
              <Tag color={statusColor[text]} style={{ textAlign: "center" }}>
                {statusText[text]}
              </Tag>
            ) : (
              <Tag
                color={statusColor[text]}
                onClick={() => statusClick(record)}
                style={{ cursor: "pointer", width: "90%", textAlign: "center" }}
              >
                {statusText[text]}
              </Tag>
            )}
          </>
        );
      },
      width: 120,
    },
    {
      title: "Priority",
      dataIndex: "priority",
      render: (text, record) => {
        return (
          <InputNumber
            style={{ width: "80%" }}
            bordered={false}
            defaultValue={text}
            min={1}
            max={10}
            onChange={(number) => onPriorityChange(record, number)}
          />
        );
      },
      width: 100,
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      render: (text, record) =>
        text ? `${moment.unix(text.seconds).format(dateFormat)}` : "",
      width: 130,
    },
    { title: "Description", dataIndex: "description", width: 600 },
    {
      title: "Actions",
      render: (text, record) => {
        return (
          <Popconfirm
            placement="bottom"
            title={"Delete maintenance?"}
            okText={"Yes"}
            cancelText="No"
            onConfirm={() => deleteMaintenance(record)}
          >
            <Button
              type="link"
              style={{ width: "50px", borderLeft: "0px", paddingLeft: "0px" }}
            >
              {"Delete"}
            </Button>
          </Popconfirm>
        );
      },
      fixed: "right",
      width: 100,
    },
  ];

  return props.idea.bots ? (
    <>
      {props.idea.bots && bots && (
        <>
          <Row gutter={[40, 20]}>
            <Divider>Maintenance List</Divider>
          </Row>
          <Row gutter={[40, 20]}>
            <Table
              columns={columns}
              dataSource={maintenanceList}
              style={{ width: "100%" }}
              rowKey={(record) => record.maintenance.id + "_" + record.botKey}
              pagination={{ pageSize: 7 }}
              scroll={{ x: "max-content" }}
            />
          </Row>
          <Row gutter={[40, 20]} justify={"end"}>
            <Popconfirm
              placement="bottomLeft"
              title={"Save Changes?"}
              onConfirm={updateBots}
              okText={"Yes"}
              cancelText={"Cancel"}
              disabled={!tableChange}
            >
              <Button disabled={!tableChange} type="primary">
                {"Save Changes"}
              </Button>
            </Popconfirm>
          </Row>
        </>
      )}
      <Divider>New Maintenance</Divider>

      <Form
        onFinish={onFinish}
        form={form}
        name="New Bot"
        initialValues={{ priority: 1 }}
      >
        <Row gutter={[10, 20]} justify="center">
          <Col span={10}>
            <Row gutter={[10, 20]}>
              <Col span={12}>
                <Form.Item
                  name={"type"}
                  label={"Type"}
                  labelAlign={"left"}
                  rules={[
                    {
                      required: true,
                      message: "Required field",
                    },
                  ]}
                >
                  <Select placeholder={"Select a type of maintenance"}>
                    {props.company.maintenanceType.map((type) => {
                      return (
                        <Select.Option key={type} value={type}>
                          {type}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={"priority"}
                  label={"Priority"}
                  labelAlign={"left"}
                  rules={[
                    {
                      required: true,
                      message: "Required field",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} min={1} max={10} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={"bot"}
                  label={"Bot name"}
                  labelAlign={"left"}
                  rules={[
                    {
                      required: true,
                      message: "Required field",
                    },
                  ]}
                >
                  <Select placeholder={"Select a bot"}>
                    {deliveredBots.map((bot) => {
                      return (
                        <Select.Option key={bot.key} value={bot.key}>
                          {bot.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={"date"}
                  label={"Schedule date"}
                  labelAlign={"left"}
                  rules={[
                    {
                      required: true,
                      message: "Required field",
                    },
                  ]}
                >
                  <DatePicker.RangePicker
                    format={dateFormat}
                    defaultValue={[moment()]}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={"asignee"}
                  label={"Team"}
                  labelAlign={"left"}
                  rules={[
                    {
                      required: true,
                      message: "Required field",
                    },
                  ]}
                >
                  {getChildren()}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={"description"}
                  label={"Description"}
                  labelAlign={"left"}
                  rules={[{ required: true, message: "Required field" }]}
                >
                  <Input.TextArea
                    placeholder={"Maintenance description"}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Form.Item>
                <Button
                  type={"primary"}
                  style={{ marginLeft: "6px" }}
                  htmlType="submit"
                >
                  Add Maintenance
                </Button>
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  ) : (
    <Empty description={"No Bots"} />
  );
};

export default SOCOFARMaintenance;
