import React from "react";
import { message } from "antd";
import AbancaDashboard from "./Abanca/AbancaDashboard";
import HEBDashboard from "./HEB/HEBDashboard";
import PruebaDashboard from "./Prueba/PruebaDashboard";
import LALADashboard from "./LALA/LALADashboard";
import SocofarDashboard from "./Socofar/SocofarDashboard";
import WalmartDashboard from "./Walmart/WalmartDashboard";

const CompanyDashboard = (props) => {
  if (props.companyName && props.userInfo && props.ideas)
    switch (props.companyName) {
      case "ABANCA":
        return (
          <AbancaDashboard userInfo={props.userInfo} ideas={props.ideas} />
        );
      case "HEB":
        return <HEBDashboard userInfo={props.userInfo} ideas={props.ideas} />;
      case "LALA":
        return <LALADashboard userInfo={props.userInfo} ideas={props.ideas} />;
      case "Walmart":
        return (
          <WalmartDashboard userInfo={props.userInfo} ideas={props.ideas} />
        );
      case "Socofar":
        return (
          <SocofarDashboard userInfo={props.userInfo} ideas={props.ideas} />
        );
      case "PRUEBA":
        return (
          <PruebaDashboard userInfo={props.userInfo} ideas={props.ideas} />
        );
      default:
        message.error("");
        return <></>;
    }
  else {
    message.error("");
    return <></>;
  }
};

export default CompanyDashboard;
