import React from "react";
import {
  Row,
  Col,
  Form,
  Checkbox,
  Space,
  Button,
  Radio,
  Slider,
  Card,
  Typography,
} from "antd";
import CalculatorJson from "./calculator.json";
import { checkNullable, zeroMoreTwo } from "./CalculatorCalcs/Constants";

const { Item } = Form;

const calculator = CalculatorJson.home;

const initialFormValues = {
  uniqueData: false,
  ocrImageUd: 0,
  ocrEmailUd: 0,
  ocrPdfUd: 0,
  ocrExcelUd: 0,
  ocrManuscriptUd: 0,
  requiredValidation: false,
};

const ocrSliders = [
  { name: "ocrImageUd", value: calculator.data_image_fc },
  { name: "ocrEmailUd", value: calculator.data_email },
  { name: "ocrPdfUd", value: calculator.data_pdf },
  { name: "ocrExcelUd", value: calculator.data_excel_varios },
  { name: "ocrManuscriptUd", value: calculator.data_manuscript },
];

const transformChecks = [
  "data_caption_input_zero",
  "data_caption_input_two",
  "data_caption_input_three",
  "data_caption_output_zero",
  "data_caption_output_two",
  "data_caption_output_three",
];

const marks = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  10: 10,
};

const TransformationComponent = () =>
  transformChecks.map((transform) => (
    <Col span={24} key={transform} offset={1}>
      <Item name={transform} valuePropName="checked">
        <Checkbox>{calculator[transform]}</Checkbox>
      </Item>
    </Col>
  ));

const RadioGroup = () => (
  <Radio.Group>
    <Space direction="vertical" size="middle">
      {zeroMoreTwo.map((element) => (
        <Radio value={element.value} key={element.name}>
          {element.name}
        </Radio>
      ))}
    </Space>
  </Radio.Group>
);

const Data = ({ nextStep, resetAll }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    // console.log(values);
    nextStep();
  };

  return (
    <Card
      type="inner"
      title={
        <Typography.Title level={5} className="primary-color">
          {calculator.data_title}
        </Typography.Title>
      }
    >
      <Row>
        <Form
          name="data"
          labelCol={12}
          layout="vertical"
          size="large"
          form={form}
          initialValues={initialFormValues}
          onFinish={onFinish}
          requiredMark={false}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Row gutter={[16, 0]}>
                    <Col span={24}>
                      <Space size="middle">
                        {calculator.data_unique}
                        <Item name="uniqueData" valuePropName="checked">
                          <Checkbox />
                        </Item>
                      </Space>
                    </Col>
                    <Col span={24}>
                      <Item
                        label={calculator.data_unique_negative}
                        name="canIdentifyUniqueData"
                        rules={[
                          {
                            required: true,
                            message: "Campo Requerido",
                          },
                        ]}
                      >
                        <Row>
                          <Col span={24} offset={1}>
                            <Radio.Group>
                              <Space direction="vertical" size="middle">
                                {checkNullable.map((element) => (
                                  <Radio
                                    value={Number(element.value)}
                                    key={element.name}
                                  >
                                    {element.name}
                                  </Radio>
                                ))}
                              </Space>
                            </Radio.Group>
                          </Col>
                        </Row>
                      </Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[16, 0]}>
                    <Col span={24}>{calculator.data_input_format}</Col>
                    <Col span={24} offset={1}>
                      <Item name="inputFromWeb" valuePropName="checked">
                        <Checkbox>{calculator.data_download_web}</Checkbox>
                      </Item>
                    </Col>
                    <Col span={24} offset={1}>
                      <Item name="inputFromExcel" valuePropName="checked">
                        <Checkbox> {calculator.data_excel}</Checkbox>
                      </Item>
                    </Col>
                    <Col span={24} offset={1}>
                      <Item name="inputFromOther" valuePropName="checked">
                        <Checkbox> {calculator.data_sql}</Checkbox>
                      </Item>
                    </Col>
                    <Col span={24}>{calculator.data_ocr}</Col>
                    {ocrSliders.map((ocr) => (
                      <Col span={24} key={ocr.name} offset={1}>
                        {ocr.value + ":"}
                        <Row>
                          <Col span={16}>
                            <Item name={ocr.name}>
                              <Slider
                                marks={marks}
                                step={10}
                                min={0}
                                max={10}
                              />
                            </Item>
                          </Col>
                        </Row>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Item
                    label={calculator.data_input_data}
                    name="data_input_data"
                    rules={[
                      {
                        required: true,
                        message: "Campo Requerido",
                      },
                    ]}
                  >
                    <Row gutter={[16, 0]}>
                      <Col span={24} offset={1}>
                        <RadioGroup />
                      </Col>
                    </Row>
                  </Item>
                </Col>
                <Col span={24}>
                  <Row gutter={[16, 0]}>
                    <Col span={24}>{calculator.data_output_format}</Col>
                    <Col span={24} offset={1}>
                      <Item name="outEmail" valuePropName="checked">
                        <Checkbox>{calculator.data_email_info}</Checkbox>
                      </Item>
                    </Col>
                    <Col span={24} offset={1}>
                      <Item name="outExcel" valuePropName="checked">
                        <Checkbox> {calculator.data_excel}</Checkbox>
                      </Item>
                    </Col>
                    <Col span={24} offset={1}>
                      <Item name="outOtherProcess" valuePropName="checked">
                        <Checkbox> {calculator.data_launch_process}</Checkbox>
                      </Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Item
                    label={calculator.data_many_output}
                    name="outMoreTwo"
                    rules={[
                      {
                        required: true,
                        message: "Campo Requerido",
                      },
                    ]}
                  >
                    <Row gutter={[16, 0]}>
                      <Col span={24} offset={1}>
                        <RadioGroup />
                      </Col>
                    </Row>
                  </Item>
                </Col>
                <Col span={24}>
                  <Row gutter={[16, 0]}>
                    <Col span={24}>
                      <Space size={"middle"}>
                        {calculator.data_tramsform}
                        <Item name="requiredValidation" valuePropName="checked">
                          <Checkbox />
                        </Item>
                      </Space>
                    </Col>
                    <TransformationComponent />
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="end">
                <Button
                  htmlType="submit"
                  shape="round"
                  className="button-submit-secondary submit-fixed submit-secondary-spaced"
                  onClick={resetAll}
                >
                  Cancelar
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  shape="round"
                  className="button-submit submit-fixed"
                >
                  Siguiente
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Row>
    </Card>
  );
};

export default Data;
