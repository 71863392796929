import React, { useState, useEffect } from "react";
import {
  Layout,
  Menu,
  Row,
  Typography,
  Col,
  Space,
  Checkbox,
  Input,
  Form,
  Button,
} from "antd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { LeftCircleOutlined } from "@ant-design/icons";
import { updateBoards } from "../../../../../db/firedataCalls";
import DeleteBoards from "./DeleteBoards";
const clone = require("rfdc")();

const { Header, Sider, Content } = Layout;

const SettingsPage = ({ closeSettings, company, userInfo, columns }) => {
  const [boards, setBoards] = useState([]);
  const [view, setView] = useState("1");
  const [editingBoard, setEditingBoard] = useState(null);
  const [editingValue, setEditingValue] = useState(null);

  useEffect(() => {
    if (company.todoConfig) {
      // Deconstruct to get only boards
      const {
        todoConfig: { boards },
      } = company;
      setBoards(boards);
    }
  }, [company]);

  // Customize order
  const order = () => {
    let tempBoards = clone(boards);
    const reorder = (startIndex, endIndex) => {
      const result = Array.from(boards);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      const newBoards = result.map((board, index) => ({
        ...board,
        order: index,
      }));
      updateBoards(newBoards, userInfo.companyId);
    };

    const onDragEnd = (result) => {
      if (!result.destination) return;
      const { source, destination } = result;
      reorder(source.index, destination.index);
    };

    if (tempBoards.length > 0)
      return (
        <Row>
          <Col span={24}>
            <Typography.Title level={2}>Orden</Typography.Title>
          </Col>
          <Col span={8}></Col>
          <Col span={16}>
            <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
              <div>
                <Row>
                  <Droppable droppableId={"1"} key={1}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            background: snapshot.isDraggingOver && "lightblue",
                            padding: "2px 16px",
                            width: 230,
                            height: (boards.length + 1) * 50,
                          }}
                        >
                          {boards.map((item, index) => {
                            return (
                              <Draggable
                                key={item.value}
                                draggableId={String(item.value)}
                                index={index}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={{
                                        userSelect: "none",
                                        padding: 16,
                                        minHeight: "50px",
                                        ...provided.draggableProps.style,
                                      }}
                                      className="css-card"
                                    >
                                      {index + 1} : {item.name}
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                        </div>
                      );
                    }}
                  </Droppable>
                </Row>
              </div>
            </DragDropContext>
          </Col>
        </Row>
      );
    else return null;
  };

  //  Customize highlighted boards
  const highlighted = () => {
    let tempBoards = clone(boards);
    const changeHighlighted = (marked) => {
      tempBoards.map((board) => {
        board.highlighted = null;
        if (marked.indexOf(board.name) !== -1) board.highlighted = true;
        return { ...board };
      });
      updateBoards(tempBoards, userInfo.companyId);
    };
    if (tempBoards.length > 0)
      return (
        <Row>
          <Col span={24}>
            <Typography.Title level={2}>Destacados</Typography.Title>
          </Col>
          <Col span={6}>
            <Typography.Text style={{ textAlign: "center" }}>
              Actividades que aparecerán marcadas en la pestaña de mis
              asignaciones
            </Typography.Text>
          </Col>
          <Col span={2}></Col>
          <Col span={16}>
            <Checkbox.Group
              options={tempBoards.map(({ name }) => name)}
              value={tempBoards.map((board) => board.highlighted && board.name)}
              onChange={(marked) => changeHighlighted(marked)}
            />
          </Col>
        </Row>
      );
    else return null;
  };

  //   First view (to customize boards)
  const updateBoardName = (text, board) => {
    let tempBoards = clone(boards);
    const index = tempBoards.findIndex(({ value }) => board.value === value);
    tempBoards[index].name = text;
    updateBoards(tempBoards, userInfo.companyId);
    setEditingValue(null);
    setEditingBoard(null);
  };

  const customizeBoards = () => {
    return (
      <Space size="large" direction="vertical">
        {customizeTitles()}
        {highlighted()}
        {order()}
      </Space>
    );
  };

  const customizeTitles = () => {
    if (boards)
      return (
        <Row>
          <Col span={24}>
            <Typography.Title level={2}>Títulos</Typography.Title>
          </Col>
          <Col span={8}></Col>
          <Col span={16}>
            {boards.map((board, index) => (
              <Row
                gutter={[20, 10]}
                style={{ paddingTop: 7 }}
                key={board.value}
              >
                <Space size="middle">
                  <Typography.Paragraph style={{ fontSize: 20 }}>
                    {index + 1 + ":"}
                  </Typography.Paragraph>
                  <Typography.Paragraph
                    style={{ fontSize: 20 }}
                    editable={{
                      onStart: () => {
                        setEditingBoard(board.value);
                        setEditingValue(board.name);
                      },
                      onChange: (text) => updateBoardName(text, board),
                    }}
                  >
                    {editingBoard === board.value ? editingValue : board.name}
                  </Typography.Paragraph>
                </Space>
              </Row>
            ))}
          </Col>
        </Row>
      );
    else return null;
  };

  const addBoard = () => {
    const onFinish = (values) => {
      let tempBoards = clone(boards);
      tempBoards.push({
        ...values,
        order: tempBoards.length,
        value:
          Number(
            tempBoards.reduce(
              (max, board) => (board.value > max ? board.value : max),
              0
            )
          ) + 1,
      });
      updateBoards(tempBoards, userInfo.companyId);
      closeSettings(1);
    };
    return (
      <Row>
        <Col span={24}>
          <Typography.Title level={2}>Añadir un nuevo tablero</Typography.Title>
        </Col>
        <Col span={8}></Col>
        <Col span={16}>
          <Form
            name="basic"
            initialValues={{ highlighted: false }}
            onFinish={(values) => onFinish(values)}
          >
            <Form.Item
              label="Título"
              name="name"
              labelAlign="left"
              rules={[
                {
                  required: true,
                  message: "Se necesita un nombre para el tablero",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="highlighted" valuePropName="checked">
              <Checkbox>Destacado</Checkbox>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Crear
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    );
  };

  const loadContent = () => {
    const option = Number(view);
    if (option === 1) return customizeBoards();
    if (option === 2) return addBoard();
    if (option === 3)
      return (
        <DeleteBoards
          closeSettings={closeSettings}
          boards={boards}
          columns={columns}
          userInfo={userInfo}
        />
      );
    else return null;
  };

  return (
    <Content style={{ overflow: "auto" }}>
      <Header style={{ padding: 18, height: 90 }}>
        <Row>
          <Col span={1}>
            <LeftCircleOutlined
              style={{ fontSize: "230%" }}
              onClick={() => closeSettings(1)}
            />
          </Col>
          <Col span={23} style={{ paddingTop: 12 }}>
            <Typography.Title style={{ marginLeft: 10 }} level={2}>
              Configuración
            </Typography.Title>
          </Col>
        </Row>
      </Header>
      <Layout>
        <Sider trigger={null}>
          <Row style={{ height: "100%" }}>
            <Menu
              theme="light"
              mode="inline"
              selectedKeys={[view]}
              onSelect={(item) => setView(item.key)}
            >
              <Menu.Item key="1">Personalizar tableros</Menu.Item>
              <Menu.Item key="2">Añadir tableros</Menu.Item>
              <Menu.Item key="3">Eliminar tableros</Menu.Item>
            </Menu>
          </Row>
        </Sider>
        <Content className="content-box">{loadContent()}</Content>
      </Layout>
    </Content>
  );
};

export default SettingsPage;
