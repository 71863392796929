import React, { useState, useEffect } from "react";
import moment from "moment";
import { Button, Badge, message, Row, Col } from "antd";
import { Layout, Spin, Card, Typography } from "antd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import "../../../../App.css";
import { updateActivity } from "../../../../db/firedataCalls";
import {
  EditOutlined,
  PlusCircleTwoTone,
  UpCircleOutlined,
  DownCircleOutlined,
} from "@ant-design/icons";
import ActivityModal from "./ActivityModal";
import { priorityMap } from "./Filters";
const boardWidth = 300;

const ActivitiesPanel = ({
  loading,
  columns,
  company,
  ideas,
  userInfo,
  setColumns,
  update,
}) => {
  const { Content } = Layout;
  const [activityModal, setActivityModal] = useState(false);
  const [editActivity, setEditActivity] = useState();
  const [modifiers, setModifiers] = useState();
  const [toggleColum, setToggleColum] = useState(true);

  useEffect(() => {
    return () => update();
  }, [userInfo.activities, update]);

  const onDragEnd = async (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.activities];
      const destItems = [...destColumn.activities];
      let [removed] = sourceItems.splice(source.index, 1);
      if (removed.personal) {
        let tempActivities = userInfo.activities;
        const index = tempActivities.findIndex(({ id }) => id === removed.id);
        if (destColumn.id === 4) {
          tempActivities[index].completed = true;
          tempActivities[index].completedDate = {
            seconds: moment().utc().unix(),
            nanoseconds: 0,
          };
        } else if (sourceColumn.id === 4) {
          tempActivities[index].completed = false;
          tempActivities[index].completedDate = null;
        }
        tempActivities[index].status = destColumn.id;
        await updateActivity(null, tempActivities, userInfo) /*  */
          .then(() => {
            removed.status = destColumn.id;
            if (destColumn.id === 4) {
              removed.completed = true;
              removed.completedDate = {
                seconds: moment().utc().unix(),
                nanoseconds: 0,
              };
            } else if (sourceColumn.id === 4) {
              removed.completed = false;
              removed.completedDate = null;
            }
            destItems.splice(destination.index, 0, removed);
            setColumns({
              ...columns,
              [source.droppableId]: {
                ...sourceColumn,
                activities: sourceItems,
              },
              [destination.droppableId]: {
                ...destColumn,
                activities: destItems,
              },
            });
          })
          .catch(() => message.error("ERROR: error en BD"));
      } else {
        let tempActivities = removed.idea.activities;
        const index = tempActivities.findIndex(({ id }) => id === removed.id);
        if (destColumn.id === 4) {
          tempActivities[index].completed = true;
          tempActivities[index].completedDate = {
            seconds: moment().utc().unix(),
            nanoseconds: 0,
          };
        } else if (sourceColumn.id === 4) {
          tempActivities[index].completed = false;
          tempActivities[index].completedDate = null;
        }
        tempActivities[index].status = destColumn.id;
        await updateActivity(removed.idea.id, tempActivities, null)
          .then(() => {
            removed.status = destColumn.id;
            if (destColumn.id === 4) {
              removed.completed = true;
              removed.completedDate = {
                seconds: moment().utc().unix(),
                nanoseconds: 0,
              };
            } else if (sourceColumn.id === 4) {
              removed.completed = false;
              removed.completedDate = null;
            }
            destItems.splice(destination.index, 0, removed);
            setColumns({
              ...columns,
              [source.droppableId]: {
                ...sourceColumn,
                activities: sourceItems,
              },
              [destination.droppableId]: {
                ...destColumn,
                activities: destItems,
              },
            });
          })
          .catch(() => message.error("ERROR: error en BD"));
      }
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.activities];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          activities: copiedItems,
        },
      });
    }
  };

  const openEditModal = (activity) => {
    setEditActivity({ ...activity, personal: activity.idea ? false : true });
    setModifiers({ origin: "ActivitiesPanel" });
    setActivityModal(true);
  };

  const ActivityCard = (activity, index, snapshot) => {
    let priority = priorityMap.find(({ value }) => value === activity.priority);
    return (
      <Draggable key={activity.id} draggableId={activity.id} index={index}>
        {(provided, snapshotDraggable) => {
          return (
            <Card
              hoverable={!snapshot.isDraggingOver}
              className={
                snapshotDraggable.isDragging
                  ? "isdragging-draggable-card"
                  : "draggable-card"
              }
              bordered={!snapshot.isDraggingOver}
              actions={
                !snapshotDraggable.isDragging &&
                !snapshot.isDraggingOver && [
                  <EditOutlined
                    key="edit"
                    onClick={() => openEditModal(activity)}
                  />,
                  <Badge color={priority.color} text={priority.label} />,
                  <Typography.Text type="secondary">
                    {activity.endDate
                      ? moment
                          .unix(activity.endDate.seconds)
                          .format("DD/MM/YYYY")
                      : "Sin Fecha"}
                  </Typography.Text>,
                ]
              }
            >
              <span
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={{ ...provided.draggableProps.style }}
                className={
                  snapshotDraggable.isDragging
                    ? "isdragging-inner-span"
                    : snapshot.isDraggingOver
                    ? "isdraggingOver-inner-span"
                    : "draggable-inner-span"
                }
              >
                <Card.Meta
                  description={
                    <Typography.Text>{activity.title}</Typography.Text>
                  }
                />
                <Typography.Text type="secondary" style={{ fontSize: "10px" }}>
                  {activity.personal
                    ? "Personal"
                    : activity.idea.idABANCA
                    ? activity.idea.idABANCA + ". " + activity.idea.name
                    : activity.idea.name}
                </Typography.Text>
              </span>
            </Card>
          );
        }}
      </Draggable>
    );
  };

  const CardList = (columnId, column) => {
    return (
      <Col style={{ maxWidth: boardWidth }} key={columnId}>
        <Card
          title={column.name}
          key={columnId}
          className="kanban-card"
          bodyStyle={{ margin: 0, border: 0, padding: 0 }}
          extra={
            column.id === 4 && (
              <Button
                type="text"
                onClick={() => setToggleColum(!toggleColum)}
                icon={
                  toggleColum ? <DownCircleOutlined /> : <UpCircleOutlined />
                }
              ></Button>
            )
          }
        >
          <Droppable droppableId={columnId} key={columnId}>
            {(provided, snapshot) => {
              return (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    background: snapshot.isDraggingOver && "lightblue",
                    padding: 4,
                    width: 250,
                    height: "auto",
                    maxHeight: "62vh",
                    overflowY: "auto",
                    position: "relative",
                  }}
                >
                  {column.id === 4
                    ? !toggleColum &&
                      column.activities.map((activity, index) =>
                        ActivityCard(activity, index, snapshot)
                      )
                    : column.activities.map((activity, index) =>
                        ActivityCard(activity, index, snapshot)
                      )}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
          <Button
            icon={<PlusCircleTwoTone twoToneColor="#69c0ff" />}
            block
            style={{ marginTop: "8px", color: "#69c0ff" }}
            onClick={() => {
              setModifiers({ panel: Number(column.id) });
              setActivityModal(true);
            }}
            type="link"
          >
            Nueva Actividad
          </Button>
        </Card>
      </Col>
    );
  };

  return (
    <Content style={{ overflow: "auto" }}>
      <ActivityModal
        visible={activityModal}
        setVisible={setActivityModal}
        setActivity={setEditActivity}
        activity={editActivity}
        company={company}
        ideasOptions={ideas}
        modifiers={modifiers}
        setModifiers={setModifiers}
        userInfo={userInfo}
      />
      <DragDropContext
        onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
      >
        <div>
          {loading ? (
            <Spin
              style={{
                height: "100vh",
                width: "100vw",
                textAlign: "center",
                marginTop: "25%",
              }}
              size="large"
              spinning={loading}
            />
          ) : (
            <Row
              style={{
                margin: "0 auto",
                width: boardWidth * Object.keys(columns).length,
              }}
            >
              {columns &&
                Object.entries(columns).map(([columnId, column]) => {
                  return CardList(columnId, column);
                })}
            </Row>
          )}
        </div>
      </DragDropContext>
    </Content>
  );
};

export default ActivitiesPanel;
