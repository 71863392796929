import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Spin,
  Drawer,
  Button,
  Table,
  Tag,
  Progress,
  Tooltip,
} from "antd";
import "../../../../../../App.css";
import CompanyIdeaDetails from "../../../Details/CompanyIdeaDetails";
import InputSearch from "../../InputSearch";
import { Resizable } from "react-resizable";
import ReactExport from "react-data-export";
import moment from "moment";

const BotsSummary = (props) => {
  const [botsData, setBotsData] = useState(null);
  const [botStatus, setBotStatus] = useState([]);
  const [arrayExcel, setArrayExcel] = useState([]);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const [showDatailsDrawer, setShowDetailsDrawer] = useState({
    idea: null,
    visible: false,
    idIdea: null,
  });

  const stageFilter = Object.entries(props.company.activities).map((stage) => ({
    text: stage[1],
    value: stage[1],
  }));

  const statusFilter = Object.entries(props.company.botStatus).map(
    (status) => ({
      text: status[1],
      value: status[1],
    })
  );

  const columns = [
    {
      title: "Area",
      dataIndex: "workCategory",
      width: "18%",
    },
    {
      title: "Idea Name",
      dataIndex: "ideaName",
      className: "clickable-col",
      render: (name, record) => (
        <span
          style={{ padding: "13px 13px 6px 6px", cursor: "pointer" }}
          onClick={() => handleRowClick(record.ideaId)}
        >
          {name}
        </span>
      ),
      width: "20%",
    },
    {
      title: "Bot Name",
      dataIndex: "name",
      width: "18%",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (record) => {
        let color;
        color =
          record === 0
            ? "processing"
            : record === 1
            ? "success"
            : record === 3
            ? "red"
            : "default";
        return (
          botStatus && (
            <>
              <Tag color={color} style={{ width: "90%", textAlign: "center" }}>
                {botStatus[record].toUpperCase()}
              </Tag>
            </>
          )
        );
      },
      onFilter: (value, record) => {
        return record && record.status
          ? botStatus[record.status].indexOf(value) === 0
          : false;
      },
      filters: [...statusFilter],
      width: "10%",
    },
    {
      title: "Stage",
      dataIndex: "stage",
      render: (text) => {
        return (
          <Tag color="default" style={{ width: "90%", textAlign: "center" }}>
            {text.toUpperCase()}
          </Tag>
        );
      },
      width: "10%",
      onFilter: (value, record) => {
        return record && record.stage
          ? record.stage.toString().indexOf(value) === 0
          : false;
      },
      filters: [...stageFilter],
    },
    {
      title: "Due date",
      dataIndex: "dueDateDiff",
      render: (text, record) => {
        if (text === -1 || !record.dueDate)
          return (
            <Tag color="default" style={{ width: "90%", textAlign: "center" }}>
              NOT SET
            </Tag>
          );
        else
          return (
            <Tooltip
              title={`${
                record.stageInfo.realEndDate ? "Completed on" : ""
              } ${moment
                .unix(record.dueDate.seconds)
                .format("dddd, MMMM Do YYYY")}`}
            >
              {text === 5 ? (
                <Tag
                  color="green"
                  style={{ width: "90%", textAlign: "center" }}
                >
                  COMPLETED
                </Tag>
              ) : text === 1 ? (
                <Tag color="red" style={{ width: "90%", textAlign: "center" }}>
                  OVERDUE
                </Tag>
              ) : text === 2 ? (
                <Tag
                  color="orange"
                  style={{ width: "90%", textAlign: "center" }}
                >
                  THIS WEEK
                </Tag>
              ) : text === 3 ? (
                <Tag color="lime" style={{ width: "90%", textAlign: "center" }}>
                  THIS MONTH
                </Tag>
              ) : (
                <Tag color="blue" style={{ width: "90%", textAlign: "center" }}>
                  ON TIME
                </Tag>
              )}
            </Tooltip>
          );
      },
      onFilter: (value, record) => record.dueDateDiff === value,
      filters: [
        { text: "OVERDUE", value: 1 },
        { text: "THIS WEEK", value: 2 },
        { text: "THIS MONTH", value: 3 },
        { text: "ON TIME", value: 4 },
        { text: "NOT SET", value: -1 },
        { text: "COMPLETED", value: 5 },
      ],
      width: "10%",
    },
    {
      title: "Maintenance",
      render: (record) => {
        if (record.maintenance) {
          let completed = 0;
          record.maintenance.forEach(
            (maintenance) => maintenance.realEndDate && completed++
          );
          return (
            <Tooltip
              title={
                "Completed: " +
                completed +
                " / Total: " +
                record.maintenance.length
              }
            >
              <Progress
                percent={(completed / record.maintenance.length) * 100}
                size="small"
                status="active"
                strokeColor="#096dd9"
                trailColor="#b5f5ec"
              />
            </Tooltip>
          );
        } else {
          return (
            <Tag color="default" style={{ textAlign: "center" }}>
              {"No Maintenances"}
            </Tag>
          );
        }
      },
      width: "14%",
    },
  ];

  useEffect(() => {
    if (props.ideas) {
      let bots = [];
      props.ideas.forEach((doc) => {
        doc.bots &&
          doc.bots.forEach((bot) =>
            bots.push({
              ...bot,
              ideaName: doc.name,
              workCategory: doc.workCategory,
              ideaId: doc.id,
            })
          );
      });
      let stageActivities = [];
      bots.forEach((element, index) => {
        let stage = "Nothing";
        let dueDate = null;
        let diff = -1;
        let stageInfo = null;
        let idea = props.ideas.find(({ id }) => id === element.ideaId);
        if (idea.activities) {
          stageActivities = idea.activities.filter(
            (activity) => activity.bot === element.key
          );
          if (stageActivities.length >= 1) {
            stage = stageActivities[stageActivities.length - 1].stage;
            dueDate = stageActivities[stageActivities.length - 1].endDate;
            stageInfo = stageActivities[stageActivities.length - 1];
          }
        }
        bots[index].stage = stage;
        bots[index].stageInfo = stageInfo;
        if (dueDate) {
          bots[index].dueDate = dueDate;
          if (bots[index].stageInfo.realEndDate) {
            bots[index].dueDate = bots[index].stageInfo.realEndDate;
            diff = 5;
          } else if (
            Number(moment.unix(dueDate.seconds).diff(moment(), "days")) < 0
          ) {
            diff = 1;
          } else if (
            Number(
              moment
                .unix(dueDate.seconds)
                .diff(moment().startOf("week"), "days")
            ) < 7
          ) {
            diff = 2;
          } else if (
            Number(
              moment.unix(dueDate.seconds).diff(moment().endOf("month"), "days")
            ) < 0
          ) {
            diff = 3;
          } else {
            diff = 4;
          }
        }
        bots[index].dueDateDiff = diff;
      });
      if (showDatailsDrawer.visible) {
        let index = props.ideas.findIndex(
          (idea) => idea.id === showDatailsDrawer.idIdea
        );
        setShowDetailsDrawer({
          visible: true,
          idea: props.ideas[index],
          idIdea: props.ideas[index].id,
        });
      }
      setBotsData(bots);
      setTableState({ data: bots });
      setBotStatus(props.company.botStatus);
    }
  }, [
    props.ideas,
    props.company.botStatus,
    showDatailsDrawer.idIdea,
    showDatailsDrawer.visible,
  ]);

  const downloadData = async () => {
    var arrayExcel = [];
    var maintenance = "";
    botsData.forEach((bot) => {
      if (bot.maintenance) {
        let completed = 0;
        bot.maintenance.forEach(
          (maintenance) => maintenance.realEndDate && completed++
        );
        maintenance = completed + "/" + bot.maintenance.length;
      } else {
        maintenance = "No maintenances";
      }
      arrayExcel.push({
        workCategory: bot.workCategory,
        ideaName: bot.ideaName,
        name: bot.name,
        stage: bot.stage,
        status: botStatus[bot.status],
        maintenance: maintenance,
      });
    });
    setArrayExcel(arrayExcel);
    setTimeout(() => {
      var link = document.getElementById("excelBots");
      link.click();
    }, 1000);
  };

  const Loader = () => (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        textAlign: "center",
        marginTop: "25%",
      }}
    >
      <Spin size="large" spinning={true}></Spin>
    </div>
  );

  const ResizableTitle = (props) => {
    const { onResize, width, ...restProps } = props;

    if (!width) {
      return <th {...restProps} />;
    }

    return (
      <Resizable
        width={width}
        height={0}
        handle={
          <span
            className="react-resizable-handle"
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        }
        onResize={onResize}
        draggableOpts={{ enableUserSelectHack: false }}
      >
        <th {...restProps}></th>
      </Resizable>
    );
  };

  const onClose = () => {
    setShowDetailsDrawer({ idea: null, visible: false, idIdea: null });
  };

  const handleRowClick = (rowId) => {
    let index = props.ideas.findIndex((idea) => idea.id === rowId);
    setShowDetailsDrawer({
      visible: true,
      idea: props.ideas[index],
      idIdea: props.ideas[index].id,
    });
  };

  const tableComponents = {
    header: {
      cell: ResizableTitle,
    },
  };
  const [tableState, setTableState] = useState({
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
  });

  const handleOnKeyChange = (key) => {
    if (!key) setTableState({ data: botsData });

    key = key.toLowerCase();
    const filteredData = botsData.filter(
      (entry) =>
        entry.ideaName.toLowerCase().includes(key) ||
        entry.name.toLowerCase().includes(key) ||
        entry.workCategory.toLowerCase().includes(key)
    );

    setTableState({ data: filteredData });
  };

  return botsData ? (
    <div style={{ padding: 10 }}>
      <Drawer
        style={{ background: "white" }}
        theme="light"
        width="100vw"
        onClose={onClose}
        visible={showDatailsDrawer.visible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          ></div>
        }
        destroyOnClose
      >
        <CompanyIdeaDetails
          company={props.company}
          onCloseDrawer={onClose}
          userInfo={props.userInfo}
          companyName={props.companyName}
          mappings={props.mappings}
          idea={showDatailsDrawer.idea}
        />
      </Drawer>
      <Row gutter={[20, 30]}>
        <Col span={24}>
          <Row>
            <Col span={6}>
              <InputSearch handleOnKeyChange={handleOnKeyChange} />
            </Col>
            <Col span={16}></Col>
            <Col span={2}>
              <Button
                onClick={() => {
                  downloadData();
                }}
                style={{ color: "white", backgroundColor: "#009900" }}
              >
                Excel
              </Button>
              <ExcelFile
                element={
                  <button id={"excelBots"} style={{ display: "none" }}>
                    Download Data
                  </button>
                }
                filename={"BotsSummary"}
              >
                <ExcelSheet key={"0"} data={arrayExcel} name={"Bots"}>
                  <ExcelColumn label="Area" value="workCategory" key={0} />
                  <ExcelColumn label="Idea" value="ideaName" key={1} />
                  <ExcelColumn label="Bot" value="name" key={2} />
                  <ExcelColumn label="Stage" value="stage" key={3} />
                  <ExcelColumn label="Status" value="status" key={4} />
                  <ExcelColumn
                    label="Maintenance"
                    value="maintenance"
                    key={5}
                  />
                </ExcelSheet>
              </ExcelFile>
            </Col>
          </Row>
          <Row>
            <Col>
              {/* {console.log(botsData)} */}
              <br></br>
              <Table
                pagination={{
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }}
                bordered
                components={tableComponents}
                rowKey={(record) => record.key + record.ideaId}
                columns={columns}
                dataSource={tableState.data}
                scroll={{ x: 1300 }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  ) : (
    <Loader />
  );
};

export default BotsSummary;
