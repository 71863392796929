import * as Constants from "./Constants";
import { RadioModel } from "./Classes";
import CalculatorJson from "../calculator.json";

const calculator = CalculatorJson.home;

// Calc for the EnvironmentSystem Section

export const getEnvironmentSystemResult = (
  environmentSystem
  /*
    {
      environmentSystem.office, (Boolean)
      environmentSystem.desktop, (Boolean)
      environmentSystem.web, (Boolean)
      environmentSystem.citrix, (Boolean)
      environmentSystem.terminal, (Boolean)
      environmentSystem.sap, (Boolean)
      environmentSystem.externalSystems, (Boolean)
      environmentSystem.requiredChanges, (Boolean)
    }
  */
) => {
  var internalCorrection = 0;

  var officeTemp = environmentSystem.office ? 1 : 1;
  var desktopTemp = environmentSystem.desktop ? 1 : 1;
  var webTemp = environmentSystem.web ? 1.1 : 1;
  var citrixTemp = environmentSystem.citrix ? 1.5 : 1;
  var terminalTemp = environmentSystem.terminal ? 2 : 1;
  var sapTemp = environmentSystem.sap ? 1.2 : 1;

  var requiredChangesTemp = environmentSystem.requiredChanges ? 1.2 : 1;
  var externalSystemsTemp = environmentSystem.externalSystems ? 1.2 : 1;

  // En el original también lo asigna
  internalCorrection =
    officeTemp *
    desktopTemp *
    webTemp *
    citrixTemp *
    terminalTemp *
    sapTemp *
    requiredChangesTemp *
    externalSystemsTemp;

  return internalCorrection;
};

// Calcs for the Data Section

export const getDataResult = (
  data
  /*
    {
      data.uniqueData, (Boolean)
      data.canIdentifyUniqueData, (Number)
      data.inputFromWeb, (Boolean)
      data.inputFromExcel, (Boolean)
      data.inputFromOther, (Boolean)
      data.ocrImageUd, (Number)
      data.ocrEmailUd, (Number)
      data.ocrPdfUd, (Number)
      data.ocrExcelUd, (Number)
      data.ocrManuscriptUd, (Number)
      data.inputMoreTwoUd, ({name (String), value (number)})
      data.outEmail, (Boolean)
      data.outExcel, (Boolean)
      data.outOtherProcess, (Boolean)
      data.outMoreTwo, ({name (String), value (number)})
      data.requiredValidation, (Boolean)
    }
  */
) => {
  var uniqueDataUd = 0.0;
  var uniqueDataTotal = 0.0;
  var uniqueDataAmount = 0.0;

  var canIdentifyUniqueDataUd = 0.0;
  var canIdentifyUniqueDataTotal = 0.0;
  var canIdentifyUniqueDataAmount = 0.0;

  var ocrImageTotal = 0.0;
  var ocrImageAmount = 0.0;

  var ocrEmailTotal = 0.0;
  var ocrEmailAmount = 0.0;

  var ocrPdfTotal = 0.0;
  var ocrPdfAmount = 0.0;

  var ocrExcelTotal = 0.0;
  var ocrExcelAmount = 0.0;

  var ocrManuscriptTotal = 0.0;
  var ocrManuscriptAmount = 0.0;

  if (data.uniqueData) {
    uniqueDataUd = Constants.readFile;
  } else {
    uniqueDataUd = Constants.readFile * 5;
  }

  switch (data.canIdentifyUniqueData) {
    case 0: // Si
      canIdentifyUniqueDataUd = Constants.changeInputFile;
      break;
    case 1: // No
      canIdentifyUniqueDataUd = Constants.changeInputFile * 20;
      break;
  }

  uniqueDataTotal = uniqueDataUd * Constants.readFile;
  uniqueDataAmount = uniqueDataTotal * Constants.mix;

  canIdentifyUniqueDataTotal =
    canIdentifyUniqueDataUd * Constants.changeInputFile;
  canIdentifyUniqueDataAmount = canIdentifyUniqueDataTotal * Constants.mix;

  ocrImageTotal = data.ocrImageUd * Constants.imageTemplate;
  ocrImageAmount = ocrImageTotal * Constants.mix;

  ocrEmailTotal = data.ocrEmailUd * Constants.email;
  ocrEmailAmount = ocrEmailTotal * Constants.mix;

  ocrPdfTotal = data.ocrPdfUd * Constants.pDF;
  ocrPdfAmount = ocrPdfTotal * Constants.mix;

  ocrExcelTotal = data.ocrExcelUd * Constants.excel;
  ocrExcelAmount = ocrExcelTotal * Constants.mix;

  ocrManuscriptTotal = data.ocrManuscriptUd * Constants.manuscript;
  ocrManuscriptAmount = ocrManuscriptTotal * Constants.mix;

  return (
    uniqueDataAmount +
    canIdentifyUniqueDataAmount +
    ocrImageAmount +
    ocrEmailAmount +
    ocrPdfAmount +
    ocrExcelAmount +
    ocrManuscriptAmount
  );
};

// Calcs for the Control Section

export const getControlResult = (
  control
  /* 
    {
      control.decisionTree, (Number)
      control.exception, (Number)
      control.exceptionHandled, (Number)
      control.maintenance, (Boolean)
      control.communication, (Boolean)
      control.recursive, (Boolean)
    }
  */
) => {
  var exceptionTotal = 0.0;
  var exceptionAmount = 0.0;

  var exceptionHandledTotal = 0.0;
  var exceptionHandledAmount = 0.0;

  var decisionTreeTotal = 0.0;
  var decisionTreeAmount = 0.0;

  exceptionTotal =
    Constants.kException * Constants.kException * control.exception;
  exceptionAmount = Constants.mix * exceptionTotal;

  exceptionHandledTotal =
    Constants.kExceptionHandled *
    Constants.kExceptionHandled *
    control.exceptionHandled;
  exceptionHandledAmount = Constants.mix * exceptionHandledTotal;

  decisionTreeTotal =
    Constants.kDecisionRule * Constants.kDecisionRule * control.decisionTree;
  decisionTreeAmount = Constants.mix * decisionTreeTotal;

  return exceptionAmount + exceptionHandledAmount + decisionTreeAmount;
};

// Calcs for the Interaction Section

export const getInteractionResult = (
  interaction
  /* 
    {
      interaction.screens, (Number)
      interaction.login, (Boolean)
      interaction.generationExcel, (Boolean)
      interaction.transformationExcel, (Boolean)
      interaction.macrosExcel, (Boolean)
      interaction.generationMail, (Boolean)
      interaction.attachMail, (Boolean)
      interaction.downloadMail, (Boolean)
    }
  */
) => {
  var loginUd;
  var emailCreationUd;
  var excelCreationUd;
  var excelModifyUd;
  var excelMacroUd;
  var mailAttachUd;
  var emailDownloadUd;

  if (interaction.login) loginUd = 1;
  else loginUd = 0;

  if (interaction.generationMail) emailCreationUd = 1;
  else emailCreationUd = 0;

  if (interaction.generationExcel) excelCreationUd = 1;
  else excelCreationUd = 0;

  if (interaction.transformationExcel) excelModifyUd = 1;
  else excelModifyUd = 0;

  if (interaction.macrosExcel) excelMacroUd = 1;
  else excelMacroUd = 0;

  if (interaction.attachMail) mailAttachUd = 1;
  else mailAttachUd = 0;

  if (interaction.downloadMail) emailDownloadUd = 1;
  else emailDownloadUd = 0;

  var loginTotal = loginUd * Constants.logIn;
  var loginAmount = loginTotal * Constants.mix;

  var excelCreationTotal1 = excelCreationUd * Constants.generation;
  var excelCreationTotal2 = excelCreationUd * Constants.templates;
  var excelCreationAmount1 = excelCreationTotal1 * Constants.mix;
  var excelCreationAmount2 = excelCreationTotal2 * Constants.mix;

  var excelCreationTotal = excelCreationTotal1 + excelCreationTotal2;
  var excelCreationAmount = excelCreationAmount1 + excelCreationAmount2;

  var excelModifyTotal = excelModifyUd * Constants.dataTreatment;
  var excelModifyAmount = excelModifyTotal * Constants.mix;

  var excelMacroTotal = excelMacroUd * Constants.macros;
  var excelMacroAmount = excelMacroTotal * Constants.mix;

  var emailCreationTotal = emailCreationUd * Constants.sendMail;
  var emailCreationAmount = emailCreationTotal * Constants.mix;

  var mailAttachTotal = mailAttachUd * Constants.moveFiles;
  var mailAttachAmount = mailAttachTotal * Constants.mix;

  var emailDownloadTotal = emailDownloadUd * Constants.downloadMailC;
  var emailDownloadAmount = emailDownloadTotal * Constants.mix;

  var actionNavTotal = interaction.screens * Constants.actionNav;
  var actionNavAmount = actionNavTotal * Constants.mix;

  var actionScreenTotal = interaction.screens * Constants.actionScreen;
  var actionScreenAmount = actionScreenTotal * Constants.mix;

  var captureTotal = interaction.screens * Constants.captureC;
  var captureAmount = captureTotal * Constants.mix;

  return (
    loginAmount +
    excelCreationAmount +
    excelModifyAmount +
    excelMacroAmount +
    mailAttachAmount +
    emailDownloadAmount +
    emailCreationAmount +
    actionNavAmount +
    actionScreenAmount +
    captureAmount
  );
};

// Calcs for the CalculatorBrain

export const getCalculatorBrainResult = (
  questions
  /*
    [
      {name (String), value (Number)}
    ]
   */
) => {
  var highAvg = 0.0;
  var result = "";

  var standardAvg =
    (questions[0].value +
      questions[2].value +
      questions[3].value +
      questions[4].value +
      questions[6].value +
      questions[7].value) /
    6;

  highAvg = (standardAvg + questions[1].value + questions[5].value) / 3;

  if (questions[1].value <= 2 || questions[5].value <= 2)
    result = "no_recommended";
  else if (highAvg <= 3) result = "no_recommended";
  else if (highAvg > 3 && highAvg < 5) result = "valuable_automation";
  else if (highAvg >= 5 && highAvg < 7.5) result = "recommended_automation";
  else result = "high_recommended_automation";

  return { highAvg: highAvg.toFixed(2), result: result };
};

// Calcs for the ComplexityResult

const getComplexityResult = (environmentCorrection, currentIndex) => {
  var complexityLevel =
    (environmentCorrection - Constants.kComplexity[currentIndex].amount) /
      (Constants.kComplexity[currentIndex + 1].amount -
        Constants.kComplexity[currentIndex].amount) +
    Constants.kComplexity[currentIndex].complexity;

  return {
    amount: Constants.kComplexity[currentIndex].amount,
    complexity: Constants.kComplexity[currentIndex].complexity,
    complexityLevel: complexityLevel,
    complexityName: Constants.kComplexity[currentIndex].complexityName,
    definition: Constants.kComplexity[currentIndex].definition,
  };
};

// Calcs for the FinalComplexityResult

export const getFinalComplexityResult = (
  environmentSystem,
  data,
  control,
  interaction
) => {
  var projectPercent = 1 - (Constants.gadi + Constants.dev + Constants.qa);
  var sum =
    getDataResult(data) +
    getInteractionResult(interaction) +
    getControlResult(control) +
    (environmentSystem.web ? 1 : 0) * Constants.openNav * Constants.mix;

  var documentation = projectPercent * sum;
  var implementation = Constants.qa * sum;
  var subTotal = sum + documentation + implementation + Constants.gadi * sum;
  var total = subTotal * getEnvironmentSystemResult(environmentSystem);

  var complexityResult = {};

  for (let index = 0; index < 10; index++) {
    if (
      Constants.kComplexity[index].amount < total &&
      Constants.kComplexity[index + 1].amount > total
    )
      complexityResult = getComplexityResult(total, index);
    if (
      complexityResult &&
      Object.keys(complexityResult).length > 0 &&
      complexityResult.constructor === Object
    )
      break;
  }

  return complexityResult;
};

// Sección Control del Código

class Control {
  constructor(
    decisionTree,
    exception,
    exceptionHandled,
    maintenance,
    communication,
    recursive
  ) {
    this.decisionTree = decisionTree; // RadioModel
    this.exception = exception; // RadioModel
    this.exceptionHandled = exceptionHandled; //RadioModel
    this.maintenance = maintenance; // Boolean
    this.communication = communication; // Boolean
    this.recursive = recursive; // Boolean
  }

  getResult() {
    var exceptionTotal = 0.0;
    var exceptionAmount = 0.0;

    var exceptionHandledTotal = 0.0;
    var exceptionHandledAmount = 0.0;

    var decisionTreeTotal = 0.0;
    var decisionTreeAmount = 0.0;

    exceptionTotal =
      Constants.kException * Constants.kException * this.exception.value;
    exceptionAmount = Constants.mix * exceptionTotal;

    exceptionHandledTotal =
      Constants.kExceptionHandled *
      Constants.kExceptionHandled *
      this.exceptionHandled.value;
    exceptionHandledAmount = Constants.mix * exceptionHandledTotal;

    decisionTreeTotal =
      Constants.kDecisionRule *
      Constants.kDecisionRule *
      this.decisionTree.value;
    decisionTreeAmount = Constants.mix * decisionTreeTotal;

    return exceptionAmount + exceptionHandledAmount + decisionTreeAmount;
  }
}

const useControl = new Control(
  new RadioModel("Una sola rama", 1),
  new RadioModel("No hay excepciones", 0),
  new RadioModel("0", 0),
  false,
  false,
  false
);

// Sección Data del Código

class Data {
  constructor(
    uniqueData,
    canIdentifyUniqueData,
    inputFromWeb,
    inputFromExcel,
    inputFromOther,
    inputMoreTwoUd,
    inputString,
    ocrImageUd,
    ocrEmailUd,
    ocrPdfUd,
    ocrExcelUd,
    ocrManuscriptUd,
    outEmail,
    outExcel,
    outOtherProcess,
    outMoreTwo,
    outString,
    requiredValidation
  ) {
    this.uniqueData = uniqueData; // Boolean
    this.canIdentifyUniqueData = canIdentifyUniqueData; // RadioModel
    this.inputFromWeb = inputFromWeb; // Boolean
    this.inputFromExcel = inputFromExcel; // Boolean
    this.inputFromOther = inputFromOther; // Boolean
    this.ininputMoreTwoUd = inputMoreTwoUd; // RadioModel
    this.inputString = inputString; // String
    this.ocrImageUd = ocrImageUd; // Integer
    this.ocrEmailUd = ocrEmailUd; // Integer
    this.ocrPdfUd = ocrPdfUd; // Integer
    this.ocrExcelUd = ocrExcelUd; // Integer
    this.ocrManuscriptUd = ocrManuscriptUd; // Integer
    this.outoutEmail = outEmail; // Boolean
    this.outExcel = outExcel; // Boolean
    this.outOtherProcess = outOtherProcess; // Boolean
    this.outMoreTwo = outMoreTwo; // RadioModel
    this.outString = outString; // String
    this.requiredValidation = requiredValidation; // Boolean
  }

  set inputString(value) {
    // value must be ininputMoreTwoUd.value
    switch (value) {
      case 0:
        this.inputString = calculator["data_caption_input_zero"];
        break;
      case 1:
      case 2:
        this.inputString = calculator["data_caption_input_two"];
        break;
      case 3:
        this.inputString = calculator["data_caption_input_three"];
        break;
    }
  }

  set outString(value) {
    // value must be outMoreTwo.value
    switch (value) {
      case 0:
        this.outString = calculator["data_caption_output_zero"];
        break;
      case 1:
      case 2:
        this.outString = calculator["data_caption_output_two"];
        break;
      case 3:
        this.outString = calculator["data_caption_output_three"];
        break;
    }
  }

  getResult() {
    var uniqueDataUd = 0.0;
    var uniqueDataTotal = 0.0;
    var uniqueDataAmount = 0.0;

    var canIdentifyUniqueDataUd = 0.0;
    var canIdentifyUniqueDataTotal = 0.0;
    var canIdentifyUniqueDataAmount = 0.0;

    var ocrImageTotal = 0.0;
    var ocrImageAmount = 0.0;

    var ocrEmailTotal = 0.0;
    var ocrEmailAmount = 0.0;

    var ocrPdfTotal = 0.0;
    var ocrPdfAmount = 0.0;

    var ocrExcelTotal = 0.0;
    var ocrExcelAmount = 0.0;

    var ocrManuscriptTotal = 0.0;
    var ocrManuscriptAmount = 0.0;

    if (this.uniqueData) {
      uniqueDataUd = Constants.readFile;
    } else {
      uniqueDataUd = Constants.readFile * 5;
    }

    switch (this.canIdentifyUniqueData.value) {
      case 0: // Si
        canIdentifyUniqueDataUd = Constants.changeInputFile;
        break;
      case 1: // No
        canIdentifyUniqueDataUd = Constants.changeInputFile * 20;
        break;
    }

    uniqueDataTotal = uniqueDataUd * Constants.readFile;
    uniqueDataAmount = uniqueDataTotal * Constants.mix;

    canIdentifyUniqueDataTotal =
      canIdentifyUniqueDataUd * Constants.changeInputFile;
    canIdentifyUniqueDataAmount = canIdentifyUniqueDataTotal * Constants.mix;

    ocrImageTotal = this.ocrImageUd * Constants.imageTemplate;
    ocrImageAmount = ocrImageTotal * Constants.mix;

    ocrEmailTotal = this.ocrEmailUd * Constants.email;
    ocrEmailAmount = ocrEmailTotal * Constants.mix;

    ocrPdfTotal = this.ocrPdfUd * Constants.pDF;
    ocrPdfAmount = ocrPdfTotal * Constants.mix;

    ocrExcelTotal = this.ocrExcelUd * Constants.excel;
    ocrExcelAmount = ocrExcelTotal * Constants.mix;

    ocrManuscriptTotal = this.ocrManuscriptUd * Constants.manuscript;
    ocrManuscriptAmount = ocrManuscriptTotal * Constants.mix;

    return (
      uniqueDataAmount +
      canIdentifyUniqueDataAmount +
      ocrImageAmount +
      ocrEmailAmount +
      ocrPdfAmount +
      ocrExcelAmount +
      ocrManuscriptAmount
    );
  }
}

const useData = new Data(
  false,
  new RadioModel("NA", 2),
  false,
  false,
  false,
  new RadioModel("0", 0),
  "",
  0,
  0,
  0,
  0,
  0,
  false,
  false,
  false,
  new RadioModel("0", 0),
  "",
  false
);

// Sección EnvironmentSystem del Código

class EnvironmentSystem {
  constructor(
    office,
    desktop,
    web,
    citrix,
    terminal,
    sap,
    externalSystems,
    requiredChanges,
    internalCorrection
  ) {
    this.office = office; // Boolean
    this.desktop = desktop; // Boolean
    this.web = web; // Boolean
    this.citrix = citrix; // Boolean
    this.terminal = terminal; // Boolean
    this.sap = sap; // Boolean
    this.externalSystems = externalSystems; // Boolean
    this.requiredChanges = requiredChanges; // Boolean
    this.internalCorrection = internalCorrection; // Double
  }

  getResult() {
    this.internalCorrection = 0;

    var officeTemp = this.office ? 1 : 1;
    var desktopTemp = this.desktop ? 1 : 1;
    var webTemp = this.web ? 1.1 : 1;
    var citrixTemp = this.citrix ? 1.5 : 1;
    var terminalTemp = this.terminal ? 2 : 1;
    var sapTemp = this.sap ? 1.2 : 1;

    var requiredChangesTemp = this.requiredChanges ? 1.2 : 1;
    var externalSystemsTemp = this.externalSystems ? 1.2 : 1;

    this.internalCorrection =
      officeTemp *
      desktopTemp *
      webTemp *
      citrixTemp *
      terminalTemp *
      sapTemp *
      requiredChangesTemp *
      externalSystemsTemp;

    return this.internalCorrection;
  }
}

var useEnvironmentSystem = new EnvironmentSystem(
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  0
);

// Sección Interaction del Código

class Interaction {
  constructor(
    screens,
    login,
    generationExcel,
    transformationExcel,
    macrosExcel,
    generationMail,
    attachMail,
    downloadMail,
    loginUd,
    loginTotal,
    loginAmount,
    excelCreationUd,
    excelCreationTotal,
    excelCreationAmount,
    excelModifyUd,
    excelModifyTotal,
    excelModifyAmount,
    excelMacroUd,
    excelMacroTotal,
    excelMacroAmount,
    mailAttachUd,
    mailAttachTotal,
    mailAttachAmount,
    emailDownloadUd,
    emailDownloadTotal,
    emailDownloadAmount,
    emailCreationUd,
    emailCreationTotal,
    emailCreationAmount,
    actionNavTotal,
    actionNavAmount,
    actionScreenTotal,
    actionScreenAmount,
    captureTotal,
    captureAmount
  ) {
    this.screens = screens; // Integer
    this.login = login; // Boolean
    this.generationExcel = generationExcel; // Boolean
    this.transformationExcel = transformationExcel; // Boolean
    this.macrosExcel = macrosExcel; // Boolean
    this.generationMail = generationMail; // Boolean
    this.attachMail = attachMail; // Boolean
    this.downloadMail = downloadMail; // Boolean
    this.loginUd = loginUd; // Double
    this.loginTotal = loginTotal; // Double
    this.loginAmount = loginAmount; // Double
    this.excelCreationUd = excelCreationUd; // Double
    this.excelCreationTotal = excelCreationTotal; // Double
    this.excelCreationAmount = excelCreationAmount; // Double
    this.excelModifyUd = excelModifyUd; // Double
    this.excelModifyTotal = excelModifyTotal; // Double
    this.excelModifyAmount = excelModifyAmount; // Double
    this.excelMacroUd = excelMacroUd; // Double
    this.excelMacroTotal = excelMacroTotal; // Double
    this.excelMacroAmount = excelMacroAmount; // Double
    this.mailAttachUd = mailAttachUd; // Double
    this.mailAttachTotal = mailAttachTotal; // Double
    this.mailAttachAmount = mailAttachAmount; // Double
    this.emailDownloadUd = emailDownloadUd; // Double
    this.emailDownloadTotal = emailDownloadTotal; // Double
    this.emailDownloadAmount = emailDownloadAmount; // Double
    this.emailCreationUd = emailCreationUd; // Double
    this.emailCreationTotal = emailCreationTotal; // Double
    this.emailCreationAmount = emailCreationAmount; // Double
    this.actionNavTotal = actionNavTotal; // Double
    this.actionNavAmount = actionNavAmount; // Double
    this.actionScreenTotal = actionScreenTotal; // Double
    this.actionScreenAmount = actionScreenAmount; // Double
    this.captureTotal = captureTotal; // Double
    this.captureAmount = captureAmount; // Double
  }

  getResult() {
    if (this.login) this.loginUd = 1;
    else this.loginUd = 0;

    if (this.generationMail) this.emailCreationUd = 1;
    else this.emailCreationUd = 0;

    if (this.generationExcel) this.excelCreationUd = 1;
    else this.excelCreationUd = 0;

    if (this.transformationExcel) this.excelModifyUd = 1;
    else this.excelModifyUd = 0;

    if (this.macrosExcel) this.excelMacroUd = 1;
    else this.excelMacroUd = 0;

    if (this.attachMail) this.mailAttachUd = 1;
    else this.mailAttachUd = 0;

    if (this.downloadMail) this.emailDownloadUd = 1;
    else this.emailDownloadUd = 0;

    this.loginTotal = this.loginUd * Constants.logIn;
    this.loginAmount = this.loginTotal * Constants.mix;

    var excelCreationTotal1 = this.excelCreationUd * Constants.generation;
    var excelCreationTotal2 = this.excelCreationUd * Constants.templates;
    var excelCreationAmount1 = excelCreationTotal1 * Constants.mix;
    var excelCreationAmount2 = excelCreationTotal2 * Constants.mix;

    this.excelCreationTotal = excelCreationTotal1 + excelCreationTotal2;
    this.excelCreationAmount = excelCreationAmount1 + excelCreationAmount2;

    this.excelModifyTotal = this.excelModifyUd * Constants.dataTreatment;
    this.excelModifyAmount = this.excelModifyTotal * Constants.mix;

    this.excelMacroTotal = this.excelMacroUd * Constants.macros;
    this.excelMacroAmount = this.excelMacroTotal * Constants.mix;

    this.emailCreationTotal = this.emailCreationUd * Constants.sendMail;
    this.emailCreationAmount = this.emailCreationTotal * Constants.mix;

    this.mailAttachTotal = this.mailAttachUd * Constants.moveFiles;
    this.mailAttachAmount = this.mailAttachTotal * Constants.mix;

    this.emailDownloadTotal = this.emailDownloadUd * Constants.downloadMailC;
    this.emailDownloadAmount = this.emailDownloadTotal * Constants.mix;

    var actionNavTotal = this.screens * Constants.actionNav;
    var actionNavAmount = actionNavTotal * Constants.mix;

    var actionScreenTotal = this.screens * Constants.actionScreen;
    var actionScreenAmount = actionScreenTotal * Constants.mix;

    var captureTotal = this.screens * Constants.captureC;
    var captureAmount = captureTotal * Constants.mix;

    return (
      this.loginAmount +
      this.excelCreationAmount +
      this.excelModifyAmount +
      this.excelMacroAmount +
      this.mailAttachAmount +
      this.emailDownloadAmount +
      this.emailCreationAmount +
      actionNavAmount +
      actionScreenAmount +
      captureAmount
    );
  }
}

var useInteraction = new Interaction(
  0,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0
);

// Sección
