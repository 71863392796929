import React, { useEffect, useState } from "react";
import { Table, Row, Col, Drawer, Tag } from "antd";
import "../../../../../../App.css";
import moment from "moment";
import InputSearch from "../../InputSearch";
import CreateIdeaHandler from "../../../CreateIdea/CreateIdeaHandler";
import CompanyIdeaDetails from "../../../Details/CompanyIdeaDetails";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import ExcelDownload from "../../../../../CustomComponents/ABANCA/ExcelDownload";

const Search = (props) => {
  const [state, setState] = useState({
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
  });
  const [tableData, setTableData] = useState([]);
  const [showDatailsDrawer, setShowDetailsDrawer] = useState({
    idea: null,
    visible: false,
    idIdea: null,
  });
  const [dirFilter, setDirFilter] = useState([]);
  const [dirGenFilter, setDirGenFilter] = useState([]);
  const [tipoTareaFilter, setTipoTareaFilter] = useState([]);

  const priorities = {
    VERY_HIGH: "Muy alta",
    HIGH: "Alta",
    MEDIUM: "Media",
    LOW: "Baja",
    VERY_LOW: "Muy baja",
  };
  const prioritiesColor = {
    VERY_HIGH: "#C41B24",
    HIGH: "#ccb894",
    MEDIUM: "#CECE94",
    LOW: "#97AC85",
    VERY_LOW: "#94B8CC",
  };
  const MapPriority = (priority) => {
    if (priority < 0.5) return "VERY_LOW";
    if (priority < 1.5) return "LOW";
    if (priority < 2.5) return "MEDIUM";
    if (priority < 3) return "HIGH";
    if (priority >= 3) return "VERY_HIGH";
  };

  const getStatus = (record) => {
    switch (record.status) {
      case -1:
        return "Sin procesar";
      case 2:
        return "En progreso";
      case 3:
        if (record.productionStatus) {
          return record.productionStatus === -1 ? "Detenido" : "Finalizado";
        }
        return "En producción";
      case 4:
        return "Rechazada";
      default:
        return "Undefined";
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "idABANCA",
      sorter: (a, b) =>
        a.idABANCA
          ? b.idABANCA
            ? parseInt(a.idABANCA) - parseInt(b.idABANCA)
            : parseInt(a.idABANCA) - 0
          : b.idABANCA
          ? 0 - parseInt(b.idABANCA)
          : 0 - 0,
      width: "5%",
    },
    {
      title: "Nombre",
      dataIndex: "name",
      render: (text, record) => (
        <span
          style={{ padding: "13px 13px 6px 6px", cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {record.name}
        </span>
      ),
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
    },
    {
      title: "Fecha creación",
      dataIndex: "createdDate",
      render: (createdDate) =>
        createdDate
          ? moment.unix(createdDate.seconds).format("DD/MM/YYYY")
          : "",
      sorter: {
        compare: (a, b) => a.createdDate.seconds - b.createdDate.seconds,
      },
    },
    {
      title: "Prioridad",
      dataIndex: "priority",
      sortDirections: ["descend", "ascend"],
      sorter: {
        compare: (a, b) =>
          a.priority
            ? b.priority
              ? a.priority - b.priority
              : a.priority - 0
            : b.priority
            ? 0 - b.priority
            : 0 - 0,
      },
      render: (priority, record) => {
        if (priority) {
          priority = MapPriority(priority);
          let color = prioritiesColor[priority];
          return (
            <Tag color={color} key={priority}>
              {`${priorities[priority].toUpperCase()}` +
                (record.priority ? ` (${record.priority})` : "")}
            </Tag>
          );
        } else {
          return <Tag key={priority}>{"SIN ASIGNAR"}</Tag>;
        }
      },
      filters: [
        { text: "Muy baja", value: "Muy baja" },
        { text: "Baja", value: "Baja" },
        { text: "Media", value: "Media" },
        { text: "Alta", value: "Alta" },
        { text: "Muy alta", value: "Muy alta" },
        { text: "Sin asignar", value: "Sin asignar" },
      ],
      onFilter: (value, record) => {
        return record.priority
          ? priorities[MapPriority(record.priority)].indexOf(value) === 0
          : "Sin asignar".indexOf(value) === 0;
      },
    },
    {
      title: "Dirección General",
      dataIndex: "dir_general",
      render: (text) => {
        return text && text;
      },
      sorter: {
        compare: (a, b) => a.dir_general.localeCompare(b.dir_general),
      },
      filters: dirGenFilter,
      onFilter: (value, record) => {
        return record.dir_general.indexOf(value) === 0;
      },
    },
    {
      title: "Dirección",
      dataIndex: "direccion",
      render: (text) => {
        return text && text;
      },
      sorter: {
        compare: (a, b) => a.direccion.localeCompare(b.direccion),
      },
      filters: dirFilter,
      onFilter: (value, record) => {
        return record.direccion.indexOf(value) === 0;
      },
    },
    {
      title: "Tipo de tarea",
      dataIndex: "tipoTarea",
      render: (text) => (text && text !== 0 ? text : ""),
      filters: tipoTareaFilter,
      onFilter: (value, record) => {
        return record.tipoTarea
          ? record.tipoTarea.indexOf(value) === 0
          : "Sin asignar".indexOf(value) === 0;
      },
    },
    {
      title: "Estatus",
      dataIndex: "status",
      render: (status, record) => {
        return getStatus(record);
      },
      sorter: {
        compare: (a, b) => a.status - b.status,
      },
      filters: [
        { text: "Sin procesar", value: "Sin procesar" },
        { text: "En progreso", value: "En progreso" },
        { text: "Detenido", value: "Detenido" },
        { text: "Finalizado", value: "Finalizado" },
        { text: "En producción", value: "En producción" },
        { text: "Rechazadas", value: "Rechazada" },
      ],
      onFilter: (value, record) => {
        return getStatus(record).indexOf(value) === 0;
      },
    },
    {
      title: "Petición CER",
      className: "center-column",
      dataIndex: "peticionCER",
      width: 100,
      render: (text, record) =>
        record.peticionCER ? (
          <CheckCircleFilled
            style={{
              fontWeight: "bolder",
              fontSize: "1.5em",
              color: "#b7eb8f",
            }}
          />
        ) : (
          <CloseCircleFilled
            style={{
              fontWeight: "bolder",
              fontSize: "1.5em",
              color: "#ffa39e",
            }}
          />
        ),
      onFilter: (value, record) => {
        return record.peticionCER === value;
      },
      filters: [
        { text: "Peticion CER", value: true },
        { text: "No peticion CER", value: false },
      ],
    },
  ];

  const handleRowClick = (row) => {
    setShowDetailsDrawer({ visible: true, idea: row, idIdea: row.id });
  };

  useEffect(() => {
    if (props.ideas) {
      let ideas = props.ideas;
      let direccion = [];
      let dir_general = [];
      setTableData(ideas);
      setState({
        data: ideas,
      });
      ideas.forEach((idea) => {
        if (!direccion.find((dir) => dir.value === idea.direccion))
          direccion.push({ text: idea.direccion, value: idea.direccion });
        if (
          !dir_general.find(
            (dir_general) => dir_general.value === idea.dir_general
          )
        )
          dir_general.push({ text: idea.dir_general, value: idea.dir_general });
      });
      setDirFilter(direccion);
      setDirGenFilter(dir_general);
    }
    if (showDatailsDrawer.visible) {
      let index = props.ideas.findIndex(
        (idea) => idea.id === showDatailsDrawer.idIdea
      );
      setShowDetailsDrawer({
        visible: true,
        idea: props.ideas[index],
        idIdea: props.ideas[index].id,
      });
    }
    let tipos = [];
    Object.values(props.company.tipoTarea).forEach((tipo) => {
      tipos.push({ text: tipo, value: tipo });
    });
    tipos.push({ text: "Sin asignar", value: "Sin asignar" });
    setTipoTareaFilter(tipos);
  }, [props.ideas, showDatailsDrawer.visible, showDatailsDrawer.idIdea]);

  const { data } = state;

  const handleOnKeyChange = (key) => {
    if (!key) setState({ data: tableData });

    key = key.toLowerCase();
    const filteredData = tableData.filter(
      (entry) =>
        entry.name.toLowerCase().includes(key) ||
        (entry.idABANCA && entry.idABANCA.toString().includes(key)) ||
        entry.direccion.toLowerCase().includes(key) ||
        entry.dir_general.toLowerCase().includes(key)
    );

    setState({ data: filteredData });
  };

  const onClose = () => {
    setShowDetailsDrawer({ visible: false, idea: null, idIdea: null });
  };

  return (
    <>
      <Row>
        <Col span={6}>
          <InputSearch handleOnKeyChange={handleOnKeyChange} />
        </Col>

        <Col offset={2} span={3}>
          <CreateIdeaHandler
            userInfo={props.userInfo}
            company={props.company}
            ideas={tableData}
          />
        </Col>
        <Col span={11}></Col>
        <Col span={2}>
          <ExcelDownload
            data={data}
            name={"Peticiones"}
            id={"excel1"}
            company={props.company}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            bordered
            dataSource={data}
            rowKey={(idea) => idea.id}
            columns={columns}
            scroll={{ x: 1300 }}
          />

          <Drawer
            style={{ background: "white" }}
            width="100vw"
            onClose={onClose}
            visible={showDatailsDrawer.visible}
            footer={
              <div
                style={{
                  textAlign: "right",
                }}
              ></div>
            }
            destroyOnClose
          >
            <CompanyIdeaDetails
              company={props.company}
              onCloseDrawer={onClose}
              userInfo={props.userInfo}
              companyName={props.companyName}
              mappings={props.mappings}
              idea={showDatailsDrawer.idea}
            />
          </Drawer>
        </Col>
      </Row>
    </>
  );
};

export default Search;
