import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Space,
  Button,
  Drawer,
  message,
  Tooltip,
} from "antd";
import {
  WarningOutlined,
  ExclamationCircleOutlined,
  SendOutlined,
} from "@ant-design/icons";
import moment from "moment";
import InputSearch from "../../InputSearch";
import { useTranslation } from "react-i18next";
import { onChangeApprovedABANCA } from "./InProgressValidations";
import { updateIdeaStatus } from "../../../../../../db/firedataCalls";
import CompanyIdeaDetails from "../../../Details/CompanyIdeaDetails";
import ReactExport from "react-data-export";

const InProgress = (props) => {
  const { t } = useTranslation();
  const [arrayExcel, setArrayExcel] = useState([]);

  const cardIconsStyle = {
    fontWeight: "bolder",
    fontSize: "2em",
    color: "#1890ff",
  };
  const cardTextStyle = {
    fontWeight: "bolder",
    fontSize: "large",
    color: "#1890ff",
  };

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const [inProgressData, setInProgressData] = useState({
    ideas: [],
    atRisk: 0,
    total: 0,
    offTrack: 0,
  });

  const getStatusColor = (targetDate) => {
    let days = 0;

    if (targetDate) {
      let a = moment.unix(targetDate._seconds);
      let b = moment(new Date());
      days = moment.duration(a.diff(b)).asDays();
    } else return "#b7eb8f";

    if (days <= 0) return "#ff7875";
    if (days > 0 && days <= 14) return "#ffcc00";
    else return "#b7eb8f";
  };

  const [showDatailsDrawer, setShowDetailsDrawer] = useState({
    idea: null,
    visible: false,
    idIdea: null,
  });

  const handleRowClick = (row) => {
    setShowDetailsDrawer({ visible: true, idea: row, idIdea: row.id });
  };

  const success = () => {
    message.success(t("Idea aprobada exitosamente"));
  };

  const error = (mensaje) => {
    message.error(t(mensaje.split(":")[1]));
  };

  const warning = () => {
    message.warning(
      t(
        "Existen actividades no completadas favor de completar antes de aprobar"
      )
    );
  };

  const onChangeApproved = async (idea, value) => {
    let res = "";
    switch (props.userInfo.companyId) {
      case "82Xt7pXfGf8YE9TcvfuN":
        res = await onChangeApprovedABANCA(idea, value);
        if (res === "true") success();
        if (res === "warning") warning();
        if (res.includes("error")) error(res);
        break;

      default:
        updateIdeaStatus(idea, 3);
        success();
        break;
    }
  };

  const onClose = () => {
    setShowDetailsDrawer({ idea: null, visible: false, idIdea: null });
  };

  const CircleSvg = ({ color }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill={color}
      version="1.1"
    >
      <circle cx="20" cy="20" r="10" />
    </svg>
  );

  const CircleIcon = (props) => <CircleSvg {...props} />;
  const columns = [
    {
      title: "ID",
      dataIndex: "idABANCA",
      sorter: (a, b) => parseInt(a.idABANCA) - parseInt(b.idABANCA),
      width: "5%",
    },
    {
      title: "Idea",
      dataIndex: "name",
      render: (text, record) => (
        <span
          style={{ padding: "13px 13px 6px 6px", cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {record.name}
        </span>
      ),
    },
    {
      title: t("table.headers.workCategory"),

      render: (priority, record) => {
        return record.departmentTitle;
      },
    },
    {
      title: t("Estatus"),
      dataIndex: "fechaMax",
      className: "center-column",
      render: (fechaMax) => (
        <Tooltip
          title={
            fechaMax &&
            fechaMax._seconds &&
            moment.unix(fechaMax._seconds).format("DD/MM/YYYY")
          }
        >
          <div>
            <CircleIcon color={getStatusColor(fechaMax)} />
          </div>
        </Tooltip>
      ),
    },
    {
      title: t("Usuario asignado"),
      dataIndex: "asigneeName",
    },
    {
      title: t("Fecha prevista"),
      dataIndex: "fechaMax",
      render: (fechaMax) =>
        fechaMax &&
        fechaMax._seconds &&
        moment.unix(fechaMax._seconds).format("DD/MM/YYYY"),
    },
    {
      title: t("Actividades completadas"),
      dataIndex: "activities",
      render: (text, record) =>
        record.activities && record.activities.length
          ? `${(
              (record.activities.filter((activity) => activity.completed)
                .length /
                record.activities.length) *
              100
            ).toFixed(2)}%`
          : "Sin actividades",
    },
    {
      title: t("Pase a producción"),
      fixed: "right",
      width: 190,
      render: (record) => (
        <Space size="middle">
          <Button
            style={{ width: "170px" }}
            key={record.id}
            onClick={(e) => onChangeApproved(record, true)}
            className="ant-btn"
          >
            {t("Pase a producción")}
          </Button>
        </Space>
      ),
    },
  ];

  const [tableState, setTableState] = useState({
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
  });

  const handleOnKeyChange = (key) => {
    if (!key) setTableState({ data: inProgressData.ideas });

    key = key.toLowerCase();
    const filteredData = inProgressData.ideas.filter(
      (entry) =>
        entry.name.toLowerCase().includes(key) ||
        (entry.idABANCA && entry.idABANCA.toString().includes(key)) ||
        (entry.departmentTitle &&
          entry.departmentTitle.toLowerCase().includes(key))
    );

    setTableState({ data: filteredData });
  };

  useEffect(() => {
    if (props.ideas) {
      let ideas = [];
      props.ideas.forEach(
        (doc) =>
          doc.status === 2 &&
          ideas.push({
            ...doc,
            id: doc.id,
          })
      );
      let total = ideas.length;
      console.log("total: ", total);
      setTableState({ data: ideas });
      let atRisk = ideas.filter((idea) => {
        let days = 0;
        if (idea.fechaMax) {
          let a = moment.unix(idea.fechaMax._seconds);
          let b = moment(new Date());
          days = moment.duration(a.diff(b)).asDays();
        }

        if (days > 0 && days <= 14) return true;
        else return false;
      }).length;
      let offTrack = ideas.filter((idea) => {
        let days = 0;
        if (idea.fechaMax) {
          let a = moment.unix(idea.fechaMax._seconds);
          let b = moment(new Date());
          days = moment.duration(a.diff(b)).asDays();
        }

        if (days <= 0 && idea.fechaMax) return true;
        else return false;
      }).length;
      setInProgressData({
        total: total,
        atRisk: atRisk,
        ideas: ideas,
        offTrack: offTrack,
      });
      if (showDatailsDrawer.visible) {
        let index = props.ideas.findIndex(
          (idea) => idea.id === showDatailsDrawer.idIdea
        );
        setShowDetailsDrawer({
          visible: true,
          idea: props.ideas[index],
          idIdea: props.ideas[index].id,
        });
      }
    }
  }, [props.ideas, showDatailsDrawer.idIdea, showDatailsDrawer.visible]);

  const downloadData = async () => {
    var arrayExcel = [];
    tableState.data.forEach((idea) => {
      arrayExcel.push({
        idABANCA: idea.id,
        name: idea.name,
        departmentTitle: idea.departmentTitle,
        status: idea.status,
        asigneeName: idea.asigneeName,
        fechaMax: idea.fechaMax,
        activities: idea.activities,
      });
    });
    setArrayExcel(arrayExcel);
    setTimeout(() => {
      var link = document.getElementById("excelBots3");
      link.click();
    }, 1000);
  };

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col span={8}>
          <Card>
            <Row>
              <Col span={18}>
                <Row>
                  <span>{t("TOTAL EN PROGRESO")}</span>
                </Row>
                <Row>
                  <span style={cardTextStyle}>{inProgressData.total}</span>
                </Row>
              </Col>
              <Col span={6}>
                <SendOutlined style={cardIconsStyle} />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Row>
              <Col span={18}>
                <Row>
                  <span>{t("EN RIESGO")}</span>
                </Row>
                <Row>
                  <span style={cardTextStyle}>{inProgressData.atRisk}</span>
                </Row>
              </Col>
              <Col span={6}>
                <WarningOutlined
                  style={{ ...cardIconsStyle, color: "#fadb14" }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Row>
              <Col span={18}>
                <Row>
                  <span>{t("VENCIDAS")}</span>
                </Row>
                <Row>
                  <span style={cardTextStyle}>{inProgressData.offTrack}</span>
                </Row>
              </Col>
              <Col span={6}>
                <ExclamationCircleOutlined
                  style={{ ...cardIconsStyle, color: "red" }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <br />
          <br />
          <div>
            <Drawer
              style={{ background: "white" }}
              theme="light"
              width="100vw"
              onClose={onClose}
              visible={showDatailsDrawer.visible}
              footer={
                <div
                  style={{
                    textAlign: "right",
                  }}
                ></div>
              }
            >
              <CompanyIdeaDetails
                company={props.company}
                onCloseDrawer={onClose}
                userInfo={props.userInfo}
                companyName={props.companyName}
                mappings={props.mappings}
                idea={showDatailsDrawer.idea}
              />
            </Drawer>
            <Row>
              <Col span={6}>
                <InputSearch handleOnKeyChange={handleOnKeyChange} />
              </Col>
              <Col span={16}></Col>
              <Col span={2}>
                <Button
                  onClick={() => {
                    downloadData();
                  }}
                  style={{ color: "white", backgroundColor: "#009900" }}
                >
                  Excel
                </Button>
                <ExcelFile
                  element={
                    <button id={"excelBots3"} style={{ display: "none" }}>
                      Download Data
                    </button>
                  }
                  filename={"In Progress"}
                >
                  <ExcelSheet key={"0"} data={arrayExcel} name={"Bots"}>
                    <ExcelColumn label="ID" value="idABANCA" key={0} />
                    <ExcelColumn label="Idea" value="name" key={1} />
                    <ExcelColumn
                      label={t("table.headers.workCategory")}
                      value="departmentTitle"
                      key={2}
                    />
                    <ExcelColumn
                      label={t("Estatus")}
                      value="fechaMax"
                      key={3}
                    />
                    <ExcelColumn
                      label={t("Usuario asignado")}
                      value="asigneeName"
                      key={4}
                    />
                    <ExcelColumn
                      label={t("Fecha prevista")}
                      value="fechaMax"
                      key={5}
                    />
                    <ExcelColumn
                      label={t("Actividades completadas")}
                      value="activities"
                      key={6}
                    />
                  </ExcelSheet>
                </ExcelFile>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table
                  bordered
                  columns={columns}
                  dataSource={tableState.data}
                  rowKey={(record) => record.id}
                  size="small"
                  scroll={{ x: 1300 }}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default InProgress;
