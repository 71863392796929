import React, { useEffect, useState } from "react";
import { Table, Row, Col, Drawer } from "antd";
import "../../../../../../App.css";
import moment from "moment";
import InputSearch from "../../InputSearch";
import CreateIdeaHandler from "../../../CreateIdea/CreateIdeaHandler";
import CompanyIdeaDetails from "../../../Details/CompanyIdeaDetails";
import { useTranslation } from "react-i18next";

const Search = (props) => {
  const [state, setState] = useState({
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
  });

  const { t } = useTranslation();
  const [tableData, setTableData] = useState([]);
  const [showDatailsDrawer, setShowDetailsDrawer] = useState({
    idea: null,
    visible: false,
    idIdea: null,
  });

  const getStatus = (record) => {
    switch (record.status) {
      case -1:
        return t("table.statuses.0");
      case 1:
        return t("table.statuses.1");
      case 2:
        return t("table.statuses.2");
      case 3:
        return t("table.statuses.3");
      case 4:
        return t("table.statuses.4");
      default:
        return "Undefined";
    }
  };

  const columns = [
    {
      title: "Idea",
      dataIndex: "name",
      render: (text, record) => (
        <span
          style={{ padding: "13px 13px 6px 6px", cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {record.name}
        </span>
      ),
    },
    {
      title: t("table.headers.createdDate"),
      dataIndex: "createdDate",
      render: (createdDate) =>
        createdDate
          ? moment.unix(createdDate.seconds).format("DD/MM/YYYY")
          : "",
      sorter: {
        compare: (a, b) => a.createdDate - b.createdDate,
        multiple: 2,
      },
    },
    {
      title: t("table.headers.priority"),
      dataIndex: "priority",
      sortDirections: ["descend", "ascend"],
      sorter: {
        compare: (a, b) => a.priority - b.priority,
      },
    },
    {
      title: t("table.headers.workCategory"),
      dataIndex: "workCategory",
    },
    {
      title: t("table.headers.status"),
      dataIndex: "status",
      render: (status, record) => {
        return getStatus(record);
      },
      sorter: {
        compare: (a, b) => a.status - b.status,
        multiple: 2,
      },
      filters: [
        { text: t("table.statuses.0"), value: t("table.statuses.0") },
        { text: t("table.statuses.1"), value: t("table.statuses.1") },
        { text: t("table.statuses.2"), value: t("table.statuses.2") },
        { text: t("table.statuses.3"), value: t("table.statuses.3") },
        { text: t("table.statuses.4"), value: t("table.statuses.4") },
      ],
      onFilter: (value, record) => {
        return getStatus(record).indexOf(value) === 0;
      },
    },
  ];

  const handleRowClick = (row) => {
    setShowDetailsDrawer({ visible: true, idea: row, idIdea: row.id });
  };

  useEffect(() => {
    if (props.ideas) {
      let ideas = props.ideas;
      setTableData(ideas);
      setState({
        data: ideas,
      });
      if (showDatailsDrawer.visible) {
        let index = props.ideas.findIndex(
          (idea) => idea.id === showDatailsDrawer.idIdea
        );
        setShowDetailsDrawer({
          visible: true,
          idea: props.ideas[index],
          idIdea: props.ideas[index].id,
        });
      }
    }
  }, [props.ideas, showDatailsDrawer.idIdea, showDatailsDrawer.visible]);

  const { data } = state;

  const handleOnKeyChange = (key) => {
    if (!key) setState({ data: tableData });

    key = key.toLowerCase();

    const filteredData = tableData.filter(
      (entry) =>
        entry.name.toLowerCase().includes(key) ||
        (entry.id && entry.id.toString().includes(key)) ||
        (entry.workCategory && entry.workCategory.toLowerCase().includes(key))
    );

    setState({ data: filteredData });
  };

  const onClose = () => {
    setShowDetailsDrawer({ visible: false, idea: null, idIdea: null });
  };

  return (
    <>
      <Row>
        <Col span={6}>
          <InputSearch handleOnKeyChange={handleOnKeyChange} />
        </Col>

        <Col offset={2} span={3}>
          <CreateIdeaHandler
            userInfo={props.userInfo}
            company={props.company}
            ideas={tableData}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            bordered
            dataSource={data}
            rowKey={(idea) => idea.id}
            columns={columns}
            scroll={{ x: 1300 }}
          />

          <Drawer
            style={{ background: "white" }}
            width="100vw"
            onClose={onClose}
            visible={showDatailsDrawer.visible}
            footer={
              <div
                style={{
                  textAlign: "right",
                }}
              ></div>
            }
            destroyOnClose
          >
            <CompanyIdeaDetails
              company={props.company}
              onCloseDrawer={onClose}
              userInfo={props.userInfo}
              companyName={props.companyName}
              mappings={props.mappings}
              idea={showDatailsDrawer.idea}
            />
          </Drawer>
        </Col>
      </Row>
    </>
  );
};

export default Search;
