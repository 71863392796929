import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Table,
  DatePicker,
  message,
  Select,
  Divider,
  Input,
  Tag,
  Popconfirm,
  Modal,
  Avatar,
  Tooltip,
} from "antd";
import { presetPalettes } from "@ant-design/colors";
import { updateActivitiesHEB } from "../../../../../db/firedataCalls";
import moment from "moment";
import "../../../../../App.css";
import LALAComments from "./LALAComments";
import LALAAssigned from "./LALAAssigned";
const dateFormat = "DD/MM/YYYY";

const LALAActivities = (props) => {
  const [form] = Form.useForm();
  const [statusMap, setStatusMap] = useState([]);
  const [users, setUsers] = useState([]);
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    let statusMap = [];
    let users = [];
    let colors = [];
    if (props.company.users) users = Object.values(props.company.users);
    Object.entries(props.company.activityStatus).forEach(([k, v]) => {
      switch (Number(k)) {
        case 0:
          statusMap.push({ status: Number(k), color: "default", name: v });
          break;
        case 1:
          statusMap.push({ status: Number(k), color: "orange", name: v });
          break;
        case 2:
          statusMap.push({ status: Number(k), color: "green", name: v });
          break;
        default:
          statusMap.push({ status: Number(k), color: "grey", name: v });
          break;
      }
      Object.values(presetPalettes).forEach((colorSet) => {
        colors.push(colorSet.primary);
      });
      let res = users.length / colors.length;
      if (res > 1) {
        for (let a = 0; a < Math.round(res); a++) {
          colors.push(...colors);
        }
      }
      /* users = [
        {
          companyId: "QrEN5Mn25tnNhdPET601",
          email: "juane.soto@softtek.com",
          firstName: "Juan",
          lastName1: "Soto",
          lastName2: "Luévano",
          rol: "User",
          status: "Active",
          uid: "7FVuUhLFfBUVXOfDeEo3UQvax7k1",
          username: "juane.soto@softtek.com",
        },
        {
          companyId: "QrEN5Mn25tnNhdPET601",
          email: "luis.luna@softtek.com",
          firstName: "Luis",
          lastName1: "de Luna",
          lastName2: "Saucedo",
          rol: "User",
          status: "Active",
          uid: "7FVuUFfBUVXOfDeEo3UQvax7k1",
          username: "luis.luna@softtek.com",
        },
        {
          companyId: "QrEN5Mn25tnNhdPET601",
          email: "javier.gutierrez@softtek.com",
          firstName: "Javier",
          lastName1: "Gutiérrez",
          lastName2: "Pérez",
          rol: "User",
          status: "Active",
          uid: "7FVuUhLFfVXOfDeEo3UQvax7k1",
          username: "javier.gutierrez@softtek.com",
        },
        {
          companyId: "QrEN5Mn25tnNhdPET601",
          email: "jorge.bohorquez@softtek.com",
          firstName: "Jorge",
          lastName1: "Bohorquez",
          lastName2: "Ibarra",
          rol: "User",
          status: "Active",
          uid: "7FVuUhLFfBUVXOfDeEovax7k1",
          username: "jorge.bohorquez@softtek.com",
        },
      ]; */
      users = users.map((user, index) => ({ ...user, color: colors[index] }));
      setUsers(users);
      setStatusMap(statusMap);
    });
    let currentStage = props.idea.activities.find(
      ({ bot, stage }) => bot === props.stage.bot && stage === props.stage.stage
    );
    setActivities(
      currentStage && currentStage.activities ? currentStage.activities : []
    );
  }, [
    props.company,
    props.stage.activities,
    props.idea,
    props.stage.bot,
    props.stage.stage,
  ]);

  const closeDrawer = () => {
    props.setVisible(false);
    props.setStage(null);
  };

  const onFinish = async (values) => {
    let user = null;
    let tempActivities = activities;
    let stages = props.idea.activities;
    let index = stages.findIndex(
      ({ bot, stage }) => bot === props.stage.bot && stage === props.stage.stage
    );
    values.asignee = values.asignee.map((asignee) => {
      user = users.find(({ uid }) => uid === asignee);
      return {
        name: returnName(user),
        email: user.email,
        uid: user.uid,
      };
    });
    tempActivities.push({
      asignee: values.asignee,
      startDate: { seconds: values.date[0].unix(), nanoseconds: 0 },
      endDate: { seconds: values.date[1].unix(), nanoseconds: 0 },
      status: 0,
      name: values.name,
      id:
        Number(
          tempActivities.reduce(
            (max, activity) => (activity.id > max ? activity.id : max),
            0
          )
        ) + 1,
    });
    stages[index].activities = tempActivities;
    form.resetFields();
    setActivities([...tempActivities]);
    updateActivitiesHEB(props.idea.id, stages)
      .then(() => {
        message.success("Activity succesfully added. Email sent to asignee");
      })
      .catch((error) => {
        message.error("There was an error while adding the activity.");
        console.log(error);
      });
  };

  const returnName = (user) => {
    return (
      user.firstName +
      " " +
      (user.lastName1 &&
        user.lastName1 + (user.lastName2 && " " + user.lastName2))
    );
  };

  const deleteActivity = (record) => {
    let tempActivities = activities;
    tempActivities = tempActivities.filter(({ id }) => id !== record.id);
    let stages = props.idea.activities;
    let index = stages.findIndex(
      ({ bot, stage }) => bot === props.stage.bot && stage === props.stage.stage
    );
    stages[index].activities = tempActivities;
    updateActivitiesHEB(props.idea.id, stages)
      .then(() => {
        message.success("Activity succesfully deleted.");
        setActivities([...tempActivities]);
        /* closeDrawer(); */
      })
      .catch((error) => {
        message.error("There was an error deleting the activity.");
        console.log(error);
      });
  };

  const changeActivityStatus = (record) => {
    let index = activities.findIndex(({ id }) => id === record.id);
    let tempActivity = activities[index];
    let tempActivities = activities;
    tempActivity.status += 1;
    tempActivities[index] = tempActivity;
    let stages = props.idea.activities;
    index = stages.findIndex(
      ({ bot, stage }) => bot === props.stage.bot && stage === props.stage.stage
    );
    stages[index].activities = tempActivities;
    updateActivitiesHEB(props.idea.id, stages)
      .then(() => {
        message.success("Activity succesfully updated.");
        setActivities([...tempActivities]);
      })
      .catch((error) => {
        message.error("There was an error updating the activity.");
        console.log(error);
      });
  };

  const getChildren = () => {
    return (
      <Select
        placeholder={"Asign to someone"}
        mode="multiple"
        optionLabelProp="label"
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {users.map((user) => (
          <Select.Option
            key={user.uid}
            value={user.uid}
            label={returnName(user)}
          >
            <Tooltip placement="right" title={user.email}>
              <Avatar style={{ backgroundColor: user.color }}>
                {user.firstName[0].toUpperCase() +
                  user.lastName1[0].toUpperCase()}
              </Avatar>
              {" " + returnName(user)}
            </Tooltip>
          </Select.Option>
        ))}
      </Select>
    );
  };

  const columns = [
    {
      title: "Activity",
      dataIndex: "name",
    },
    {
      title: "Expected Date",
      render: (text, record) =>
        record
          ? `${moment
              .unix(record.startDate.seconds)
              .format(dateFormat)} - ${moment
              .unix(record.endDate.seconds)
              .format(dateFormat)} `
          : "",
    },
    {
      title: "Comments",
      render: (text, record) => {
        return (
          <LALAComments
            record={record}
            users={users}
            userInfo={props.userInfo}
            idea={props.idea}
            stage={props.stage}
            origin={"LALAActivities"}
          />
        );
      },
      width: "20%",
    },
    {
      title: "Team",
      render: (text, record) => {
        return (
          <LALAAssigned
            record={record}
            users={users}
            userInfo={props.userInfo}
            idea={props.idea}
            stage={props.stage}
            origin={"LALAActivities"}
          />
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (record) => {
        let tag = statusMap.find(({ status }) => status === record);
        return (
          tag && (
            <Tag
              style={{ width: "80px", textAlign: "center" }}
              color={tag.color}
            >
              {tag.name}
            </Tag>
          )
        );
      },
    },
    {
      title: "Change Status",
      key: "action",
      render: (text, record) => {
        return (
          <Popconfirm
            placement="bottom"
            title={"Change status?"}
            okText="Yes"
            disabled={record.status === 2}
            cancelText="No"
            onConfirm={() => changeActivityStatus(record)}
          >
            <Button disabled={record.status === 2}>Change Status</Button>
          </Popconfirm>
        );
      },
    },
    {
      title: "Action",
      render: (text, record) => {
        return (
          <Popconfirm
            placement="bottom"
            title={"Delete activity?"}
            okText={"Yes"}
            cancelText="No"
            onConfirm={() => deleteActivity(record)}
          >
            <Button
              type="link"
              style={{ width: "50px", borderLeft: "0px", paddingLeft: "0px" }}
            >
              {"Delete"}
            </Button>
          </Popconfirm>
        );
      },
    },
  ];
  return props.stage ? (
    <Modal
      title={"Activities"}
      visible={props.visible}
      onOk={closeDrawer}
      onCancel={closeDrawer}
      width={1300}
      footer={null}
    >
      <Col>
        <Table
          columns={columns}
          dataSource={activities}
          rowKey={(record) => record.id}
          pagination={{ pageSize: 3 }}
        />
      </Col>
      <Divider>New Activity</Divider>

      <Form onFinish={onFinish} form={form} name="New Bot">
        <Row gutter={[10, 20]} justify="end">
          <Col span={20}>
            <Row gutter={[10, 20]}>
              <Col span={13}>
                <Form.Item
                  name={"name"}
                  label={"Activity"}
                  labelAlign={"left"}
                  rules={[
                    {
                      required: true,
                      message: "Required field",
                    },
                  ]}
                >
                  <Input placeholder={"Activity"} />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item
                  name={"date"}
                  label={"Date"}
                  labelAlign={"left"}
                  rules={[
                    {
                      required: true,
                      message: "Required field",
                    },
                  ]}
                >
                  <DatePicker.RangePicker />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[10, 20]}>
              <Col span={13}>
                <Form.Item
                  name={"asignee"}
                  label={"Asign the activity"}
                  labelAlign={"left"}
                  rules={[{ required: true, message: "Required field" }]}
                >
                  {getChildren()}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[80, 20]}>
              <Form.Item>
                <Button
                  type={"primary"}
                  style={{ marginLeft: "6px" }}
                  htmlType="submit"
                >
                  Add Activity
                </Button>
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  ) : null;
};

export default LALAActivities;
