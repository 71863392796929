import firebase from '../server/firebase';
import moment from 'moment';
import fire from 'firebase';
import { message } from 'antd';
const statuses = {
  not_processed: -1,
  in_pipeline: 1,
  in_progress: 2,
  deployed: 3,
};

/**
 * getLastMonthsIdeas regresa un arreglo con las ideas de los ultimos n meses
 * especificados en el parametro months.
 * */
export const getLastMonthsIdeas = (months, ideas) => {
  try {
    let data = [];
    let endDate = moment().subtract(months, 'months');
    ideas.forEach((doc) => {
      if (doc.createdDate.seconds >= endDate.unix()) data.push(doc);
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};

/**
 * @param status
 * Esta función regresa la suma del campo estimatedBenefit de todas las ideas
 * para obtener el total de beneficio estimado.
 */
export const getBenefitSumByStatus = (status, docs) => {
  try {
    let benefitSum = 0;
    for (let doc of docs) {
      //Se hace la validación por status
      if (doc.status === status) {
        if (!doc.estimatedBenefit || Number(doc.estimatedBenefit) === 'NaN')
          continue;
        benefitSum += Number(doc.estimatedBenefit);
      }
    }

    return benefitSum;
  } catch (error) {
    console.error(error);
  }
};

export const getIdeasCountByStatus = async (ideas) => {
  try {
    let notProcessedIdeas = [];
    ideas.forEach((idea) => {
      if (idea.status === statuses.not_processed) notProcessedIdeas.push(idea);
    });

    let inPipeline = [];
    ideas.forEach((idea) => {
      if (idea.status === statuses.in_pipeline) inPipeline.push(idea);
    });

    let inProgress = [];
    ideas.forEach((idea) => {
      if (idea.status === statuses.in_progress) inProgress.push(idea);
    });

    let deployed = [];
    ideas.forEach((idea) => {
      if (idea.status === statuses.deployed) deployed.push(idea);
    });

    let data = {
      not_processed: notProcessedIdeas ? notProcessedIdeas.length : 0,
      in_pipeline: inPipeline ? inPipeline.length : 0,
      in_progress: inProgress ? inProgress.length : 0,
      deployed: deployed ? deployed.length : 0,
    };

    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getIncidencias = async (ideas) => {
  var completed = [];
  var noncompleted = [];

  ideas.forEach((idea) => {
    if (idea.issues) {
      idea.issues.forEach((issue) => {
        if (issue.closed) {
          completed.push(issue);
        } else {
          noncompleted.push(issue);
        }
      });
    }
  });

  const data = [
    { type: 'Completadas', value: completed.length },
    { type: 'Incompletas', value: noncompleted.length },
  ];

  return data;
};

export const getAllWorkCategoryStats = async (ideas) => {
  let workCategoryStats = {};

  let hrStats = getStatsByWorkCategory('RH', ideas);
  let itStats = getStatsByWorkCategory('IT', ideas);
  let csStats = getStatsByWorkCategory('CUSTOMER_SERVICES', ideas);
  let dsStats = getStatsByWorkCategory('DELIVERY_SERVICES', ideas);
  let adminStats = getStatsByWorkCategory('ADMIN', ideas);
  let prodStats = getStatsByWorkCategory('PRODUCTION', ideas);

  workCategoryStats['RH'] = hrStats;
  workCategoryStats['IT'] = itStats;
  workCategoryStats['CUSTOMER_SERVICES'] = csStats;
  workCategoryStats['DELIVERY_SERVICES'] = dsStats;
  workCategoryStats['ADMIN'] = adminStats;
  workCategoryStats['PRODUCTION'] = prodStats;

  return workCategoryStats;
};

export const getStatsByWorkCategory = (workCategory, ideas) => {
  try {
    const ideasByWorkCategory = [];
    ideas.forEach((idea) => {
      if (idea.workCategory === workCategory) ideasByWorkCategory.push(idea);
    });
    const ideasByWorkCategoryDeployed = [];
    ideas.forEach((idea) => {
      if (
        idea.workCategory === workCategory &&
        idea.status === statuses.deployed
      )
        ideasByWorkCategoryDeployed.push(idea);
    });

    const totalBenefit = ideasByWorkCategory.reduce((prev, cur) => {
      return prev + cur.estimatedBenefit;
    }, 0);

    const totalIdeas = ideasByWorkCategory.length;
    const totalDeployed = ideasByWorkCategoryDeployed.length;
    let data = {
      workCategory: workCategory,
      totalIdeasSubmitted: totalIdeas,
      totalIdeasDeployed: totalDeployed,
      totalBenefit: totalBenefit,
    };

    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getStageBenefit = (stage, ideas) => {
  let filtered = [];
  ideas.forEach((idea) => {
    if (idea.status === stage) filtered.push(idea);
  });

  const totalBenefit = filtered.reduce((prev, cur) => {
    return prev + cur.estimatedBenefit;
  }, 0);
  return totalBenefit;
};

export const getBenefitsByStage = (ideas) => {
  let benefitsByStage = {};

  try {
    let notProcessed = getStageBenefit(statuses.not_processed, ideas);
    let inPipeline = getStageBenefit(statuses.in_pipeline, ideas);
    let inProgress = getStageBenefit(statuses.in_progress, ideas);
    let deployed = getStageBenefit(statuses.deployed, ideas);

    benefitsByStage['not_processed'] = notProcessed;
    benefitsByStage['in_pipeline'] = inPipeline;
    benefitsByStage['in_progress'] = inProgress;
    benefitsByStage['deployed'] = deployed;

    return benefitsByStage;
  } catch (error) {
    console.error(error);
  }
};

export const getIdeasPorDireccion = (ideas) => {
  let ideasPorDireccion = {};
  ideas.forEach((idea) => {
    if (ideasPorDireccion[idea.departmentTitle]) {
      ideasPorDireccion[idea.departmentTitle] =
        ideasPorDireccion[idea.departmentTitle] + 1;
    } else {
      ideasPorDireccion[idea.departmentTitle] = 1;
    }
  });

  return ideasPorDireccion;
};

export const createIdea = async (values) => {
  try {
    await firebase
      .database()
      .ref()
      .child('ideas')
      .child(values.id)
      .update(values);
  } catch (error) {
    console.error(error);
  }
};

export const updateIdea = async (id, values) => {
  try {
    await firebase.database().ref().child('ideas').child(id).update(values);
  } catch (error) {
    console.error(error);
  }
};

//Será usada para la creación de ideas de HEB, LALA y Socofar
export const createIdeaHEB = async (values) => {
  try {
    let pushRef = await firebase
      .database()
      .ref()
      .child('ideas')
      .push({ ...values });
    let id = pushRef.key;

    return await firebase
      .database()
      .ref()
      .child('ideas')
      .child(id)
      .update({ id: id });
  } catch (error) {
    console.error(error);
  }
};

export const loginUser = async (values) => {
  try {
    firebase
      .auth()
      .signInWithEmailAndPassword(values.username, values.password)
      .then((result) => {
        console.log('Logged in', result);
      })
      .catch(function (error) {
        /* let errorCode = error.code;
        let errorMessage = error.message;
        console.error(errorCode, errorMessage); */
        message.error('The email/password is incorrect');
        return error;
      });
  } catch (error) {
    console.error(error);
  }
};
export const loginUserMicrosoft = async (newUser) => {
  try {
    var provider = new fire.auth.OAuthProvider('microsoft.com');
    provider.addScope('openid');
    provider.addScope('profile');
    provider.addScope('User.Read');
    provider.addScope('user.readbasic.all');

    // TODO: Make a logon for softtek accounts
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        const currUser = firebase.auth().currentUser;
        if (newUser && currUser) {
          newUser.email = currUser.providerData[0].email;
          if (
            String(newUser.email).endsWith('@externos.abanca.com') ||
            String(newUser.email).endsWith('@abanca.com')
          )
            newUser.companyId = '82Xt7pXfGf8YE9TcvfuN';
          else newUser.companyId = '0SSRHnxHythDoAcaApkw';
          if (!newUser.lastName2) newUser.lastName2 = '';
          signUpMicrosoft(result, newUser);
        }
      })
      .catch(async (error) => {
        message.error('An error happened', error);
      });
  } catch (error) {
    console.error('An error happened', error);
  }
};

export const signUpMicrosoft = async (result, values) => {
  try {
    await firebase
      .database()
      .ref()
      .child('users')
      .orderByChild('uid')
      .equalTo(result.user.uid)
      .limitToLast(1)
      .once('value')
      .then(async (snapshot) => {
        if (snapshot.exists()) {
          return message.error('The user already exists! ');
        } else
          await firebase
            .database()
            .ref()
            .child('users')
            .push({
              uid: result.user.uid,
              ...values,
              rol: 'USER',
              status: 3,
            })
            .then((newUser) => {
              console.log(newUser.key);
              message.success('Request succesful!');
              window.location.reload();
            });
      });
  } catch (error) {
    message.error('An error happened');
    // console.error(error);
  }
};

export const uploadFile = async (id, file) => {
  let storageRef = firebase.storage().ref();
  let fileRef = storageRef.child(`idea_files/${id}/${file.name}`);

  return await fileRef
    .put(file)
    .then((snapshot) => {
      return { name: snapshot.ref.name };
    })
    .catch((error) => console.error('Error uploading file.', error));
};

export const removeFile = async (id, fileName) => {
  let storageRef = firebase.storage().ref();
  let fileRef = storageRef.child(`idea_files/${id}/${fileName}`);

  await fileRef.delete();
  return true;
};
export const getDownloadURL = async (id, fileName) => {
  let storageRef = firebase.storage().ref();
  let fileRef = storageRef.child(`idea_files/${id}/${fileName}`);

  let url = await fileRef.getDownloadURL();
  return url;
};

export const getAuthUserInfo = async (uid) => {
  const user = await firebase
    .database()
    .ref()
    .child('users')
    .orderByChild('uid')
    .equalTo(uid)
    .once('value');
  return user.val();
};

export const getUsers = (companyId) => {
  return firebase
    .database()
    .ref()
    .child('users')
    .orderByChild('companyId')
    .equalTo(companyId);
};

export const updateIdeaStatus = async (idea, status) => {
  await firebase
    .database()
    .ref()
    .child('ideas')
    .child(idea.id)
    .update({ status: status });
};

export const createNewIdea = async (values, userInfo, users) => {
  // Create Idea for ABANCA
  try {
    let _date = moment().utc();
    values['createdDate'] = { seconds: _date.unix(), nanoseconds: 0 };
    values['status'] = 0;
    values['companyId'] = userInfo.companyId;
    values['createdBy'] = {
      email: userInfo.email,
      firstName: userInfo.firstName,
      lastName1: userInfo.lastName1,
      lastName2: userInfo.lastName2,
      uid: userInfo.uid,
      username: userInfo.username ? userInfo.username : null,
    };
    values['history'] = [
      {
        eventType: 'CREATED',
        date: { seconds: _date.unix(), nanoseconds: 0 },
        performer: {
          uid: userInfo.uid,
          firstName: userInfo.firstName,
          lastName1: userInfo.lastName1,
        },
      },
    ];
    values.status = 2;
    // console.log(users);
    let index = users.findIndex((user) => user.uid === values.asignee);
    values.asigneeName =
      users[index].firstName +
      ' ' +
      users[index].lastName1 +
      (users[index].lastName2 ? ' ' + users[index].lastName2 : '');
    // console.log(values);
    let pushRef = await firebase
      .database()
      .ref()
      .child('ideas')
      .push({ ...values });
    let id = pushRef.key;
    // console.log(id);
    // TODO: check that this works property
    await setNewABANCAid(userInfo.companyId, id);
    return await firebase
      .database()
      .ref()
      .child('ideas')
      .child(id)
      .update({ id: id });
  } catch (error) {
    console.error(error);
  }
};

//Será usada para actualizar las actividades en HEB, LALA y Socofar
export const updateActivitiesHEB = async (id, stages) => {
  await firebase
    .database()
    .ref()
    .child('ideas')
    .child(id)
    .update({ activities: stages });
};

export const updateIssueABANCA = async (id, issues) => {
  await firebase
    .database()
    .ref()
    .child('ideas')
    .child(id)
    .update({ issues: issues });
};

export const updateActivity = async (ideaId, activities, user) => {
  if (ideaId) {
    await firebase
      .database()
      .ref()
      .child('ideas')
      .child(ideaId)
      .update({ activities: activities });
  } else if (user) {
    await firebase
      .database()
      .ref()
      .child('personalActivities')
      .child(user.companyId)
      .child(user.uid)
      .update({ activities: activities });
  }
};

export const updateBoards = async (boards, companyId) => {
  await firebase
    .database()
    .ref()
    .child('companies')
    .child(companyId)
    .child('todoConfig')
    .update({ boards: boards });
};

export const updateUser = async (user, userKey, trigger) => {
  await firebase.database().ref().child('users').child(userKey).update(user);
  await firebase
    .database()
    .ref()
    .child('companies')
    .child(user.companyId)
    .update({ changesTrigger: !trigger });
};

export const getNewFBKey = async () => {
  let pushRef = await firebase.database().ref().child('ideas').push();
  return pushRef.key;
};

export const setNewABANCAid = async (companyId, ideaId) => {
  const idABANCARef = firebase
    .database()
    .ref(`companies/${companyId}/idABANCA`);
  await idABANCARef
    .transaction((prevState) => (prevState || 0) + 1)
    .then(async ({ committed, snapshot }) => {
      if (committed) {
        const id = snapshot.val();
        await updateIdea(ideaId, { idABANCA: id, status: 2 }).catch(() =>
          console.error('Error al actualizar')
        );
      }
    })
    .catch(() => console.error('Error al actualizar'));
};
