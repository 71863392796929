import React from "react";
import HEBIdeaDetails from "./HEB/HEBIdeaDetails";
import ABANCAIdeaDetails from "./ABANCA/ABANCAIdeaDetails";
import PRUEBAIdeaDetails from "./PRUEBA/PRUEBAIdeaDetails";
import LALAIdeaDetails from "./LALA/LALAIdeaDetails";
import SOCOFARIdeaDetails from "./SOCOFAR/SOCOFARIdeaDetails";
import WalmartIdeaDetails from "./Walmart/WalmartIdeaDetails";
import DesigualIdeaDetails from "./DESIGUAL";
import GenericIdeaDetails from "./GENERIC/GenericIdeaDetails";

const CompanyIdeaDetails = (props) => {
  switch (props.companyName) {
    case "HEB":
      return (
        <HEBIdeaDetails
          userInfo={props.userInfo}
          onCloseDrawer={props.onCloseDrawer}
          mappings={props.mappings}
          companyName={props.companyName}
          idea={props.idea}
          company={props.company}
          activeKey={props.activeKey}
          activityKeys={props.activityKeys}
        />
      );

    case "ABANCA":
      return (
        <ABANCAIdeaDetails
          onCloseDrawer={props.onCloseDrawer}
          userInfo={props.userInfo}
          mappings={props.mappings}
          companyName={props.companyName}
          idea={props.idea}
          company={props.company}
          modifiers={props.modifiers}
        />
      );

    case "LALA":
      return (
        <LALAIdeaDetails
          userInfo={props.userInfo}
          onCloseDrawer={props.onCloseDrawer}
          mappings={props.mappings}
          companyName={props.companyName}
          idea={props.idea}
          company={props.company}
          activeKey={props.activeKey}
          activityKeys={props.activityKeys}
        />
      );

    case "Socofar":
      return (
        <SOCOFARIdeaDetails
          userInfo={props.userInfo}
          onCloseDrawer={props.onCloseDrawer}
          mappings={props.mappings}
          companyName={props.companyName}
          idea={props.idea}
          company={props.company}
          activeKey={props.activeKey}
          activityKeys={props.activityKeys}
        />
      );

    case "Walmart":
      return (
        <WalmartIdeaDetails
          userInfo={props.userInfo}
          onCloseDrawer={props.onCloseDrawer}
          mappings={props.mappings}
          companyName={props.companyName}
          idea={props.idea}
          company={props.company}
          activeKey={props.activeKey}
          activityKeys={props.activityKeys}
        />
      );

    case "PRUEBA":
      return (
        <PRUEBAIdeaDetails
          onCloseDrawer={props.onCloseDrawer}
          userInfo={props.userInfo}
          mappings={props.mappings}
          companyName={props.companyName}
          idea={props.idea}
          company={props.company}
        />
      );
    case "Desigual":
      return (
        <DesigualIdeaDetails
          onCloseDrawer={props.onCloseDrawer}
          userInfo={props.userInfo}
          mappings={props.mappings}
          companyName={props.companyName}
          idea={props.idea}
          company={props.company}
        />
      );

    case "Generic":
      return (
        <GenericIdeaDetails
          userInfo={props.userInfo}
          onCloseDrawer={props.onCloseDrawer}
          mappings={props.mappings}
          companyName={props.companyName}
          idea={props.idea}
          company={props.company}
          activeKey={props.activeKey}
          activityKeys={props.activityKeys}
        />
      );

    default:
      return (
        <HEBIdeaDetails
          userInfo={props.userInfo}
          onCloseDrawer={props.onCloseDrawer}
          mappings={props.mappings}
          companyName={props.companyName}
          idea={props.idea}
          company={props.company}
        />
      );
  }
};

export default CompanyIdeaDetails;
