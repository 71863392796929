import React, { useState, useEffect } from "react";
import { Tabs, Spin, Modal, Button, message, Select } from "antd";
import "../../../../../App.css";
import Search from "./Tabs/SearchIdeas";
import Inbox from "./Tabs/Inbox";
import InProgress from "./Tabs/InProgress";
import Deployed from "./Tabs/Deployed";
import BotsSummary from "./Tabs/BotsSummary";
import Maintenances from "./Tabs/MaintenancesSummary";
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;
const { Option } = Select;

const Loader = () => (
  <div
    style={{
      height: "100vh",
      width: "100vw",
      textAlign: "center",
      marginTop: "25%",
    }}
  >
    <Spin size="large" spinning={true}></Spin>
  </div>
);

const GenericTabs = (props) => {
  const [ideas, setIdeas] = useState();
  const [company, setCompany] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [master, setMaster] = useState(true);
  const [masterSelection, setMasterSelection] = useState(null);
  const [masterModal, setMasterModal] = useState(false);
  const { t } = useTranslation();

  const defineSubCompany = () => {
    if (masterSelection) {
      var updateUserInfo = props.userInfo;
      var updateCompany = props.company;
      var updateUsers = props.company.allUsers;
      var updateRemovedUsers = props.company.deletedUsers;
      var updateActiveUsers = props.company.users;
      if (props.ideas) {
        setIdeas(
          props.ideas.filter((idea) => idea.subCompanyId === masterSelection)
        );
      }
      updateUserInfo.subCompanyId = masterSelection;
      Object.entries(updateUsers).map((user) => {
        if (
          user[1].subCompanyId !== masterSelection &&
          user[1].subCompanyId !== "all"
        )
          return delete updateUsers[user[0]];
      });
      Object.entries(updateRemovedUsers).map((deletedUser) => {
        if (
          deletedUser[1].subCompanyId !== masterSelection &&
          deletedUser[1].subCompanyId !== "all"
        ) {
          return delete updateRemovedUsers[deletedUser[0]];
        }
      });
      Object.entries(updateActiveUsers).map((user) => {
        if (
          user[1].subCompanyId !== masterSelection &&
          user[1].subCompanyId !== "all"
        )
          return delete updateActiveUsers[user[0]];
      });
      updateCompany.allUsers = updateUsers;
      updateCompany.deletedUsers = updateRemovedUsers;
      updateCompany.users = updateActiveUsers;
      setUserInfo(updateUserInfo);
      setCompany(updateCompany);
      setMasterModal(false);
      setMaster(true);
    } else {
      message.warning(t("Generic.MasterModal.ModalMessage"));
    }
  };

  useEffect(() => {
    if (props.company && props.userInfo) {
      if (props.userInfo.subCompanyId === "all") {
        setMaster(false);
        setMasterModal(true);
      } else if (masterSelection) {
        defineSubCompany();
      } else {
        setCompany(props.company);
        setUserInfo(props.userInfo);
        if (props.ideas) {
          setIdeas(props.ideas);
        }
      }
    }
  }, [props.ideas, props.company, props.companyName]);

  return (
    <>
      {master ? (
        <Tabs
          defaultActiveKey="1"
          type="card"
          size="default"
          style={{ marginRight: 20, marginLeft: 20, paddingTop: 10 }}
        >
          <TabPane tab={t("Generic.TabsTitle.Search")} key="IDEA">
            <Search
              companyName={props.companyName}
              company={company}
              userInfo={userInfo}
              mappings={props.mappings}
              ideas={ideas}
              areas={props.areas}
            />
          </TabPane>

          <TabPane tab={t("Generic.TabsTitle.Inbox")} key="INBOX">
            <Inbox
              companyName={props.companyName}
              company={company}
              userInfo={userInfo}
              mappings={props.mappings}
              ideas={ideas}
              areas={props.areas}
            />
          </TabPane>

          <TabPane tab={t("Generic.TabsTitle.InProgress")} key="IN_PROGRESS">
            <InProgress
              companyName={props.companyName}
              company={company}
              userInfo={userInfo}
              mappings={props.mappings}
              ideas={ideas}
              areas={props.areas}
            />
          </TabPane>

          <TabPane tab={t("Generic.TabsTitle.Deployed")} key="PROD">
            <Deployed
              companyName={props.companyName}
              company={company}
              userInfo={userInfo}
              mappings={props.mappings}
              ideas={ideas}
              areas={props.areas}
            />
          </TabPane>

          <TabPane tab={t("Generic.TabsTitle.BotsSummary")} key="BOTS_SUMMARY">
            <BotsSummary
              companyName={props.companyName}
              company={company}
              userInfo={userInfo}
              mappings={props.mappings}
              ideas={ideas}
              areas={props.areas}
            />
          </TabPane>

          <TabPane tab={"Maintenances Summary"} key="MAINTENANCES_SUMMARY">
            <Maintenances
              company={props.company}
              userInfo={userInfo}
              ideas={ideas}
              areas={props.areas}
            />
          </TabPane>
        </Tabs>
      ) : (
        <>
          <Loader />
          <Modal
            title={t("Generic.MasterModal.ModalTitle")}
            visible={masterModal}
            onCancel={null}
            footer={null}
            onOk={defineSubCompany}
          >
            <Select
              placeholder={t("Generic.MasterModal.ModalDesciption")}
              onChange={(value) => {
                setMasterSelection(value);
              }}
              style={{ width: "250px" }}
            >
              {Object.entries(props.company.subCompanies).map((subCompany) => {
                return (
                  <Option key={subCompany[0]} value={subCompany[0]}>
                    {subCompany[1]}
                  </Option>
                );
              })}
            </Select>
            <Button
              style={{ marginLeft: "10px", width: "100px" }}
              onClick={defineSubCompany}
            >
              {t("Generic.MasterModal.Confirm")}
            </Button>
          </Modal>
        </>
      )}
    </>
  );
};

export default GenericTabs;
