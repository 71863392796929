import React, { useState, useEffect, useReducer } from "react";
import { Layout, Menu, Spin, Avatar, Select, Space, message } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  HomeOutlined,
  AreaChartOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Login from "./Components/Login/Login";
import OnBoarding from "./Components/ConfigAdmin/OnBoarding";
import "./App.css";
import { useTranslation } from "react-i18next";
import firebase from "./server/firebase";
import CompanyTabs from "./Components/Search/Idea/Tabs/CompanyTabs";
import CompanyDashboard from "./Components/Dashboard/CompanyDashboard";
/* import TabsConfig from "./Components/ConfigAdmin/TabsConfig";
 */
import UsersTab from "./Components/ConfigAdmin/UsersTab";
import { getAuthUserInfo } from "./db/firedataCalls";
import PropTypes from "prop-types";
import i18next from "i18next";
import moment from "moment";
import uid from "uid-safe";

const { Option } = Select;
const { SubMenu } = Menu;
const centros = require("./centros.json");
export const AppContext = React.createContext();

const Page = (props) => {
  // Set up Initial State
  const initialState = {
    approvedIdeas: new Map([]),
    prioritizedIdeas: new Map([]),
  };
  // sé que se ve raro y tal vez esté mal, pero es un work arround a un problema
  // que pasa al usar el hook useReducer
  const [appState, setAppState] = useState({
    _approvedIdeas: new Map([]),
    _prioritizedIdeas: new Map([]),
  });
  /**
   * state se utiliza para proveer inormación que se comparte con un AppContext.Provider
   * entre los distintos componentes que integran la sección de tabs en el RPA Manager.
   */
  const { Header, Content, Footer, Sider } = Layout;
  const [collapsed, setCollapsed] = useState(true);
  // esto debe cambiarse a realtime database
  const [hideComponents, setHideComponents] = useState({
    tabs: true,
    dashboard: false,
    users: false,
    onboarding: false,
  });
  const { t } = useTranslation();

  const reducer = (state, action) => {
    switch (action.type) {
      case "UPDATE_APPROVED":
        setAppState({
          ...appState,
          _approvedIdeas: appState._approvedIdeas.set(
            action.data.id,
            action.data
          ),
        });
        return {
          approvedIdeas: appState._approvedIdeas,
          prioritizedIdeas: appState._prioritizedIdeas,
        };

      case "DELETE_APPROVED":
        let approved = appState._approvedIdeas;
        approved.delete(action.data.id);

        let prioritized = appState._prioritizedIdeas;
        prioritized.delete(action.data.id);
        setAppState({
          _approvedIdeas: approved,
          _prioritizedIdeas: prioritized,
        });
        return {
          approvedIdeas: appState._approvedIdeas,
          prioritizedIdeas: appState._prioritizedIdeas,
        };

      case "UPDATE_PRIORITIZED":
        let prioritizedIdeas = appState._prioritizedIdeas.set(
          action.data.id,
          action.data
        );
        setAppState({ ...appState, _prioritizedIdeas: prioritizedIdeas });
        return {
          prioritizedIdeas: appState._prioritizedIdeas,
          approvedIdeas: appState._approvedIdeas,
        };

      case "DELETE_PRIORITIZED":
        let _prioritized = appState._prioritizedIdeas;
        _prioritized.delete(action.data.id);
        setAppState({ ...appState, _prioritizedIdeas: _prioritized });
        return {
          prioritizedIdeas: appState._prioritizedIdeas,
          approvedIdeas: appState._approvedIdeas,
        };

      default:
        return initialState;
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const displayMenuOption = ({ key }) => {
    switch (key) {
      case "DASHBOARD":
        setHideComponents({
          tabs: false,
          dashboard: true,
          /* tabsConfig: false, */
          onboarding: false,
          users: false,
        });
        break;
      case "TABS":
        setHideComponents({
          tabs: true,
          dashboard: false,
          /* tabsConfig: false, */
          onboarding: false,
          users: false,
        });
        break;
      case "TABS_CONFIG":
        setHideComponents({
          tabs: false,
          dashboard: false,
          /*  tabsConfig: true, */
          onboarding: false,
          users: false,
        });
        break;
      case "ONBOARDING":
        setHideComponents({
          /* tabsConfig: false, */
          tabs: false,
          dashboard: false,
          users: false,
          onboarding: true,
        });
        break;
      case "USERS":
        setHideComponents({
          /* tabsConfig: false, */
          tabs: false,
          dashboard: false,
          users: true,
          onboarding: false,
        });
        break;
      default:
        break;
    }
  };
  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const showOnBoarding = () => {
    if (
      props.userInfo.rol === "ADMIN" ||
      props.userInfo.rol === "SOFTTEK" ||
      props.userInfo.companyId === "0SSRHnxHythDoAcaApkw" ||
      props.userInfo.companyId === "82Xt7pXfGf8YE9TcvfuN"
    )
      return true;
    else return false;
  };

  return (
    <Layout style={{ height: "100%", minHeight: "100vh" }}>
      <Header
        style={{
          paddingLeft: 25,
          background:
            "url('https://firebasestorage.googleapis.com/v0/b/rpa-manager.appspot.com/o/iconos%2FRPA_Banner_72dpi-01.png?alt=media&token=b44f001b-907b-46d4-8200-6b5524e0eff0')",
          borderBottom: "1px solid lightgray",
          backgroundSize: "auto",
        }}
      >
        <img
          key="img"
          src="https://firebasestorage.googleapis.com/v0/b/rpa-manager.appspot.com/o/iconos%2Frpa-manager.png?alt=media&token=87ae54b7-1c1f-4f2f-894d-5540d4ae5076"
          href="/"
          alt="RPA MANAGER"
          style={{ height: "24px" }}
        />

        <span
          style={{
            position: "absolute",
            right: "10px",
          }}
        >
          <Select
            defaultValue={i18next.language}
            style={{ width: 150 }}
            bordered={false}
            onChange={(value) => {
              i18next.changeLanguage(value);
              window.location.reload();
            }}
          >
            {i18next.languages.map((lng) => {
              let language;
              switch (lng) {
                case "es":
                  language = {
                    lng: "Español",
                    src: "https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/160/apple/237/flag-for-spain_1f1ea-1f1f8.png",
                  };
                  break;
                case "en":
                  language = {
                    lng: "English",
                    src: "https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/160/apple/118/flag-for-united-states_1f1fa-1f1f8.png",
                  };
                  break;
                default:
                  language = {
                    lng: "English",
                    src: "https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/160/apple/118/flag-for-united-states_1f1fa-1f1f8.png",
                  };
                  break;
              }
              return (
                <Option value={lng} key={lng}>
                  <Space>
                    <img
                      src={language.src}
                      width="20"
                      alt={language.lng}
                      height="20"
                    ></img>
                    <span>{`${language.lng} (${lng})`}</span>
                  </Space>
                </Option>
              );
            })}
          </Select>
        </span>
      </Header>
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{
            backgroundColor: "white",
            borderRight: "1px solid lightgray",
            width: "100vh",
          }}
          className="site-layout-background"
        >
          <div className="logo" />
          <Menu theme="light" mode="inline" defaultSelectedKeys={["1"]}>
            <SubMenu
              style={{}}
              key="sub1"
              icon={
                <Avatar
                  style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
                >
                  {props.userInfo.firstName[0] + props.userInfo.lastName1[0]}
                </Avatar>
              }
            >
              <Menu.ItemGroup
                title={
                  props.userInfo.firstName + " " + props.userInfo.lastName1
                }
              >
                {showOnBoarding() && (
                  <Menu.Item key="ONBOARDING" onClick={displayMenuOption}>
                    On Boarding
                  </Menu.Item>
                )}
                {/*  <Menu.Item   
                  key="TABS_CONFIG"
                  onClick={displayMenuOption}
                  hidden={!(props.userInfo.rol === "SOFTTEK")}
                >
                  {t("Configurar pestañas")}
                </Menu.Item> */}
                <Menu.Item key="logout" onClick={props.handleLogOut}>
                  {t("app.menu.logout")}
                </Menu.Item>
              </Menu.ItemGroup>
            </SubMenu>
            {props.userInfo && props.userInfo.subCompanyId ? (
              <></>
            ) : (
              <Menu.Item
                key="DASHBOARD"
                icon={<AreaChartOutlined />}
                onClick={displayMenuOption}
              >
                Dashboard
              </Menu.Item>
            )}
            <Menu.Item
              key="TABS"
              icon={<HomeOutlined />}
              onClick={displayMenuOption}
            >
              {t("Inicio")}
            </Menu.Item>
            {(props.userInfo.uid === "RnKgnZkO2PU8ZO6sIGjzzzsSRgc2" ||
              props.userInfo.uid === "Pd2XJTak6WY2BSXA15cMRAuT0u52" ||
              props.userInfo.uid === "RckGQ9TgesUtN3Zqn0aThBK6BLH3" ||
              props.userInfo.uid === "xqGfcTKwiEh1Q53lmo9aHj2wuC13" ||
              props.userInfo.uid === "60fjInUYTHbYVZXdyqaZzEpuIpx1") && (
              <Menu.Item
                key="USERS"
                icon={<TeamOutlined />}
                onClick={displayMenuOption}
              >
                Usuarios
              </Menu.Item>
            )}
            <Menu.Item>
              {React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: toggle,
                }
              )}
            </Menu.Item>
          </Menu>
        </Sider>
        <Content style={{ background: "white" }}>
          {/** AppContext.Provider se utiliza para mover información entre los componentes  */}
          <AppContext.Provider value={{ state, dispatch }}>
            {hideComponents.dashboard && (
              <CompanyDashboard
                userInfo={props.userInfo}
                ideas={props.ideas}
                companyName={props.company.name}
              />
            )}
            {hideComponents.onboarding && (
              <OnBoarding
                companyName={props.company.name}
                userInfo={props.userInfo}
              />
            )}
            {/* {hideComponents.tabsConfig && (
              <TabsConfig userInfo={props.userInfo} />
            )} */}
            {hideComponents.tabs && (
              <CompanyTabs
                companyName={props.company.name}
                company={props.company}
                userInfo={props.userInfo}
                mappings={props.mappings}
                ideas={props.ideas}
                areas={props.areas}
              />
            )}
            {hideComponents.users && (
              <UsersTab
                company={props.company}
                userInfo={props.userInfo}
                ideas={props.ideas}
              />
            )}
          </AppContext.Provider>
        </Content>
      </Layout>
      <Footer style={{ textAlign: "center" }}>
        Softtek Innovation {new Date().getFullYear()}
      </Footer>
    </Layout>
  );
};

const Loader = () => (
  <div
    style={{
      height: "100vh",
      width: "100vw",
      textAlign: "center",
      marginTop: "25%",
    }}
  >
    <Spin size="large" spinning={true}></Spin>
  </div>
);
/**
 * Este es el componente principal. En el archivo existen otros dos componentes
 * definidos como constantes.
 *
 * Lo que hace este componente es validar el estado userInfo para determinar si
 * existe información del usuario dentro de userInfo. Si la hay, ello indica que
 * el usuario inició sesión, ya que a ese estado se le asigna valor al realizar
 * exitosamente el login con el sistema de autenticación de firebase. Renderiza el
 * componente Page, definido aquí mismo como constante. Los props que el componente
 * Page recibe, también se asignan al realizar una sesión exitosa.
 *
 * Si no existe quiere decir que no se ha iniciado sesión, entonces renderiza el
 * componente Login, definido aquí mismo como constante.
 */
export default function App() {
  /** userInfo: información sobre el usuario, almacenada en la base de datos realtime database. */
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ideas, setIdeas] = useState([]); // ideas de la empresa
  const [areas, setAreas] = useState([]);

  /**
   * mappings  contiene la información individual de acuerdo a la empresa
   * a la cual pertenece el usuario que inició sesión.
   * workCategories: información sobre las áreas laborales de la empresa.
   * Ej: TI, Ventas, RRHH, etc.
   * activities: las actividades que la empresa define que deben llevarse acabo
   * en el proceso de desarrollo de la robotización.
   * status: los estatus que puede tener cada idea durante el proceso de robotización.
   */
  const [mappings, setMappings] = useState({
    workCategories: new Map([]),
    activities: new Map([]),
    status: new Map([]),
  });
  const [company, setCompany] = useState(null);
  const [isLogged, setIsLogged] = useState(false);
  const db = firebase.database().ref();
  const dbUsers = firebase.database().ref("users");
  const dbIdeas = firebase.database().ref("ideas");
  const dbGeneralIdeas = firebase.database().ref("generalIdeas");

  const handleLogOut = () => {
    setLoading(true);
    setIdeas([]);
    firebase
      .auth()
      .signOut()
      .then(() => {
        setUserInfo(null);
        window.location.reload();
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(true));
  };

  useEffect(() => {
    /**
     * Esta función escucha por intentos de login y regresa el objeto user con o sin información
     * de la cuenta en el sistema de autenticación, dependiendo de si el intento fue exitoso.
     */
    firebase.auth().onAuthStateChanged(async (user) => {
      setLoading(true);
      setIdeas([]);
      /** Existe información, entonces obtenemos más datos del usuario y los mappings
       * para la compañia a la que pertenece el usuario, desde realtime database. */
      function modifyIdea(newArray) {
        setIdeas(() => [...newArray]);
      }

      function getCenters(idea) {
        if (idea.departmentValue) {
          let name = "";
          let areaIndex = centros.departments.findIndex(
            (department) => department.value === idea.departmentValue
          );
          let levelNumber = centros.departments[areaIndex].levels.length;
          switch (levelNumber) {
            case 0:
              idea.dir_general = "";
              idea.direccion = "";
              idea.area = "";
              break;
            case 1:
              name =
                centros.departments[
                  centros.departments.findIndex(
                    (department) =>
                      department.value ===
                      centros.departments[areaIndex].levels[0]
                  )
                ].title +
                " (" +
                centros.departments[
                  centros.departments.findIndex(
                    (department) =>
                      department.value ===
                      centros.departments[areaIndex].levels[0]
                  )
                ].value +
                ")";
              idea.dir_general = name;
              idea.direccion = name;
              idea.area =
                idea.departmentTitle + " (" + idea.departmentValue + ")";
              break;
            case 2:
              name =
                centros.departments[
                  centros.departments.findIndex(
                    (department) =>
                      department.value ===
                      centros.departments[areaIndex].levels[0]
                  )
                ].title +
                " (" +
                centros.departments[
                  centros.departments.findIndex(
                    (department) =>
                      department.value ===
                      centros.departments[areaIndex].levels[0]
                  )
                ].value +
                ")";
              idea.dir_general = name;
              idea.direccion = name;
              idea.area =
                idea.departmentTitle + " (" + idea.departmentValue + ")";
              break;
            case 3:
              idea.dir_general =
                centros.departments[
                  centros.departments.findIndex(
                    (department) =>
                      department.value ===
                      centros.departments[areaIndex].levels[levelNumber - 2]
                  )
                ].title +
                " (" +
                centros.departments[
                  centros.departments.findIndex(
                    (department) =>
                      department.value ===
                      centros.departments[areaIndex].levels[levelNumber - 2]
                  )
                ].value +
                ")";
              name =
                centros.departments[
                  centros.departments.findIndex(
                    (department) =>
                      department.value ===
                      centros.departments[areaIndex].levels[levelNumber - 3]
                  )
                ].title +
                " (" +
                centros.departments[
                  centros.departments.findIndex(
                    (department) =>
                      department.value ===
                      centros.departments[areaIndex].levels[levelNumber - 3]
                  )
                ].value +
                ")";
              idea.direccion = name;
              idea.area =
                idea.departmentTitle + " (" + idea.departmentValue + ")";
              break;
            default:
              idea.dir_general =
                centros.departments[
                  centros.departments.findIndex(
                    (department) =>
                      department.value ===
                      centros.departments[areaIndex].levels[levelNumber - 2]
                  )
                ].title +
                " (" +
                centros.departments[
                  centros.departments.findIndex(
                    (department) =>
                      department.value ===
                      centros.departments[areaIndex].levels[levelNumber - 2]
                  )
                ].value +
                ")";
              idea.direccion =
                centros.departments[
                  centros.departments.findIndex(
                    (department) =>
                      department.value ===
                      centros.departments[areaIndex].levels[levelNumber - 3]
                  )
                ].title +
                " (" +
                centros.departments[
                  centros.departments.findIndex(
                    (department) =>
                      department.value ===
                      centros.departments[areaIndex].levels[levelNumber - 3]
                  )
                ].value +
                ")";
              idea.area =
                idea.departmentTitle + " (" + idea.departmentValue + ")";
              break;
          }
        }
        return idea;
      }

      if (user) {
        let userInfoData = null;
        userInfoData = await getAuthUserInfo(user.uid);
        if (userInfoData) setIsLogged(true);
        if (userInfoData && Object.values(userInfoData)[0].status === 1) {
          const userKey = Object.keys(userInfoData)[0];
          userInfoData = {
            ...Object.values(userInfoData)[0],
            dbKey_: userKey,
          };
          const genToken = uid.sync(24);
          const date = {
            seconds: moment().unix(),
            nanoseconds: 0,
          };
          if (userInfoData.tokenDate) {
            let currentDay = moment.unix(date.seconds).format("DD/MM/YYYY");
            let tokenDay = moment
              .unix(userInfoData.tokenDate.seconds)
              .format("DD/MM/YYYY");
            if (currentDay !== tokenDay) {
              userInfoData.token = genToken;
              userInfoData.tokenDate = date;
              await dbUsers
                .child(userKey)
                .update({ token: genToken, tokenDate: date });
            }
          } else {
            userInfoData.token = genToken;
            userInfoData.tokenDate = date;
            await dbUsers
              .child(userKey)
              .update({ token: genToken, tokenDate: date });
          }
          if (userInfoData.hasOwnProperty("subCompanyId")) {
            setUserInfo(userInfoData);
            if (userInfoData.subCompanyId === "all") {
              dbGeneralIdeas
                // .limitToLast(10)
                .on("child_added", (newIdea) => {
                  if (!newIdea.val().id) {
                    firebase
                      .database()
                      .ref()
                      .child("generalIdeas")
                      .child(newIdea.key)
                      .update({ id: newIdea.key });
                    console.log("updated:", newIdea.key);
                  }
                  let nuevaIdea = newIdea.val();
                  nuevaIdea.id = newIdea.key;
                  var arregloModificadas = ideas;
                  arregloModificadas.push(nuevaIdea);
                  modifyIdea(arregloModificadas);
                });

              dbGeneralIdeas.on("child_changed", (modifiedIdea) => {
                if (ideas.length > 0) {
                  var arregloModificadas = ideas;
                  let index = arregloModificadas.findIndex(
                    (element) => element.id === modifiedIdea.val().id
                  );
                  if (index !== -1) {
                    arregloModificadas[index] = modifiedIdea.val();
                    modifyIdea(arregloModificadas);
                  }
                }
              });

              dbGeneralIdeas.on("child_removed", (modifiedIdea) => {
                if (ideas.length > 0) {
                  console.log(ideas);
                  var arregloModificadas = ideas;
                  let index = arregloModificadas.findIndex(
                    (element) => element.id === modifiedIdea.val().id
                  );
                  if (index !== -1) {
                    arregloModificadas.splice(index, 1);
                    modifyIdea(arregloModificadas);
                  }
                }
              });
            } else {
              dbGeneralIdeas
                .orderByChild("subCompanyId")
                .equalTo(userInfoData.subCompanyId)
                // .limitToLast(10)
                .on("child_added", (newIdea) => {
                  if (!newIdea.val().id) {
                    firebase
                      .database()
                      .ref()
                      .child("generalIdeas")
                      .child(newIdea.key)
                      .update({ id: newIdea.key });
                    console.log("updated:", newIdea.key);
                  }
                  let nuevaIdea = newIdea.val();
                  nuevaIdea.id = newIdea.key;
                  var arregloModificadas = ideas;
                  arregloModificadas.push(nuevaIdea);
                  modifyIdea(arregloModificadas);
                });

              dbGeneralIdeas
                .orderByChild("subCompanyId")
                .equalTo(userInfoData.subCompanyId)
                .on("child_changed", (modifiedIdea) => {
                  if (ideas.length > 0) {
                    var arregloModificadas = ideas;
                    let index = arregloModificadas.findIndex(
                      (element) => element.id === modifiedIdea.val().id
                    );
                    if (index !== -1) {
                      arregloModificadas[index] = modifiedIdea.val();
                      modifyIdea(arregloModificadas);
                    }
                  }
                });

              dbGeneralIdeas
                .orderByChild("subCompanyId")
                .equalTo(userInfoData.subCompanyId)
                .on("child_removed", (modifiedIdea) => {
                  if (ideas.length > 0) {
                    console.log(ideas);
                    var arregloModificadas = ideas;
                    let index = arregloModificadas.findIndex(
                      (element) => element.id === modifiedIdea.val().id
                    );
                    if (index !== -1) {
                      arregloModificadas.splice(index, 1);
                      modifyIdea(arregloModificadas);
                    }
                  }
                });
            }
          } else {
            db.child("personalActivities")
              .child(userInfoData.companyId)
              .child(userInfoData.uid)
              .child("activities")
              .on("value", (activities) => {
                userInfoData.activities = activities.val();
              });

            setUserInfo(userInfoData);
            dbIdeas
              .orderByChild("companyId")
              .equalTo(userInfoData.companyId)
              // .limitToLast(10)
              .on("child_added", (newIdea) => {
                if (!newIdea.val().id) {
                  firebase
                    .database()
                    .ref()
                    .child("ideas")
                    .child(newIdea.key)
                    .update({ id: newIdea.key });
                  console.log("updated:", newIdea.key);
                }
                let nuevaIdea = newIdea.val();
                nuevaIdea.id = newIdea.key;
                var arregloModificadas = ideas;
                arregloModificadas.push(getCenters(nuevaIdea));
                modifyIdea(arregloModificadas);
              });
            dbIdeas
              .orderByChild("companyId")
              .equalTo(userInfoData.companyId)
              .on("child_changed", (modifiedIdea) => {
                if (ideas.length > 0) {
                  var arregloModificadas = ideas;
                  let index = arregloModificadas.findIndex(
                    (element) => element.id === modifiedIdea.val().id
                  );
                  if (index !== -1) {
                    arregloModificadas[index] = getCenters(modifiedIdea.val());
                    modifyIdea(arregloModificadas);
                  }
                }
              });
            dbIdeas
              .orderByChild("companyId")
              .equalTo(userInfoData.companyId)
              .on("child_removed", (modifiedIdea) => {
                if (ideas.length > 0) {
                  console.log(ideas);
                  var arregloModificadas = ideas;
                  let index = arregloModificadas.findIndex(
                    (element) => element.id === modifiedIdea.val().id
                  );
                  if (index !== -1) {
                    arregloModificadas.splice(index, 1);
                    modifyIdea(arregloModificadas);
                  }
                }
              });
          }

          var compañia = null;
          if (userInfoData.hasOwnProperty("subCompanyId")) {
            if (userInfoData.subCompanyId === "all") {
              db.child("companies")
                .child(userInfoData.companyId)
                .on("value", async (empresa) => {
                  compañia = empresa.val();
                  dbUsers
                    .orderByChild("companyId")
                    .equalTo(userInfoData.companyId)
                    .on("value", (users) => {
                      //Users Status 1: Active, 2: Inactive, 3: Pending
                      var activeUsers = Object.fromEntries(
                        Object.entries(users.val()).filter(
                          (usuario) => usuario[1].status === 1
                        )
                      );
                      var removedUsers = Object.fromEntries(
                        Object.entries(users.val()).filter(
                          (usuario) => usuario[1].status === 2
                        )
                      );
                      compañia.users = activeUsers;
                      compañia.deletedUsers = removedUsers;
                      compañia.allUsers = users.val();
                      setCompany(compañia);
                    });
                  setCompany(compañia);
                });
            } else {
              db.child("companies")
                .child(userInfoData.companyId)
                .on("value", async (empresa) => {
                  compañia = empresa.val();
                  var masterUsers = {};
                  await dbUsers
                    .orderByChild("subCompanyId")
                    .equalTo("all")
                    .once("value", (mUsers) => {
                      if (mUsers.val()) {
                        masterUsers = Object.fromEntries(
                          Object.entries(mUsers.val()).filter(
                            (usuario) => usuario[1].status === 1
                          )
                        );
                      }
                    });
                  dbUsers
                    .orderByChild("subCompanyId")
                    .equalTo(userInfoData.subCompanyId)
                    .on("value", (users) => {
                      //Users Status 1: Active, 2: Inactive, 3: Pending
                      var activeUsers = Object.fromEntries(
                        Object.entries(users.val()).filter(
                          (usuario) => usuario[1].status === 1
                        )
                      );
                      var removedUsers = Object.fromEntries(
                        Object.entries(users.val()).filter(
                          (usuario) => usuario[1].status === 2
                        )
                      );
                      compañia.users = activeUsers;
                      compañia.deletedUsers = removedUsers;
                      compañia.allUsers = users.val();
                      if (masterUsers) {
                        Object.entries(masterUsers).forEach((usuario) => {
                          compañia.users[usuario[0]] = usuario[1];
                          compañia.allUsers[usuario[0]] = usuario[1];
                        });
                      }
                      setCompany(compañia);
                    });
                  setCompany(compañia);
                });
            }
          } else {
            db.child("companies")
              .child(userInfoData.companyId)
              .on("value", async (empresa) => {
                compañia = empresa.val();
                dbUsers
                  .orderByChild("companyId")
                  .equalTo(userInfoData.companyId)
                  .on("value", (users) => {
                    //Users Status 1: Active, 2: Inactive, 3: Pending
                    var activeUsers = Object.fromEntries(
                      Object.entries(users.val()).filter(
                        (usuario) => usuario[1].status === 1
                      )
                    );
                    var removedUsers = Object.fromEntries(
                      Object.entries(users.val()).filter(
                        (usuario) => usuario[1].status === 2
                      )
                    );
                    compañia.users = activeUsers;
                    compañia.deletedUsers = removedUsers;
                    compañia.allUsers = users.val();
                    setCompany(compañia);
                  });
                setCompany(compañia);
              });
          }
          setMappings({});
          setLoading(false);
        } else {
          message.error("User not registered/approved");
          //  firebase.auth().signOut();
        }
      }
      setLoading(false);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    //Si cierra sesión todas las tabs hace reload para volver a limpiar todo
    firebase.auth().onAuthStateChanged((user) => {
      if (userInfo && userInfo.dbKey_)
        dbUsers.child(userInfo.dbKey_).on("child_changed", (change) => {
          if (change.key === "status") firebase.auth().signOut();
        });
      if (!user && userInfo) {
        window.location.reload();
      }
    });
    let areas = [];
    if (ideas)
      ideas.forEach((i) => {
        areas.indexOf(i.workCategory) === -1 && areas.push(i.workCategory);
      });
    setAreas(areas.map((area) => ({ text: area, value: area })));
    // eslint-disable-next-line
  }, [loading]);

  return (
    <>
      {!loading ? (
        <>
          {userInfo ? (
            /** La property handleLogOut es una función que cierra sesión. */
            // estas properties podrían ponerse en un context con el useContext
            // tal vez sea buena idea intentarlo, ya que muchos componentes la "heredan".
            <>
              {company ? (
                <Page
                  company={company}
                  mappings={mappings}
                  handleLogOut={handleLogOut}
                  userInfo={userInfo}
                  ideas={ideas}
                  areas={areas}
                />
              ) : (
                <Loader />
              )}
            </>
          ) : (
            <Login isLogged={isLogged} />
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}

Page.propTypes = {
  company: PropTypes.object,
  mappings: PropTypes.object,
  handleLogOut: PropTypes.func,
  userInfo: PropTypes.object,
  ideas: PropTypes.array,
};
