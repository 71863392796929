import React, { useState, useEffect } from "react";
import firebase from "../../../../../server/firebase";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Popconfirm,
  Tag,
  message,
  Select,
  InputNumber,
  Radio,
  Modal,
  Space,
} from "antd";
import "../../../../../App.css";
import moment from "moment";

const BotsModal = (props) => {
  const [form] = Form.useForm();
  const [botStatus, setBotStatus] = useState(0);
  const [editStatus, setEditStatus] = useState(false);
  const [uipathLicense, setUipathLicense] = useState(false);

  useEffect(() => {
    console.log(props.idea);
    if (props.botDrawer.botKey && props.bots) {
      let bot =
        props.bots[
          props.bots.findIndex(({ key }) => key === props.botDrawer.botKey)
        ];
      if (bot) {
        setBotStatus(bot.status);
        setUipathLicense(bot.tool.indexOf("uipath") > -1);
        form.setFieldsValue(bot);
      }
    } else {
      setBotStatus(0);
      setUipathLicense(false);
    }
  }, [props.idea, props.botDrawer.botKey, props.bots, form]);

  const onDelete = async () => {
    let bot =
      props.bots[
        props.bots.findIndex(({ key }) => key === props.botDrawer.botKey)
      ];
    let actividades = props.idea.activities ? props.idea.activities : [];
    let activities = actividades.filter((activity) => activity.bot === bot.key);
    if (activities.length > 0) {
      message.warning("The Bot has stages, so it can't be deleted");
    } else {
      let tempBots = props.bots.filter(
        ({ key }) => key !== props.botDrawer.botKey
      );
      await firebase
        .database()
        .ref()
        .child("ideas")
        .child(props.idea.id)
        .update({ bots: tempBots })
        .then(
          props.setBotDrawer({ visible: false, botKey: null, editing: false }),
          setEditStatus(false),
          setUipathLicense(false)
        );
    }
  };

  const onFinish = async (values) => {
    let tempBots = [];
    let alphaNum = false;
    let numeric = true;
    let id = /^[0-9a-zA-ZñÑ]+$/;
    let number = /^\d+(\.\d+)?$/;
    if (values.idProcess.match(number) && values.idRPA.match(number))
      alphaNum = true;
    numeric = values.idBot ? values.idBot.match(id) : true;
    if (alphaNum && numeric) {
      if (props.botDrawer.editing) {
        let index = props.bots.findIndex(
          ({ key }) => key === props.botDrawer.botKey
        );
        tempBots = props.bots;
        if (botStatus === 1) {
          tempBots[index].deliveredDate = {
            seconds: moment.utc().unix(),
            nanoseconds: 0,
          };
        }
        tempBots[index]["name"] = values.name;
        tempBots[index]["status"] = botStatus;
        tempBots[index]["alert"] = values.alert;
        tempBots[index]["alertSE"] = values.alertSE.trim();
        tempBots[index]["devComp"] = values.devComp;
        tempBots[index]["duration"] = values.duration;
        tempBots[index]["exeComp"] = values.exeComp;
        tempBots[index]["execution"] = values.execution;
        tempBots[index]["idBot"] = values.idBot ? values.idBot.trim() : null;
        tempBots[index]["idProcess"] = values.idProcess.trim();
        tempBots[index]["idRPA"] = values.idRPA.trim();
        tempBots[index]["license"] = values.license ? values.license : null;
        tempBots[index]["tool"] = values.tool;
        tempBots[index]["monday"] = values.monday ? values.monday.trim() : null;
        tempBots[index]["tuesday"] = values.tuesday
          ? values.tuesday.trim()
          : null;
        tempBots[index]["wednesday"] = values.wednesday
          ? values.wednesday.trim()
          : null;
        tempBots[index]["thursday"] = values.thursday
          ? values.thursday.trim()
          : null;
        tempBots[index]["friday"] = values.friday ? values.friday.trim() : null;
        tempBots[index]["saturday"] = values.saturday
          ? values.saturday.trim()
          : null;
        tempBots[index]["sunday"] = values.sunday ? values.sunday.trim() : null;
        tempBots[index]["month"] = values.month ? values.month.trim() : null;
      } else {
        let nextKey = 1;
        if (props.bots && props.bots.length > 0) {
          tempBots = props.bots;
          nextKey = props.bots.reduce(
            (max, bot) => (bot.key > max ? bot.key : max),
            props.bots[0].key
          );
          nextKey++;
        }
        tempBots.push({
          key: nextKey,
          name: values.name ? values.name : `Bot ${nextKey}`,
          status: botStatus,
          alert: values.alert,
          alertSE: values.alertSE.trim(),
          devComp: values.devComp,
          duration: values.duration,
          exeComp: values.exeComp,
          execution: values.execution,
          idBot: values.idBot ? values.idBot.trim() : null,
          idProcess: values.idProcess,
          idRPA: values.idRPA,
          license: values.license ? values.license : null,
          tool: values.tool,
          monday: values.monday ? values.monday.trim() : null,
          tuesday: values.tuesday ? values.tuesday.trim() : null,
          wednesday: values.wednesday ? values.wednesday.trim() : null,
          thursday: values.thursday ? values.thursday.trim() : null,
          friday: values.friday ? values.friday.trim() : null,
          saturday: values.saturday ? values.saturday.trim() : null,
          sunday: values.sunday ? values.sunday.trim() : null,
          month: values.month ? values.month.trim() : null,
        });
      }

      await firebase
        .database()
        .ref()
        .child("ideas")
        .child(props.idea.id)
        .update({ bots: tempBots });

      message.success();
      form.resetFields();
      props.setBotDrawer({ visible: false, botKey: null, editing: false });
    } else {
      message.error(
        "ID Bot must be Alphanumeric and ID Proceso/ID RPA Numeric"
      );
    }
  };

  const toolChange = (value) => {
    if (value.indexOf("uipath") > -1) {
      setUipathLicense(true);
    } else {
      setUipathLicense(false);
      form.setFieldsValue({ license: null });
    }
  };

  return (
    <Modal
      title={props.botDrawer.editing ? "Edit Bot" : "New Bot"}
      visible={props.botDrawer.visible}
      onCancel={() => {
        props.setBotDrawer({ visible: false, botKey: null, editing: false });
        setEditStatus(false);
        setUipathLicense(false);
      }}
      footer={null}
      width={600}
      destroyOnClose={true}
    >
      <Form onFinish={onFinish} form={form} name="New Bot" preserve={false}>
        <Row gutter={[10, 20]}>
          <Col span={24}>
            <Row gutter={[10, 20]}>
              <Col span={12}>
                <Form.Item
                  name={"name"}
                  label={
                    props.botDrawer.editing ? "Bot name" : "Bot name (optional)"
                  }
                  labelAlign={"left"}
                  rules={
                    props.botDrawer.editing
                      ? [
                          {
                            required: true,
                            message: "Required field",
                          },
                        ]
                      : []
                  }
                >
                  <Input
                    placeholder={"New bot's name"}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name={"idBot"} label={"ID Bot"} labelAlign={"left"}>
                  <Input placeholder={"ID Bot"} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[10, 20]}>
              <Col span={12}>
                <Form.Item
                  name={"idProcess"}
                  label={"ID proceso"}
                  labelAlign={"left"}
                  rules={[
                    {
                      required: true,
                      message: "Required field",
                    },
                  ]}
                >
                  <Input placeholder={"ID proceso"} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={"idRPA"}
                  label={"ID RPA"}
                  labelAlign={"left"}
                  rules={[
                    {
                      required: true,
                      message: "Required field",
                    },
                  ]}
                >
                  <Input placeholder={"ID RPA"} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[10, 20]}>
              <Col span={12}>
                <Form.Item
                  name={"tool"}
                  label={"Herramienta"}
                  labelAlign={"left"}
                  rules={[
                    {
                      required: true,
                      message: "Required field",
                    },
                  ]}
                >
                  <Select
                    placeholder={"Selecciona herramienta"}
                    mode={"multiple"}
                    allowClear
                    onChange={toolChange}
                  >
                    {props.company.botTools.map((tool) => {
                      return (
                        <Select.Option value={tool.id} key={tool.id}>
                          {tool.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={"license"}
                  label={"Licencia en Uso (Uipath)"}
                  labelAlign={"left"}
                  rules={
                    uipathLicense
                      ? [
                          {
                            required: true,
                            message: "Required field",
                          },
                        ]
                      : []
                  }
                >
                  <Select
                    placeholder={"Selecciona licencia en uso"}
                    disabled={!uipathLicense}
                  >
                    <Select.Option key={1} value={1}>
                      Licencia de ejecución 1
                    </Select.Option>
                    <Select.Option key={2} value={2}>
                      Licencia de ejecución 2
                    </Select.Option>
                    <Select.Option key={3} value={3}>
                      N/A
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[10, 20]}>
              <Col span={12}>
                <Form.Item
                  name={"devComp"}
                  label={"Complejidad (DEV)"}
                  labelAlign={"left"}
                  rules={[
                    {
                      required: true,
                      message: "Required field",
                    },
                  ]}
                >
                  <Select placeholder={"Selecciona complejidad (DEV)"}>
                    <Select.Option key={"Baja"} value={1}>
                      Baja
                    </Select.Option>
                    <Select.Option key={"Media"} value={2}>
                      Media
                    </Select.Option>
                    <Select.Option key={"Alta"} value={3}>
                      Alta
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={"exeComp"}
                  label={"Complejidad (Ejecución)"}
                  labelAlign={"left"}
                  rules={[
                    {
                      required: true,
                      message: "Required field",
                    },
                  ]}
                >
                  <Select placeholder={"Selecciona complejidad (Ejecución)"}>
                    <Select.Option key={"Baja"} value={1}>
                      Baja
                    </Select.Option>
                    <Select.Option key={"Media"} value={2}>
                      Media
                    </Select.Option>
                    <Select.Option key={"Alta"} value={3}>
                      Alta
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[10, 20]}>
              <Col span={12}>
                <Form.Item
                  name={"execution"}
                  label={"Tipo de Ejecución"}
                  labelAlign={"left"}
                  rules={[
                    {
                      required: true,
                      message: "Required field",
                    },
                  ]}
                >
                  <Select placeholder={"Selecciona tipo de ejecución"}>
                    {props.company.botExecution.map((execution) => {
                      return (
                        <Select.Option key={execution.id} value={execution.id}>
                          {execution.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={"duration"}
                  label={"Duración de ejecución (min)"}
                  labelAlign={"left"}
                  rules={[
                    {
                      required: true,
                      message: "Required field",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} min={1} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[10, 20]}>
              <Col span={12}>
                <Form.Item
                  name={"alertSE"}
                  label={"Alerta Inicio y Fin"}
                  labelAlign={"left"}
                  rules={[{ required: true, message: "Required field" }]}
                >
                  <Input
                    placeholder={"Alerta Inicio y Fin"}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={"alert"}
                  label={"Alerta Incidencia"}
                  labelAlign={"left"}
                  rules={[{ required: true, message: "Required field" }]}
                >
                  <Radio.Group value={true}>
                    <Radio value={true}>Si</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[10, 20]}>
              <Col span={12}>
                <label style={{ marginTop: "5px" }}>Status:</label>
              </Col>
            </Row>
            <Row gutter={[10, 20]}>
              <Col span={6}>
                <Tag
                  color={
                    botStatus === 0
                      ? "processing"
                      : botStatus === 1
                      ? "success"
                      : botStatus === 3
                      ? "red"
                      : "default"
                  }
                  style={{
                    cursor: props.botDrawer.editing ? "pointer" : "text",
                    marginTop: "10px",
                  }}
                  onClick={
                    props.botDrawer.editing
                      ? () => {
                          setEditStatus(true);
                        }
                      : () => {}
                  }
                >
                  {props.company.botStatus[botStatus].toUpperCase()}
                </Tag>
              </Col>
              {props.botDrawer.editing && editStatus && (
                <>
                  {botStatus === 1 ? (
                    <>
                      <Col span={6}>
                        <Popconfirm
                          placement="bottomLeft"
                          title="Disable bot"
                          onConfirm={() => {
                            setBotStatus(3);
                            setEditStatus(false);
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            danger
                            style={{ width: "110px", marginTop: "5px" }}
                          >
                            Disable
                          </Button>
                        </Popconfirm>
                      </Col>
                    </>
                  ) : botStatus === 3 ? (
                    <>
                      <Col span={6}>
                        <Popconfirm
                          placement="bottomLeft"
                          title="Enable bot"
                          onConfirm={() => {
                            setBotStatus(1);
                            setEditStatus(false);
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button style={{ width: "110px", marginTop: "5px" }}>
                            Enable
                          </Button>
                        </Popconfirm>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col span={6}>
                        <Popconfirm
                          placement="bottomLeft"
                          title={"Deliver bot"}
                          onConfirm={() => {
                            setBotStatus(1);
                            setEditStatus(false);
                          }}
                          okText="Yes"
                          cancelText="No"
                          disabled={botStatus === 1}
                        >
                          <Button
                            disabled={botStatus === 1}
                            style={{ width: "110px", marginTop: "5px" }}
                          >
                            {botStatus === 1
                              ? props.company.botStatus[1]
                              : "Deliver"}
                          </Button>
                        </Popconfirm>
                      </Col>
                      <Col span={6}>
                        <Popconfirm
                          placement="bottomLeft"
                          title={
                            botStatus === 2
                              ? "Remove bot hold"
                              : "Put bot on hold"
                          }
                          onConfirm={() => {
                            setBotStatus(botStatus === 2 ? 0 : 2);
                            setEditStatus(false);
                          }}
                          okText="Yes"
                          cancelText="No"
                          disabled={botStatus === 1}
                        >
                          <Button
                            disabled={botStatus === 1}
                            style={{ width: "110px", marginTop: "5px" }}
                          >
                            {botStatus === 2 ? "Continue dev" : "Put on hold"}
                          </Button>
                        </Popconfirm>
                      </Col>
                    </>
                  )}
                </>
              )}
            </Row>
            <Row gutter={[10, 20]}>
              <Col span={12}>
                <label style={{ marginTop: "5px" }}>Programación</label>
              </Col>
            </Row>
            <Row gutter={[10, 20]}>
              <Col span={12}>
                <Space align={"baseline"}>
                  <label style={{ width: "70px" }}>Lunes: </label>
                  <Form.Item name={"monday"}>
                    <Input placeholder={"Lunes"} />
                  </Form.Item>
                </Space>
              </Col>
              <Col span={12}>
                <Space align={"baseline"}>
                  <label style={{ width: "70px" }}>Martes: </label>
                  <Form.Item name={"tuesday"}>
                    <Input placeholder={"Martes"} />
                  </Form.Item>
                </Space>
              </Col>
            </Row>
            <Row gutter={[10, 20]}>
              <Col span={12}>
                <Space align={"baseline"}>
                  <label style={{ width: "70px" }}>Miércoles: </label>
                  <Form.Item name={"wednesday"}>
                    <Input placeholder={"Miércoles"} />
                  </Form.Item>
                </Space>
              </Col>
              <Col span={12}>
                <Space align={"baseline"}>
                  <label style={{ width: "70px" }}>Jueves: </label>
                  <Form.Item name={"thursday"}>
                    <Input placeholder={"Jueves"} />
                  </Form.Item>
                </Space>
              </Col>
            </Row>
            <Row gutter={[10, 20]}>
              <Col span={12}>
                <Space align={"baseline"}>
                  <label style={{ width: "70px" }}>Viernes: </label>
                  <Form.Item name={"friday"}>
                    <Input placeholder={"Viernes"} />
                  </Form.Item>
                </Space>
              </Col>
              <Col span={12}>
                <Space align={"baseline"}>
                  <label style={{ width: "70px" }}>Sábado: </label>
                  <Form.Item name={"saturday"}>
                    <Input placeholder={"Sábado"} />
                  </Form.Item>
                </Space>
              </Col>
            </Row>
            <Row gutter={[10, 20]}>
              <Col span={12}>
                <Space align={"baseline"}>
                  <label style={{ width: "70px" }}>Domingo: </label>
                  <Form.Item name={"sunday"}>
                    <Input placeholder={"Domingo"} />
                  </Form.Item>
                </Space>
              </Col>
            </Row>
            <Row gutter={[10, 20]}>
              <Col span={12}>
                <Form.Item
                  name={"month"}
                  label={"Fecha del Mes"}
                  labelAlign={"left"}
                >
                  <Input
                    placeholder={"Fecha del mes"}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[10, 20]}>
              <Col span={6}>
                <Form.Item>
                  <Button
                    type={"primary"}
                    style={{ marginTop: "10px" }}
                    htmlType="submit"
                  >
                    {props.botDrawer.editing ? "Save changes" : "Add bot"}
                  </Button>
                </Form.Item>
              </Col>
              <Col span={12}></Col>
              <Col span={6}>
                {props.botDrawer.editing ? (
                  <Form.Item>
                    <Popconfirm
                      placement="bottomLeft"
                      title={"Delete bot?"}
                      onConfirm={onDelete}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        type={"primary"}
                        danger
                        style={{ marginTop: "10px", marginLeft: "54px" }}
                      >
                        Delete
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                ) : (
                  <Form.Item>
                    <Button
                      type={"default"}
                      danger
                      style={{ marginTop: "10px", marginLeft: "52px" }}
                      onClick={() => {
                        props.setBotDrawer({
                          visible: false,
                          botKey: null,
                          editing: false,
                        });
                        setEditStatus(false);
                        setUipathLicense(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default BotsModal;
