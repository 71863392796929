import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, Row, Col, Upload, message } from "antd";
import { createIdea, uploadFile } from "../../../../../db/firedataCalls";
import { useTranslation } from "react-i18next";
import { UploadOutlined } from "@ant-design/icons";
const areas = require("../../../../../centros.json");

const { Option } = Select;

const DetailsForm = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [workCategories, setWorkCategories] = useState([]);
  /* const colors = [
    "#ffccc7",
    "#ffa39e",
    "#ff7875",
    "#ff4d4f",
    "#f5222d",
    "#ffe7ba",
    "#ffd591",
    "#ffc069",
    "#ffa940",
    "#fa8c16",
    "#ffffb8",
    "#fffb8f",
    "#fff566",
    "#ffec3d",
    "#fadb14",
    "#d9f7be",
    "#b7eb8f",
    "#95de64",
    "#73d13d",
    "#52c41a",
    "#bae7ff",
    "#91d5ff",
    "#69c0ff",
    "#40a9ff",
    "#1890ff",
  ]; */

  const successUpdate = () => {
    message.success(("Información guardada correctamente"));
  };

  const errorUpdate = () => {
    message.error(("Hubo un error al guardar la información"));
  };

  const successFiles = () => {
    message.success(("Archivos subidos correctamente"));
  };

  const errorFiles = () => {
    message.error(("Error al subir archivos"));
  };

  const onFinish = (values) => {
    if (loadingFiles) {
      console.error("Uploading files...");
    }

    setLoading(true);
    values["id"] = props.ideaData.id;
    values["uploads"] = form.getFieldValue("uploads");
    /* values = JSON.parse(JSON.stringify(values, function (k, v) {
            if (v === undefined) { return null; } return v;
        })); */
    values.departmentTitle =
      areas.departments[
        areas.departments.findIndex(
          (departmen) => departmen.value === values.departmentValue
        )
      ].title;
    values.departmentLevels =
      areas.departments[
        areas.departments.findIndex(
          (departmen) => departmen.value === values.departmentValue
        )
      ].levels;
    Object.entries(values).forEach((k) => {
      if (k[1] === undefined) delete values[k[0]];
    });
    createIdea(values)
      .then(() => {
        successUpdate();
        setLoading(false);
      })
      .catch((error) => {
        errorUpdate();
        console.error(error);
      });
  };

  const handleUploadFile = (file) => {
    setLoadingFiles(true);
    uploadFile(props.ideaData.id, file)
      .then((uploadResponse) => {
        let uploads = [];

        if (uploadResponse == null) {
          console.error("no se pudo subir el archivo");
        }

        if (!form.getFieldValue("uploads")) {
          form.setFieldsValue({ uploads: uploads });
        }

        let currUploads = form.getFieldValue("uploads");

        if (Array.isArray(currUploads)) {
          //si no existe lo agrega
          if (
            currUploads.findIndex(
              (upload) => upload.name === uploadResponse.name
            ) === -1
          )
            currUploads.push({
              name: uploadResponse.name,
            });
        } else {
          currUploads = [{ name: uploadResponse.name }];
        }

        form.setFieldsValue({ uploads: currUploads });
        successFiles();
        setLoadingFiles(false);
      })
      .catch((error) => {
        console.error(error);
        errorFiles();
      });
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  useEffect(() => {
    if (props.ideaData) {
      form.setFieldsValue({ ...props.ideaData });
      areas.departments.forEach((area) => {
        setWorkCategories((workCategories) => [
          ...workCategories,
          {
            value: area.value,
            name: area.title,
            key: area.value,
          },
        ]);
      });
    }
    // eslint-disable-next-line
  }, [props.ideaData]);

  return (
    <>
      <Form
        form={form}
        name="datails-form"
        onFinish={onFinish}
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 16,
        }}
        layout="vertical"
        size="medium"
        labelAlign="right"
      >
        <Row gutter={[10, 10]}>
          <Col span={11}>
            <Form.Item
              labelCol={8}
              wrapperCol={14}
              name="name"
              label={t("form_fields.create_idea.name.label")}
            >
              <Input />
            </Form.Item>
            <Form.Item
              labelCol={8}
              wrapperCol={14}
              name="description"
              label={t("form_fields.create_idea.description.label")}
            >
              <Input.TextArea />
            </Form.Item>
            <div className="ant-col ant-form-item-label">
              <label title="Files">{t("Archivos")}</label>
            </div>
            <br />
            <Upload
              name="files"
              listType="text"
              action={(file) => handleUploadFile(file)}
              customRequest={dummyRequest}
            >
              <Button>
                <UploadOutlined /> {t("form_fields.create_idea.upload.label")}
              </Button>
            </Upload>
            <br />
          </Col>
          <Col span={12}>
            <Row gutter={6}>
              <Col span={24}>
                <Form.Item
                  colon={false}
                  labelAlign="left"
                  label={t("form_fields.create_idea.workCategory.label")}
                  name="departmentValue"
                  rules={[
                    {
                      required: true,
                      message: t("Campo requerido"),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {workCategories.map((area) => {
                      return (
                        <Option key={area.value} value={area.value}>
                          {area.name + " (" + area.value.toString() + ")"}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              labelCol={8}
              wrapperCol={14}
              name="comments"
              label={"Comentarios"}
            >
              <Input.TextArea autoSize={{ minRows: 5 }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Form.Item style={{ textAlign: "left" }}>
              <Button
                type="primary"
                htmlType="submit"
                block={loadingFiles}
                loading={loading}
              >
                {t("Guardar")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default DetailsForm;
