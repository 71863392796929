import React, { useState, useEffect } from "react";
import firebase from "../../../../../server/firebase";
import moment from "moment";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Divider,
  Table,
  Select,
  DatePicker,
  Empty,
  Popconfirm,
  Tooltip,
  Avatar,
  Tag,
  InputNumber,
  Space,
} from "antd";
import WalmartAssigned from "./WalmartAssigned";
import WalmartComments from "./WalmartComments";
import "../../../../../App.css";
import { presetPalettes } from "@ant-design/colors";
import { EnterOutlined } from "@ant-design/icons";
const clone = require("rfdc")();

const WalmartMaintenance = (props) => {
  const [bots, setBots] = useState([]);
  const [maintenanceList, setMaintenanceList] = useState([]);
  const [botFilterList, setBotFilterList] = useState([]);
  const [tableChange, setTableChange] = useState(false);
  const dateFormat = "DD/MM/YYYY";
  const [form] = Form.useForm();
  const [deliveredBots, setDeliveredBots] = useState([]);
  const [users, setUsers] = useState([]);
  const [editStatus, setEditStatus] = useState({ id: -1, bot: -1 });
  const [editIDRF, setEditIDRF] = useState({ id: -1, bot: -1 });
  const [editStatusRF, setEditStatusRF] = useState({ id: -1, bot: -1 });
  const [editCategory, setEditCategory] = useState({ id: -1, bot: -1 });

  const statusColor = {
    0: "default",
    1: "warning",
    2: "success",
    3: "purple",
  };

  const statusText = {
    0: "PENDING",
    1: "IN PROGRESS",
    2: "COMPLETED",
    3: "ON HOLD",
  };

  useEffect(() => {
    let cloneBots = [];
    let tempList = [];
    let tempBotFilterList = [];
    let colors = [];
    let users = [];
    if (props.company.users) users = Object.values(props.company.users);
    if (props.idea.bots) {
      cloneBots = clone(props.idea.bots);
      setBots(cloneBots);
      setDeliveredBots(cloneBots.filter((bot) => bot.status === 1));
      cloneBots.forEach((bot) => {
        if (bot.maintenance) {
          tempBotFilterList.push({ text: bot.name, value: bot.key });
          bot.maintenance.forEach((maintenance) => {
            tempList.push({
              id: maintenance.id,
              botKey: bot.key,
              botName: bot.name,
              startDate: maintenance.startDate,
              endDate: maintenance.endDate,
              realStartDate: maintenance.realStartDate
                ? maintenance.realStartDate
                : null,
              realEndDate: maintenance.realEndDate
                ? maintenance.realEndDate
                : null,
              type: maintenance.type,
              description: maintenance.description,
              maintenance: maintenance,
              asignee: maintenance.asignee,
              comments: maintenance.comments,
              priority: maintenance.priority,
              status: maintenance.status,
              createdDate: maintenance.createdDate,
              schdStartDate: maintenance.schdStartDate
                ? maintenance.schdStartDate
                : null,
              schdEndDate: maintenance.schdEndDate
                ? maintenance.schdEndDate
                : null,
              category: maintenance.category,
              idRF: maintenance.idRF,
              statusRF: maintenance.statusRF,
            });
          });
        }
      });
      Object.values(presetPalettes).forEach((colorSet) => {
        colors.push(colorSet.primary);
      });
      let res = users.length / colors.length;
      if (res > 1) {
        for (let a = 0; a < Math.round(res); a++) {
          colors.push(...colors);
        }
      }
      /* users = [
          {
            companyId: "QrEN5Mn25tnNhdPET601",
            email: "juane.soto@softtek.com",
            firstName: "Juan",
            lastName1: "Soto",
            lastName2: "Luévano",
            rol: "User",
            status: "Active",
            uid: "7FVuUhLFfBUVXOfDeEo3UQvax7k1",
            username: "juane.soto@softtek.com",
          },
          {
            companyId: "QrEN5Mn25tnNhdPET601",
            email: "luis.luna@softtek.com",
            firstName: "Luis",
            lastName1: "de Luna",
            lastName2: "Saucedo",
            rol: "User",
            status: "Active",
            uid: "7FVuUFfBUVXOfDeEo3UQvax7k1",
            username: "luis.luna@softtek.com",
          },
          {
            companyId: "QrEN5Mn25tnNhdPET601",
            email: "javier.gutierrez@softtek.com",
            firstName: "Javier",
            lastName1: "Gutiérrez",
            lastName2: "Pérez",
            rol: "User",
            status: "Active",
            uid: "7FVuUhLFfVXOfDeEo3UQvax7k1",
            username: "javier.gutierrez@softtek.com",
          },
          {
            companyId: "QrEN5Mn25tnNhdPET601",
            email: "jorge.bohorquez@softtek.com",
            firstName: "Jorge",
            lastName1: "Bohorquez",
            lastName2: "Ibarra",
            rol: "User",
            status: "Active",
            uid: "7FVuUhLFfBUVXOfDeEovax7k1",
            username: "jorge.bohorquez@softtek.com",
          },
        ]; */
      users = users.map((user, index) => ({ ...user, color: colors[index] }));
      setUsers(users);
      setBotFilterList(tempBotFilterList);
      setMaintenanceList(tempList);
    }
  }, [props.idea.bots, props.company.users]);

  const onFinish = async (values) => {
    let user = null;
    let tempBots = props.idea.bots;
    let index = bots.findIndex(({ key }) => key === values.bot);
    values.asignee = values.asignee.map((asignee) => {
      user = users.find(({ uid }) => uid === asignee);
      return {
        name: returnName(user),
        email: user.email,
        uid: user.uid,
      };
    });
    if (tempBots[index].maintenance)
      tempBots[index].maintenance.push({
        createdDate: { seconds: moment().unix(), nanoseconds: 0 },
        schdStartDate: { seconds: values.date[0].unix(), nanoseconds: 0 },
        schdEndDate: { seconds: values.date[1].unix(), nanoseconds: 0 },
        endDate: { seconds: values.date[1].unix(), nanoseconds: 0 },
        description: values.description,
        id:
          Number(
            tempBots[index].maintenance.reduce(
              (max, maintenance) =>
                maintenance.id > max ? maintenance.id : max,
              0
            )
          ) + 1,
        asignee: values.asignee,
        type: values.type,
        priority: values.priority,
        status: 0,
        category: values.category,
        idRF: values.idRF,
        statusRF: values.statusRF,
      });
    else
      tempBots[index].maintenance = [
        {
          createdDate: { seconds: moment().unix(), nanoseconds: 0 },
          schdStartDate: { seconds: values.date[0].unix(), nanoseconds: 0 },
          schdEndDate: { seconds: values.date[1].unix(), nanoseconds: 0 },
          description: values.description,
          id: 1,
          asignee: values.asignee,
          priority: values.priority,
          status: 0,
          type: values.type,
          category: values.category,
          idRF: values.idRF,
          statusRF: values.statusRF,
        },
      ];
    await firebase
      .database()
      .ref()
      .child("ideas")
      .child(props.idea.id)
      .update({ bots: tempBots });
    form.resetFields();
    setTableChange(false);
  };

  const updateBots = async () => {
    await firebase
      .database()
      .ref()
      .child("ideas")
      .child(props.idea.id)
      .update({ bots: bots });
    setTableChange(false);
  };

  const onDateChange = (obj, value, type) => {
    let changeType = "";
    switch (type) {
      case 0:
        changeType = "schdStartDate";
        break;
      case 1:
        changeType = "schdEndDate";
        break;
      default:
        changeType = "realEndDate";
        break;
    }
    let tempBots = bots;
    let botIndex = tempBots.findIndex(({ key }) => key === obj.botKey);
    let mainIndex = tempBots[botIndex].maintenance.findIndex(
      ({ id }) => id === obj.id
    );
    if (value) {
      obj[changeType] = {
        seconds: value.unix(),
        nanoseconds: 0,
      };
      tempBots[botIndex].maintenance[mainIndex][changeType] = obj[changeType];
    } else {
      tempBots[botIndex].maintenance[mainIndex][changeType] = null;
      obj[changeType] = null;
    }
    setTableChange(true);
    setBots(tempBots);
  };

  const onPriorityChange = (obj, value) => {
    let tempBots = bots;
    let botIndex = tempBots.findIndex(({ key }) => key === obj.botKey);
    let mainIndex = tempBots[botIndex].maintenance.findIndex(
      ({ id }) => id === obj.id
    );
    if (value) {
      tempBots[botIndex].maintenance[mainIndex].priority = value;
    }
    setTableChange(true);
    setBots(tempBots);
  };

  const onStatusChange = (nextStatus, obj, e) => {
    e.preventDefault();
    let tempBots = bots;
    let botIndex = tempBots.findIndex(({ key }) => key === obj.botKey);
    let mainIndex = tempBots[botIndex].maintenance.findIndex(
      ({ id }) => id === obj.id
    );
    tempBots[botIndex].maintenance[mainIndex].status = nextStatus;
    obj.status = nextStatus;
    if (nextStatus === 2) {
      tempBots[botIndex].maintenance[mainIndex].realEndDate = {
        seconds: moment().unix(),
        nanoseconds: 0,
      };
      obj.realEndDate = { seconds: moment().unix(), nanoseconds: 0 };
    }

    setBots(tempBots);
    setEditStatus({ id: -1, bot: -1 });
    setTableChange(true);
  };

  const onIDRFChange = (obj, value) => {
    let tempBots = bots;
    let botIndex = tempBots.findIndex(({ key }) => key === obj.botKey);
    let mainIndex = tempBots[botIndex].maintenance.findIndex(
      ({ id }) => id === obj.id
    );
    if (value) {
      tempBots[botIndex].maintenance[mainIndex].idRF = value;
      obj.idRF = value;
    }
    setBots(tempBots);
    setEditIDRF({ id: -1, bot: -1 });
    setTableChange(true);
  };

  const onStatusRFChange = (obj, value) => {
    let tempBots = bots;
    let botIndex = tempBots.findIndex(({ key }) => key === obj.botKey);
    let mainIndex = tempBots[botIndex].maintenance.findIndex(
      ({ id }) => id === obj.id
    );
    if (value) {
      tempBots[botIndex].maintenance[mainIndex].statusRF = value;
      obj.statusRF = value;
    }
    setBots(tempBots);
    setEditStatusRF({ id: -1, bot: -1 });
    setTableChange(true);
  };

  const onCategoryChange = (obj, value) => {
    let tempBots = bots;
    let botIndex = tempBots.findIndex(({ key }) => key === obj.botKey);
    let mainIndex = tempBots[botIndex].maintenance.findIndex(
      ({ id }) => id === obj.id
    );
    if (value) {
      tempBots[botIndex].maintenance[mainIndex].category = value;
      obj.category = value;
    }
    setBots(tempBots);
    setEditCategory({ id: -1, bot: -1 });
    setTableChange(true);
  };

  const deleteMaintenance = (record) => {
    let tempBots = bots;
    let index = bots.findIndex(({ key }) => key === record.botKey);
    let maintenance = tempBots[index].maintenance.filter(
      ({ id }) => id !== record.maintenance.id
    );
    tempBots[index].maintenance = maintenance;
    setBots(tempBots);
    updateBots();
    setTableChange(false);
  };

  const returnName = (user) => {
    return (
      user.firstName +
      " " +
      (user.lastName1 &&
        user.lastName1 + (user.lastName2 && " " + user.lastName2))
    );
  };

  const getChildren = () => {
    return (
      <Select
        placeholder={"Asign to someone"}
        mode="multiple"
        optionLabelProp="label"
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {users.map((user) => (
          <Select.Option
            key={user.uid}
            value={user.uid}
            label={returnName(user)}
          >
            <Tooltip placement="right" title={user.email}>
              <Avatar style={{ backgroundColor: user.color }}>
                {user.firstName[0].toUpperCase() +
                  user.lastName1[0].toUpperCase()}
              </Avatar>
              {" " + returnName(user)}
            </Tooltip>
          </Select.Option>
        ))}
      </Select>
    );
  };

  const columns = [
    {
      title: "Maintenance Type",
      dataIndex: "type",
      /* filters: [...botFilterList],
        filterMultiple: false,
        onFilter: (value, record) => record.botKey === value, */
      fixed: "left",
      width: 180,
    },
    {
      title: "Bot Name",
      dataIndex: "botName",
      filters: [...botFilterList],
      filterMultiple: false,
      onFilter: (value, record) => record.botKey === value,
      fixed: "left",
      width: 180,
    },
    {
      title: "Comments",
      render: (text, record) => {
        return (
          <WalmartComments
            record={record}
            setPopoverRecord={() => false}
            users={users}
            userInfo={props.userInfo}
            idea={props.idea}
            bots={bots}
            origin={"WalmartMaintenance"}
          />
        );
      },
      width: 210,
    },
    {
      title: "Team",
      render: (text, record) => {
        return (
          <WalmartAssigned
            record={record}
            setPopoverRecord={() => false}
            users={users}
            userInfo={props.userInfo}
            idea={props.idea}
            bots={bots}
            origin={"WalmartMaintenance"}
          />
        );
      },
      width: 150,
    },
    {
      title: "Schedule Start Date",
      dataIndex: "schdStartDate",
      render: (text, record) => {
        return (
          <DatePicker
            defaultValue={text && moment(moment.unix(text.seconds), dateFormat)}
            format={dateFormat}
            onChange={(date) => onDateChange(record, date, 0)}
            bordered={false}
            suffixIcon={null}
          />
        );
      },
      width: 130,
    },
    {
      title: "Schedule End Date",
      dataIndex: "schdEndDate",
      render: (text, record) => {
        return (
          <DatePicker
            defaultValue={text && moment(moment.unix(text.seconds), dateFormat)}
            format={dateFormat}
            onChange={(date) => onDateChange(record, date, 1)}
            bordered={false}
            suffixIcon={null}
            allowClear={false}
          />
        );
      },
      width: 130,
    },
    {
      title: "End Date",
      dataIndex: "realEndDate",
      render: (text, record) => {
        return record.status === 2 ? (
          <DatePicker
            defaultValue={text && moment(moment.unix(text.seconds), dateFormat)}
            format={dateFormat}
            onChange={(date) => onDateChange(record, date, 3)}
            bordered={false}
            suffixIcon={null}
            allowClear={false}
          />
        ) : (
          <span style={{ textAlign: "center", paddingLeft: "12px" }}>
            Not finished
          </span>
        );
      },
      width: 130,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => {
        return editStatus.id === record.id &&
          editStatus.bot === record.botKey ? (
          <>
            <Space direction="vertical">
              <Tag
                color={statusColor[0]}
                style={{ cursor: "pointer", width: "90%", textAlign: "center" }}
                onClick={(e) => onStatusChange(0, record, e)}
              >
                {statusText[0]}
              </Tag>
              <Tag
                color={statusColor[1]}
                style={{ cursor: "pointer", width: "90%", textAlign: "center" }}
                onClick={(e) => onStatusChange(1, record, e)}
              >
                {statusText[1]}
              </Tag>
              <Tag
                color={statusColor[3]}
                style={{ cursor: "pointer", width: "90%", textAlign: "center" }}
                onClick={(e) => onStatusChange(3, record, e)}
              >
                {statusText[3]}
              </Tag>
              <Tag
                color={statusColor[2]}
                style={{ cursor: "pointer", width: "90%", textAlign: "center" }}
                onClick={(e) => onStatusChange(2, record, e)}
              >
                {statusText[2]}
              </Tag>
            </Space>
          </>
        ) : (
          <>
            {text === 2 ? (
              <Tag color={statusColor[text]} style={{ textAlign: "center" }}>
                {statusText[text]}
              </Tag>
            ) : (
              <Tag
                color={statusColor[text]}
                onClick={() => {
                  setEditStatus({ id: record.id, bot: record.botKey });
                }}
                style={{ cursor: "pointer", width: "90%", textAlign: "center" }}
              >
                {statusText[text]}
              </Tag>
            )}
          </>
        );
      },
      width: 120,
    },
    {
      title: "Priority",
      dataIndex: "priority",
      render: (text, record) => {
        return (
          <InputNumber
            style={{ width: "80%" }}
            bordered={false}
            defaultValue={text}
            min={1}
            max={10}
            onChange={(number) => onPriorityChange(record, number)}
          />
        );
      },
      width: 100,
    },
    {
      title: "Category",
      dataIndex: "category",
      render: (text, record) => {
        return editCategory.id === record.id &&
          editCategory.bot === record.botKey ? (
          <>
            <Select
              placeholder={"Category of maintenance"}
              defaultValue={text}
              onChange={(value) => {
                onCategoryChange(record, value);
              }}
              style={{ width: "100%" }}
            >
              {props.company.maintenanceCategory.map((type) => {
                return (
                  <Select.Option key={type} value={type}>
                    {type}
                  </Select.Option>
                );
              })}
            </Select>
          </>
        ) : (
          <>
            <span
              style={{
                padding: "6px 6px 3px 3px",
                cursor: "pointer",
                color: text ? "" : "DarkGrey",
              }}
              onClick={() => {
                setEditCategory({ id: record.id, bot: record.botKey });
              }}
            >
              {text ? text : "Select category"}
            </span>
          </>
        );
      },
      width: 250,
    },
    {
      title: "ID RF",
      dataIndex: "idRF",
      render: (text, record) => {
        return editIDRF.id === record.id && editIDRF.bot === record.botKey ? (
          <>
            <Input
              placeholder={"ID RF"}
              style={{ width: "90%" }}
              defaultValue={text}
              suffix={<EnterOutlined />}
              onPressEnter={(e) => {
                onIDRFChange(record, e.target.value);
              }}
            />
          </>
        ) : (
          <>
            <span
              style={{
                padding: "13px 13px 6px 6px",
                cursor: "text",
                color: text ? "" : "DarkGrey",
              }}
              onClick={() => {
                setEditIDRF({ id: record.id, bot: record.botKey });
              }}
            >
              {text ? text : "Type ID RF"}
            </span>
          </>
        );
      },
      width: 180,
    },
    {
      title: "Status RF",
      dataIndex: "statusRF",
      render: (text, record) => {
        return editStatusRF.id === record.id &&
          editStatusRF.bot === record.botKey ? (
          <>
            <Input
              placeholder={"Status RF"}
              style={{ width: "90%" }}
              defaultValue={text}
              suffix={<EnterOutlined />}
              onPressEnter={(e) => {
                onStatusRFChange(record, e.target.value);
              }}
            />
          </>
        ) : (
          <>
            <span
              style={{
                padding: "13px 13px 6px 6px",
                cursor: "text",
                color: text ? "" : "DarkGrey",
              }}
              onClick={() => {
                setEditStatusRF({ id: record.id, bot: record.botKey });
              }}
            >
              {text ? text : "Type Status RF"}
            </span>
          </>
        );
      },
      width: 180,
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      render: (text, record) =>
        text ? `${moment.unix(text.seconds).format(dateFormat)}` : "",
      width: 130,
    },
    { title: "Description", dataIndex: "description", width: 600 },
    {
      title: "Actions",
      render: (text, record) => {
        return (
          <Popconfirm
            placement="bottom"
            title={"Delete maintenance?"}
            okText={"Yes"}
            cancelText="No"
            onConfirm={() => deleteMaintenance(record)}
          >
            <Button
              type="link"
              style={{ width: "50px", borderLeft: "0px", paddingLeft: "0px" }}
            >
              {"Delete"}
            </Button>
          </Popconfirm>
        );
      },
      fixed: "right",
      width: 100,
    },
  ];

  return props.idea.bots ? (
    <>
      {props.idea.bots && bots && (
        <>
          <Row gutter={[40, 20]}>
            <Divider>Maintenance List</Divider>
          </Row>
          <Row gutter={[40, 20]}>
            <Table
              columns={columns}
              dataSource={maintenanceList}
              style={{ width: "100%" }}
              rowKey={(record) => record.maintenance.id + "_" + record.botKey}
              pagination={{ pageSize: 7 }}
              scroll={{ x: "max-content" }}
            />
          </Row>
          <Row gutter={[40, 20]} justify={"end"}>
            <Popconfirm
              placement="bottomLeft"
              title={"Save Changes?"}
              onConfirm={updateBots}
              okText={"Yes"}
              cancelText={"Cancel"}
              disabled={!tableChange}
            >
              <Button disabled={!tableChange} type="primary">
                {"Save Changes"}
              </Button>
            </Popconfirm>
          </Row>
        </>
      )}
      <Divider>New Maintenance</Divider>

      <Form
        onFinish={onFinish}
        form={form}
        name="New Bot"
        initialValues={{ priority: 1 }}
      >
        <Row gutter={[10, 20]} justify="center">
          <Col span={10}>
            <Row gutter={[10, 20]}>
              <Col span={12}>
                <Form.Item
                  name={"type"}
                  label={"Type"}
                  labelAlign={"left"}
                  rules={[
                    {
                      required: true,
                      message: "Required field",
                    },
                  ]}
                >
                  <Select placeholder={"Select a type of maintenance"}>
                    {props.company.maintenanceType.map((type) => {
                      return (
                        <Select.Option key={type} value={type}>
                          {type}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={"priority"}
                  label={"Priority"}
                  labelAlign={"left"}
                  rules={[
                    {
                      required: true,
                      message: "Required field",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} min={1} max={10} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={"bot"}
                  label={"Bot name"}
                  labelAlign={"left"}
                  rules={[
                    {
                      required: true,
                      message: "Required field",
                    },
                  ]}
                >
                  <Select placeholder={"Select a bot"}>
                    {deliveredBots.map((bot) => {
                      return (
                        <Select.Option key={bot.key} value={bot.key}>
                          {bot.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={"date"}
                  label={"Schedule date"}
                  labelAlign={"left"}
                  rules={[
                    {
                      required: true,
                      message: "Required field",
                    },
                  ]}
                >
                  <DatePicker.RangePicker
                    format={dateFormat}
                    defaultValue={[moment()]}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={"asignee"}
                  label={"Team"}
                  labelAlign={"left"}
                  rules={[
                    {
                      required: true,
                      message: "Required field",
                    },
                  ]}
                >
                  {getChildren()}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={"category"}
                  label={"Category"}
                  labelAlign={"left"}
                  rules={[
                    {
                      required: true,
                      message: "Required field",
                    },
                  ]}
                >
                  <Select placeholder={"Select a category of maintenance"}>
                    {props.company.maintenanceCategory.map((type) => {
                      return (
                        <Select.Option key={type} value={type}>
                          {type}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={"idRF"}
                  label={"ID RF"}
                  labelAlign={"left"}
                  rules={[
                    {
                      required: true,
                      message: "Required field",
                    },
                  ]}
                >
                  <Input
                    placeholder={"Maintenance ID RF"}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={"statusRF"}
                  label={"Status RF"}
                  labelAlign={"left"}
                  rules={[
                    {
                      required: true,
                      message: "Required field",
                    },
                  ]}
                >
                  <Input
                    placeholder={"Maintenance Status RF"}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={"description"}
                  label={"Description"}
                  labelAlign={"left"}
                  rules={[{ required: true, message: "Required field" }]}
                >
                  <Input.TextArea
                    placeholder={"Maintenance description"}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Form.Item>
                <Button
                  type={"primary"}
                  style={{ marginLeft: "6px" }}
                  htmlType="submit"
                >
                  Add Maintenance
                </Button>
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  ) : (
    <Empty description={"No Bots"} />
  );
};

export default WalmartMaintenance;
