import React, { useEffect } from "react";
import { Funnel } from "@antv/g2plot";
import { useTranslation } from "react-i18next";

const FunnelBenefitStageChart = (props) => {
  const { t } = useTranslation();

  let workCategoryStats = props.chartData;

  const data = [
    {
      action: t("dashboard.charts.labels.not_processed"), //area de negocio
      pv: workCategoryStats["not_processed"]
        ? workCategoryStats["not_processed"]
        : 0,
    },
    {
      action: t("dashboard.charts.labels.in_pipeline"), //area de negocio
      pv: workCategoryStats["in_pipeline"]
        ? workCategoryStats["in_pipeline"]
        : 0,
    },
    {
      action: t("dashboard.charts.labels.in_progress"),
      pv: workCategoryStats["in_progress"]
        ? workCategoryStats["in_progress"]
        : 0,
    },
    {
      action: t("dashboard.charts.labels.deployed"),
      pv: workCategoryStats["deployed"] ? workCategoryStats["deployed"] : 0,
    },
  ];

  useEffect(() => {
    console.log();
    new Funnel(
      document.getElementById("funnel_benefit_stage_chart_container"),
      {
        data: data,
        xField: "action",
        yField: "pv",
        transpose: true,
        dynamicHeight: true,
        label: {
          precision: 2,
          field: "pv",
          visible: true,
          formatter: (action, pv) =>
            `${action} $${new Intl.NumberFormat("es-MX", {
              maximumSignificantDigits: 3,
            }).format(pv.element.data.pv)}`,
        },
        responsive: true,
        description: { visible: false },
        percentage: {
          visible: false,
        },
      }
    ).render();
    //eslint-disable-next-line
  }, []);

  return <div id="funnel_benefit_stage_chart_container"></div>;
};

export default FunnelBenefitStageChart;
