import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Card, Divider, Spin } from "antd";
import {
  ToTopOutlined,
  SendOutlined,
  ToolOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import IdeaVelocityChart from "../Prueba/PruebaCharts/IdeaVelocityChart";
import OpportunitiesByStageChart from "../Prueba/PruebaCharts/OpportunitiesByStageChart";
import ForecastBenefitStatsCard from "../Prueba/PruebaCards/ForecastBenefitStatsCard";
import BenefitDeployedStatsCard from "../Prueba/PruebaCards/BenefitDeployedStatsCard";
import BenefitInProgressStatsCard from "../Prueba/PruebaCards/BenefitInProgressStatsCard";
import BenefitInPipelineStatsCard from "../Prueba/PruebaCards/BenefitInPipelineStatsCard";
import BulletBenefitChart from "../Prueba/PruebaCharts/BulletBenefitChart";
import FunelChart from "../Prueba/PruebaCharts/FunelChart";
import FunnelByStageChart from "../Prueba/PruebaCharts/FunnelByStageChart";
import {
  getBenefitSumByStatus,
  getLastMonthsIdeas,
  getIdeasCountByStatus,
  getAllWorkCategoryStats,
  getBenefitsByStage,
} from "../../../db/firedataCalls";
import { useTranslation } from "react-i18next";

import "../../../App";

const cardsStyle = { minHeight: "100%", textAlign: "center" };
const colStyle = { padding: "0.5em" };

const PruebaDashboard = (props) => {
  const [loading] = useState(true);
  const { t } = useTranslation();

  const [state, setState] = useState({
    loading: true,
    benefitDeployed: 0,
    benefitInProgress: 0,
    benefitInPipeline: 0,
    benefitNotProcessed: 0,
    ideaVelocityChartData: [],
    opportunitiesByStageChartData: {},
    bulletBenefitChartData: [],
    sentIdeasChartData: [],
    benefitByDepartmentChartData: [],
  });

  /** Obtenemos toda la información necesaia para  */
  const getAllInfo = async () => {
    try {
      setState({ ...state, loading: true });
      //Se obtienen las ideas de la empresa
      let ideas = props.ideas;
      let [
        benefitDeployed,
        benefitInProgress,
        benefitInPipeline,
        benefitNotProcessed,
        ideaVelocityChartData,
        opportunitiesByStageChartData,
        bulletBenefitChartData,
        benefitByDepartmentChartData,
      ] = await Promise.all([
        getBenefitSumByStatus(3, ideas),
        getBenefitSumByStatus(2, ideas),
        getBenefitSumByStatus(1, ideas),
        getBenefitSumByStatus(0, ideas),
        getLastMonthsIdeas(6, ideas),
        getIdeasCountByStatus(ideas),
        getAllWorkCategoryStats(ideas),
        getBenefitsByStage(ideas),
      ]);

      setState({
        ...state,
        loading: false,
        benefitDeployed: benefitDeployed,
        benefitInProgress: benefitInProgress,
        benefitInPipeline: benefitInPipeline,
        benefitNotProcessed: benefitNotProcessed,
        ideaVelocityChartData: ideaVelocityChartData,
        opportunitiesByStageChartData: opportunitiesByStageChartData,
        bulletBenefitChartData: bulletBenefitChartData,
        sentIdeasChartData: opportunitiesByStageChartData,
        benefitByDepartmentChartData: benefitByDepartmentChartData,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (props.ideas) getAllInfo();
    // eslint-disable-next-line
  }, [props.ideas]);

  return (
    <Layout>
      {state.loading ? (
        <Spin
          style={{
            height: "100vh",
            width: "100vw",
            textAlign: "center",
            marginTop: "25%",
          }}
          size="large"
          spinning={loading}
        ></Spin>
      ) : (
        <>
          <Row justify="center" gutter={6}>
            <Col
              className="gutter-row"
              xs={24}
              sm={24}
              md={6}
              lg={6}
              style={colStyle}
            >
              <Card style={cardsStyle}>
                <Row>
                  <Col span={18}>
                    <Row>
                      <span style={{ color: "#1890ff" }}>
                        {t("dashboard.cards.production.title")}
                      </span>
                    </Row>
                    <Row>
                      <BenefitDeployedStatsCard
                        benefitDeployed={state.benefitDeployed}
                      />
                    </Row>
                  </Col>
                  <Col span={6}>
                    <ToTopOutlined
                      style={{
                        fontWeight: "bolder",
                        fontSize: "2em",
                        color: "#1890ff",
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col
              className="gutter-row"
              xs={24}
              sm={24}
              md={6}
              lg={6}
              style={colStyle}
            >
              <Card style={cardsStyle}>
                <Row>
                  <Col span={18}>
                    <Row>
                      <span style={{ color: "#1890ff" }}>
                        {t("dashboard.cards.inprogress.title")}
                      </span>
                    </Row>
                    <Row>
                      <BenefitInProgressStatsCard
                        benefitInProgress={state.benefitInProgress}
                      />
                    </Row>
                  </Col>
                  <Col span={6}>
                    <SendOutlined
                      style={{
                        fontWeight: "bolder",
                        fontSize: "2em",
                        color: "#1890ff",
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col
              className="gutter-row"
              xs={24}
              sm={24}
              md={6}
              lg={6}
              style={colStyle}
            >
              <Card style={cardsStyle}>
                <Row>
                  <Col span={18}>
                    <Row>
                      <span style={{ color: "#1890ff" }}>
                        {t("dashboard.cards.inpipeline.title")}
                      </span>
                    </Row>
                    <Row>
                      <BenefitInPipelineStatsCard
                        benefitInPipeline={state.benefitInPipeline}
                      />
                    </Row>
                  </Col>
                  <Col span={6}>
                    <ToolOutlined
                      style={{
                        fontWeight: "bolder",
                        fontSize: "2em",
                        color: "#1890ff",
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col
              className="gutter-row"
              xs={24}
              sm={24}
              md={6}
              lg={6}
              style={colStyle}
            >
              <Card style={cardsStyle}>
                <Row>
                  <Col span={18}>
                    <Row>
                      <span style={{ color: "#1890ff" }}>
                        {t("dashboard.cards.notprocessed.title")}
                      </span>
                    </Row>
                    <Row>
                      <ForecastBenefitStatsCard
                        benefitNotProcessed={state.benefitNotProcessed}
                      />
                    </Row>
                  </Col>
                  <Col span={6}>
                    <FilterOutlined
                      style={{
                        fontWeight: "bolder",
                        fontSize: "2em",
                        color: "#1890ff",
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row justify="center" gutter={6}>
            <Col
              className="gutter-row"
              xs={24}
              sm={24}
              md={12}
              lg={12}
              style={colStyle}
            >
              <Card style={cardsStyle}>
                <Divider orientation="left">
                  {t("dashboard.charts.velocity.title")}
                </Divider>
                <Row>
                  <Col span={24}>
                    <IdeaVelocityChart
                      chartData={state.ideaVelocityChartData}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col
              className="gutter-row"
              xs={24}
              sm={24}
              md={12}
              lg={12}
              style={colStyle}
            >
              <Card style={cardsStyle}>
                <Divider orientation="left">
                  {t("dashboard.charts.bystatus.title")}
                </Divider>
                <Row>
                  <Col span={24}>
                    <OpportunitiesByStageChart
                      chartData={state.opportunitiesByStageChartData}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col
              className="gutter-row"
              xs={24}
              sm={24}
              md={12}
              lg={12}
              style={colStyle}
            >
              <Card style={cardsStyle}>
                <Divider orientation="left">
                  {t("dashboard.charts.completed.title")}
                </Divider>
                <Row>
                  <Col span={24}>
                    <BulletBenefitChart
                      chartData={state.bulletBenefitChartData}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col
              className="gutter-row"
              xs={24}
              sm={24}
              md={12}
              lg={12}
              style={colStyle}
            >
              <Card style={cardsStyle}>
                <Divider orientation="left">
                  {t("dashboard.charts.sent.title")}
                </Divider>
                <Row>
                  <Col span={24}>
                    <FunelChart chartData={state.sentIdeasChartData} />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col
              className="gutter-row"
              xs={24}
              sm={24}
              md={24}
              lg={24}
              style={colStyle}
            >
              <Card style={cardsStyle}>
                <Divider orientation="left">
                  {t("dashboard.charts.byworkcategory.title")}
                </Divider>
                <Row>
                  <Col span={24}>
                    <FunnelByStageChart
                      chartData={state.benefitByDepartmentChartData}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </Layout>
  );
};
export default PruebaDashboard;