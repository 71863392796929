import React, { useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Radio,
  Select,
  Button,
  InputNumber,
  message,
  Input,
  Checkbox,
  Divider,
} from "antd";
import { useTranslation } from "react-i18next";
import { updateIdea } from "../../../../../db/firedataCalls";
import moment from "moment";
import {
  afectacion,
  consistencia,
  duration,
  estandarizacion,
  frecuencia,
  legibilidad,
  manrep,
  porcentajeHabitual,
  procesos,
  reglas,
  sistemas,
  volumentrias,
} from "../../../../Constants/Desigual/DetailsQuestionary";
const { Option } = Select;

const JustificacionIdea = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const format = "DD/MM/YYYY";

  useEffect(() => {
    if (props.ideaData) {
      form.setFieldsValue({ ...props.ideaData });
      if (props.ideaData.fechaMax) {
        form.setFieldsValue({ hasFechaMax: true });
        form.setFieldsValue({ fechaMax: moment(getDateString(), format) });
      } else {
        form.setFieldsValue({ hasFechaMax: false });
      }
    }
    // eslint-disable-next-line
  }, [props.ideaData]);

  const successUpdate = () => {
    message.success(t("Información guardada correctamente"));
  };

  const errorUpdate = () => {
    message.error(t("Hubo un error al guardar la información"));
  };

  const getDateString = () => {
    return new Date(
      props.ideaData.fechaMax._seconds * 1000 +
        props.ideaData.fechaMax._nanoseconds / 1000000
    ).toLocaleDateString("en-GB");
  };

  const onFinish = async (values) => {
    console.log(values);
    updateIdea(props.ideaData.id, values)
      .then(() => {
        successUpdate();
      })
      .catch((err) => {
        errorUpdate();
      });
  };

  const onFinishFailed = (errorInfo) => {
    errorUpdate();
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      form={form}
      name="justificacion"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 16 }}
      layout="vertical"
      size="medium"
      labelAlign="right"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row gutter={[40, 20]}>
        <Divider style={{ marginBottom: 60 }}>Ejecución del proceso</Divider>
        <Col span={12}>
          <Form.Item
            labelCol={8}
            wrapperCol={14}
            name="manual"
            label={"¿Cómo de manual y repetitivo es el proceso?"}
            rules={[
              {
                required: true,
                message: "Campo requerido",
              },
            ]}
          >
            <Select style={{ width: "100%" }}>
              {manrep.map(({ key, value }) => (
                <Option key={key} value={value} children={value} />
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            labelCol={8}
            wrapperCol={14}
            name="frecuencia"
            label={"¿Con qué frecuencia se ejecuta el proceso?"}
            rules={[
              {
                required: true,
                message: "Campo requerido",
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              optionFilterProp="value"
            >
              {frecuencia.map(({ key, value }) => (
                <Option key={key} value={value} children={value} />
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            labelCol={8}
            wrapperCol={14}
            label={"¿Cuál es el nº de ejecuciones del proceso?"}
          >
            <Input.Group compact style={{ width: "100%" }}>
              <Form.Item
                name="volumetrias"
                noStyle
                rules={[
                  {
                    required: true,
                    message: "Campo requerido",
                  },
                ]}
              >
                <InputNumber style={{ width: "80%" }} />
              </Form.Item>
              <Form.Item
                name="volumetriasUM"
                noStyle
                rules={[
                  {
                    required: true,
                    message: "Campo requerido",
                  },
                ]}
              >
                <Select style={{ width: "20%" }}>
                  {volumentrias.map(({ key, value }) => (
                    <Option key={key} value={value} children={value} />
                  ))}
                </Select>
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            labelCol={8}
            wrapperCol={14}
            label={"¿Cuánto dura cada ejecución del proceso?"}
          >
            <Input.Group compact style={{ width: "100%" }}>
              <Form.Item
                name="duracion"
                noStyle
                rules={[
                  {
                    required: true,
                    message: "Campo requerido",
                  },
                ]}
              >
                <InputNumber style={{ width: "80%" }} />
              </Form.Item>
              <Form.Item
                name="duracionUM"
                noStyle
                rules={[
                  {
                    required: true,
                    message: "Campo requerido",
                  },
                ]}
              >
                <Select style={{ width: "20%" }}>
                  {duration.map(({ key, value }) => (
                    <Option key={key} value={value} children={value} />
                  ))}
                </Select>
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            labelCol={8}
            wrapperCol={14}
            name="picosOperativos"
            label={"¿Existen picos en la operativa?"}
            rules={[
              {
                required: true,
                message: "Campo requerido",
              },
            ]}
          >
            <Radio.Group>
              <Radio.Button value={true}>Si</Radio.Button>
              <Radio.Button value={false}>No</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            labelCol={8}
            wrapperCol={14}
            name="aspectos"
            label={"¿A cuál de estos aspectos afecta el proceso?"}
            rules={[
              {
                required: true,
                message: "Campo requerido",
              },
            ]}
          >
            <Checkbox.Group style={{ width: "100%" }}>
              <Row>
                {afectacion.map(({ key, value }) => (
                  <Col span={8} key={key}>
                    <Checkbox value={value}>{value}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Col>
        <Divider style={{ marginBottom: 60, marginTop: 60 }}>
          Gestión de datos
        </Divider>
        <Col span={12}>
          <Form.Item
            labelCol={8}
            wrapperCol={14}
            name="consistencia"
            label={
              "Los datos de entrada, ¿se obtienen siempre del mismo origen y de la misma manera?"
            }
            rules={[
              {
                required: true,
                message: "Campo requerido",
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              optionFilterProp="value"
            >
              {consistencia.map(({ key, value }) => (
                <Option key={key} value={value} children={value} />
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            labelCol={8}
            wrapperCol={14}
            name="reglas"
            label={"¿Contiene reglas claras, sencillas y concisas?"}
            rules={[
              {
                required: true,
                message: "Campo requerido",
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              optionFilterProp="value"
            >
              {reglas.map(({ key, value }) => (
                <Option key={key} value={value} children={value} />
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            labelCol={8}
            wrapperCol={14}
            name="datosEstandarizados"
            label={
              "Los datos de entrada, ¿tienen siempre el mismo formato? ¿o es necesario transformarlos según el caso?"
            }
            rules={[
              {
                required: true,
                message: "Campo requerido",
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              optionFilterProp="value"
            >
              {estandarizacion.map(({ key, value }) => (
                <Option key={key} value={value} children={value} />
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            labelCol={8}
            wrapperCol={14}
            name="datosLegibles"
            label={"¿Cuál es la dificultad para leer u obtener los datos?"}
            rules={[
              {
                required: true,
                message: "Campo requerido",
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              optionFilterProp="value"
            >
              {legibilidad.map(({ key, value }) => (
                <Option key={key} value={value} children={value} />
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            labelCol={8}
            wrapperCol={14}
            name="procedimientoHabitual"
            label={"¿Cuántas operaciones siguen el procedimiento habitual?"}
            rules={[
              {
                required: true,
                message: "Campo requerido",
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              optionFilterProp="value"
            >
              {porcentajeHabitual.map(({ key, value }) => (
                <Option key={key} value={value} children={value} />
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Divider style={{ marginBottom: 60, marginTop: 60 }}>Entorno</Divider>
        <Col span={12}>
          <Form.Item
            labelCol={8}
            wrapperCol={14}
            name="cambiosProceso"
            label={
              "¿Se prevén cambios en las tareas que se realizan en el proceso?"
            }
            rules={[
              {
                required: true,
                message: "Campo requerido",
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              optionFilterProp="value"
            >
              {procesos.map(({ key, value }) => (
                <Option key={key} value={value} children={value} />
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            labelCol={8}
            wrapperCol={14}
            name="cambioSistema"
            label={
              "¿Se prevén cambios en las aplicaciones o documentos que se utilizan en el proceso?"
            }
            rules={[
              {
                required: true,
                message: "Campo requerido",
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              optionFilterProp="value"
            >
              {sistemas.map(({ key, value }) => (
                <Option key={key} value={value} children={value} />
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            labelCol={8}
            wrapperCol={14}
            name="NoAplicaciones"
            label={
              "¿Nº de aplicaciones / transacciones intervinientes en el proceso?"
            }
            rules={[
              {
                required: true,
                message: "Campo requerido",
              },
            ]}
          >
            <InputNumber placeholder="Número" style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Row justify="end">
            <Col span={4}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  size="large"
                  style={{
                    fontWeight: "bold",
                    fontsize: "15px",
                    backgroundColor: "#f09819",
                    borderColor: "#f09819",
                    boxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  }}
                >
                  Guardar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default JustificacionIdea;
