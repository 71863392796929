import firebase from "../../../../../../server/firebase";
export const onChangeApprovedABANCA = async (idea, value, user) => {
  const apiURL =
    "https://us-central1-rpa-manager.cloudfunctions.net/addComment?";
  let incompletedActivities = [];
  if (idea.activities)
    incompletedActivities = idea.activities.filter(
      (activity) => !activity.completed
    );

  if (!idea.idBluePrism) {
    return "ERROR: Debes completar los datos en la pestaña pase a producción.";
  }

  if (incompletedActivities.length > 0) {
    return "warning";
  } else {
    let retValue = "ERROR:DB";
    const mailId = user.email.split("@")[0];
    const comment = "Se modifico el estado de la petición a producción.";
    const name =
      user &&
      user.firstName.replaceAll(" ", "+") +
        "+" +
        user.lastName1.replaceAll(" ", "+") +
        (user.lastName2 ? "+" + user.lastName2.replaceAll(" ", "+") : null);
    const apiCall = `${apiURL}id=${idea.id}&comentario=${comment}&mailId=${mailId}&nombre=${name}&tipo=produccion`;
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      mode: "no-cors",
    };
    await fetch(apiCall, requestOptions).then(async () => {
      await firebase
        .database()
        .ref()
        .child("ideas")
        .child(idea.id)
        .update({
          approved: value,
          status: value ? 3 : 2,
        })
        .then(() => {
          retValue = "true";
        });
    });
    return retValue;
  }
};
