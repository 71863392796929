import React, { useEffect, useState } from "react";
import firebase from "../../../../../server/firebase";
import moment from "moment";
import {
  Form,
  Typography,
  Button,
  Row,
  Col,
  Table,
  Empty,
  DatePicker,
  message,
  Select,
  Divider,
  Tag,
  Popconfirm,
} from "antd";
import "../../../../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import HEBActivities from "./HEBActivities";
const dateFormat = "DD/MM/YYYY";
const clone = require("rfdc")();

const IdeaActivities = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [selectOptions, setSelectOptions] = useState([]);
  const [bots, setBots] = useState([]);
  const [statusMap, setStatusMap] = useState([]);
  const [tableChange, setTableChange] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [stage, setStage] = useState();
  const [botStages, setBostStages] = useState([]);
  const [activityFlag, setActivityFalg] = useState([true]);
  const [tableActivities, setTableActivities] = useState([]);

  useEffect(() => {
    let options = [];
    let statusMap = [];
    Object.values(props.company.activities).forEach((activity) => {
      options.push({
        label: activity,
        value: activity,
      });
    });
    Object.entries(props.company.stageStatus).forEach(([k, v]) => {
      switch (Number(k)) {
        case 0:
          statusMap.push({ status: Number(k), color: "default", name: v });
          break;
        case 1:
          statusMap.push({ status: Number(k), color: "orange", name: v });
          break;
        case 2:
          statusMap.push({ status: Number(k), color: "green", name: v });
          break;
        default:
          break;
      }
      setStatusMap(statusMap);
    });
    setSelectOptions(options);
    props.idea.bots && setBots(props.idea.bots);
    props.idea.activities && setTableActivities(clone(props.idea.activities));
    if (props.activityKeys && activityFlag) {
      setStage(
        props.idea.activities.find(
          ({ bot, stage }) =>
            bot === props.activityKeys.bot && stage === props.activityKeys.stage
        )
      );
      setShowModal(true);
      setActivityFalg(false);
    }
    // eslint-disable-next-line
  }, [props.idea.activities, props.company.activities, props.idea.bots]);

  const stageFilter = Object.entries(props.company.activities).map((stage) => ({
    text: stage[1],
    value: stage[1],
  }));

  const botFilter = props.idea.bots
    ? props.idea.bots.map((bot) => ({
        text: bot.name,
        value: bot.key,
      }))
    : [];

  const fillActivitiesTemplate = () => {
    let activities = [];
    Object.values(props.company.activities).forEach((activity) => {
      activities.push({
        status: "Pending",
        stage: activity,
        bot: null,
        startDate: null,
        endDate: null,
      });
    });
    form.setFieldsValue({ activities: activities });
  };

  const handleStatusChange = async (record) => {
    let activities = props.idea.activities;
    let index = activities.findIndex(
      ({ bot, stage }) => bot === record.bot && stage === record.stage
    );
    if (activities[index].status < 2) {
      if (!activities[index].realStartDate && activities[index].status === 0) {
        activities[index].realStartDate = {
          seconds: moment().unix(),
          nanoseconds: 0,
        };
      }
      if (!activities[index].realEndDate && activities[index].status === 1) {
        activities[index].realEndDate = {
          seconds: moment().unix(),
          nanoseconds: 0,
        };
      }
      activities[index] = {
        ...props.idea.activities[index],
        completed: activities[index].status + 1 === 2 ? true : false,
        status: activities[index].status + 1,
      };
    }

    await firebase
      .database()
      .ref()
      .child("ideas")
      .child(props.idea.id)
      .update({ activities: activities })
      .catch((error) => console.error(error));
  };

  const onDateChange = (obj, value, type) => {
    let changeType = "";
    switch (type) {
      case 0:
        changeType = "startDate";
        break;
      case 1:
        changeType = "endDate";
        break;
      case 2:
        changeType = "realStartDate";
        break;
      default:
        changeType = "realEndDate";
        break;
    }
    if (value)
      obj[changeType] = {
        seconds: value.unix(),
        nanoseconds: 0,
      };
    else obj[changeType] = null;
    setTableChange(true);
  };

  const activitiesColumns = [
    {
      title: "Stage",
      dataIndex: "stage",
      key: "stage",
      render: (text, record) => {
        return (
          <Col
            style={{ cursor: "pointer" }}
            onClick={() => {
              setStage(record);
              setShowModal(true);
            }}
          >
            <Button
              type="link"
              style={{ borderLeft: "0px", paddingLeft: "0px" }}
            >
              {text}
            </Button>
          </Col>
        );
      },
      onFilter: (value, record) => {
        return record && record.stage
          ? record.stage.toString().indexOf(value) === 0
          : false;
      },
      filters: [...stageFilter],
      sorter: {
        compare: (a, b) =>
          props.company.activities.findIndex(
            (activity) => activity === a.stage
          ) -
          props.company.activities.findIndex(
            (activity) => activity === b.stage
          ),
        multiple: 2,
      },
      defaultSortOrder: "ascend",
      width: 150,
    },
    {
      title: "Bot Asignado",
      dataIndex: "bot",
      key: "bot",
      render: (text) => {
        let bot = bots.find(({ key }) => key === text);
        return bot && bot.name;
      },
      onFilter: (value, record) => record.bot === value,
      filters: [...botFilter],
      sorter: {
        compare: (a, b) => a.bot - b.bot,
        multiple: 1,
      },
      width: 200,
    },
    {
      title: "Expected Start Date",
      dataIndex: "startDate",
      render: (text, record, index) => {
        return props.userInfo.rol /*&& props.userInfo.rol === "User"*/ ? (
          <DatePicker
            bordered={false}
            onChange={(date) => onDateChange(record, date, 0)}
            defaultValue={
              record.startDate
                ? moment(moment.unix(record.startDate.seconds), dateFormat)
                : ""
            }
            allowClear={false}
            format={dateFormat}
          />
        ) : record.startDate ? (
          moment.unix(record.startDate.seconds).format(dateFormat)
        ) : (
          "Not Set"
        );
      },
      width: 160,
    },
    {
      title: "Expected End Date",
      dataIndex: "endDate",
      render: (text, record, index) => {
        return props.userInfo.rol /*&& props.userInfo.rol === "User"*/ ? (
          <DatePicker
            bordered={false}
            onChange={(date) => onDateChange(record, date, 1)}
            defaultValue={
              record.endDate
                ? moment(moment.unix(record.endDate.seconds), dateFormat)
                : ""
            }
            allowClear={false}
            format={dateFormat}
          />
        ) : record.endDate ? (
          moment.unix(record.endDate.seconds).format(dateFormat)
        ) : (
          "Not Set"
        );
      },
      width: 160,
    },
    {
      title: "Real Start Date",
      dataIndex: "realStartDate",
      render: (text, record, index) => {
        return props.userInfo.rol /*&& props.userInfo.rol === "User"*/ ? (
          <DatePicker
            bordered={false}
            onChange={(date) => onDateChange(record, date, 2)}
            defaultValue={
              record.realStartDate
                ? moment(moment.unix(record.realStartDate.seconds), dateFormat)
                : ""
            }
            format={dateFormat}
          />
        ) : record.realStartDate ? (
          moment.unix(record.realStartDate.seconds).format(dateFormat)
        ) : (
          "Not Set"
        );
      },
      sorter: {
        compare: (a, b) =>
          a.realStartDate && a.realStartDate.seconds
            ? b.realStartDate && b.realStartDate.seconds
              ? a.realStartDate.seconds - b.realStartDate.seconds
              : a.realStartDate.seconds - 0
            : b.realStartDate && b.realStartDate.seconds
            ? 0 - b.realStartDate.seconds
            : 0 - 0,
      },
      width: 160,
    },
    {
      title: "Real End Date",
      dataIndex: "realEndDate",
      render: (text, record, index) => {
        return props.userInfo.rol /*&& props.userInfo.rol === "User"*/ ? (
          <DatePicker
            bordered={false}
            onChange={(date) => onDateChange(record, date, 3)}
            defaultValue={
              record.realEndDate
                ? moment(moment.unix(record.realEndDate.seconds), dateFormat)
                : ""
            }
            format={dateFormat}
            allowClear={record.status === 2 ? false : true}
          />
        ) : record.realEndDate ? (
          moment.unix(record.realEndDate.seconds).format(dateFormat)
        ) : (
          "Not Set"
        );
      },
      sorter: {
        compare: (a, b) =>
          a.realEndDate && a.realEndDate.seconds
            ? b.realEndDate && b.realEndDate.seconds
              ? a.realEndDate.seconds - b.realEndDate.seconds
              : a.realEndDate.seconds - 0
            : b.realEndDate && b.realEndDate.seconds
            ? 0 - b.realEndDate.seconds
            : 0 - 0,
      },
      width: 160,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (record) => {
        let tag = statusMap.find(({ status }) => status === record);
        return (
          tag && (
            <Tag color={tag.color} style={{ width: "90%" }}>
              {tag.name}
            </Tag>
          )
        );
      },
      width: 100,
    },
    {
      title: "Change Status",
      dataIndex: "status",
      key: "action",
      render: (text, record, index) => {
        return (
          <Button
            disabled={record.status === 2 ? true : false}
            style={{ width: "100px" }}
            onClick={() => handleStatusChange(record)}
          >
            {record.completed
              ? "Completed"
              : statusMap.length > 0 &&
                statusMap.find(({ status }) => status === text + 1) &&
                statusMap.find(({ status }) => status === text + 1).name}
          </Button>
        );
      },
      width: 120,
    },
    {
      title: "Action",
      key: "delete",
      render: (text, record, index) => {
        return (
          <Popconfirm
            placement="bottom"
            title={t("DeleteActivity")}
            onConfirm={() => deleteActivity(record)}
            okText={t("Yes")}
            cancelText="No"
          >
            <Button
              type="link"
              style={{ width: "50px", borderLeft: "0px", paddingLeft: "0px" }}
            >
              Delete
            </Button>
          </Popconfirm>
        );
      },
      width: 100,
    },
  ];

  const deleteActivity = async (record) => {
    let activities = props.idea.activities;
    let tempActivities = activities.filter(
      ({ bot, stage }) => !(bot === record.bot && stage === record.stage)
    );
    await firebase
      .database()
      .ref()
      .child("ideas")
      .child(props.idea.id)
      .update({ activities: tempActivities })
      .catch((error) => console.error(error));
  };

  const onChangeDate = async () => {
    let activities = tableActivities;
    let hide;
    await firebase
      .database()
      .ref()
      .child("ideas")
      .child(props.idea.id)
      .update({ activities: activities })
      .then((hide = message.loading("Saving...", 0)))
      .catch((error) => console.error(error));
    setTableChange(false);
    setTimeout(hide, 2500);
  };

  const determineStage = (key, fields) => {
    key = fields.findIndex((element) => element.key === key);
    if (
      form.getFieldsValue() &&
      form.getFieldsValue().activities &&
      form.getFieldsValue().activities[key] &&
      form.getFieldsValue().activities[key].bot
    ) {
      let value = form.getFieldsValue().activities[key].bot;
      let options = [];
      let botStages = [];
      let activities = props.idea.activities ? props.idea.activities : [];
      let allStages = props.company.activities;
      let bot = bots.find((e) => e.key === Number.parseInt(value));
      activities.forEach((activity) => {
        if (activity.bot === bot.key) {
          botStages.push(activity.stage);
        }
      });
      allStages.forEach((stage) => {
        if (!botStages.includes(stage)) {
          options.push({ label: stage, value: stage });
        }
      });
      setBostStages(options);
    } else setBostStages(selectOptions);
  };

  const onFinish = async (values) => {
    if (values.activities) {
      let botList = [];
      let activities = [];
      let flag = true;
      let text;
      values.activities.forEach((activity) => {
        if (botList.length > 0) {
          if (botList.includes(activity.bot + " - " + activity.stage)) {
            text =
              "Could not save. The Stage: " +
              activity.stage +
              " repeats for a Bot";
            flag = false;
          }
        } else {
          botList.push(activity.bot + " - " + activity.stage);
        }
        activity.status = 0;
        activity.startDate = {
          seconds: activity.date[0].unix(),
          nanoseconds: 0,
        };
        activity.endDate = {
          seconds: activity.date[1].unix(),
          nanoseconds: 0,
        };
        activity.bot = Number(activity.bot);
        activity.completed = false;
        delete activity.date;
        activities.push(activity);
      });
      if (!flag) {
        message.warning(text);
      } else {
        if (props.idea.activities) {
          activities = [...props.idea.activities, ...activities];
        }
        form.resetFields();

        await firebase
          .database()
          .ref()
          .child("ideas")
          .child(props.idea.id)
          .update({ activities: activities })
          .catch((error) => console.error(error));
      }
    }
  };
  return (
    <>
      <Row gutter={[10, 20]}>
        <Col span={24}>
          {props.idea.activities && props.idea.activities.length > 0 && (
            <>
              <Table
                columns={activitiesColumns}
                dataSource={tableActivities}
                rowKey={(record) =>
                  String(
                    props.idea.activities.findIndex(
                      ({ bot, stage }) =>
                        bot === record.bot && stage === record.stage
                    ) +
                      "_" +
                      props.idea.id
                  )
                }
                pagination={{ pageSize: 7 }}
                scroll={{ x: "max-content" }}
              />
              {stage && (
                <HEBActivities
                  visible={showModal}
                  setVisible={setShowModal}
                  stage={stage}
                  setStage={setStage}
                  idea={props.idea}
                  company={props.company}
                  userInfo={props.userInfo}
                />
              )}
              <Row gutter={[40, 20]} justify={"end"}>
                <Popconfirm
                  placement="bottomLeft"
                  title={"Save Changes?"}
                  onConfirm={onChangeDate}
                  okText={t("Yes")}
                  cancelText={t("Cancel")}
                  disabled={!tableChange}
                >
                  <Button disabled={!tableChange} type="primary">
                    {"Save Changes"}
                  </Button>
                </Popconfirm>
              </Row>
              <Divider />
            </>
          )}
          <Form form={form} name="activitiesList" onFinish={onFinish}>
            <Form.List name="activities">
              {(fields, { add, remove }) => {
                return (
                  <>
                    {fields.length < 1 &&
                    ((props.idea.activities &&
                      props.idea.activities.length < 1) ||
                      !props.idea.activities) ? (
                      <Empty style={{ height: "200px" }}>
                        <Form.Item>
                          <Button
                            type="primary"
                            onClick={() => {
                              fillActivitiesTemplate();
                            }}
                            block
                          >
                            <PlusOutlined /> Cargar template de actividades
                          </Button>
                        </Form.Item>
                      </Empty>
                    ) : (
                      <>
                        {fields.length > 0 && (
                          <Row gutter={[20, 24]}>
                            <Col span={6} style={{ textAlign: "center" }}>
                              <Typography.Text type="secondary">
                                Assigned Bot
                              </Typography.Text>
                            </Col>
                            <Col span={6} style={{ textAlign: "center" }}>
                              <Typography.Text type="secondary">
                                Bot Stage
                              </Typography.Text>
                            </Col>
                            <Col span={5} style={{ textAlign: "center" }}>
                              <Typography.Text type="secondary">
                                Date
                              </Typography.Text>
                            </Col>
                          </Row>
                        )}
                        {fields.map((field, index) => (
                          <Row
                            style={{
                              paddingTop: "24px",
                              paddingBottom: "24px",
                            }}
                            gutter={[20, 24]}
                            key={field.key}
                          >
                            <Col span={6}>
                              <Form.Item
                                {...field}
                                name={[field.name, "bot"]}
                                fieldKey={[field.fieldKey, "bot"]}
                                rules={[
                                  {
                                    required: true,
                                    message: t("Campo requerido"),
                                  },
                                ]}
                              >
                                <Select
                                  placeholder={"Assigned Bot"}
                                  /* dropdownRender={(menu) => (
                                    <>
                                      {menu}
                                      <Divider style={{ margin: "4px 0" }} />
                                      <Row
                                        style={{
                                          display: "flex",
                                          flexWrap: "nowrap",
                                          padding: 8,
                                        }}
                                      >
                                        <Col span={16}>
                                          <Input
                                            value={nameBot}
                                            onChange={onChangeNameBot}
                                          />
                                        </Col>
                                        <Col
                                          span={8}
                                          style={{
                                            padding: 4,
                                            color: "#00a0e9",
                                            cursor: "pointer",
                                          }}
                                          onClick={addBot}
                                        >
                                          <PlusOutlined /> Add Bot
                                        </Col>
                                      </Row>
                                    </>
                                  )} */
                                  onSelect={() => {
                                    if (
                                      form.getFieldsValue() &&
                                      props.idea.activities
                                    ) {
                                      let activities = form.getFieldsValue();
                                      let key = fields.findIndex(
                                        (element) => element.key === field.key
                                      );
                                      let activity =
                                        form.getFieldsValue().activities[key];
                                      if (activity && activity.stage) {
                                        delete activity.stage;
                                        activities.activities[key] = activity;
                                        form.setFieldsValue(activities);
                                      }
                                    }
                                  }}
                                >
                                  {bots.map((bot) => (
                                    <Select.Option key={bot.key}>
                                      {bot.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                {...field}
                                name={[field.name, "stage"]}
                                fieldKey={[field.fieldKey, "stage"]}
                                rules={[
                                  {
                                    required: true,
                                    message: t("Campo requerido"),
                                  },
                                ]}
                              >
                                <Select
                                  options={botStages}
                                  placeholder="Stage"
                                  onFocus={() =>
                                    determineStage(field.key, fields)
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <Form.Item
                                {...field}
                                name={[field.name, "date"]}
                                fieldKey={[field.fieldKey, "date"]}
                                rules={[
                                  {
                                    required: true,
                                    message: t("Campo requerido"),
                                  },
                                ]}
                              >
                                <DatePicker.RangePicker />
                              </Form.Item>
                            </Col>
                            <Col span={1}>
                              <MinusCircleOutlined
                                className="center-element"
                                onClick={() => remove(field.name)}
                              />
                            </Col>
                          </Row>
                        ))}
                      </>
                    )}
                    <Row gutter={[20, 20]}>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{
                            display: !fields.length && "none",
                            marginLeft: "10px",
                          }}
                        >
                          {t("Guardar")}
                        </Button>
                      </Form.Item>
                    </Row>
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => {
                          add();
                        }}
                        block
                        style={{ marginTop: "20px" }}
                      >
                        <PlusOutlined /> {t("Añadir actividad")}
                      </Button>
                    </Form.Item>
                  </>
                );
              }}
            </Form.List>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default IdeaActivities;
