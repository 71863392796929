import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, Col, Row, message } from "antd";
import { updateUser } from "../../../../db/firedataCalls";

const tailLayout = {
  width: "100%",
  backgroundColor: "#f5f5f5",
  borderStyle: "none",
};

const UserModal = (props) => {
  const [userForm] = Form.useForm();
  const [submit, setSubmit] = useState(0); //1 = update, 2 = approve, 3 = delete

  useEffect(() => {
    if (props.user) {
      userForm.setFieldsValue({ ...props.user });
    }
    // eslint-disable-next-line
  }, [props.user]);

  const closeModal = () => {
    props.setVisible(false);
    userForm.resetFields();
    props.setUser(null);
    props.setUserID("");
  };

  const onFinish = (values) => {
    if (values) {
      let tempUser = props.user;
      tempUser.firstName = values.firstName;
      tempUser.lastName1 = values.lastName1;
      tempUser.lastName2 = values.lastName2 ? values.lastName2 : "";
      tempUser.username = values.username;
      if (submit === 2) {
        tempUser.status = 1;
      } else if (submit === 3) {
        tempUser.status = 2;
      }
      updateUser(tempUser, props.userID, props.trigger);
      message.success(
        submit === 1
          ? "Información Actualizada"
          : submit === 2
          ? "Petición Aprobada"
          : "Registro de Usuario Borrado"
      );
    } else {
      message.error("Algo salió mal");
    }
    setSubmit(0);
    closeModal();
  };

  return (
    props.user && (
      <>
        <Modal
          title={"Información del Usuario"}
          visible={props.visible}
          onCancel={() => closeModal()}
          footer={null}
        >
          <Form name="user" form={userForm} onFinish={onFinish}>
            <Row gutter={[40, 15]} justify="center">
              <Col span={10}>
                <Form.Item
                  label="Nombre"
                  labelAlign="left"
                  name="firstName"
                  style={{ float: "left" }}
                  rules={[
                    {
                      required: true,
                      message: "El usuario debe incluir nombre",
                    },
                  ]}
                >
                  <Input
                    style={{ ...tailLayout }}
                    bordered={false}
                    placeholder="Nombre"
                  />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  label="Primer Apellido"
                  labelAlign="left"
                  name="lastName1"
                  style={{ float: "left" }}
                  rules={[
                    {
                      required: true,
                      message: "El usuario debe incluir primer apellido",
                    },
                  ]}
                >
                  <Input
                    style={{ ...tailLayout }}
                    bordered={false}
                    placeholder="Primer Apellido"
                  />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  label="Segundo Apellido"
                  labelAlign="left"
                  name="lastName2"
                  style={{ float: "left" }}
                >
                  <Input
                    style={{ ...tailLayout }}
                    bordered={false}
                    placeholder="Segundo Apellido"
                  />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  label="Usuario"
                  labelAlign="left"
                  name="username"
                  style={{ float: "left" }}
                  rules={[
                    {
                      required: true,
                      message: "El usuario debe incluir nombre de usuario",
                    },
                  ]}
                >
                  <Input
                    style={{ ...tailLayout }}
                    bordered={false}
                    placeholder="Nombre Usuario"
                  />
                </Form.Item>
              </Col>
              <Col span={20}>
                <Form.Item
                  label="Correo"
                  labelAlign="left"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "El usuario debe incluir correo",
                    },
                  ]}
                >
                  <Input
                    style={{ ...tailLayout, cursor: "default" }}
                    bordered={false}
                    placeholder="Email"
                    disabled={true}
                  />
                </Form.Item>
              </Col>

              <Col span={10}>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    type="primary"
                    ghost
                    onClick={() => setSubmit(2)}
                    block={true}
                    disabled={props.user.status === 3 ? false : true}
                  >
                    Aprobar Petición
                  </Button>
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    type="default"
                    danger
                    onClick={() => setSubmit(3)}
                    block={true}
                    disabled={props.user.status === 2 ? true : false}
                  >
                    Eliminar
                  </Button>
                </Form.Item>
              </Col>

              <Col span={10}>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    type="primary"
                    block={true}
                    onClick={() => {
                      setSubmit(1);
                    }}
                  >
                    Guardar
                  </Button>
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item>
                  <Button
                    type="primary"
                    danger
                    onClick={() => closeModal()}
                    block={true}
                  >
                    Cancelar
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </>
    )
  );
};

export default UserModal;
