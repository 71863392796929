import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Drawer, message } from "antd";
import moment from "moment";
import { Button, Table, Tag, Space, Popconfirm } from "antd";
import "../../../../../../App.css";
import CompanyIdeaDetails from "../../../Details/CompanyIdeaDetails";
import { approveButton } from "./InboxValidations";
import InputSearch from "../../InputSearch";
import firebase from "../../../../../../server/firebase";
import { useTranslation } from "react-i18next";
import { Resizable } from "react-resizable";
import { CloseCircleFilled, CheckCircleFilled } from "@ant-design/icons";
import ReactExport from "react-data-export";

const Inbox = (props) => {
  const [inboxData, setInboxData] = useState(null);
  const [arrayExcel, setArrayExcel] = useState([]);

  const { t } = useTranslation();
  const priorities = {
    VERY_HIGH: t("table.priorities.VERY_HIGH"),
    HIGH: t("table.priorities.HIGH"),
    MEDIUM: t("table.priorities.MEDIUM"),
    LOW: t("table.priorities.LOW"),
    VERY_LOW: t("table.priorities.VERY_LOW"),
  };

  const prioritiesColor = {
    VERY_HIGH: "#C41B24",
    HIGH: "#ccb894",
    MEDIUM: "#CECE94",
    LOW: "#97AC85",
    VERY_LOW: "#94B8CC",
  };

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const [showDatailsDrawer, setShowDetailsDrawer] = useState({
    idea: null,
    visible: false,
    idIdea: null,
  });

  const columns = [
    {
      title: t("table.headers.name"),
      dataIndex: "name",
      className: "clickable-col",
      render: (name, record) => (
        <span
          style={{ padding: "13px 13px 6px 6px", cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {name}
        </span>
      ),
    },
    {
      title: t("table.headers.createdDate"),
      dataIndex: "createdDate",
      render: (createdDate) =>
        createdDate
          ? moment.unix(createdDate.seconds).format("DD/MM/YYYY")
          : "",
      sorter: {
        compare: (a, b) => a.createdDate.seconds - b.createdDate.seconds,
      },
    },
    {
      title: t("table.headers.priority"),
      dataIndex: "priority",
      sortDirections: ["descend", "ascend"],
      sorter: {
        compare: (a, b) => a.priority - b.priority,
      },
      render: (priority, record) => {
        if (priority) {
          priority = MapPriority(priority);
          let color = prioritiesColor[priority];
          return (
            <Tag color={color} key={priority}>
              {`${priorities[priority].toUpperCase()}` +
                (record.priority ? ` (${record.priority})` : "")}
            </Tag>
          );
        } else {
          return <Tag key={priority}>{t("SIN ASIGNAR")}</Tag>;
        }
      },
    },
    {
      title: t("table.headers.workCategory"),

      render: (priority, record) => {
        return record.departmentTitle;
      },
    },
    {
      title: t("Petición CER"),
      className: "center-column",
      dataIndex: "peticionCER",
      width: 100,
      render: (text, record) =>
        record.peticionCER ? (
          <CheckCircleFilled
            style={{
              fontWeight: "bolder",
              fontSize: "1.5em",
              color: "#b7eb8f",
            }}
          />
        ) : (
          <CloseCircleFilled
            style={{
              fontWeight: "bolder",
              fontSize: "1.5em",
              color: "#ffa39e",
            }}
          />
        ),
      onFilter: (value, record) => {
        return record.peticionCER === value;
      },
      filters: [
        { text: "Peticion CER", value: true },
        { text: "No peticion CER", value: false },
      ],
    },
    {
      title: "",
      key: "action",
      fixed: "right",
      width: 250,
      render: (text, record) => {
        return (
          <Space size={"middle"} width={"100%"}>
            <Popconfirm
              placement="bottomLeft"
              title={t("ApprovePetitionPopMsg")}
              onConfirm={(e) => handleApproveButton(record, e)}
              okText={t("Yes")}
              cancelText="No"
            >
              <Button
                style={{ width: "100px" }}
                key={record.id}
                className={"ant-btn"}
              >
                {t("Aprobar")}
              </Button>
            </Popconfirm>
            <Popconfirm
              placement="bottomLeft"
              title={t("RejectPetitionPopMsg")}
              onConfirm={(e) => handleRejectButton(record, e)}
              okText={t("Yes")}
              cancelText="No"
            >
              <Button
                style={{ width: "100px" }}
                key={record.id}
                className={"ant-btn-dangerous"}
              >
                {t("RejectPetitionBtn")}
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const MapPriority = (priority) => {
    if (priority < 0.5) return "VERY_LOW";
    if (priority < 1.5) return "LOW";
    if (priority < 2.5) return "MEDIUM";
    if (priority < 3) return "HIGH";
    if (priority >= 3) return "VERY_HIGH";
  };

  const Loader = () => (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        textAlign: "center",
        marginTop: "25%",
      }}
    >
      <Spin size="large" spinning={true}></Spin>
    </div>
  );

  const ResizableTitle = (props) => {
    const { onResize, width, ...restProps } = props;

    if (!width) {
      return <th {...restProps} />;
    }

    return (
      <Resizable
        width={width}
        height={0}
        handle={
          <span
            className="react-resizable-handle"
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        }
        onResize={onResize}
        draggableOpts={{ enableUserSelectHack: false }}
      >
        <th {...restProps}></th>
      </Resizable>
    );
  };

  const onClose = () => {
    setShowDetailsDrawer({ idea: null, visible: false, idIdea: null });
  };

  const handleApproveButton = (idea, event) => {
    event.preventDefault();
    handleMail(idea, "Aprobada");
    let msg = approveButton(idea, props.ideas);
    if (msg === "IdeaApproved") message.success(t("IdeaApproved"));
    else message.warning(t(msg));
  };

  const handleRejectButton = (idea, event) => {
    event.preventDefault();
    handleMail(idea, "Rechazada");
    firebase
      .database()
      .ref()
      .child("ideas")
      .child(idea.id)
      .update({ status: 4 })
      .catch((err) => console.error(err));
  };

  const handleRowClick = (row) => {
    setShowDetailsDrawer({ visible: true, idea: row, idIdea: row.id });
  };

  const handleMail = (idea, ideaResult) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    /* var raw =
      '{\n SenderName: "RPA-MANAGER",\n SenderMail:"noreply@abanca.rerobot.ai",\n To: ["' + 
      mail +
      '"],\n Subject:"' +
      ideaResult +
      '" ,\n isHTML: "true",\n HTMLBody:"<h3>Le informamos que la idea: ' +
      idea.name +
      " ha sido " +
      ideaResult.toLowerCase() +
      '</h3>",\n}'; */

    var raw =
      '{\n SenderName: "RPA-MANAGER",\n SenderMail:"noreply@rerobot.com",\n To: ["' +
      idea.createdBy.email +
      '"],\n Subject:"' +
      ideaResult +
      '" ,\n isHTML: "true",\n HTMLBody:"<h3>Le informamos que la petición ' +
      idea.name +
      " ha sido " +
      ideaResult.toLowerCase() +
      '</h3>",\n}';

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://automationplatform.azurewebsites.net/api/mailnotification",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    if (props.ideas) {
      let ideas = [];
      props.ideas.forEach(
        (doc) =>
          doc.status === -1 &&
          ideas.push({
            ...doc,
            id: doc.id,
          })
      );

      setInboxData(ideas);
      setTableState({ data: ideas });

      if (showDatailsDrawer.visible) {
        let index = props.ideas.findIndex(
          (idea) => idea.id === showDatailsDrawer.idIdea
        );
        setShowDetailsDrawer({
          visible: true,
          idea: props.ideas[index],
          idIdea: props.ideas[index].id,
        });
      }
    }
  }, [props.ideas, t, showDatailsDrawer.idIdea, showDatailsDrawer.visible]);

  const tableComponents = {
    header: {
      cell: ResizableTitle,
    },
  };
  const [tableState, setTableState] = useState({
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
  });

  const handleOnKeyChange = (key) => {
    if (!key) setTableState({ data: inboxData });

    key = key.toLowerCase();
    const filteredData = inboxData.filter(
      (entry) =>
        entry.name.toLowerCase().includes(key) ||
        (entry.idABANCA && entry.idABANCA.toString().includes(key)) ||
        (entry.departmentTitle &&
          entry.departmentTitle.toLowerCase().includes(key))
    );

    setTableState({ data: filteredData });
  };

  const downloadData = async () => {
    var arrayExcel = [];
    console.log("inboxData: ", inboxData);
    inboxData.forEach((idea) => {
      arrayExcel.push({
        name: idea.name,
        createdDate: moment.unix(idea.createdDate.seconds).format("DD/MM/YYYY"),
        priority: idea.priority,
        departmentTitle: idea.departmentTitle,
        peticionCER: idea.peticionCER,
      });
    });
    setArrayExcel(arrayExcel);
    setTimeout(() => {
      var link = document.getElementById("excelBots1");
      link.click();
    }, 1000);
  };

  return inboxData ? (
    <div style={{ padding: 10 }}>
      <Drawer
        style={{ background: "white" }}
        theme="light"
        width="100vw"
        onClose={onClose}
        visible={showDatailsDrawer.visible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          ></div>
        }
      >
        <CompanyIdeaDetails
          company={props.company}
          onCloseDrawer={onClose}
          userInfo={props.userInfo}
          companyName={props.companyName}
          mappings={props.mappings}
          idea={showDatailsDrawer.idea}
        />
      </Drawer>
      <Row gutter={[20, 30]}>
        <Col span={24}>
          <Row>
            <Col span={6}>
              <InputSearch handleOnKeyChange={handleOnKeyChange} />
            </Col>
            <Col span={16}></Col>
            <Col span={2}>
              <Button
                onClick={() => {
                  downloadData();
                }}
                style={{ color: "white", backgroundColor: "#009900" }}
              >
                Excel
              </Button>
              <ExcelFile
                element={
                  <button id={"excelBots1"} style={{ display: "none" }}>
                    Download Data
                  </button>
                }
                filename={"Not processed"}
              >
                <ExcelSheet key={"0"} data={arrayExcel} name={"Bots"}>
                  <ExcelColumn
                    label={t("table.headers.name")}
                    value="name"
                    key={1}
                  />
                  <ExcelColumn
                    label={t("table.headers.createdDate")}
                    value="createdDate"
                    key={2}
                  />
                  <ExcelColumn
                    label={t("table.headers.priority")}
                    value="priority"
                    key={3}
                  />
                  <ExcelColumn
                    label={t("table.headers.workCategory")}
                    value="departmentTitle"
                    key={4}
                  />
                  <ExcelColumn
                    label={t("Petición CER")}
                    value="peticionCER"
                    key={5}
                  />
                </ExcelSheet>
              </ExcelFile>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table
                bordered
                components={tableComponents}
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={tableState.data}
                scroll={{ x: 1300 }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  ) : (
    <Loader />
  );
};

export default Inbox;
