import React from 'react';

const BenefitDeployedStatsCard = (props) => {

  return (
    <>
      <span>{`$${new Intl.NumberFormat('es-MX', { maximumSignificantDigits: 3, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(props.benefitDeployed)}`}</span>
    </>
  );
};

export default BenefitDeployedStatsCard;