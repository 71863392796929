import React, { useEffect, useState } from "react";
import moment from "moment";
import { Modal, Form, Input, Button, Checkbox, Popconfirm } from "antd";
import { Col, DatePicker, Select, Badge, Avatar, message, Row } from "antd";
import { presetPalettes } from "@ant-design/colors";
import { updateActivity } from "../../../../db/firedataCalls";
import { priorityMap } from "./Filters";
import "./../../../../App.css";
import uid from "uid-safe";
const dateFormat = "DD/MM/YYYY";
const tailLayout = {
  width: "100%",
  backgroundColor: "#f5f5f5",
  borderStyle: "none",
};

const ActivityModal = (props) => {
  const [activityForm] = Form.useForm();
  const [personal, setPersonal] = useState(true);
  const [personalLocked, setPersonalLocked] = useState(false);
  const [editing, setEditing] = useState(false);
  const [users, setUsers] = useState([]);
  const [deletedUsers, setDeletedUsers] = useState([]);

  useEffect(() => {
    if (props.activity) {
      setEditing(true);
      if (props.activity.personal)
        activityForm.setFieldsValue({
          ...props.activity,
          startDate:
            props.activity.startDate &&
            moment(moment.unix(props.activity.startDate.seconds), dateFormat),
          endDate:
            props.activity.endDate &&
            moment(moment.unix(props.activity.endDate.seconds), dateFormat),
        });
      else
        activityForm.setFieldsValue({
          ...props.activity,
          ideaId: props.activity.idea.id,
          startDate:
            props.activity.startDate &&
            moment(moment.unix(props.activity.startDate.seconds), dateFormat),
          endDate:
            props.activity.endDate &&
            moment(moment.unix(props.activity.endDate.seconds), dateFormat),
        });
      if (props.modifiers && !props.activity.personal)
        props.modifiers.origin === "ActivitiesPanel" && setPersonalLocked(true);
      props.activity.personal ? setPersonal(true) : setPersonal(false);
    }
    if (props.modifiers) {
      if (props.modifiers.origin === "ABANCAActivities") {
        let activityprops = { ideaId: props.idea.id, personal: false };
        if (!props.activity)
          activityprops = { ...activityprops, priority: 1, status: 1 };
        activityForm.setFieldsValue({ ...activityprops });
        setPersonal(false);
        setPersonalLocked(true);
      }
      if (props.modifiers.panel)
        activityForm.setFieldsValue({
          personal: true,
          priority: 1,
          status: props.modifiers.panel,
        });
    }
  }, [props.activity, props.modifiers, props.idea, activityForm]);

  useEffect(() => {
    if (props.company) {
      let users = [];
      let colors = [];
      let deletedUsers = [];
      if (props.company.users) users = Object.values(props.company.users);
      if (props.company.deletedUsers)
        deletedUsers = Object.values(props.company.deletedUsers);
      Object.values(presetPalettes).forEach((colorSet) => {
        colors.push(colorSet.primary);
      });
      let res = (users.length + deletedUsers.length) / colors.length;
      if (res > 1) {
        for (let a = 0; a < Math.round(res); a++) {
          colors.push(...colors);
        }
      }
      // users = [ { companyId: "QrEN5Mn25tnNhdPET601", email: "juane.soto@softtek.com", firstName: "Juan", lastName1: "Soto", lastName2: "Luévano", rol: "User", status: "Active", uid: "7FVuUhLFfBUVXOfDeEo3UQvax7k1", username: "juane.soto@softtek.com", }, { companyId: "QrEN5Mn25tnNhdPET601", email: "luis.luna@softtek.com", firstName: "Luis", lastName1: "de Luna", lastName2: "Saucedo", rol: "User", status: "Active", uid: "7FVuUFfBUVXOfDeEo3UQvax7k1", username: "luis.luna@softtek.com", }, { companyId: "QrEN5Mn25tnNhdPET601", email: "javier.gutierrez@softtek.com", firstName: "Javier", lastName1: "Gutiérrez", lastName2: "Pérez", rol: "User", status: "Active", uid: "7FVuUhLFfVXOfDeEo3UQvax7k1", username: "javier.gutierrez@softtek.com", }, { companyId: "QrEN5Mn25tnNhdPET601", email: "jorge.bohorquez@softtek.com", firstName: "Jorge", lastName1: "Bohorquez", lastName2: "Ibarra", rol: "User", status: "Active", uid: "7FVuUhLFfBUVXOfDeEovax7k1", username: "jorge.bohorquez@softtek.com", }, ];
      users = users.map((user, index) => ({ ...user, color: colors[index] }));
      deletedUsers = deletedUsers.map((user, index) => ({
        ...user,
        color: colors[index],
      }));
      setUsers(users);
      setDeletedUsers(deletedUsers);
    }
  }, [props.company]);

  const deleteActivity = async (noMessage) => {
    let tempActivities = [];
    if (props.activity.personal) {
      if (props.userInfo.activities) tempActivities = props.userInfo.activities;
      tempActivities = tempActivities.filter(
        ({ id }) => !(id === props.activity.id)
      );
      await updateActivity(null, tempActivities, props.userInfo)
        .then(() => {
          message.success("Actividad eliminada con éxito");
          closeModal();
        })
        .catch((error) => message.error("Error al actualizar: ", error));
      closeModal();
    } else if (props.modifiers.origin === "ABANCAActivities") {
      let tempIdea = props.idea;
      tempActivities = tempIdea.activities.filter(
        ({ id }) => !(id === props.activity.id)
      );
      await updateActivity(tempIdea.id, tempActivities, null)
        .then(() => {
          if (!noMessage) message.success("Actividad eliminada con éxito");
          closeModal();
        })
        .catch((error) => message.error("Error al actualizar: ", error));
    } else if (
      props.ideasOptions.find((idea) => props.activity.idea.id === idea.id)
    ) {
      let tempIdea = props.ideasOptions.find(
        (idea) => props.activity.idea.id === idea.id
      );
      tempActivities = tempIdea.activities.filter(
        ({ id }) => !(id === props.activity.id)
      );
      await updateActivity(tempIdea.id, tempActivities, null)
        .then(() => {
          message.success("Actividad eliminada con éxito");
          closeModal();
        })
        .catch((error) => message.error("Error al actualizar: ", error));
    }
  };

  const newActivity = async (activity) => {
    let tempActivities = [];
    if (activity.status === 4) {
      activity.completed = true;
      activity.completedDate = {
        seconds: moment().utc().unix(),
        nanoseconds: 0,
      };
    }
    activity.id = uid.sync(24);
    activity.creationDate = { seconds: moment().utc().unix(), nanoseconds: 0 };
    if (activity.personal) {
      if (props.userInfo.activities) tempActivities = props.userInfo.activities;
      delete activity.ideaId;
      delete activity.personal;
      tempActivities.push({ ...activity });
      await updateActivity(null, tempActivities, props.userInfo)
        .then(() => {
          message.success("Actividad agregada éxito");
          closeModal();
        })
        .catch((error) => message.error("Error al actualizar: ", error));
      closeModal();
    } else if (props.modifiers.origin === "ABANCAActivities") {
      let tempIdea = props.idea;
      delete activity.ideaId;
      delete activity.personal;
      if (tempIdea.activities) tempActivities = tempIdea.activities;
      tempActivities.push({ ...activity });
      await updateActivity(tempIdea.id, tempActivities, null)
        .then(() => {
          message.success("Actividad agregada éxito");
          closeModal();
        })
        .catch((error) => message.error("Error al actualizar: ", error));
    } else if (props.ideasOptions.find((idea) => activity.ideaId === idea.id)) {
      let tempIdea = props.ideasOptions.find(
        (idea) => activity.ideaId === idea.id
      );
      delete activity.ideaId;
      delete activity.personal;
      if (tempIdea.activities) tempActivities = tempIdea.activities;

      tempActivities.push({ ...activity });
      await updateActivity(tempIdea.id, tempActivities, null)
        .then(() => {
          message.success("Actividad agregada éxito");
          closeModal();
        })
        .catch((error) => message.error("Error al actualizar: ", error));
    } else {
      message.error("Petición no encontrada");
    }
  };

  const modifyActivity = async (activity) => {
    let tempActivities = [];
    if (activity.status === 4) {
      activity.completed = true;
      activity.completedDate = {
        seconds: moment().utc().unix(),
        nanoseconds: 0,
      };
    } else {
      activity.completed = false;
      activity.completedDate = null;
    }
    activity.id = props.activity.id;
    props.activity.creationDate &&
      (activity.creationDate = props.activity.creationDate);
    if (props.activity.personal) {
      if (activity.personal) {
        if (props.userInfo.activities)
          tempActivities = props.userInfo.activities;
        delete activity.ideaId;
        delete activity.personal;
        let index = tempActivities.findIndex(({ id }) => id === activity.id);
        tempActivities[index] = activity;
        await updateActivity(null, tempActivities, props.userInfo)
          .then(() => {
            message.success("Actividad actualizada con éxito");
            closeModal();
          })
          .catch((error) => message.error("Error al actualizar: ", error));
        closeModal();
      } else if (
        props.ideasOptions.find((idea) => activity.ideaId === idea.id)
      ) {
        let tempIdea = props.ideasOptions.find(
          (idea) => activity.ideaId === idea.id
        );
        delete activity.ideaId;
        delete activity.personal;
        if (tempIdea.activities) tempActivities = tempIdea.activities;
        tempActivities.push({ ...activity });
        deleteActivity(true);
        await updateActivity(tempIdea.id, tempActivities, null)
          .then(() => {
            message.success("Actividad actualizada con éxito");
            closeModal();
          })
          .catch((error) => message.error("Error al actualizar: ", error));
      } else {
        message.error("Petición no encontrada");
      }
    } else {
      delete activity.ideaId;
      delete activity.personal;
      if (
        props.modifiers.origin === "ABANCAActivities" &&
        props.idea.activities
      ) {
        tempActivities = props.idea.activities;
        let index = tempActivities.findIndex(({ id }) => id === activity.id);
        tempActivities[index] = activity;
      } else if (props.activity.idea.activities) {
        tempActivities = props.activity.idea.activities;
        let index = tempActivities.findIndex(({ id }) => id === activity.id);
        tempActivities[index] = activity;
      } else tempActivities.push({ ...activity });
      await updateActivity(props.activity.idea.id, tempActivities, null)
        .then(() => {
          message.success("Actividad actualizada con éxito");
          closeModal();
        })
        .catch((error) => message.error("Error al actualizar: ", error));
    }
  };

  const onFinish = (values) => {
    let activity = {
      ...values,
      endDate: values.endDate
        ? { seconds: moment(values.endDate).unix(), nanoseconds: 0 }
        : null,
      startDate: values.startDate
        ? { seconds: moment(values.startDate).unix(), nanoseconds: 0 }
        : null,
      description: values.description ? values.description : null,
    };
    if (editing) modifyActivity(activity);
    else newActivity(activity);
  };

  /* const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  }; */

  const closeModal = () => {
    props.setVisible(false);
    activityForm.resetFields();
    props.setActivity(null);
    props.setModifiers(null);
    setPersonal(true);
    setEditing(false);
    setPersonalLocked(false);
  };

  const getUserAndCode = (user) => {
    return (
      user.firstName +
      " " +
      user.lastName1 +
      (user.lastName2 ? " " + user.lastName2 : null) +
      " (" +
      user.username +
      ")"
    );
  };

  return (
    <Modal
      title={editing ? "Editar actividad" : "Nueva actividad"}
      visible={props.visible}
      onCancel={() => closeModal()}
      footer={null}
    >
      <Form
        name="activity"
        form={activityForm}
        onFinish={onFinish}
        preserve={false}
      >
        <Row gutter={[40, 15]} justify="center">
          <Col span={10}>
            <Form.Item
              label="Estado"
              labelAlign="left"
              name="status"
              style={{ float: "left" }}
            >
              <Select size="small" bordered={false} style={{ ...tailLayout }}>
                {props.company.todoConfig.boards.map((label) => (
                  <Select.Option key={label.value} value={label.value}>
                    {label.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label="Prioridad"
              labelAlign="left"
              name="priority"
              style={{ float: "right" }}
            >
              <Select size="small" bordered={false} style={{ ...tailLayout }}>
                {priorityMap.map((priority) => (
                  <Select.Option key={priority.value} value={priority.value}>
                    <Badge color={priority.color} text={priority.label} />
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={20}>
            <Form.Item
              label="Título"
              labelAlign="left"
              name="title"
              rules={[
                {
                  required: true,
                  message: "La actividad debe tener un título",
                },
              ]}
            >
              <Input
                style={{ ...tailLayout }}
                allowClear
                bordered={false}
                placeholder="Breve título de la actividad"
              />
            </Form.Item>
          </Col>
          <Col span={20}>
            <Form.Item label="Descripción" labelAlign="left" name="description">
              <Input.TextArea
                autoSize={{ minRows: 3, maxRows: 5 }}
                placeholder="Descripción de la actividad"
                style={{ ...tailLayout }}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label="Fecha de inicio"
              labelAlign="left"
              name="startDate"
              style={{ float: "left" }}
            >
              <DatePicker
                size="small"
                bordered={false}
                placeholder="Selecciona fecha"
                style={{ ...tailLayout }}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label="Fecha de término"
              labelAlign="left"
              name="endDate"
              style={{ float: "left" }}
            >
              <DatePicker
                size="small"
                placeholder="Selecciona fecha"
                bordered={false}
                style={{ ...tailLayout }}
              />
            </Form.Item>
          </Col>
          <Col span={20}>
            <Form.Item name="personal" valuePropName="checked">
              <Checkbox
                onChange={(e) => setPersonal(e.target.checked)}
                disabled={personalLocked}
              >
                Actividad personal (no asociada a petición)
              </Checkbox>
            </Form.Item>
          </Col>
          {!personal && (
            <>
              <Col span={20}>
                <Form.Item
                  label="Petición asociada"
                  labelAlign="left"
                  name="ideaId"
                  rules={[
                    {
                      required: true,
                      message: "Necesitas asociar la actividad a una petición",
                    },
                  ]}
                >
                  <Select
                    disabled={personalLocked && (editing || personalLocked)}
                    bordered={false}
                    placeholder="Selecciona una petición"
                    style={{ ...tailLayout }}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.text.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    {props.ideasOptions.map((idea) => {
                      let text = idea.idABANCA
                        ? idea.idABANCA + " " + idea.name
                        : idea.name;
                      return (
                        <Select.Option
                          value={idea.id}
                          key={idea.id}
                          text={text}
                        >
                          {idea.idABANCA && `(${idea.idABANCA}) `}
                          {idea.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={20}>
                <Form.Item
                  label="Persona asignada"
                  labelAlign="left"
                  name="assignee"
                  rules={[
                    {
                      required: true,
                      message: "Se requiere una persona asignada",
                    },
                  ]}
                >
                  <Select
                    bordered={false}
                    placeholder="Asigna la actividad"
                    style={{ ...tailLayout }}
                    optionLabelProp="label"
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    {users.map((user) => (
                      <Select.Option
                        key={user.uid}
                        value={user.uid}
                        label={getUserAndCode(user)}
                      >
                        <Avatar style={{ backgroundColor: user.color }}>
                          {user.firstName[0].toUpperCase() +
                            user.lastName1[0].toUpperCase()}
                        </Avatar>
                        {" " + getUserAndCode(user)}
                      </Select.Option>
                    ))}
                    {deletedUsers.map((user) => (
                      <Select.Option
                        key={user.uid}
                        value={user.uid}
                        label={getUserAndCode(user)}
                        disabled
                      >
                        <Avatar style={{ backgroundColor: user.color }}>
                          {user.firstName[0].toUpperCase() +
                            user.lastName1[0].toUpperCase()}
                        </Avatar>
                        {" " + getUserAndCode(user)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </>
          )}
          <Col span={20}>
            <Row>
              <Col span={5}>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Guardar
                  </Button>
                </Form.Item>
              </Col>
              <Col span={14}></Col>
              <Col span={5}>
                {editing && (
                  <Popconfirm
                    placement="bottom"
                    title={"¿Borrar actividad?"}
                    onConfirm={() => deleteActivity()}
                    okText="Sí"
                    cancelText="No"
                  >
                    <Button type="primary" danger>
                      Eliminar
                    </Button>
                  </Popconfirm>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ActivityModal;
