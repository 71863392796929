import React, { useEffect, useState } from "react";
import "../../App.css";
import { Table, Row, Col, Badge, Button, Typography } from "antd";
import { SolutionOutlined } from "@ant-design/icons";
import InputSearch from "../Search/Idea/Tabs/InputSearch";
import UserModal from "../CustomComponents/ABANCA/UsersComponent/UserModal";

const UsersTab = (props) => {
  const [state, setState] = useState({
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
  });
  const [tableData, setTableData] = useState([]);
  const [pendingCount, setPendingCount] = useState(0);
  const [visible, setVisible] = useState(false);
  const [user, setUser] = useState(null);
  const [userID, setUserID] = useState("");

  useEffect(() => {
    if (props.company) {
      let usersData = [];
      let users = Object.entries(props.company.allUsers);
      let count = Object.entries(props.company.allUsers).filter(
        (user) => user[1].status === 3
      ).length;
      users.forEach((user) => {
        if (user[1].uid !== "RnKgnZkO2PU8ZO6sIGjzzzsSRgc") {
          usersData.push({
            ...user[1],
            id: user[0],
            name:
              user[1].firstName +
              " " +
              user[1].lastName1 +
              (user[1].lastName2 ? " " + user[1].lastName2 : ""),
          });
        }
      });
      if (users) {
        setPendingCount(count);
        setTableData(usersData);
        setState({ data: usersData });
      }
    }
  }, [props.company, props.company.allUsers]);

  const columns = [];

  if (props.userInfo.uid === "RnKgnZkO2PU8ZO6sIGjzzzsSRgc2") {
    columns.push({ title: "DB ID", dataIndex: "id" });
  }

  columns.push(
    {
      title: "Usuario",
      dataIndex: "username",
    },
    {
      title: "Nombre",
      dataIndex: "name",
      render: (text, record) => (
        <Typography.Paragraph
          style={{ padding: "13px 13px 6px 6px", cursor: "pointer" }}
          onClick={() => showUser(record)}
        >
          <Typography.Link>{text}</Typography.Link>
        </Typography.Paragraph>
      ),
    },
    {
      title: "Correo",
      dataIndex: "email",
    },
    {
      title: "Estatus",
      dataIndex: "status",
      render: (text, record) => (
        <span>
          {text === 1 ? "Activo" : text === 2 ? "Inactivo" : "Pendiente"}
        </span>
      ),
    }
  );

  const handleOnKeyChange = (key) => {
    if (!key) setState({ data: tableData });
    else {
      key = key.toLowerCase();
      const filteredData = tableData.filter(
        (entry) =>
          entry.name.toLowerCase().includes(key) ||
          entry.username.toLowerCase().includes(key) ||
          entry.email.toLowerCase().includes(key) ||
          entry.status.toLowerCase().includes(key)
      );

      setState({ data: filteredData });
    }
  };

  const showPendings = () => {
    const pendingFilter = tableData.filter(
      (entry) => entry.status === 3
    );

    setState({ data: pendingFilter });
  };

  const showUser = (record) => {
    if (props.company.allUsers) {
      let user = props.company.allUsers[record.id];
      setVisible(true);
      setUser(user);
      setUserID(record.id);
    }
  };

  return (
    <>
      <div style={{ marginRight: 20, marginLeft: 20, paddingTop: 10 }}>
        <UserModal
          user={user}
          setUser={setUser}
          visible={visible}
          setVisible={setVisible}
          userID={userID}
          setUserID={setUserID}
          trigger={props.company.changesTrigger}
        />
        <Row gutter={[20, 30]}>
          <Col span={6}>
            <InputSearch handleOnKeyChange={handleOnKeyChange} />
          </Col>
          <Col span={6}>
            <Button
              type="primary"
              style={{ marginTop: "5px" }}
              onClick={() => {
                setState({ data: tableData });
              }}
            >
              Mostar Todos
            </Button>
          </Col>
          <Col span={6}></Col>
          <Col span={6}>
            <Badge count={pendingCount} style={{ marginTop: "7px" }}>
              <SolutionOutlined
                style={{ fontSize: "32px", marginTop: "5px", color: "#ff7a45" }}
                onClick={showPendings}
              />
            </Badge>
            <span
              style={{
                paddingLeft: "8px",
                fontSize: "16px",
                color: "#ff7a45",
              }}
            >
              Peticiones de Acceso
            </span>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              pagination={{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
              }}
              bordered
              dataSource={state.data}
              rowKey={(record) => record.username}
              columns={columns}
              scroll={{ x: "max-content" }}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default UsersTab;
