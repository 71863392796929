import React, { useEffect, useState } from "react";
import firebase from "../../../../../server/firebase";
import moment from "moment";
import {
  Form,
  Button,
  Row,
  Col,
  Table,
  DatePicker,
  Select,
  Tag,
  Popconfirm,
  Input,
  InputNumber,
} from "antd";
import "../../../../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { getNewFBKey } from "../../../../../db/firedataCalls";
import WalmartActivities from "./WalmartActivities";
import Modal from "antd/lib/modal/Modal";
const dateFormat = "DD/MM/YYYY";
const clone = require("rfdc")();

const IdeaActivities = (props) => {
  const [form] = Form.useForm();
  const [bots, setBots] = useState([]);
  const [statusMap, setStatusMap] = useState([]);
  const [tableChange, setTableChange] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [stage, setStage] = useState();
  const [activityFlag, setActivityFalg] = useState([true]);
  const [tableActivities, setTableActivities] = useState([]);
  const [sprintModal, setSprintModal] = useState(false);

  useEffect(() => {
    let statusMap = [];
    Object.entries(props.company.stageStatus).forEach(([k, v]) => {
      switch (Number(k)) {
        case 0:
          statusMap.push({ status: Number(k), color: "default", name: v });
          break;
        case 1:
          statusMap.push({ status: Number(k), color: "orange", name: v });
          break;
        case 2:
          statusMap.push({ status: Number(k), color: "green", name: v });
          break;
        default:
          break;
      }
      setStatusMap(statusMap);
    });
    props.idea.bots && setBots(props.idea.bots);
    props.idea.activities && setTableActivities(clone(props.idea.activities));
    if (props.activityKeys && activityFlag) {
      setStage(
        props.idea.activities.find(({ key }) => key === props.activityKeys.key)
      );
      setShowModal(true);
      setActivityFalg(false);
    }
    //eslint-disable-next-line
  }, [props.idea.activities, props.company.activities, props.idea.bots]);

  const ModalSprint = () => {
    return (
      <Modal
        visible={sprintModal}
        footer={null}
        destroyOnClose
        onCancel={() => setSprintModal(false)}
      >
        <Form
          form={form}
          labelAlign="left"
          name="activitiesList"
          onFinish={onFinish}
        >
          <Col span={24}>
            <Form.Item
              name={"bot"}
              label="Bot"
              rules={[
                {
                  required: true,
                  message: "Required Field",
                },
              ]}
            >
              <Select placeholder={"Assigned Bot"} onSelect={determineSprint}>
                {bots.map((bot) => (
                  <Select.Option key={bot.key}>{bot.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={"stage"}
              label="Sprint"
              rules={[
                {
                  required: true,
                  message: "Required field",
                  type: "number",
                  min: 1,
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={"description"}
              label="Description"
              rules={[
                {
                  required: true,
                  message: "Required field",
                },
              ]}
            >
              <Input.TextArea placeholder="Breve sprint description" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={"date"}
              label="Date"
              rules={[
                {
                  required: true,
                  message: "Required field",
                },
              ]}
            >
              <DatePicker.RangePicker />
            </Form.Item>
          </Col>
          <Col span={24} style={{ marginTop: 15 }}>
            <Button type="primary" htmlType="submit">
              Add Sprint
            </Button>
          </Col>
        </Form>
      </Modal>
    );
  };

  const botFilter = props.idea.bots
    ? props.idea.bots.map((bot) => ({
        text: bot.name,
        value: bot.key,
      }))
    : [];

  const handleStatusChange = async (record) => {
    let activities = props.idea.activities;
    let index = activities.findIndex(({ key }) => key === record.key);
    if (activities[index].status < 2) {
      if (!activities[index].realStartDate && activities[index].status === 0) {
        activities[index].realStartDate = {
          seconds: moment().unix(),
          nanoseconds: 0,
        };
      }
      if (!activities[index].realEndDate && activities[index].status === 1) {
        activities[index].realEndDate = {
          seconds: moment().unix(),
          nanoseconds: 0,
        };
      }
      activities[index] = {
        ...props.idea.activities[index],
        completed: activities[index].status + 1 === 2 ? true : false,
        status: activities[index].status + 1,
      };
    }

    await firebase
      .database()
      .ref()
      .child("ideas")
      .child(props.idea.id)
      .update({ activities: activities })
      .catch((error) => console.error(error));
  };

  const onDateChange = (obj, value, type) => {
    let changeType = "";
    if (type === 0) changeType = "realStartDate";
    else changeType = "realEndDate";
    if (value)
      obj[changeType] = {
        seconds: value.unix(),
        nanoseconds: 0,
      };
    else obj[changeType] = null;
    setTableChange(true);
  };

  const activitiesColumns = [
    {
      title: "Sprint",
      dataIndex: "stage",
      key: "stage",
      width: 100,
      render: (text, record) => {
        return (
          <Col
            style={{ cursor: "pointer" }}
            onClick={() => {
              setStage(record);
              setShowModal(true);
            }}
          >
            <Button
              type="link"
              style={{ borderLeft: "0px", paddingLeft: "0px" }}
            >
              {text}
            </Button>
          </Col>
        );
      },
      sorter: {
        compare: (a, b) =>
          props.company.activities.findIndex(
            (activity) => activity === a.stage
          ) -
          props.company.activities.findIndex(
            (activity) => activity === b.stage
          ),
        multiple: 2,
      },
      defaultSortOrder: "ascend",
    },
    {
      title: "Assigned Bot",
      dataIndex: "bot",
      key: "bot",
      width: 175,
      render: (text) => {
        let bot = bots.find(({ key }) => key === text);
        return bot && bot.name;
      },
      onFilter: (value, record) => record.bot === value,
      filters: [...botFilter],
      sorter: {
        compare: (a, b) => a.bot - b.bot,
        multiple: 1,
      },
    },
    {
      title: "Expected Date",
      width: 200,
      render: (text, record, index) =>
        record
          ? `${moment
              .unix(record.startDate.seconds)
              .format(dateFormat)} - ${moment
              .unix(record.endDate.seconds)
              .format(dateFormat)} `
          : "",
    },
    {
      title: "Real Start Date",
      dataIndex: "realStartDate",
      width: 155,
      render: (text, record, index) => {
        return props.userInfo.rol /*&& props.userInfo.rol === "User"*/ ? (
          <DatePicker
            bordered={false}
            onChange={(date) => onDateChange(record, date, 0)}
            defaultValue={
              record.realStartDate
                ? moment(moment.unix(record.realStartDate.seconds), dateFormat)
                : ""
            }
            format={dateFormat}
          />
        ) : record.realStartDate ? (
          moment.unix(record.realStartDate.seconds).format(dateFormat)
        ) : (
          "Not Set"
        );
      },
      sorter: {
        compare: (a, b) => a.realStartDate.seconds - b.realStartDate.seconds,
      },
    },
    {
      title: "Real End Date",
      dataIndex: "realEndDate",
      width: 155,
      render: (text, record, index) => {
        return props.userInfo.rol /*&& props.userInfo.rol === "User"*/ ? (
          <DatePicker
            bordered={false}
            onChange={(date) => onDateChange(record, date, 1)}
            defaultValue={
              record.realEndDate
                ? moment(moment.unix(record.realEndDate.seconds), dateFormat)
                : ""
            }
            format={dateFormat}
            allowClear={record.status === 2 ? false : true}
          />
        ) : record.realEndDate ? (
          moment.unix(record.realEndDate.seconds).format(dateFormat)
        ) : (
          "Not Set"
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 130,
      render: (record) => {
        let tag = statusMap.find(({ status }) => status === record);
        return tag && <Tag color={tag.color}>{tag.name}</Tag>;
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 300,
    },
    {
      title: "Change Status",
      dataIndex: "status",
      key: "action",
      width: 100,
      render: (text, record, index) => {
        return (
          <Button
            disabled={record.status === 2 ? true : false}
            style={{ width: "100px" }}
            onClick={() => handleStatusChange(record)}
          >
            {record.completed
              ? "Completed"
              : statusMap.length > 0 &&
                statusMap.find(({ status }) => status === text + 1) &&
                statusMap.find(({ status }) => status === text + 1).name}
          </Button>
        );
      },
      fixed: "right",
    },
    {
      title: "Action",
      key: "delete",
      render: (text, record, index) => {
        return (
          <Popconfirm
            placement="bottom"
            title="Are you sure you want to dalete?"
            onConfirm={() => deleteActivity(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="link"
              style={{ width: "50px", borderLeft: "0px", paddingLeft: "0px" }}
            >
              {"Delete"}
            </Button>
          </Popconfirm>
        );
      },
      fixed: "right",
    },
  ];

  const deleteActivity = async (record) => {
    let activities = props.idea.activities;
    let tempActivities = activities.filter(({ key }) => !(key === record.key));
    await firebase
      .database()
      .ref()
      .child("ideas")
      .child(props.idea.id)
      .update({ activities: tempActivities })
      .catch((error) => console.error(error));
  };

  const onChangeDate = async () => {
    let activities = tableActivities;
    await firebase
      .database()
      .ref()
      .child("ideas")
      .child(props.idea.id)
      .update({ activities: activities })
      .catch((error) => console.error(error));
    setTableChange(false);
  };

  const determineSprint = () => {
    let sprint = 1;
    if (form.getFieldsValue() && props.idea.activities) {
      let activities = props.idea.activities.filter(
        ({ bot }) => bot === Number(form.getFieldsValue().bot)
      );
      if (activities.length > 0)
        sprint = Math.max(...activities.map(({ stage }) => stage)) + 1;
    }
    form.setFieldsValue({ stage: sprint });
  };

  const onFinish = async (activity) => {
    let tempActivities = props.idea.activities || [];
    activity.status = 0;
    activity.startDate = {
      seconds: activity.date[0].unix(),
      nanoseconds: 0,
    };
    activity.endDate = {
      seconds: activity.date[1].unix(),
      nanoseconds: 0,
    };
    activity.bot = Number(activity.bot);
    activity.completed = false;
    activity.key = await getNewFBKey();
    delete activity.date;

    tempActivities.push({ ...activity });
    form.resetFields();
    await firebase
      .database()
      .ref()
      .child("ideas")
      .child(props.idea.id)
      .update({ activities: tempActivities })
      .catch((error) => console.error(error));
    setSprintModal(false);
  };
  return (
    <>
      <Row gutter={[10, 20]}>
        <Col span={20}></Col>
        <Col span={4}>
          <Button type="primary" onClick={() => setSprintModal(true)}>
            Add Sprint
          </Button>
          {ModalSprint()}
        </Col>
        <Col span={24}>
          {props.idea.activities && props.idea.activities.length > 0 && (
            <>
              <Table
                columns={activitiesColumns}
                dataSource={tableActivities}
                rowKey={(record) => record.key}
                pagination={{ pageSize: 7 }}
                scroll={{ x: "max-content" }}
              />
              {stage && (
                <WalmartActivities
                  visible={showModal}
                  setVisible={setShowModal}
                  stage={stage}
                  setStage={setStage}
                  idea={props.idea}
                  company={props.company}
                  userInfo={props.userInfo}
                />
              )}
              <Row gutter={[40, 20]} justify={"end"}>
                <Popconfirm
                  placement="bottomLeft"
                  title={"Save Changes?"}
                  onConfirm={onChangeDate}
                  okText="Yes"
                  cancelText="Cancel"
                  disabled={!tableChange}
                >
                  <Button disabled={!tableChange} type="primary">
                    {"Save Changes"}
                  </Button>
                </Popconfirm>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default IdeaActivities;
