import React, { useState, useEffect } from "react";
import firebase from "../../../../../server/firebase";
import moment from "moment";
import {
  Row,
  Col,
  Spin,
  Button,
  Form,
  Input,
  Select,
  InputNumber,
  Alert,
  message,
} from "antd";
import "../../../../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const WalmartDetails = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [displayError, setDisplayError] = useState("none");

  const Loader = () => (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        textAlign: "center",
        marginTop: "25%",
      }}
    >
      <Spin size="large" spinning={true}></Spin>
    </div>
  );
  const onFinish = async (values) => {
    Object.entries(values).forEach(([k, v]) => {
      if (v === undefined) values[k] = null;
    });
    var localValues = values;

    let user = {
      uid: props.userInfo.uid,
      firstName: props.userInfo.firstName,
      lastName1: props.userInfo.lastName1,
    };
    let _date = moment.utc();
    localValues.workCategory = localValues.workCategory.trim().toUpperCase();
    localValues.updatedDate = { seconds: _date.unix(), nanoseconds: 0 };
    localValues.history = props.idea.history ? props.idea.history : [];
    localValues.history.push({
      eventType: "MODIFIED",
      date: localValues.updatedDate,
      performer: user,
    });
    try {
      await firebase
        .database()
        .ref()
        .child("ideas")
        .child(props.idea.id)
        .update(localValues);
      setLoading(false);
      message.success();
    } catch (error) {
      setDisplayError("block");
    }
  };
  useEffect(() => {
    if (props.idea) {
      form.setFieldsValue({ ...props.idea });
    }
  }, [props.idea, form]);
  return props.loading ? (
    <Loader />
  ) : (
    <>
      <Row>
        <Form
          form={form}
          name="create-idea"
          onFinish={onFinish}
          labelCol={{
            span: 7,
          }}
          wrapperCol={{
            span: 17,
          }}
          layout="vertical"
          size="middle"
          style={{ width: "100%" }}
          labelAlign="right"
        >
          <Row gutter={6}>
            <Col span={24}>
              <Form.Item
                labelCol={2}
                wrapperCol={18}
                name="name"
                label={t("form_fields.create_idea_HEB.name.label")}
                rules={[
                  {
                    required: true,
                    message: t(
                      "form_fields.create_idea_HEB.name.error_message"
                    ),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                labelCol={2}
                wrapperCol={18}
                name="description"
                label={t("form_fields.create_idea_HEB.description.label")}
                rules={[
                  {
                    required: true,
                    message: t(
                      "form_fields.create_idea_HEB.description.error_message"
                    ),
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={12}
                wrapperCol={18}
                name="equipo"
                label={t("form_fields.create_idea_HEB.responsableTeam.label")}
                rules={[
                  {
                    required: true,
                    message: t(
                      "form_fields.create_idea_HEB.responsableTeam.error_message"
                    ),
                  },
                ]}
              >
                <Select
                  placeholder={t(
                    "form_fields.create_idea_HEB.responsableTeam.placeholder"
                  )}
                >
                  <Option value={"Walmart"} key={"Walmart"}>
                    Walmart
                  </Option>
                  <Option value={"Softtek"} key={"Softtek"}>
                    Softtek
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={12}
                wrapperCol={18}
                name="asigneeName"
                label={t("form_fields.create_idea_HEB.user.label")}
                rules={[
                  {
                    required: true,
                    message: t(
                      "form_fields.create_idea_HEB.user.error_message"
                    ),
                  },
                ]}
              >
                <Input
                  placeholder={t(
                    "form_fields.create_idea_HEB.user.placeholder"
                  )}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                labelCol={12}
                wrapperCol={18}
                label={t("form_fields.create_idea_HEB.workCategory.label")}
                name="workCategory"
                rules={[
                  {
                    required: true,
                    message: t(
                      "form_fields.create_idea_HEB.workCategory.error_message"
                    ),
                  },
                ]}
              >
                <Input placeholder={"Area"} />
              </Form.Item>
            </Col>
            <Col span={12}></Col>
            <Col span={12}>
              <Form.Item
                labelCol={12}
                wrapperCol={8}
                label={t("form_fields.create_idea_HEB.complexity.label")}
                name="complexity"
                rules={[
                  {
                    required: true,
                    message: t(
                      "form_fields.create_idea_HEB.complexity.error_message"
                    ),
                  },
                ]}
              >
                <Select style={{ width: "100%" }}>
                  <Option key="0" value={0}>
                    {"Muy Bajo"}
                  </Option>
                  <Option key="1" value={1}>
                    {"Bajo "}
                  </Option>
                  <Option key="2" value={2}>
                    {"Medio"}
                  </Option>
                  <Option key="3" value={3}>
                    {"Alto"}
                  </Option>
                  <Option key="4" value={4}>
                    {"Muy Alto"}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={12}
                wrapperCol={8}
                label={t("form_fields.create_idea_HEB.priority.label")}
                name="priority"
                rules={[
                  {
                    required: true,
                    message: t(
                      "form_fields.create_idea_HEB.priority.error_message"
                    ),
                  },
                ]}
              >
                <InputNumber></InputNumber>
                {/* <Select style={{ width: "100%" }}>
                    <Option key="0" value={0}>
                      {"Muy Bajo"}
                    </Option>
                    <Option key="1" value={1}>
                      {"Bajo "}
                    </Option>
                    <Option key="2" value={2}>
                      {"Medio"}
                    </Option>
                    <Option key="3" value={3}>
                      {"Alto"}
                    </Option>
                    <Option key="4" value={4}>
                      {"Muy Alto"}
                    </Option>
                  </Select> */}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                labelCol={2}
                wrapperCol={18}
                name="descriptionBenefit"
                label={t(
                  "form_fields.create_idea_HEB.descriptionBenefit.label"
                )}
                rules={[
                  {
                    required: true,
                    message: t(
                      "form_fields.create_idea_HEB.descriptionBenefit.error_message"
                    ),
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={6}>
            <Col span={12}>
              <br />
              <Form.Item>
                <Button
                  style={{ width: "100%", height: "32" }}
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  {t("buttons.save")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col>
              <Alert
                message="Error"
                description={t("form_fields.create_idea_HEB.errorMessage")}
                type="error"
                closable
                style={{ display: displayError }}
              />
            </Col>
          </Row>
        </Form>
      </Row>
    </>
  );
};

export default WalmartDetails;
