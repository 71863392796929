import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Tabs } from "antd";
import IdeaActivities from "./IdeaActivities";
import WalmartDetails from "./WalmartDetails";
import WalmartBots from "./WalmartBots";
import WalmartMaintenance from "./WalmartMaintenance";
import "../../../../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";

const WalmartIdeaDetails = (props) => {
  const [idea, setIdea] = useState({});
  const [loading, setLoading] = useState(true);
  const { TabPane } = Tabs;

  useEffect(() => {
    if (props.idea) {
      setLoading(true);
      setIdea({ ...props.idea });
      setLoading(false);
    }
  }, [props.idea]);

  const Loader = () => (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        textAlign: "center",
        marginTop: "25%",
      }}
    >
      <Spin size="large" spinning={true}></Spin>
    </div>
  );

  return loading ? (
    <Loader />
  ) : (
    <>
      <Tabs
        theme="light"
        defaultActiveKey={props.activeKey ? props.activeKey : "1"}
        type="card"
        size="medium"
        style={{ marginRight: 20, marginLeft: 20, paddingTop: 10 }}
      >
        <TabPane tab="Details" key="1">
          <WalmartDetails
            loading={loading}
            idea={idea}
            company={props.company}
            userInfo={props.userInfo}
          />
        </TabPane>
        <TabPane tab="Sprints" key="2">
          <IdeaActivities
            idea={idea}
            company={props.company}
            mappings={props.mappings}
            userInfo={props.userInfo}
            activityKeys={props.activityKeys}
          />
        </TabPane>
        <TabPane tab="Bots" key="3">
          <Row gutter={[10, 20]}>
            <Col span={24}>
              <WalmartBots
                idea={idea}
                company={props.company}
                userInfo={props.userInfo}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Maintenance" key="4">
          <Row gutter={[10, 20]}>
            <Col span={24}>
              <WalmartMaintenance
                idea={idea}
                company={props.company}
                userInfo={props.userInfo}
              />
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </>
  );
};

export default WalmartIdeaDetails;
