import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Chart,
  Axis,
  Interval,
  Legend,
  Annotation,
  Coordinate,
} from "bizcharts";
import DataSet from "@antv/data-set";

const FunelChart = (props) => {
  const { t } = useTranslation();
  const [dv, setDv] = useState(null);
  useEffect(() => {
    let responseData = props.chartData;
    const { DataView } = DataSet;
    let dataArray = [
      {
        action: "Total",
        pv:
          responseData.not_processed +
          responseData.in_pipeline +
          responseData.in_progress +
          responseData.deployed,
      },
    ];
    Object.keys(responseData).forEach((key) => {
      if (responseData[key] > 0)
        dataArray.push({
          action: t("dashboard.charts.labels." + key),
          pv: responseData[key],
        });
    });
    let DV = new DataView().source(dataArray);
    setDv(
      DV.transform({
        type: "map",
        callback(row) {
          row.percent =
            row.pv /
            (responseData.not_processed +
              responseData.in_pipeline +
              responseData.in_progress +
              responseData.deployed);
          return row;
        },
      })
    );
    // eslint-disable-next-line
  }, [props.chartData]);

  return (
    <>
      {dv && (
        <Chart height={600} data={dv.rows} padding={[20, 120, 95]} forceFit>
          <Axis
            name="action"
            label={null}
            line={null}
            grid={null}
            tickLine={null}
          />
          <Axis name="percent" grid={null} label={null} />
          <Coordinate scale={[1, -1]} transpose type="rect" />
          <Legend />
          {dv.rows.map((obj) => {
            return (
              <Annotation.Text
                top={true}
                position={{
                  action: obj.action,
                  percent: "median",
                }}
                content={parseInt(obj.percent * 100) + "%"}
                style={{
                  fill: "#fff",
                  fontSize: "12",
                  textAlign: "center",
                  shadowBlur: 2,
                  shadowColor: "rgba(0, 0, 0, .45)",
                }}
              />
            );
          })}
          <Interval
            position="action*percent"
            adjust="symmetric"
            shape="funnel"
            color={[
              "action",
              ["#0050B3", "#1890FF", "#40A9FF", "#69C0FF", "#BAE7FF"],
            ]}
            tooltip={[
              "action*pv",
              (action, pv) => {
                return { name: action, value: pv };
              },
            ]}
            label={[
              "action*pv",
              (action, pv) => {
                return { content: action + " " + pv };
              },
              {
                offset: 35,
                labelLine: {
                  style: {
                    lineWidth: 1,
                    stroke: "rgba(0, 0, 0, 0.15)",
                  },
                },
              },
            ]}
          ></Interval>
        </Chart>
      )}
    </>
  );
};

export default FunelChart;
