import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Radio,
  Select,
  Input,
  DatePicker,
  InputNumber,
  message,
  Modal,
  Divider,
  Button,
  Tooltip,
  Avatar,
} from "antd";
import { useTranslation } from "react-i18next";
import { createNewIdea, getUsers } from "../../../../db/firedataCalls";
import * as fb from "firebase/app";
const areas = require("../../../../centros.json");

const { Option } = Select;

const CreatePRUEBA = (props) => {
  const { t } = useTranslation();
  const [showAlternatives, setShowAlternatives] = useState();
  const [showFechaMaxima, setShowFechaMaxima] = useState();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [format] = useState("DD/MM/YYYY");
  const [workCategories, setWorkCategories] = useState([]);
  const [state, setState] = useState({
    ModalText: props.modalTitle ? props.modalTitle : "",
    visible: false,
    confirmLoading: false,
  });

  const colors = [
    "#ffccc7",
    "#ffa39e",
    "#ff7875",
    "#ff4d4f",
    "#f5222d",
    "#ffe7ba",
    "#ffd591",
    "#ffc069",
    "#ffa940",
    "#fa8c16",
    "#ffffb8",
    "#fffb8f",
    "#fff566",
    "#ffec3d",
    "#fadb14",
    "#d9f7be",
    "#b7eb8f",
    "#95de64",
    "#73d13d",
    "#52c41a",
    "#bae7ff",
    "#91d5ff",
    "#69c0ff",
    "#40a9ff",
    "#1890ff",
  ];
  const getUserName = (user) => {
    return user.firstName + " " + user.lastName1 + " " + user.lastName2;
  };
  useEffect(() => {
    getUsers(props.userInfo.companyId).once("value", (result) => {
      setUsers([]);
      result.forEach((doc) => {
        let user = doc.val();
        setUsers((users) => [...users, user]);
      });
    });
    if (props.company) {
      areas.departments.forEach((area) => {
        setWorkCategories((workCategories) => [
          ...workCategories,
          {
            value: area.value,
            name: area.title,
            key: area.value,
          },
        ]);
      });
    }
  }, [props.userInfo.companyId, props.company]);

  const showModal = () => {
    setState({
      visible: true,
    });
  };

  const handleCancel = () => {
    setState({
      visible: false,
    });
  };

  const { visible, confirmLoading } = state;

  const successUpdate = () => {
    message.success(t("Información guardada correctamente"));
  };

  const errorUpdate = () => {
    message.error(t("Hubo un error al guardar la información"));
  };

  const onFinish = async (values) => {
    setLoading(true);
    let prioridad = [];
    let volumetria, tiempo, periodicidad;
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    if (showAlternatives) {
      values.tiempoTarea = null;
    } else {
      values.tiempoTareaVolumen = null;
      values.volumenEstimado = null;
    }

    if (!showFechaMaxima) {
      values.fechaMax = null;
    } else {
      values.fechaMax = {
        _nanoseconds: fb.firestore.Timestamp.fromDate(values.fechaMax.toDate())
          .nanoseconds,
        _seconds: fb.firestore.Timestamp.fromDate(values.fechaMax.toDate())
          .seconds,
      };
    }

    values.departmentTitle =
      areas.departments[
        areas.departments.findIndex(
          (departmen) => departmen.value === values.departmentValue
        )
      ].title;
    values.departmentLevels =
      areas.departments[
        areas.departments.findIndex(
          (departmen) => departmen.value === values.departmentValue
        )
      ].levels;
    console.log("Success:", values);
    if (values.ahorroCostes) prioridad[0] = 0;
    else prioridad[0] = 5;
    if (values.riesgoOperacion) prioridad[1] = 5;
    else prioridad[1] = 0;
    if (values.fechaMax) prioridad[3] = 5;
    else prioridad[3] = 0;
    prioridad[4] = values.parametrizacion;
    volumetria =
      !values.volumenEstimado || values.volumenEstimado < 1
        ? 1
        : values.volumenEstimado;
    tiempo = values.tiempoTarea
      ? values.tiempoTarea
      : values.tiempoTareaVolumen;
    switch (values.periodicidad) {
      case "diaria":
        periodicidad = 1;
        break;
      case "cada hora":
        periodicidad = 24;
        break;
      case "cada 12 horas":
        periodicidad = 2;
        break;
      case "cada 18 horas":
        periodicidad = 24 / 18;
        break;
      case "semanal":
        periodicidad = 1 / 4;
        break;
      case "cada 2 semanas":
        periodicidad = 1 / 8;
        break;
      case "mensual":
        periodicidad = 1 / 22;
        break;
      case "trimestral":
        periodicidad = 1 / 66;
        break;
      default:
        periodicidad = 0;
        break;
    }
    let total = Number(
      ((volumetria * (tiempo / 60) * periodicidad * 22 * 5) / 150).toFixed(2)
    );
    prioridad[2] = total > 5 ? 5 : total;
    let priority = (prioridad.reduce(reducer) / 5).toFixed(2);
    values["priority"] = Number(priority);
    let max = 0;
    if (props.ideas && props.ideas.length > 0)
      max = props.ideas.reduce(
        (max, idea) => (idea.idABANCA > max ? idea.idABANCA : max),
        0
      );
    max++;
    console.log(
      `${volumetria} * (${tiempo} / 60) * ${periodicidad} * 22) * 5 / 150 = ${prioridad[2]}\n Prioridad: ${priority}`,
      prioridad
    );
    values["idABANCA"] = max;
    delete values["hasFechaMax"];
    Object.entries(values).forEach(([k, v]) => {
      if (v === undefined) values[k] = null;
    });
    createNewIdea(values, props.userInfo, users)
      .then(() => {
        setLoading(false);
        setState({
          visible: false,
        });
        successUpdate();
      })
      .catch((error) => {
        errorUpdate();
        console.error(error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log(props.userInfo);
    errorUpdate();
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Button
        style={{ width: "160px", height: "32" }}
        type="primary"
        onClick={showModal}
      >
        {t("buttons.new_idea")}
      </Button>
      <Modal
        title={t("buttons.new_idea")}
        visible={visible}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        width={"50%"}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <Row>
          <Form
            name="createIdea"
            onFinish={onFinish}
            size="middle"
            onFinishFailed={onFinishFailed}
          >
            <Divider type="horizontal">Detalles de la idea</Divider>
            <Row gutter={6}>
              <Col span={24}>
                <Form.Item
                  colon={false}
                  name="name"
                  labelAlign="left"
                  label={t("form_fields.create_idea.name.label")}
                  rules={[
                    {
                      required: true,
                      message: t("Campo requerido"),
                    },
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={6}>
              <Col span={24}>
                <Form.Item
                  colon={false}
                  labelAlign="left"
                  name="description"
                  label={t("form_fields.create_idea.description.label")}
                  rules={[
                    {
                      required: true,
                      message: t("Campo requerido"),
                    },
                  ]}
                >
                  <Input.TextArea style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={6}>
              <Col span={24}>
                <Form.Item
                  colon={false}
                  labelAlign="left"
                  name="comments"
                  label={"Comentarios"}
                >
                  <Input.TextArea style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={6}>
              <Col span={24}>
                <Form.Item
                  colon={false}
                  labelAlign="left"
                  name="asignee"
                  label={t("form_fields.create_idea.user.label")}
                  rules={[
                    {
                      required: true,
                      message: t("Campo requerido"),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={t("form_fields.create_idea.user.placeholder")}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.name.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    {users.map((user) => {
                      const randomColor = Math.floor(
                        Math.random() * colors.length
                      ).toString(16);
                      return (
                        <Option
                          value={user.uid}
                          key={user.uid}
                          name={user.firstName}
                        >
                          <Tooltip placement="right" title={user.email}>
                            <Avatar
                              style={{ backgroundColor: colors[randomColor] }}
                              size="small"
                            >
                              {user.firstName[0] + user.lastName1[0]}
                            </Avatar>
                            {"\t"}
                            {user.uid === props.userInfo.uid
                              ? getUserName(user) +
                                " " +
                                t("form_fields.create_idea.user.yourself")
                              : getUserName(user)}
                          </Tooltip>
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={6}>
              <Col span={24}>
                <Form.Item
                  colon={false}
                  labelAlign="left"
                  label={t("form_fields.create_idea.workCategory.label")}
                  name="departmentValue"
                  rules={[
                    {
                      required: true,
                      message: t("Campo requerido"),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {workCategories.map((area) => {
                      return (
                        <Option key={area.value} value={area.value}>
                          {area.name + " (" + area.value.toString() + ")"}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={6}>
              <Col span={24}>
                <Form.Item
                  name="peticionCER"
                  colon={false}
                  labelAlign="left"
                  label={t("Petición CER")}
                  rules={[
                    {
                      required: true,
                      message: t("Campo requerido"),
                    },
                  ]}
                >
                  <Radio.Group style={{ width: "100%" }}>
                    <Radio.Button value={true}>{t("Yes")}</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Divider type="horizontal">Justificación de la idea</Divider>
            <Row gutter={6}>
              <Col span={24}>
                <Form.Item
                  colon={false}
                  name="periodicidad"
                  labelAlign="left"
                  label="La tarea la realizas"
                  rules={[
                    {
                      required: true,
                      message: t("Campo requerido"),
                    },
                  ]}
                >
                  <Select style={{ width: "100%" }}>
                    <Option key="0" value="diaria">
                      {"Diariamente"}
                    </Option>
                    <Option key="1" value="cada hora">
                      {"Cada hora"}
                    </Option>
                    <Option key="2" value="cada 12 horas">
                      {"Cada 12 horas"}
                    </Option>
                    <Option key="3" value="cada 18 horas">
                      {"Cada 18 horas"}
                    </Option>
                    <Option key="4" value="semanal">
                      {"Semanal"}
                    </Option>
                    <Option key="5" value="cada 2 semanas">
                      {"Cada 2 semanas"}
                    </Option>
                    <Option key="6" value="mensual">
                      {"Mensual"}
                    </Option>
                    <Option key="7" value="trimestral">
                      {"Trimestral"}
                    </Option>
                    <Option key="8" value="cada 6 meses">
                      {"Cada 6 meses"}
                    </Option>
                    <Option key="9" value="anual">
                      {"Anual"}
                    </Option>
                    <Option key="10" value="puntual">
                      {"Puntual"}
                    </Option>
                    <Option key="11" value="one-off">
                      {"One-Off"}
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={6}>
              <Col span={24}>
                <Form.Item
                  colon={false}
                  name="tiempoDedicado"
                  labelAlign="left"
                  label="¿El tiempo que dedicas a la tarea es variable dependiendo del volumen archivos/correos/expedientes/documentos, etc que procesas en cada ejecución?"
                  rules={[
                    {
                      required: true,
                      message: t("Campo requerido"),
                    },
                  ]}
                >
                  <Radio.Group
                    style={{ width: "100%" }}
                    onChange={(e) => setShowAlternatives(e.target.value)}
                  >
                    <Radio.Button key="si" value={true}>
                      Sí
                    </Radio.Button>
                    <Radio.Button key="no" value={false}>
                      No
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={6} hidden={!showAlternatives}>
              <Col span={24}>
                <Form.Item
                  colon={false}
                  name="volumenEstimado"
                  labelAlign="left"
                  label="¿Cuál es el volumen estimado de archivos/correos/expedientes/documentos/etc cada vez que realizas la tarea?"
                  rules={[
                    {
                      required: !showAlternatives ? false : true,
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Unidades"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={6} hidden={showAlternatives}>
              <Col span={24}>
                <Form.Item
                  colon={false}
                  name="tiempoTarea"
                  labelAlign="left"
                  label="¿Cuánto tiempo te lleva realizar la tarea?"
                  rules={[
                    {
                      required: showAlternatives ? false : true,
                      message: t("Campo requerido"),
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Minutos"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={6} hidden={!showAlternatives}>
              <Col span={24}>
                <Form.Item
                  colon={false}
                  name="tiempoTareaVolumen"
                  labelAlign="left"
                  label="¿Cuánto tiempo te lleva realizar la tarea por cada archivo/correo/expediente/documento/etc?"
                  rules={[
                    {
                      required: !showAlternatives ? false : true,
                      message: t("Campo requerido"),
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Minutos"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={6}>
              <Col span={24}>
                <Form.Item
                  name="riesgoOperacion"
                  colon={false}
                  labelAlign="left"
                  label="¿Un error en la ejecución de la tarea o en la no ejecución de la misma puede llevar a un posible Riesgo Operacional?"
                  rules={[
                    {
                      required: true,
                      message: t("Campo requerido"),
                    },
                  ]}
                >
                  <Radio.Group style={{ width: "100%" }}>
                    <Radio.Button value={true}>Sí</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={6}>
              <Col span={24}>
                <Form.Item
                  name="ahorroCostes"
                  labelAlign="left"
                  colon={false}
                  label="¿La tarea robotizada la realizas tú o esta soporta en una empresa externa?"
                  rules={[
                    {
                      required: true,
                      message: t("Campo requerido"),
                    },
                  ]}
                >
                  <Radio.Group style={{ width: "100%" }}>
                    <Radio.Button value={true}>
                      Dentro de la empresa
                    </Radio.Button>
                    <Radio.Button value={false}>
                      En una empresa externa
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={6}>
              <Col span={24}>
                <Form.Item
                  name="hasFechaMax"
                  labelAlign="left"
                  colon={false}
                  label="¿La tarea tiene fecha límite?"
                  rules={[
                    {
                      required: true,
                      message: t("Campo requerido"),
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={(e) => {
                      setShowFechaMaxima(e.target.value);
                    }}
                    style={{ width: "100%" }}
                  >
                    <Radio.Button value={true}>Sí</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={6} hidden={!showFechaMaxima}>
              <Col span={24}>
                <Form.Item
                  name="fechaMax"
                  labelAlign="left"
                  colon={false}
                  label="Fecha Límite:"
                  rules={[
                    {
                      required: showFechaMaxima,
                      message: t("Campo requerido"),
                    },
                  ]}
                >
                  <DatePicker format={format} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={6}>
              <Col span={24}>
                <Form.Item
                  name="parametrizacion"
                  labelAlign="left"
                  colon={false}
                  label="Parametrización, la petición se considera:"
                  rules={[
                    {
                      required: true,
                      message: t("Campo requerido"),
                    },
                  ]}
                >
                  <Select style={{ width: "100%" }}>
                    <Option key="0" value={5}>
                      Muy Fácil
                    </Option>
                    <Option key="1" value={4}>
                      Fácil
                    </Option>
                    <Option key="2" value={3}>
                      Regular
                    </Option>
                    <Option key="3" value={2}>
                      Por encima de lo normal
                    </Option>
                    <Option key="4" value={1}>
                      Difícil
                    </Option>
                    <Option key="5" value={0}>
                      Muy Difícil
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
              <Row gutter={6}>
                <Col span={24}>
                  <Form.Item>
                    <Button
                      style={{ width: "160px", height: "32", marginTop: 10 }}
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                    >
                      {t("buttons.save")}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Row>
          </Form>
        </Row>
      </Modal>
    </>
  );
};

export default CreatePRUEBA;
