import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Layout,
  Table,
  Input,
  Button,
  message,
  Steps,
  Divider,
  Popconfirm,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import firebase from "../../server/firebase";
import { useTranslation } from "react-i18next";

const OnBoarding = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [hiddenStatus, sethiddenStatus] = useState({ hiddenStatus: [] });
  const [arregloDeObjetos, setArregloDeObjetos] = useState({
    arregloDeObjetos: [],
  });
  const [visibleSave, setvisibleSave] = useState(false);

  const { Step } = Steps;

  const [current, setCurrent] = useState(0);

  useEffect(() => {
    firebase
      .database()
      .ref()
      .child("companies")
      .child(props.userInfo.companyId)
      .once("value")
      .then((docSnapshot) => {
        let hiddenStatus = [];
        let indexx = 0;
        let arregloDeObjetos = [];

        Object.entries(docSnapshot.val()).map((element) => {
          //se hace un map a los componentes de la compañia de la BDD
          if (
            typeof element[1] === "object" &&
            element[0] !== "activeTabs" &&
            !element[1].hidden
          ) {
            //Se excluye el objeto de activeTabs y los nombres
            // Se excluyen los que tengan el elmento hidden
            if (indexx === 0) {
              //El primer elemento es el visible por default
              hiddenStatus.push(false);
            } else {
              //Los demás permaneses como hidden = true
              hiddenStatus.push(true);
            }
            indexx = indexx + 1;

            arregloDeObjetos.push({
              //se pobla el arreglo necesario para poblar las tablas
              name: element[0],
              info: element[1],
            });
          }
          return null;
        });
        arregloDeObjetos = arregloDeObjetos.sort(compare); //Se ordena por órden alfabético
        setArregloDeObjetos({ arregloDeObjetos: arregloDeObjetos });
        sethiddenStatus({ hiddenStatus: hiddenStatus });
      });
  }, [props.userInfo.companyId]);

  // método en donde se envía el index del step que se quiere ver, se modifica el arreglo que se utiliza
  // para saber que step es el que tendrá como valor de hidden falso.
  const onChangeCurrent = (value) => {
    let hiddens = hiddenStatus.hiddenStatus;
    let indexOfFalse = hiddens.findIndex((status) => status === false);
    hiddens[indexOfFalse] = true;
    hiddens[value] = false;
    sethiddenStatus({ hiddenStatus: hiddens });

    setCurrent(value);
  };

  const columns = [
    {
      title: t("Nombre"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Delete"),
      render: (text, record) => (
        <Row gutter={[10, 10]}>
          <Col span={18}>
            <Popconfirm
              placement="bottomLeft"
              title={t("ApproveDeletionPopMsg")}
              onConfirm={(e) => erase(text, record)}
              okText={t("Yes")}
              cancelText="No"
              disabled={record.disableDelete}
            >
              <Button icon={<DeleteOutlined />} disabled={record.disableDelete} />
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];

  const onChange = (e) => {
    if (e.target.value !== "") {
      setvisibleSave(true);
    } else {
      setvisibleSave(false);
    }
  };

  const erase = (text, record) => {
    let indexOfFalse = hiddenStatus.hiddenStatus.findIndex(
      (status) => status === false
    );
    let objetoAModificar = arregloDeObjetos.arregloDeObjetos[indexOfFalse];
    let arr = arregloDeObjetos.arregloDeObjetos;
    let category = arr[indexOfFalse].name;
    let viejoArr = objetoAModificar.info;
    viejoArr.splice(text.identifier, 1);
    arr[indexOfFalse].info = viejoArr;
    setArregloDeObjetos({ arregloDeObjetos: arr });

    let docRef = firebase
      .database()
      .ref()
      .child("companies")
      .child(props.userInfo.companyId);
    docRef
      .once("value")
      .then((doc) => {
        docRef.update({ [category]: viejoArr }); //se hace el update
        successMessage();
      })
      .catch((err) => {
        console.error(err);
        errorMessage();
      });
  };

  //método que recibe el nuevo valor a agregar a la BDD. También recibe la categoría y la subcategoría
  //necesaria para hacer el update en la bdd
  const create = (values, category) => {
    let indexOfFalse = hiddenStatus.hiddenStatus.findIndex(
      (status) => status === false
    );
    let objetoAModificar = arregloDeObjetos.arregloDeObjetos[indexOfFalse];
    let arr = arregloDeObjetos.arregloDeObjetos;
    let viejoArr = objetoAModificar.info;
    viejoArr.push(values.name);
    arr[indexOfFalse].info = viejoArr;
    setArregloDeObjetos({ arregloDeObjetos: arr });

    let docRef = firebase
      .database()
      .ref()
      .child("companies")
      .child(props.userInfo.companyId);
    docRef
      .once("value")
      .then((doc) => {
        docRef.update({ [category]: viejoArr }); //se hace el update
        successMessage();
      })
      .catch((err) => {
        console.error(err);
        errorMessage();
      });
    form.resetFields();
  };

  const successMessage = () => {
    message.success(t("Información guardada correctamente"));
  };

  const errorMessage = () => {
    message.error(t("Hubo un error al guardar la información"));
  };

  const compare = (a, b) => {
    //método para ordenas por órden alfabético
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  };

  return (
    <>
      <Layout style={{ background: "white", padding: "20px" }}>
        <Row>
          <Col span={18} offset={3}>
            <Steps current={current} onChange={onChangeCurrent}>
              {arregloDeObjetos.arregloDeObjetos.length > 0 &&
                arregloDeObjetos.arregloDeObjetos.map((option, index) => {
                  //Se crean los steps con el arreglo de objetos
                  return <Step key={index} title={option.name} />;
                })}
            </Steps>
          </Col>
        </Row>
        {arregloDeObjetos?.arregloDeObjetos?.[current] && (
          <Row gutter={[10, 10]}>
            <Col span={24}>
              {typeof Object.values(
                arregloDeObjetos.arregloDeObjetos[current].info
              )[0] !== "object" ? ( //En caso de que no haya objetos dentro del objeto
                <>
                  <Table
                    size="small"
                    title={() => (
                      <Divider orientation="left">
                        {arregloDeObjetos.arregloDeObjetos[current].name}
                      </Divider> //Es el header de la tabla
                    )}
                    columns={columns}
                    dataSource={Object.entries(
                      arregloDeObjetos.arregloDeObjetos[current].info //este es el objeto que contiene todos los elementos de la BDD
                    ).map((element) => ({
                      disableDelete:
                        arregloDeObjetos.arregloDeObjetos[current].info.length === 1,
                      identifier: element[0],
                      name: element[1],
                    }))}
                    rowKey={({ identifier }) => identifier}
                  />
                  <Form
                    form={form}
                    name={arregloDeObjetos.arregloDeObjetos[current].name}
                    onFinish={
                      (values) =>
                        create(
                          values,
                          arregloDeObjetos.arregloDeObjetos[current].name,
                          ""
                        ) //Estos son los casos que no hay subcategoría
                    }
                    // onSubmitted={handleSubmitted}
                    autoComplete="off"
                  >
                    <Form.Item name="name" fieldKey="name">
                      <Input
                        placeholder={t("Nombre")}
                        style={{ width: "50%" }}
                        onChange={onChange}
                      />
                    </Form.Item>
                    <br />
                    <Form.Item>
                      <Button
                        disabled={!visibleSave}
                        type="primary"
                        htmlType="submit"
                      >
                        {t("Guardar")}
                      </Button>
                    </Form.Item>
                  </Form>
                </>
              ) : (
                //En caso de que dentro del objeto de la BDD haya más objetos
                <>
                  {Object.entries(
                    arregloDeObjetos.arregloDeObjetos[current].info //Se hace un map para cada objeto dentro del objeto
                  ).map((element2) => {
                    return (
                      <>
                        <Table
                          size="small"
                          title={() => (
                            <Divider orientation="left">{element2[0]}</Divider> //Encabezado de la tabla
                          )}
                          columns={columns}
                          dataSource={Object.entries(
                            //elementos dentro del objeto que está dentro del objeto principal
                            element2[1]
                          ).map((element3) => ({
                            identifier: element3[0],
                            name: element3[1],
                          }))}
                        />
                        <Form
                          name={element2[0]}
                          onFinish={
                            (values) =>
                              create(
                                values,
                                arregloDeObjetos.arregloDeObjetos[current].name,
                                element2[0]
                              ) //Aquí es cuando si hay subcategoría
                          }
                          autoComplete="off"
                        >
                          <Form.Item name="name" fieldKey="name">
                            <Input
                              placeholder={t("Nombre")}
                              style={{ width: "50%" }}
                              onChange={onChange}
                            />
                          </Form.Item>
                          <br />
                          <Form.Item>
                            <Button
                              disabled={!visibleSave}
                              type="primary"
                              htmlType="submit"
                            >
                              {t("Guardar")}
                            </Button>
                          </Form.Item>
                        </Form>
                      </>
                    );
                  })}
                </>
              )}
            </Col>
          </Row>
        )}
      </Layout>
    </>
  );
};

export default OnBoarding;
