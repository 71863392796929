import React, { useEffect, useState } from "react";
import firebase from "../../../../../server/firebase";
import {
  getUsers,
  getDownloadURL,
  removeFile,
} from "../../../../../db/firedataCalls";
import moment from "moment";
import {
  Select,
  Form,
  Input,
  Button,
  Space,
  Row,
  Col,
  Spin,
  Card,
  List,
  Tabs,
  Table,
  DatePicker,
  message,
  Divider,
  Tag,
  Popconfirm,
} from "antd";
import DetailsForm from "./DetailsForm";
import JustificacionIdea from "./JustificacionIdea";
import CostBenefit from "./CostBenefit";
import "../../../../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import {
  MinusCircleOutlined,
  PlusOutlined,
  CheckCircleOutlined,
  RightCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
const dateFormat = "DD/MM/YYYY";
const { Option } = Select;

const PRUEBAIdeaDetails = (props) => {
  const { t } = useTranslation();

  const [idea, setIdea] = useState({});
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activityForm] = Form.useForm();
  const [tableChange, setTableChange] = useState(false);
  const { TabPane } = Tabs;

  const activitiesColumns = [
    {
      title: t("idea_details.activities.stage"),
      dataIndex: "stage",
    },
    {
      title: t("idea_details.activities.completed"),
      dataIndex: "completed",
      render: (text, record) =>
        record.completed ? (
          <CheckCircleOutlined
            style={{ fontWeight: "bolder", fontSize: "1em", color: "#b7eb8f" }}
          />
        ) : (
          <RightCircleOutlined
            style={{ fontWeight: "bolder", fontSize: "1em", color: "#ffa39e" }}
          />
        ),
    },
    {
      title: t("idea_details.activities.name"),
      dataIndex: "name",
    },
    {
      title: t("idea_details.activities.startDate"),
      dataIndex: "startDate",
      render: (text, record, index) => (
        <DatePicker
          bordered={false}
          onChange={(date) => onDateChange(record, date, 0)}
          defaultValue={
            record
              ? moment(moment.unix(record.startDate.seconds), dateFormat)
              : ""
          }
          format={dateFormat}
          allowClear={false}
        />
      ),
    },
    {
      title: t("idea_details.activities.endDate"),
      dataIndex: "endDate",
      render: (text, record, index) => (
        <DatePicker
          bordered={false}
          key={index}
          onChange={(date) => onDateChange(record, date, 1)}
          defaultValue={
            record
              ? moment(moment.unix(record.endDate.seconds), dateFormat)
              : ""
          }
          format={dateFormat}
          allowClear={false}
        />
      ),
    },
    {
      title: "",
      render: (text, record, index) => (
        <Space size="middle">
          <Button
            style={{ width: "120px" }}
            key={record.id}
            onClick={(e) => handleCompleteActivity(record, index)}
            className={record.completed ? "ant-btn-dangerous" : "ant-btn"}
          >
            {record.completed ? t("Uncomplete") : t("Completar")}
          </Button>
        </Space>
      ),
    },
    {
      title: "Action",
      key: "delete",
      render: (record, index) => {
        return (
          <Popconfirm
            placement="bottom"
            title={t("DeleteActivity")}
            onConfirm={() => deleteActivity(index)}
            okText={t("Yes")}
            cancelText="No"
          >
            <Button
              type="link"
              style={{ width: "50px", borderLeft: "0px", paddingLeft: "0px" }}
            >
              {t("Delete")}
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  const onDateChange = (obj, value, type) => {
    let changeType = "";
    if (type === 0) changeType = "startDate";
    else changeType = "endDate";
    if (value)
      obj[changeType] = {
        seconds: value.unix(),
        nanoseconds: 0,
      };
    else obj[changeType] = null;
    setTableChange(true);
  };

  const handleCompleteActivity = async (record, index) => {
    let ideaData = idea;
    let activities = ideaData.activities;
    if (record.completed) {
      activities[index] = { ...ideaData.activities[index], completed: false };
    } else {
      activities[index] = { ...ideaData.activities[index], completed: true };
    }

    firebase
      .database()
      .ref()
      .child("ideas")
      .child(ideaData.id)
      .update({ activities: activities })
      .catch((error) => console.error(error));
  };
  const onChangeAsigneeUser = (value) => {
    let index = users.findIndex((user) => user.uid === value);
    let asigneeName =
      users[index].firstName +
      " " +
      users[index].lastName1 +
      (users[index].lastName2 ? " " + users[index].lastName2 : "");
    firebase
      .database()
      .ref()
      .child("ideas")
      .child(idea.id)
      .update({ asignee: value, asigneeName: asigneeName })
      .then(() => {
        message.success(t("Información actualizada correctamente"));
      })
      .catch((err) => {
        message.error(t("Error al actualizar la información"));
        console.error(err);
      });
  };

  const deleteActivity = async (index) => {
    let activities = props.idea.activities;
    let tempActivities = activities.filter((activity) => activity !== index);
    await firebase
      .database()
      .ref()
      .child("ideas")
      .child(props.idea.id)
      .update({ activities: tempActivities })
      .catch((error) => console.error(error));
  };

  const onChangeDate = async () => {
    let activities = props.idea.activities;
    await firebase
      .database()
      .ref()
      .child("ideas")
      .child(props.idea.id)
      .update({ activities: activities })
      .catch((error) => console.error(error));
    setTableChange(false);
  };

  const onFinish = (values) => {
    let originalidea = idea;
    let activities = originalidea.activities ? originalidea.activities : [];
    console.log(activities, idea.id, props);
    values.activities
      .forEach((activity) => {
        let isCompleted = values.endDate
          ? moment(values.endDate).isBefore(moment())
          : false;

        activities.push({
          completed: isCompleted,
          name: activity.name || "",
          stage: activity.stage,
          startDate: {
            seconds: moment(activity.startDate).unix(),
            nanoseconds: 0,
          },
          endDate: {
            seconds: moment(activity.endDate).unix(),
            nanoseconds: 0,
          },
        });

        firebase
          .database()
          .ref()
          .child("ideas")
          .child(idea.id)
          .update({ activities })
          .then(activityForm.resetFields())
          .catch((error) => console.error(error));
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (props.idea) {
      setLoading(true);
      setIdea({ ...props.idea });
      setLoading(false);
    }
    getUsers(props.userInfo.companyId).once("value", (result) => {
      setUsers([]);
      result.forEach((doc) => {
        let user = doc.val();
        setUsers((users) => [...users, user]);
      });
    });
    return () => setTableChange(false);
  }, [props.idea, props.userInfo.companyId]);

  const Loader = () => (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        textAlign: "center",
        marginTop: "25%",
      }}
    >
      <Spin size="large" spinning={true}></Spin>
    </div>
  );

  const MapPriority = (priority) => {
    if (priority < 0.5) return "#94B8CC";
    if (priority < 1.5) return "#97AC85";
    if (priority < 2.5) return "#CECE94";
    if (priority < 3) return "#CCB894";
    if (priority >= 3) return "#C41B24";
  };

  const MapStatus = (idea) => {
    if (idea.status === -1) return t("table.statuses.1");
    if (idea.status === 2) return t("table.statuses.2");
    if (idea.status === 3) {
      if (idea.productionStatus) {
        if (idea.productionStatus === -1) return t("table.statuses.3_N1");
        if (idea.productionStatus === 1) return t("table.statuses.3_S1");
      }
      return t("table.statuses.3");
    } else return t("table.statuses.4");
  };

  const MapStatusColor = (idea) => {
    if (idea.status === -1) return "volcano";
    if (idea.status === 2) return "cyan";
    if (idea.status === 3) {
      if (idea.productionStatus) {
        if (idea.productionStatus === -1) return "red";
        if (idea.productionStatus === 1) return "lime";
      }
      return "orange";
    } else return "purple";
  };
  const downloadFile = async (fileName) => {
    let url = await getDownloadURL(props.idea.id, fileName);
    window.open(url, "_blank");
  };
  const deleteFile = async (fileName) => {
    let response = await removeFile(props.idea.id, fileName);
    if (response) {
      let index = props.idea.uploads.findIndex(
        (upload) => upload.name === fileName
      );
      let uploads = props.idea.uploads;
      uploads.splice(index, 1);
      firebase
        .database()
        .ref()
        .child("ideas")
        .child(props.idea.id)
        .update({ uploads });
    } else message.error("No se eliminó el archivo");
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <Divider orientation="left">
        <Space size="middle">
          {(idea.idABANCA ? idea.idABANCA + " - " : "") + idea.name}
          {idea.priority && (
            <Tag color={MapPriority(idea.priority)}>{idea.priority}</Tag>
          )}
          {idea.status && (
            <Tag color={MapStatusColor(idea)}>
              {MapStatus(idea).toUpperCase()}
            </Tag>
          )}
        </Space>
      </Divider>
      <Tabs
        theme="light"
        defaultActiveKey="1"
        type="card"
        size="medium"
        style={{ marginRight: 20, marginLeft: 20, paddingTop: 10 }}
      >
        <TabPane tab={t("Detalles")} key="1">
          <Row gutter={[10, 20]}>
            <Col span={12}>
              <Card
                bodyStyle={{ background: "white" }}
                type="inner"
                title={t("Datos del titular")}
              >
                <div style={{ textAlign: "left", verticalAlign: "middle" }}>
                  <div
                    style={{
                      width: "33%",
                      display: "inline-block",
                      verticalAlign: "middle",
                    }}
                  >
                    <UserOutlined style={{ verticalAlign: "middle" }} />
                    <span style={{ verticalAlign: "middle" }}>
                      {` ${idea.createdBy.firstName || t("No capturado")} ${
                        //Cambio posiblemente temporal para la asignación
                        idea.createdBy.lastName1 || ""
                      } ${idea.createdBy.lastName2 || ""} 
                        ${idea.createdBy.username || ""}`}
                    </span>
                  </div>
                </div>
              </Card>
            </Col>
            <Col span={12}>
              <Card
                bodyStyle={{ background: "white" }}
                type="inner"
                title={t("Asignación")}
              >
                <Row>
                  <Col span={1}>
                    <UserOutlined style={{ verticalAlign: "middle" }} />
                  </Col>
                  <Col span={23}>
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder={t("Usuario")}
                      optionFilterProp="children"
                      value={idea.asigneeName && idea.asigneeName}
                      onSelect={(value, event) =>
                        onChangeAsigneeUser(value, event)
                      }
                    >
                      {users.map((user) => (
                        <Option key={user.uid} value={user.uid}>{`${
                          user.firstName
                        } ${user.lastName1} ${user.lastName2 || ""} ${
                          user.username || ""
                        }`}</Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row gutter={[10, 20]}>
            <Col span={24}>
              <Card
                bodyStyle={{ background: "white" }}
                type="inner"
                title={t("Datos de la idea")}
                style={{ marginTop: 20 }}
              >
                <DetailsForm userInfo={props.userInfo} ideaData={props.idea} />
              </Card>
              {idea.uploads && (
                <Card
                  bodyStyle={{ background: "white" }}
                  type="inner"
                  title={t("Archivos")}
                  style={{ marginTop: 20 }}
                >
                  <List
                    itemLayout="horizontal"
                    dataSource={idea.uploads}
                    renderItem={(item) => (
                      <List.Item>
                        <List.Item.Meta
                          title={
                            <Button
                              type="link"
                              onClick={() => downloadFile(item.name)}
                            >
                              {item.name}
                            </Button>
                          }
                        />
                        <List.Item.Meta
                          title={
                            <Button
                              type="primary"
                              onClick={() => deleteFile(item.name)}
                            >
                              Eliminar archivo
                            </Button>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </Card>
              )}
              <Card
                bodyStyle={{ background: "white" }}
                type="inner"
                title={t("Justificación de la idea")}
                style={{ marginTop: 20 }}
              >
                <JustificacionIdea idIdea={props.idIdea} ideaData={idea} />
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tab={t("Actividades")} key="2">
          <Row gutter={[10, 20]}>
            <Col span={24}>
              <Table columns={activitiesColumns} dataSource={idea.activities} />
              <Form
                form={activityForm}
                name="dynamic_form_nest_item"
                onFinish={onFinish}
                autoComplete="off"
              >
                <Row gutter={[40, 20]} justify={"end"}>
                  <Popconfirm
                    placement="bottomLeft"
                    title={t("Save Changes?")}
                    onConfirm={onChangeDate}
                    okText={t("Yes")}
                    cancelText={t("Cancel")}
                    disabled={!tableChange}
                  >
                    <Button disabled={!tableChange} type="primary">
                      {t("Save Changes")}
                    </Button>
                  </Popconfirm>
                </Row>
                <Form.List name="activities">
                  {(fields, { add, remove }) => {
                    return (
                      <div>
                        {fields.map((field) => (
                          <Space
                            key={field.key}
                            style={{ display: "flex", marginBottom: 8 }}
                            align="start"
                          >
                            <Form.Item
                              style={{ height: 54 }}
                              {...field}
                              name={[field.name, "stage"]}
                              rules={[
                                {
                                  required: true,
                                  message: t("Campo requerido"),
                                },
                              ]}
                              fieldKey={[field.fieldKey, "stage"]}
                            >
                              <Input placeholder={t("Actividad")} />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, "name"]}
                              fieldKey={[field.fieldKey, "name"]}
                            >
                              <Input placeholder={t("Nombre")} />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, "startDate"]}
                              fieldKey={[field.fieldKey, "startDate"]}
                            >
                              <DatePicker
                                placeholder={t(
                                  "idea_details.activities.startDate"
                                )}
                                onChange={(date, dateString) =>
                                  console.log(date, dateString)
                                }
                                format={dateFormat}
                              />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, "endDate"]}
                              fieldKey={[field.fieldKey, "endDate"]}
                              rules={[
                                {
                                  required: true,
                                  message: t("Campo requerido"),
                                },
                              ]}
                            >
                              <DatePicker
                                placeholder={t(
                                  "idea_details.activities.endDate"
                                )}
                                onChange={(date, dateString) =>
                                  console.log(date, dateString)
                                }
                                format={dateFormat}
                              />
                            </Form.Item>

                            <MinusCircleOutlined
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          </Space>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => {
                              add();
                            }}
                            block
                          >
                            <PlusOutlined /> {t("Añadir actividad")}
                          </Button>
                        </Form.Item>
                      </div>
                    );
                  }}
                </Form.List>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    {t("Guardar")}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </TabPane>

        <TabPane tab={t("ProdDetailsTab")} key="3">
          <CostBenefit
            idIdea={props.idIdea}
            ideaData={idea}
            userInfo={props.userInfo}
            company={props.company}
          />
        </TabPane>
        {/* <TabPane tab={t("Historial")} key="4">
          <Row gutter={[10, 20]}>
            <Col span={24}>
              <Table columns={historyColumns} dataSource={idea.history} />
            </Col>
          </Row>
        </TabPane> */}
      </Tabs>
    </>
  );
};

export default PRUEBAIdeaDetails;
