import React, { useEffect, useState } from "react";
import { Spin, Tabs, Divider } from "antd";
import "../../../../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Details from "./Details";
import ViabilityCalculator from "../../../../Calculator";

const DesigualIdeaDetails = (props) => {
  const [idea, setIdea] = useState({});
  const [loading, setLoading] = useState(true);
  const { TabPane } = Tabs;

  useEffect(() => {
    if (props.idea) {
      setLoading(true);
      setIdea({ ...props.idea });
      setLoading(false);
      console.log(props.idea);
    }
  }, [props.idea, props.userInfo.companyId, props.company]);

  const Loader = () => (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        textAlign: "center",
        marginTop: "25%",
      }}
    >
      <Spin size="large" spinning={true}></Spin>
    </div>
  );
  return loading ? (
    <Loader />
  ) : (
    <div style={{ position: "relative" }}>
      <Divider orientation="left">{idea.name}</Divider>
      <Tabs
        theme="light"
        defaultActiveKey={"1"}
        size="medium"
        style={{ padding: "0 15px 0 15px" }}
        centered
      >
        <TabPane tab="Detalles" key="1">
          <Details
            idea={idea}
            userInfo={props.userInfo}
            company={props.company}
          />
        </TabPane>
        <TabPane tab="Calculadora" key="2">
          <ViabilityCalculator
            idea={idea}
            userInfo={props.userInfo}
            company={props.company}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default DesigualIdeaDetails;
