import React, { useState, useEffect } from "react";
import {
  Row,
  Collapse,
  Button,
  Col,
  Space,
  Typography,
  Card,
  Form,
  Input,
  Empty,
  Popover,
  Layout,
  Tag,
  message,
  Avatar,
  Comment,
  Descriptions,
} from "antd";
import moment from "moment";
import {
  FilterFilled,
  EditFilled,
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
} from "@ant-design/icons";
import { updateIssueABANCA } from "../../../../../db/firedataCalls";
import { ABANCATeams } from "../../../../CustomComponents/ABANCA/TeamsComponent";
import { NewIssueModal } from "../../../../CustomComponents/ABANCA/Issues";
import { presetPalettes } from "@ant-design/colors";
const dateFormat = "DD/MM/YYYY HH:mm";
const dateFormartShort = "DD/MM/YYYY";

const ABANCAIssues = (props) => {
  const [data, setData] = useState();
  const [users, setUsers] = useState([]);
  const [deletedUsers, setDeletedUsers] = useState([]);
  const { Panel } = Collapse;
  const { TextArea } = Input;
  const { Content } = Layout;
  const [assigneeForm] = Form.useForm();
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [solutionForm] = Form.useForm();
  const [descriptionEdit, setDescriptionEdit] = useState(false);
  const [descriptionForm] = Form.useForm();
  const [filters, setFilters] = useState([]);
  const [newIssue, setNewIssue] = useState(false);
  const [editIssue, setEditIssue] = useState(null);

  useEffect(() => {
    if (props.ideaData && props.ideaData.issues) {
      let issues = props.ideaData.issues;
      if (filters.length > 0) {
        filters.forEach((filter) => {
          filter.type === "status" && filter.value === "closed"
            ? (issues = issues.filter((issue) => issue.closed))
            : (issues = issues.filter((issue) => !issue.closed));
        });
      }
      setData([...issues]);
    } else setData([]);
    if (props.company) {
      let users = [];
      let deletedUsers = [];
      let colors = [];
      Object.values(presetPalettes).forEach((colorSet) => {
        colors.push(colorSet.primary);
      });
      let res = (users.length + deletedUsers.length) / colors.length;
      if (res > 1) {
        for (let a = 0; a < Math.round(res); a++) {
          colors.push(...colors);
        }
      }
      users = Object.values(props.company.users);
      deletedUsers = Object.values(props.company.deletedUsers);
      users = users.map((user, index) => ({ ...user, color: colors[index] }));
      deletedUsers = deletedUsers.map((user, index) => ({
        ...user,
        color: colors[index],
      }));
      setUsers([...users]);
      setDeletedUsers([...deletedUsers]);
    } else {
      setUsers([]);
      setDeletedUsers([]);
    }
  }, [props.ideaData, filters, props.company]);

  const getUserAndCode = (userUid) => {
    let user = users.find(({ uid }) => uid === userUid);
    let deletedUser = deletedUsers.find(({ uid }) => uid === userUid);
    if (user)
      return (
        user.firstName +
        " " +
        user.lastName1 +
        (user.lastName2 ? " " + user.lastName2 : null) +
        " (" +
        user.username +
        ")"
      );
    else if (deletedUser)
      return (
        deletedUser.firstName +
        " " +
        deletedUser.lastName1 +
        (deletedUser.lastName2 ? " " + deletedUser.lastName2 : null) +
        " (" +
        deletedUser.username +
        ")"
      );
  };

  const userAvatar = (userUid) => {
    let user = users.find(({ uid }) => uid === userUid);
    let deletedUser = deletedUsers.find(({ uid }) => uid === userUid);
    if (user)
      return (
        <Avatar style={{ backgroundColor: user.color }} key={userUid}>
          {user.firstName[0]}
        </Avatar>
      );
    else if (deletedUser)
      return (
        <Avatar style={{ backgroundColor: deletedUser.color }} key={userUid}>
          {deletedUser.firstName[0]}
        </Avatar>
      );
  };

  const markAsSolved = (element) => {
    let tempIssues = data;
    let index = tempIssues.findIndex(({ id }) => id === element.id);
    tempIssues[index].closedDate = {
      seconds: moment.utc().unix(),
      nanoseconds: 0,
    };
    tempIssues[index].closed = true;
    updateIssueABANCA(props.ideaData.id, tempIssues).catch(() => {
      message.error("Ocurrió un error");
    });
  };

  const onFinishSolution = (element) => (values) => {
    let tempIssues = data;
    let index = tempIssues.findIndex(({ id }) => id === element.id);
    values.date = {
      seconds: moment.utc().unix(),
      nanoseconds: 0,
    };
    values.userUid = props.userInfo.uid;
    tempIssues[index].solution = values;
    updateIssueABANCA(props.ideaData.id, tempIssues)
      .then(() => {
        message.success("Comentario añadido");
        solutionForm.resetFields();
      })
      .catch(() => {
        message.error("Ocurrió un error al comentar");
      });
  };

  const contentComment = (element) => {
    let curUser = users.find(({ uid }) => uid === props.userInfo.uid);
    return (
      <Comment
        style={{ bottom: 0, width: "100%" }}
        avatar={userAvatar(curUser.uid)}
        content={
          <Form form={solutionForm} onFinish={onFinishSolution(element)}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Form.Item
                name="comment"
                rules={[
                  {
                    required: true,
                    message: "Campo Requerido",
                  },
                ]}
                style={{ width: "80%" }}
              >
                <TextArea rows={2} />
              </Form.Item>
              <Form.Item>
                <Space>
                  <Button htmlType="submit">Comentar solución</Button>
                  <Button
                    type="primary"
                    disabled={element.closed}
                    onClick={() =>
                      solutionForm.getFieldValue("comment") &&
                      (solutionForm.submit(), markAsSolved(element))
                    }
                  >
                    Comentar y marcar como solucionado
                  </Button>
                </Space>
              </Form.Item>
            </Space>
          </Form>
        }
      />
    );
  };

  const commentHeader = (element) => {
    let user = getUserAndCode(element.solution.userUid);
    let userName = user.split("(")[0];
    let code = user.split("(")[1].slice(0, -1);
    user = users.find(({ uid }) => uid === element.solution.userUid);
    if (user === undefined)
      user = deletedUsers.find(({ uid }) => uid === element.solution.userUid);
    return (
      <Space size="middle">
        {userAvatar(user.uid)}
        <Typography.Text>{userName}</Typography.Text>
        <Typography.Text type="secondary"> #{code}</Typography.Text>
        <Typography.Text type="secondary">
          comentó el{" "}
          {moment(moment.unix(element.solution.date.seconds)).format(
            dateFormat
          )}
        </Typography.Text>
      </Space>
    );
  };

  const contentSolution = (element) => {
    return (
      <Card
        type="inner"
        title={commentHeader(element)}
        style={{ bottom: "20px", width: "100%" }}
      >
        <Typography.Text style={{ paddingLeft: "20px" }}>
          {element.solution.comment}
        </Typography.Text>
      </Card>
    );
  };

  const onFinishDescription = (element) => (values) => {
    let tempIssues = data;
    let index = tempIssues.findIndex(({ id }) => id === element.id);
    tempIssues[index].description = values.description;
    updateIssueABANCA(props.ideaData.id, tempIssues)
      .then(() => {
        message.success("Descripción editada");
      })
      .catch(() => {
        message.error("Ocurrió un error al editar");
      });
    setDescriptionEdit(false);
    descriptionForm.resetFields();
  };

  const contentDescription = (element) => {
    let description = "Sin descripción";
    if (element.description) description = element.description;
    return descriptionEdit ? (
      <Form form={descriptionForm} onFinish={onFinishDescription(element)}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Form.Item name="description" style={{ width: "90%" }}>
            <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button htmlType="submit" type="primary">
                Guardar
              </Button>
              <Button
                htmlType="submit"
                onClick={() => {
                  setDescriptionEdit(false);
                  descriptionForm.resetFields();
                }}
              >
                Cancelar
              </Button>
            </Space>
          </Form.Item>
        </Space>
      </Form>
    ) : (
      <Col style={{ textAlign: "justify", paddingLeft: 0 }}>{description}</Col>
    );
  };

  const panelContent = (element) => {
    return (
      <Layout key={element.id}>
        <Content className="issues-body">
          <Row gutter={[10, 20]} justify="center">
            <Col span={22}>
              <Space direction="vertical">
                <Descriptions
                  title={element.issue}
                  column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                >
                  {element.guardia ? (
                    <Descriptions.Item label="Creador">
                      {getUserAndCode(element.assignee[0])}
                    </Descriptions.Item>
                  ) : (
                    <Descriptions.Item label="Asignados">
                      <div style={{ cursor: "pointer" }}>
                        {teamPopOver(element)}
                      </div>
                    </Descriptions.Item>
                  )}
                  <Descriptions.Item label="Creación">
                    {moment(moment.unix(element.createdDate.seconds)).format(
                      dateFormartShort
                    )}
                  </Descriptions.Item>
                  {element.guardia && (
                    <Descriptions.Item label="Duración">
                      {element.duration
                        ? element.duration + " minutos"
                        : "Sin duración"}
                    </Descriptions.Item>
                  )}
                  <Descriptions.Item label="Estado">
                    {element.closed ? "Solucionado" : "Pendiente"}
                  </Descriptions.Item>
                  {element.closed && (
                    <Descriptions.Item label="Finalización">
                      {moment(moment.unix(element.closedDate.seconds)).format(
                        dateFormartShort
                      )}
                    </Descriptions.Item>
                  )}
                </Descriptions>
                <Row gutter={[0, 20]}>
                  <Col>
                    <span>
                      Descripción:{" "}
                      <EditFilled
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setDescriptionEdit(true);
                          descriptionForm.setFieldsValue(element);
                        }}
                      />
                    </span>
                  </Col>
                  <Col>{contentDescription(element)}</Col>
                </Row>
                <br />
                {element.solution
                  ? contentSolution(element)
                  : contentComment(element)}
              </Space>
            </Col>
            <Col span={12}></Col>
            <Col span={10}>
              <Space>
                <Button
                  style={{
                    cursor: element.solution
                      ? element.closed
                        ? "default"
                        : "pointer"
                      : "default",
                  }}
                  disabled={element.closed}
                  type="primary"
                  onClick={() => markAsSolved(element)}
                >
                  Marcar solucionado
                </Button>
                {element.guardia && (
                  <Button
                    onClick={() => {
                      setEditIssue(element);
                      setNewIssue(true);
                    }}
                  >
                    Editar
                  </Button>
                )}
              </Space>
            </Col>
          </Row>
        </Content>
      </Layout>
    );
  };

  const teamPopOver = (element) => {
    return (
      <Popover
        trigger="click"
        placement="bottom"
        content={
          <ABANCATeams
            issue={element}
            idea={props.ideaData}
            company={props.company}
          />
        }
        visible={popoverVisible}
        destroyTooltipOnHide
        onVisibleChange={(visible) => {
          setPopoverVisible(visible);
          if (element.assignee) assigneeForm.setFieldsValue(element);
        }}
      >
        <div onClick={() => setPopoverVisible(!popoverVisible)}>
          <Typography.Link>
            {element.assignee
              ? element.assignee.length > 1
                ? `${getUserAndCode(element.assignee[0])} +${
                    element.assignee.length - 1
                  }`
                : getUserAndCode(element.assignee[0])
              : "Asignar incidencia"}
          </Typography.Link>
        </div>
      </Popover>
    );
  };

  const panelHeader = (element) => {
    return (
      <>
        <Space size="middle">
          {element.closed ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : (
            <ExclamationCircleTwoTone twoToneColor="#f5222d" />
          )}
          <Typography.Text strong>{element.issue}</Typography.Text>
          {element.guardia && <Tag color="processing">Guardia</Tag>}
        </Space>
        <br />
        <Typography.Text type="secondary"> #{element.id} </Typography.Text>
        <Typography.Text type="secondary">
          generado el{" "}
          {moment(moment.unix(element.createdDate.seconds)).format(dateFormat)}{" "}
        </Typography.Text>
        {element.closed && (
          <Typography.Text type="secondary">
            cerrado el{" "}
            {moment(moment.unix(element.closedDate.seconds)).format(dateFormat)}
          </Typography.Text>
        )}
      </>
    );
  };

  const addFilter = (tipo, valor, checked) => {
    const tag = "Solucionado";
    let tempFilters = filters.filter(({ type }) => type === !tipo);
    if (!checked) {
      tempFilters = tempFilters.filter(({ value }) => value === !valor);
      setFilters([...tempFilters]);
    } else {
      const filter = { type: tipo, value: valor, tag: tag };
      setFilters([...tempFilters, filter]);
    }
  };

  return (
    <Col span={24}>
      <NewIssueModal
        visible={newIssue}
        setVisible={setNewIssue}
        userInfo={props.userInfo}
        ideas={[props.ideaData]}
        issue={editIssue}
        setIssue={setEditIssue}
        origin={"DETAILS"}
      />
      <>
        {data ? (
          <Row gutter={[10, 20]} justify={"center"}>
            <Col span={14}>
              <Space size="middle">
                <FilterFilled />
                <Typography.Text type="secondary">Estado: </Typography.Text>
                <Row>
                  <Tag.CheckableTag
                    key={"closed"}
                    checked={
                      filters.map(({ value }) => value).indexOf("closed") > -1
                    }
                    onChange={(checked) =>
                      addFilter("status", "closed", checked)
                    }
                  >
                    Solucionadas
                  </Tag.CheckableTag>
                  <Tag.CheckableTag
                    key={"open"}
                    checked={
                      filters.map(({ value }) => value).indexOf("open") > -1
                    }
                    onChange={(checked) => addFilter("status", "open", checked)}
                  >
                    Pendientes
                  </Tag.CheckableTag>
                </Row>
              </Space>
            </Col>
            <Col span={10}>
              <Button
                type="primary"
                size="small"
                onClick={() => setNewIssue(true)}
              >
                + Nueva Guardia
              </Button>
            </Col>
            <Col span={24}>
              <Collapse
                accordion
                defaultActiveKey={
                  props.modifiers
                    ? props.modifiers.issue && props.modifiers.issue.id
                    : "0"
                }
                onChange={() => {
                  setDescriptionEdit(false);
                  setPopoverVisible(false);
                }}
                destroyInactivePanel
              >
                {data.map((element) => (
                  <Panel header={panelHeader(element)} key={element.id}>
                    {panelContent(element)}
                  </Panel>
                ))}
              </Collapse>
            </Col>
          </Row>
        ) : (
          <Empty />
        )}
      </>
    </Col>
  );
};

export default ABANCAIssues;
