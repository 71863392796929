import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button, Row, Col, Table, Tag, Typography } from "antd";
import "../../../../../App.css";
import { PlusOutlined } from "@ant-design/icons";
import { ActivityModal } from "../../../../CustomComponents/ABANCA/ActivitiesPanel";
const dateFormat = "DD/MM/YYYY";
const clone = require("rfdc")();

const ABANCAActivities = (props) => {
  const [ideaActivities, setIdeaActivities] = useState([]);
  const [users, setUsers] = useState([]);
  const [companyStatus, setCompanyStatus] = useState([]);
  const [activityModal, setActivityModal] = useState(false);
  const [editActivity, setEditActivity] = useState(null);
  const [modifiers, setModifiers] = useState(null);

  useEffect(() => {
    let statusMap = [];
    setIdeaActivities(
      props.ideaData && props.ideaData.activities
        ? clone(props.ideaData.activities)
        : []
    );
    if (props.company) {
      setUsers(Object.values(props.company.users));
      Object.entries(props.company.todoConfig.boards).forEach(([k, v]) => {
        switch (Number(k)) {
          case 0:
            statusMap.push({
              status: Number(v.value),
              color: "orange",
              name: v.name,
            });
            break;
          case 1:
            statusMap.push({
              status: Number(v.value),
              color: "blue",
              name: v.name,
            });
            break;
          case 2:
            statusMap.push({
              status: Number(v.value),
              color: "default",
              name: v.name,
            });
            break;
          default:
            statusMap.push({
              status: Number(v.value),
              color: "green",
              name: v.name,
            });
            break;
        }
      });
      setCompanyStatus(statusMap);
    } else setUsers([]);
  }, [props.ideaData, props.userInfo, props.company]);

  const handleRowClick = (row) => {
    setEditActivity({ ...row, personal: false, idea: { ...props.ideaData } });
    setModifiers({ origin: "ABANCAActivities" });
    setActivityModal(true);
  };

  const priorityMap = [
    {
      label: "Urgente",
      value: 4,
      color: "red",
    },
    {
      label: "Alta",
      value: 3,
      color: "orange",
    },
    {
      label: "Media",
      value: 2,
      color: "green",
    },
    {
      label: "Baja",
      value: 1,
      color: "blue",
    },
  ];

  const activitiesColumns = [
    {
      title: "Título",
      dataIndex: "title",
      render: (text, record) => (
        <Typography.Paragraph
          style={{ padding: "13px 13px 6px 6px", cursor: "pointer" }}
          onClick={() => handleRowClick(record)} /* 
          ellipsis={{ rows: 1 }} */
        >
          <Typography.Link>
            {record.title || "Agregar un título"}
          </Typography.Link>
        </Typography.Paragraph>
      ),
      fixed: "left",
      width: 230,
    },
    {
      title: "Descripción",
      dataIndex: "description",
      render: (text) => text || "Sin descripción",
      width: 400,
    },
    {
      title: "Fecha creación",
      dataIndex: "creationDate",
      render: (text, record) =>
        text ? moment.unix(text.seconds).format(dateFormat) : "",
      sorter: {
        compare: (a, b) =>
          a.creationDate && a.creationDate.seconds
            ? b.creationDate && b.creationDate.seconds
              ? a.creationDate.seconds - b.creationDate.seconds
              : a.creationDate.seconds - 0
            : b.creationDate && b.creationDate.seconds
            ? 0 - b.creationDate.seconds
            : 0 - 0,
      },
      width: 80,
    },
    {
      title: "Fecha inicio",
      dataIndex: "startDate",
      render: (text, record) =>
        text ? moment.unix(text.seconds).format(dateFormat) : "",
      sorter: {
        compare: (a, b) =>
          a.startDate && a.startDate.seconds
            ? b.startDate && b.startDate.seconds
              ? a.startDate.seconds - b.startDate.seconds
              : a.startDate.seconds - 0
            : b.startDate && b.startDate.seconds
            ? 0 - b.startDate.seconds
            : 0 - 0,
      },
      width: 80,
    },
    {
      title: "Fecha término",
      dataIndex: "endDate",
      render: (text, record) =>
        text ? moment.unix(text.seconds).format(dateFormat) : "",
      sorter: {
        compare: (a, b) =>
          a.endDate && a.endDate.seconds
            ? b.endDate && b.endDate.seconds
              ? a.endDate.seconds - b.endDate.seconds
              : a.endDate.seconds - 0
            : b.endDate && b.endDate.seconds
            ? 0 - b.endDate.seconds
            : 0 - 0,
      },
      width: 80,
    },
    {
      title: "Prioridad",
      dataIndex: "priority",
      render: (text, record) => {
        let tag = priorityMap.find(({ value }) => value === text);
        return (
          tag && (
            <Tag
              color={tag.color}
              style={{ width: "90%", textAlign: "center" }}
            >
              {tag.label}
            </Tag>
          )
        );
      },
      sorter: { compare: (a, b) => a.priority - b.priority },
      filters: [
        { text: "Baja", value: 1 },
        { text: "Media", value: 2 },
        { text: "Alta", value: 3 },
        { text: "Urgente", value: 4 },
      ],
      onFilter: (value, record) => record.priority === value,
      width: 100,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => {
        let tag = companyStatus.find(({ status }) => status === text);
        return (
          tag && (
            <Tag
              color={tag.color}
              style={{ width: "100%", textAlign: "center" }}
            >
              {tag.name}
            </Tag>
          )
        );
      },
      sorter: { compare: (a, b) => a.status - b.status },
      filters: [
        { text: "En espera", value: 1 },
        { text: "Próximo", value: 2 },
        { text: "En proceso", value: 3 },
        { text: "Terminado", value: 4 },
      ],
      onFilter: (value, record) => record.status === value,
      width: 100,
    },
    {
      title: "Responsable",
      dataIndex: "assignee",
      render: (text, record) => {
        let user = users.find(({ uid }) => uid === text);
        return user ? (
          <span>
            {user.firstName +
              " " +
              user.lastName1 +
              (user.lastName2 ? " " + user.lastName2 : "")}
          </span>
        ) : (
          "Sin responsable"
        );
      },
      width: 150,
    },
  ];

  return (
    <Row gutter={[10, 20]}>
      <Col span={24}>
        <Button
          type="primary"
          onClick={() => {
            setModifiers({ origin: "ABANCAActivities" });
            setActivityModal(true);
          }}
        >
          <span>
            <PlusOutlined /> Añadir actividad
          </span>
        </Button>
      </Col>
      <Col span={24}>
        <Table
          columns={activitiesColumns}
          dataSource={ideaActivities}
          rowKey={(record) => record.id}
          /* tableLayout={"fixed"} */
          scroll={{ x: "max-content" }}
        />
      </Col>
      <ActivityModal
        visible={activityModal}
        setVisible={setActivityModal}
        setActivity={setEditActivity}
        activity={editActivity}
        company={props.company}
        ideasOptions={[{ ...props.ideaData }]}
        idea={props.ideaData}
        modifiers={modifiers}
        setModifiers={setModifiers}
        userInfo={props.userInfo}
      />
    </Row>
  );
};

export default ABANCAActivities;
