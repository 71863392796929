import React, { useEffect, useState } from "react";
import { Steps, Row, Col, message, Button, Select } from "antd";
import { updateActivity, updateBoards } from "../../../../../db/firedataCalls";
import firebase from "../../../../../server/firebase";
const clone = require("rfdc")();
const { Step } = Steps;

const DeleteBoards = ({ closeSettings, boards, columns, userInfo }) => {
  const [stepState, setStepState] = useState(0);
  const [deleteBoard, setDeleteBoard] = useState();
  const [skipSteps, setSkipSteps] = useState(false);
  const [unfinishedOption, setUnfinishedOption] = useState();
  const [moveOption, setMoveOption] = useState();
  const addSteps = [
    "Selecciona un tablero",
    "Actividades inconclusas",
    "Confirmación",
  ];
  useEffect(() => {
    return () => {
      reset();
    };
  }, [boards]);

  const deleteBoardFinish = () => {
    if (!skipSteps) {
      if (unfinishedOption === "move" && moveOption) {
        /* const newStatus = boards[moveOption].value;
        const column = Object.values(columns).find(
          (board) => deleteBoard.value === board.id
        );
        console.log(deleteBoard); */
        /* TODO [] Function that move activities */
      } else if (unfinishedOption === "delete") {
        const column = Object.values(columns).find(
          (board) => deleteBoard.value === board.id
        );
        column.activities.forEach((activity) => deleteActivity(activity));
        confirmDeleteBoard();
        /* TODO [] Function that delete activities */
      }
    } else {
      /* TODO [x] Function that delete board */
      confirmDeleteBoard();
    }
  };

  const deleteActivity = async (activity) => {
    let tempActivities = [];
    if (activity.personal) {
      tempActivities = userInfo.activities;
      tempActivities = tempActivities.filter(({ id }) => !(id === activity.id));
      await updateActivity(null, tempActivities, userInfo);
    } else {
      let tempIdea = activity.idea;
      tempActivities = tempIdea.activities.filter(
        ({ id }) => !(id === activity.id)
      );
      await updateActivity(tempIdea.id, tempActivities, null);
    }
  };

  const checkActivities = async () => {
    let bool = false;
    await firebase
      .database()
      .ref()
      .child("personalActivities")
      .child(userInfo.companyId)
      .once("value", (db) => {
        if (db) {
          db.forEach((doc) => {
            const activities = doc.val().activities;
            if (activities && !bool)
              bool =
                activities.find(({ status }) => status === deleteBoard.value) &&
                true;
          });
        }
      });
    if (!bool) bool = false;
    return bool;
  };

  const confirmDeleteBoard = () => {
    let tempBoards = clone(boards);
    tempBoards = tempBoards.filter(
      (board) => !(board.value === deleteBoard.value)
    );
    updateBoards(tempBoards, userInfo.companyId);
  };

  const nextStep = async () => {
    if (stepState === 0) {
      const column = Object.values(columns).find(
        (board) => deleteBoard.value === board.id
      );
      if (
        !(column.activities && column.activities.length > 0) &&
        !(await checkActivities())
      ) {
        // TODO [] make only skipsteps only if no activities at all
        setSkipSteps(true);
        setStepState(stepState + 2);
      } else setStepState(stepState + 1);
    } else setStepState(stepState + 1);
  };
  const prevStep = () => {
    if (stepState === 2 && skipSteps) setStepState(stepState - 2);
    else setStepState(stepState - 1);
  };

  const selectBoard = () => {
    if (boards)
      return (
        <div>
          <Select
            style={{ width: 200 }}
            onSelect={(boardValue) => {
              setDeleteBoard(boards.find(({ value }) => value === boardValue));
              setUnfinishedOption();
              setSkipSteps(false);
              if (boards.findIndex(({ value }) => value === boardValue) === 0)
                setMoveOption(1);
              else setMoveOption(0);
            }}
            placeholder={"Selecciona un tablero"}
            value={deleteBoard && deleteBoard.name}
          >
            {boards.map((board) => (
              <Select.Option
                key={board.value}
                value={board.value}
                disabled={board.value === 4}
              >
                {board.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      );
  };

  const unfinished = () => {
    /*  return (
      <div>
        <div>Tablero: {deleteBoard.name} </div>
        <div>
          ¿Deseas eliminar las actividades que se encuentren en este tablero o
          moverlas a otro?
        </div>
        <br />
        <div>
          <Select
            style={{ width: 200 }}
            placeholder="Seleccione una opción"
            value={unfinishedOption && unfinishedOption}
            onSelect={(value) => setUnfinishedOption(value)}
          >
            <Select.Option key="delete">Eliminar</Select.Option>
            <Select.Option key="move">Mover</Select.Option>
          </Select>
        </div>
        <br />
        {unfinishedOption === "move" && (
          <>
            <div>Selecciona el tablero al que se moveran las actividades</div>
            <br />
            <div>
              <Select
                style={{ width: 200 }}
                placeholder="Seleccione una opción"
                value={moveOption}
                onSelect={(boardValue) => setMoveOption(boardValue)}
              >
                {boards.map((board, index) => (
                  <Select.Option key={index} value={index}>
                    {board.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <br />
          </>
        )}
      </div>
    ); */
    return (
      <div style={{ padding: "0 5em" }}>
        <div>Tablero: {deleteBoard.name} </div>
        <div>
          Este tablero no puede ser eliminado debido a que cuenta con
          actividades (otro miembro de la organización puede tener actividades
          PERSONALES en este tablero)
        </div>
      </div>
    );
  };

  const getState = () => {
    if (stepState === 0) return selectBoard();
    else if (stepState === 1) return unfinished();
    else if (stepState === 2)
      return <div>¿Seguro que desea eliminar el tablero?</div>;
  };

  const reset = () => {
    setStepState(0);
    setDeleteBoard();
    setSkipSteps(false);
    setUnfinishedOption();
  };

  return (
    <Row>
      <Col span={24}>
        <Steps current={stepState} size="small">
          {addSteps.map((item) => (
            <Step key={item} title={item} />
          ))}
        </Steps>
      </Col>
      <Col span={24} className="steps-content">
        {getState()}
      </Col>
      <Col span={24} className="steps-action">
        {stepState < addSteps.length - 1 && (
          <Button
            disabled={
              !deleteBoard ||
              (stepState === 1 && !skipSteps && !unfinishedOption)
            }
            type="primary"
            onClick={() => nextStep()}
          >
            Siguiente
          </Button>
        )}
        {stepState === addSteps.length - 1 && (
          <Button
            type="primary"
            onClick={() => {
              message.success("Tablero eliminado con éxito");
              // TODO
              // [] Delete functionality disabled waiting for instructions
              // [] Make the firebase function to check among all personal activities for status
              deleteBoardFinish();
              closeSettings(1);
            }}
          >
            Completado
          </Button>
        )}
        {stepState !== addSteps.length - 1 && stepState > 0 && (
          <Button style={{ margin: "0 8px" }} onClick={() => prevStep()}>
            Previo
          </Button>
        )}
        {stepState > 0 && (
          <Button danger onClick={() => reset()}>
            Cancelar
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default DeleteBoards;
