import React, { useState, useEffect } from "react";
import firebase from "../../../../../server/firebase";
import moment from "moment";
import {
  Row,
  Col,
  Spin,
  Button,
  Form,
  Input,
  Select,
  InputNumber,
  Alert,
  message,
  Radio,
} from "antd";
import "../../../../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { PanelRender } from "../../../../CustomComponents/HEB/OTDPanel";

const { Option } = Select;
const HEBDetails = (props) => {
  const { t } = useTranslation();
  const [showBenefitType, setShowBenefitType] = useState();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [displayError, setDisplayError] = useState("none");

  const Loader = () => (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        textAlign: "center",
        marginTop: "25%",
      }}
    >
      <Spin size="large" spinning={true}></Spin>
    </div>
  );
  const onFinish = async (values) => {
    Object.entries(values).forEach(([k, v]) => {
      if (v === undefined) values[k] = null;
    });
    var localValues = values;
    var periodicidadValor = 0;
    var roi = 0;
    if (showBenefitType) {
      //Si es de tipo Beneficio
      localValues.people = null;
      localValues.executionTime = null;
      localValues.repetitive = null;

      periodicidadValor = 0;
      switch (localValues.periodicity) {
        case "diario":
          periodicidadValor = 240;
          break;
        case "semanal":
          periodicidadValor = 48;
          break;
        case "catorcena":
          periodicidadValor = 24;
          break;
        case "periodo":
          periodicidadValor = 12;
          break;
        case "anual":
          periodicidadValor = 1;
          break;
        default:
          break;
      }
      roi = periodicidadValor * localValues.benefit;
      //Cálculo del ROI en dinero
      localValues.estimatedROI = roi;
    } else {
      //Si es de tipo Tiempo
      localValues.benefit = null;

      periodicidadValor = 0;
      switch (localValues.periodicity) {
        case "diario":
          periodicidadValor = 20;
          break;
        case "semanal":
          periodicidadValor = 4;
          break;
        case "catorcena":
          periodicidadValor = 2;
          break;
        case "periodo":
          periodicidadValor = 1;
          break;
        default:
          break;
      }
      roi =
        periodicidadValor * localValues.executionTime * localValues.repetitive;
      //Cálculo del ROI en horas
      localValues.estimatedROI = roi;
    }

    let user = {
      uid: props.userInfo.uid,
      firstName: props.userInfo.firstName,
      lastName1: props.userInfo.lastName1,
    };
    let _date = moment.utc();
    localValues.updatedDate = { seconds: _date.unix(), nanoseconds: 0 };
    localValues.history = props.idea.history ? props.idea.history : [];
    localValues.history.push({
      eventType: "MODIFIED",
      date: localValues.updatedDate,
      performer: user,
    });
    try {
      await firebase
        .database()
        .ref()
        .child("ideas")
        .child(props.idea.id)
        .update(localValues);
      setLoading(false);
      message.success();
    } catch (error) {
      setDisplayError("block");
    }
  };
  useEffect(() => {
    if (props.idea) {
      form.setFieldsValue({ ...props.idea });
      setShowBenefitType(props.idea.tipoROI);
    }
  }, [props.idea, form]);
  return props.loading ? (
    <Loader />
  ) : (
    <>
      <Row>
        <Form
          form={form}
          name="create-idea"
          onFinish={onFinish}
          labelCol={{
            span: 7,
          }}
          wrapperCol={{
            span: 17,
          }}
          layout="vertical"
          size="middle"
          style={{ width: "100%" }}
          labelAlign="right"
        >
          <Row gutter={6}>
            <Col span={24}>
              <Form.Item
                labelCol={2}
                wrapperCol={18}
                name="name"
                label={t("form_fields.create_idea_HEB.name.label")}
                rules={[
                  {
                    required: true,
                    message: t(
                      "form_fields.create_idea_HEB.name.error_message"
                    ),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                labelCol={2}
                wrapperCol={18}
                name="description"
                label={t("form_fields.create_idea_HEB.description.label")}
                rules={[
                  {
                    required: true,
                    message: t(
                      "form_fields.create_idea_HEB.description.error_message"
                    ),
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={12}
                wrapperCol={18}
                name="equipo"
                label={t("form_fields.create_idea_HEB.responsableTeam.label")}
                rules={[
                  {
                    required: true,
                    message: t(
                      "form_fields.create_idea_HEB.responsableTeam.error_message"
                    ),
                  },
                ]}
              >
                <Select
                  placeholder={t(
                    "form_fields.create_idea_HEB.responsableTeam.placeholder"
                  )}
                >
                  <Option value={"Proveedor"} key={"proveedor"}>
                    Proveedor
                  </Option>
                  <Option value={"HEB"} key={"HEB"}>
                    HEB
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={12}
                wrapperCol={18}
                name="asigneeName"
                label={t("form_fields.create_idea_HEB.user.label")}
                rules={[
                  {
                    required: true,
                    message: t(
                      "form_fields.create_idea_HEB.user.error_message"
                    ),
                  },
                ]}
              >
                <Input
                  placeholder={t(
                    "form_fields.create_idea_HEB.user.placeholder"
                  )}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                labelCol={7}
                label={t("form_fields.create_idea_HEB.workCategory.label")}
                name="workCategory"
                rules={[
                  {
                    required: true,
                    message: t(
                      "form_fields.create_idea_HEB.workCategory.error_message"
                    ),
                  },
                ]}
              >
                <Select
                  placeholder={t(
                    "form_fields.create_idea_HEB.workCategory.label"
                  )}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {Object.entries(props.company.workCategories).map((areas) => {
                    return (
                      <Option value={areas[1]} key={areas[1]} name={areas[1]}>
                        {areas[1]}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={7}
                label={t("form_fields.create_idea_HEB.department.label")}
                name="department"
                rules={[
                  {
                    required: true,
                    message: t(
                      "form_fields.create_idea_HEB.department.error_message"
                    ),
                  },
                ]}
              >
                <Select
                  placeholder={t(
                    "form_fields.create_idea_HEB.department.label"
                  )}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {Object.entries(props.company.Department).map(
                    (department) => {
                      return (
                        <Option
                          value={department[1]}
                          key={department[1]}
                          name={department[1]}
                        >
                          {department[1]}
                        </Option>
                      );
                    }
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                labelCol={7}
                label={t("form_fields.create_idea_HEB.involved.label")}
                name="involvedAreas"
                rules={[
                  {
                    required: true,
                    message: t(
                      "form_fields.create_idea_HEB.involved.error_message"
                    ),
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder={t("form_fields.create_idea_HEB.select")}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {Object.entries(props.company.Department).map(
                    (department) => {
                      return (
                        <Option
                          value={department[1]}
                          key={department[1]}
                          name={department[1]}
                        >
                          {department[1]}
                        </Option>
                      );
                    }
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={12}
                wrapperCol={8}
                label={t("form_fields.create_idea_HEB.complexity.label")}
                name="complexity"
                rules={[
                  {
                    required: true,
                    message: t(
                      "form_fields.create_idea_HEB.complexity.error_message"
                    ),
                  },
                ]}
              >
                <Select style={{ width: "100%" }}>
                  <Option key="0" value={0}>
                    {"Muy Bajo"}
                  </Option>
                  <Option key="1" value={1}>
                    {"Bajo "}
                  </Option>
                  <Option key="2" value={2}>
                    {"Medio"}
                  </Option>
                  <Option key="3" value={3}>
                    {"Alto"}
                  </Option>
                  <Option key="4" value={4}>
                    {"Muy Alto"}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={12}
                wrapperCol={8}
                label={t("form_fields.create_idea_HEB.priority.label")}
                name="priority"
                rules={[
                  {
                    required: true,
                    message: t(
                      "form_fields.create_idea_HEB.priority.error_message"
                    ),
                  },
                ]}
              >
                <InputNumber></InputNumber>
                {/* <Select style={{ width: "100%" }}>
                  <Option key="0" value={0}>
                    {"Muy Bajo"}
                  </Option>
                  <Option key="1" value={1}>
                    {"Bajo "}
                  </Option>
                  <Option key="2" value={2}>
                    {"Medio"}
                  </Option>
                  <Option key="3" value={3}>
                    {"Alto"}
                  </Option>
                  <Option key="4" value={4}>
                    {"Muy Alto"}
                  </Option>
                </Select> */}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                labelCol={7}
                label={t("form_fields.create_idea_HEB.benefitType.label")}
                name="benefitType"
                rules={[
                  {
                    required: true,
                    message: t(
                      "form_fields.create_idea_HEB.benefitType.error_message"
                    ),
                  },
                ]}
              >
                <Select
                  placeholder={t(
                    "form_fields.create_idea_HEB.benefitType.label"
                  )}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {Object.entries(props.company.benefitType).map(
                    (beneficio) => {
                      return (
                        <Option
                          value={beneficio[1]}
                          key={beneficio[1]}
                          name={beneficio[1]}
                        >
                          {beneficio[1]}
                        </Option>
                      );
                    }
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                labelCol={2}
                wrapperCol={18}
                name="descriptionBenefit"
                label={t(
                  "form_fields.create_idea_HEB.descriptionBenefit.label"
                )}
                rules={[
                  {
                    required: true,
                    message: t(
                      "form_fields.create_idea_HEB.descriptionBenefit.error_message"
                    ),
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                colon={false}
                name="tipoROI"
                labelAlign="left"
                label={t("form_fields.create_idea_HEB.typeROI.label")}
                rules={[
                  {
                    required: true,
                    message: t("Campo requerido"),
                  },
                ]}
              >
                <Radio.Group
                  style={{ width: "100%" }}
                  onChange={(e) => setShowBenefitType(e.target.value)}
                >
                  <Radio.Button key="tiempo" value={false}>
                    Tiempo
                  </Radio.Button>
                  <Radio.Button key="beneficio" value={true}>
                    Beneficio
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={6} hidden={showBenefitType}>
            <Col span={12}>
              <Form.Item
                labelCol={12}
                wrapperCol={8}
                label={t("form_fields.create_idea_HEB.people.label")}
                name="people"
                rules={[
                  {
                    required: !showBenefitType ? true : false,
                    message: t(
                      "form_fields.create_idea_HEB.people.error_message"
                    ),
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  value={0}
                  min={0}
                  max={100}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={12}
                wrapperCol={8}
                label={t("form_fields.create_idea_HEB.periodicity.label")}
                name="periodicity"
                rules={[
                  {
                    required: !showBenefitType ? true : false,
                    message: t(
                      "form_fields.create_idea_HEB.periodicity.error_message"
                    ),
                  },
                ]}
              >
                <Select style={{ width: "100%" }}>
                  <Option key="0" value="diario">
                    {"Diario"}
                  </Option>
                  <Option key="2" value="semanal">
                    {"Semanal"}
                  </Option>
                  <Option key="3" value="catorcena">
                    {"Catorcena"}
                  </Option>
                  <Option key="4" value="periodo">
                    {"Periodo"}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={12}
                wrapperCol={6}
                label={t("form_fields.create_idea_HEB.executionTime.label")}
                name="executionTime"
                rules={[
                  {
                    required: !showBenefitType ? true : false,
                    message: t(
                      "form_fields.create_idea_HEB.executionTime.error_message"
                    ),
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  defaultValue={0}
                  min={0}
                  max={100}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={12}
                wrapperCol={6}
                label={t("form_fields.create_idea_HEB.repetitive.label")}
                name="repetitive"
                rules={[
                  {
                    required: !showBenefitType ? true : false,
                    message: t(
                      "form_fields.create_idea_HEB.repetitive.error_message"
                    ),
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  defaultValue={0}
                  min={0}
                  max={1000}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={12}
                wrapperCol={6}
                label={t("form_fields.create_idea_HEB.executorSalary.label")}
                name="executorHourSalary"
                rules={[
                  {
                    required: !showBenefitType ? true : false,
                    message: t(
                      "form_fields.create_idea_HEB.executorSalary.error_message"
                    ),
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  defaultValue="0"
                  min={0}
                  placeholder={0}
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6} hidden={!showBenefitType}>
            <Col span={12}>
              <Form.Item
                labelCol={12}
                wrapperCol={8}
                label={t("form_fields.create_idea_HEB.benefit.label")}
                name="benefit"
                rules={[
                  {
                    required: showBenefitType ? true : false,
                    message: t(
                      "form_fields.create_idea_HEB.benefit.error_message"
                    ),
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  defaultValue="0"
                  min={0}
                  placeholder={0}
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={12}
                wrapperCol={8}
                label={t("form_fields.create_idea_HEB.periodicity.label")}
                name="periodicity"
                rules={[
                  {
                    required: showBenefitType ? true : false,
                    message: t(
                      "form_fields.create_idea_HEB.periodicity.error_message"
                    ),
                  },
                ]}
              >
                <Select style={{ width: "100%" }}>
                  <Option key="0" value="diario">
                    {"Diario"}
                  </Option>
                  <Option key="2" value="semanal">
                    {"Semanal"}
                  </Option>
                  <Option key="3" value="catorcena">
                    {"Catorcena"}
                  </Option>
                  <Option key="4" value="periodo">
                    {"Periodo"}
                  </Option>
                  <Option key="5" value="anual">
                    {"Anual"}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col span={12}>
              <br />
              <Form.Item>
                <Button
                  style={{ width: "100%", height: "32" }}
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  {t("buttons.save")}
                </Button>
              </Form.Item>
            </Col>
            <Col span={3}>
              <br />
              <PanelRender
                ideas={[{ ...props.idea }]}
                userInfo={props.userInfo}
              ></PanelRender>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col>
              <Alert
                message="Error"
                description={t("form_fields.create_idea_HEB.errorMessage")}
                type="error"
                closable
                style={{ display: displayError }}
              />
            </Col>
          </Row>
        </Form>
      </Row>
    </>
  );
};

export default HEBDetails;
