import React, { useState, useEffect, useCallback } from "react";
import {
  Drawer,
  Badge,
  Menu,
  Row,
  Input,
  Divider,
  Tooltip,
  Modal,
  Select,
  Avatar,
} from "antd";
import { Layout } from "antd";
import {
  FileOutlined,
  TeamOutlined,
  UserOutlined,
  CalendarOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  FireOutlined,
  SettingOutlined,
  BookOutlined,
  BookFilled,
  SearchOutlined,
  ScheduleTwoTone,
  AppstoreTwoTone,
} from "@ant-design/icons";
import { presetPalettes } from "@ant-design/colors";
import ActivitiesPanel from "./ActivitiesPanel";
import SettingsPage from "./Settings/SettingsPage";
import CalendarPage from "./Calendar/CalendarPage";
import {
  priorityMap,
  onChangeSelected,
  completedFilter,
  applyFilters,
} from "./Filters";
const clone = require("rfdc")();
const { SubMenu } = Menu;
const { Header, Sider } = Layout;
const { Search } = Input;

const KanbanLayout = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [updateStuff, setUpdateStuff] = useState(false);
  //kanban: 1, settings: 2, calendar:3
  const [view, setView] = useState(1);
  const [userVisible, setUserVisible] = useState(false);
  const [users, setUsers] = useState([]);
  const [deletedUsers, setDeletedUsers] = useState([]);
  const [userKey, setUserKey] = useState("");

  useEffect(() => {
    let tempColumns = {};
    let ideas = [];
    let tempActivities = [];
    let boards = [];
    let userInfo = {};
    if (props.ideas && props.company.todoConfig) {
      ideas = clone(props.ideas);
      boards = clone(props.company.todoConfig.boards);
      boards.map(
        (board) =>
          (tempColumns[board.value] = {
            name: board.name,
            activities: [],
            id: board.value,
            order: board.order,
          })
      );
      ideas.forEach((idea) => {
        idea.activities &&
          idea.activities.forEach((activity) =>
            tempActivities.push({ ...activity, idea: idea })
          );
      });
      userInfo = clone(props.userInfo);
      if (props.userInfo.activities) {
        userInfo.activities.forEach((activity) =>
          tempActivities.push({ ...activity, personal: true })
        );
      }
    }
    tempActivities = completedFilter(tempActivities);
    if (selected.length > 0)
      tempActivities = applyFilters(
        tempActivities,
        selected,
        userInfo,
        props.company.users,
        searchValue,
        props.company.deletedUsers,
        userKey
      );
    tempActivities.forEach((activity) =>
      tempColumns[String(activity.status)].activities.push({
        ...activity,
      })
    );
    const columnsArray = Object.values(tempColumns).sort(
      (a, b) => a.order - b.order
    );
    tempColumns = {};
    columnsArray.map((column) => {
      tempColumns[column.order + 1] = { ...column };
      return true;
    });
    setColumns({ ...tempColumns });
    setLoading(false);
    setUpdateStuff(false);
  }, [
    searchValue,
    props.ideas,
    props.company,
    props.userInfo.activities,
    selected,
    props.userInfo,
    updateStuff,
    userKey,
  ]);

  useEffect(() => {
    if (props.company) {
      let users = [];
      let colors = [];
      let deletedUsers = [];
      if (props.company.users) users = Object.values(props.company.users);
      if (props.company.deletedUsers)
        deletedUsers = Object.values(props.company.deletedUsers);
      Object.values(presetPalettes).forEach((colorSet) => {
        colors.push(colorSet.primary);
      });
      let res = (users.length + deletedUsers.length) / colors.length;
      if (res > 1) {
        for (let a = 0; a < Math.round(res); a++) {
          colors.push(...colors);
        }
      }
      // users = [ { companyId: "QrEN5Mn25tnNhdPET601", email: "juane.soto@softtek.com", firstName: "Juan", lastName1: "Soto", lastName2: "Luévano", rol: "User", status: "Active", uid: "7FVuUhLFfBUVXOfDeEo3UQvax7k1", username: "juane.soto@softtek.com", }, { companyId: "QrEN5Mn25tnNhdPET601", email: "luis.luna@softtek.com", firstName: "Luis", lastName1: "de Luna", lastName2: "Saucedo", rol: "User", status: "Active", uid: "7FVuUFfBUVXOfDeEo3UQvax7k1", username: "luis.luna@softtek.com", }, { companyId: "QrEN5Mn25tnNhdPET601", email: "javier.gutierrez@softtek.com", firstName: "Javier", lastName1: "Gutiérrez", lastName2: "Pérez", rol: "User", status: "Active", uid: "7FVuUhLFfVXOfDeEo3UQvax7k1", username: "javier.gutierrez@softtek.com", }, { companyId: "QrEN5Mn25tnNhdPET601", email: "jorge.bohorquez@softtek.com", firstName: "Jorge", lastName1: "Bohorquez", lastName2: "Ibarra", rol: "User", status: "Active", uid: "7FVuUhLFfBUVXOfDeEovax7k1", username: "jorge.bohorquez@softtek.com", }, ];
      users = users.map((user, index) => ({ ...user, color: colors[index] }));
      deletedUsers = deletedUsers.map((user, index) => ({
        ...user,
        color: colors[index],
      }));
      setUsers(users);
      setDeletedUsers(deletedUsers);
    }
  }, [props.company]);

  const handleSearch = (key) => {
    key = key.trim();
    setSearchValue(key);
    let changed = { key: "textsearch", text: key };
    const [, tempSelected] = onChangeSelected(changed, selected);
    setSelected([...tempSelected]);
  };

  const getUserAndCode = (user) => {
    return (
      user.firstName +
      " " +
      user.lastName1 +
      (user.lastName2 ? " " + user.lastName2 : null) +
      " (" +
      user.username +
      ")"
    );
  };

  const FiltersSlider = () => {
    if (view === 1)
      return (
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={() => setCollapsed(!collapsed)}
          style={{ background: "white" }}
        >
          <Row justify="center" style={{ paddingTop: 20 }}>
            <Tooltip title="Vista de calendario">
              <ScheduleTwoTone
                style={{ fontSize: 25 }}
                onClick={() => {
                  if (selected.length > 0) setSelected([]);
                  setView(3);
                }}
              />
            </Tooltip>
          </Row>
          <Divider />
          <Row justify="center">
            {collapsed ? (
              <SearchOutlined
                onClick={() => {
                  setCollapsed(!collapsed);
                }}
              />
            ) : (
              <Search
                placeholder={"Buscar"}
                size="middle"
                onSearch={handleSearch}
                defaultValue={searchValue}
              />
            )}
          </Row>
          <Modal
            title={"Asignaciones de Otro Usuario"}
            visible={userVisible}
            footer={null}
            width={500}
            onCancel={() => {
              setUserVisible(false);
            }}
          >
            <Select
              placeholder="Selecciona Usuario"
              optionLabelProp="label"
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: 400 }}
              size={"default"}
              onSelect={(key) => {
                setUserKey(key);
                const [, tempSelected] = onChangeSelected(
                  { key: "assignedToOther" },
                  selected,
                  key
                );
                setSelected([...tempSelected]);
                setUserVisible(false);
              }}
            >
              {users.map((user) => (
                <Select.Option
                  key={user.uid}
                  value={user.uid}
                  label={getUserAndCode(user)}
                >
                  <Avatar style={{ backgroundColor: user.color }}>
                    {user.firstName[0].toUpperCase() +
                      user.lastName1[0].toUpperCase()}
                  </Avatar>
                  {" " + getUserAndCode(user)}
                </Select.Option>
              ))}
              {deletedUsers.map((user) => (
                <Select.Option
                  key={user.uid}
                  value={user.uid}
                  label={getUserAndCode(user)}
                >
                  <Avatar style={{ backgroundColor: user.color }}>
                    {user.firstName[0].toUpperCase() +
                      user.lastName1[0].toUpperCase()}
                  </Avatar>
                  {" " + getUserAndCode(user)}
                </Select.Option>
              ))}
            </Select>
          </Modal>
          <Menu
            theme="light"
            multiple={true}
            mode="inline"
            onClick={(changed) => {
              if (
                changed.key !== "settings" &&
                changed.key !== "assignedToOther"
              ) {
                if (changed.key === "reset") setSearchValue("");
                const [, tempSelected] = onChangeSelected(
                  changed,
                  selected,
                  userKey
                );
                setSelected([...tempSelected]);
              }
            }}
            selectedKeys={selected}
          >
            <SubMenu key="personal" icon={<UserOutlined />} title="Personal">
              <Menu.Item key="assignedToMe" onClick={() => setUserKey("")}>
                Mis asignaciones
              </Menu.Item>
              <Menu.Item key="showpersonal" onClick={() => setUserKey("")}>
                Personales
              </Menu.Item>
              <Menu.Item key="hidepersonal" onClick={() => setUserKey("")}>
                Ocultar personales
              </Menu.Item>
              <Menu.Item
                key="assignedToOther"
                onClick={() => setUserVisible(true)}
              >
                Asignaciones de Usuarios
              </Menu.Item>
            </SubMenu>
            <SubMenu key="priority" icon={<TeamOutlined />} title="Prioridad">
              {priorityMap.map((priority) => (
                <Menu.Item key={priority.label}>
                  <Badge color={priority.color} text={priority.label} />
                </Menu.Item>
              ))}
            </SubMenu>
            <SubMenu key="date" icon={<CalendarOutlined />} title="Fecha">
              <Menu.Item key="ascdate" icon={<CaretUpOutlined />}>
                Orden Ascendente
              </Menu.Item>
              <Menu.Item key="descdate" icon={<CaretDownOutlined />}>
                Orden Descendente
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="priorities"
              icon={<FireOutlined />}
              title="Prioridades"
            >
              <Menu.Item key="ascpriority" icon={<CaretUpOutlined />}>
                Orden Ascendente
              </Menu.Item>
              <Menu.Item key="descpriority" icon={<CaretDownOutlined />}>
                Orden Descendente
              </Menu.Item>
            </SubMenu>
            <Menu.Item
              key="dateless"
              icon={
                selected.includes("dateless") ? (
                  <BookFilled />
                ) : (
                  <BookOutlined />
                )
              }
            >
              {selected.includes("dateless") ? "Todas" : "Sin Fecha"}
            </Menu.Item>
            <Menu.Item key="reset" icon={<FileOutlined />}>
              Borrar filtros
            </Menu.Item>
            <Menu.Item
              key="settings"
              title="Configuración"
              icon={<SettingOutlined />}
              onClick={() => setView(2)}
            >
              Configuración
            </Menu.Item>
          </Menu>
        </Sider>
      );
    else if (view === 3)
      return (
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={() => setCollapsed(!collapsed)}
          style={{ background: "white" }}
        >
          <Row justify="center" style={{ paddingTop: 20 }}>
            <Tooltip title="Vista de Kanban">
              <AppstoreTwoTone
                style={{ fontSize: 25 }}
                onClick={() => setView(1)}
              />
            </Tooltip>
          </Row>
          <Divider />
        </Sider>
      );
    else return null;
  };

  const update = useCallback(() => {
    setUpdateStuff(true);
  }, []);

  const loadContent = () => {
    if (view === 2)
      return (
        <SettingsPage
          loading={loading}
          columns={columns}
          company={props.company}
          ideas={props.ideas}
          userInfo={props.userInfo}
          setColumns={setColumns}
          closeSettings={setView}
        />
      );
    else if (view === 3)
      return (
        <CalendarPage
          columns={columns}
          company={props.company}
          ideas={props.ideas}
          userInfo={props.userInfo}
        />
      );
    else
      return (
        <ActivitiesPanel
          loading={loading}
          columns={columns}
          company={props.company}
          ideas={props.ideas}
          userInfo={props.userInfo}
          setColumns={setColumns}
          update={update}
        />
      );
  };

  return (
    <Drawer
      style={{ margin: 0, border: 0, padding: 0 }}
      bodyStyle={{ margin: 0, border: 0, padding: 0 }}
      width="100vw"
      onClose={() => {
        props.onClose(false);
        setView(1);
      }}
      visible={props.visible}
      footer={null}
      destroyOnClose
    >
      <Layout style={{ width: "100vw", height: "100vh" }}>
        <Header
          style={{
            paddingLeft: 25,
            background:
              "url('https://firebasestorage.googleapis.com/v0/b/rpa-manager.appspot.com/o/iconos%2FRPA_Banner_72dpi-01.png?alt=media&token=b44f001b-907b-46d4-8200-6b5524e0eff0')",
            borderBottom: "1px solid lightgray",
            backgroundSize: "auto",
          }}
        >
          <img
            key="img"
            src="https://firebasestorage.googleapis.com/v0/b/rpa-manager.appspot.com/o/iconos%2Frpa-manager.png?alt=media&token=87ae54b7-1c1f-4f2f-894d-5540d4ae5076"
            href="/"
            alt="RPA MANAGER"
            style={{ height: "24px" }}
          />
        </Header>
        <Layout>
          <FiltersSlider />
          {loadContent()}
        </Layout>
      </Layout>
    </Drawer>
  );
};

export default KanbanLayout;
