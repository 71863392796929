import React, { useEffect, useState } from "react";
// Importing Spanish language for moment
import "moment/locale/es";
import { Calendar, Layout, Typography, Col, Row, Badge, Card } from "antd";
import moment from "moment";
import { priorityMap } from "../Filters";
import ActivityModal from "../ActivityModal";

const clone = require("rfdc")();
// Setting moment language to spanish
moment.updateLocale("es", null);
const today = moment();

const { Content } = Layout;

const CalendarPage = ({ columns, company, ideas, userInfo }) => {
  const [activities, setActivities] = useState([]);
  const [filteredActivities, setFilteredActivities] = useState([]);
  const [activeDate, setActiveDate] = useState(today);
  const [calendarMode, setCalendarMode] = useState("month");
  const [activityModal, setActivityModal] = useState(false);
  const [editActivity, setEditActivity] = useState();

  useEffect(() => {
    if (columns) {
      let tempActivities = [];
      //   Transform columns into arrays
      Object.values(columns)
        //   Mapping to just activities
        .map(({ activities }) => activities)
        .forEach((array) => {
          //    Joining all activities together into just one array
          tempActivities = [
            ...tempActivities,
            ...array
              //    and filtering the ones that have endDate
              .filter((activity) => activity.endDate && activity.status !== 4)
              //   also creating a moment object in every activity's end date to work with
              //   moment methods and setting them to the activities hook
              .map((activity) => ({
                ...activity,
                momentEndDate: moment.unix(activity.endDate.seconds),
              })),
          ];
        });
      setActivities(tempActivities);
      filterActivities(today, null, tempActivities);

      return () => {
        setActiveDate(today);
        setActivities([]);
      };
    }
    // eslint-disable-next-line
  }, [columns]);

  const openActivityModal = (activity) => {
    const tempActivity = clone(activity);
    delete tempActivity.momentEndDate;
    setEditActivity({
      ...tempActivity,
      personal: tempActivity.idea ? false : true,
    });
    setActivityModal(true);
  };

  // To set the filteredActivities hook
  const filterActivities = (date, mode, array) => {
    // this two lines are necesary to ensure we use the actual value of these
    // hooks and not a previous one
    const actualMode = mode ? mode : calendarMode;
    const activitySet = array ? array : activities;
    if (actualMode === "month") {
      const tempActivities = activitySet.filter(
        ({ momentEndDate }) => momentEndDate.format("L") === date.format("L")
      );
      setFilteredActivities(tempActivities);
    } else {
      const tempActivities = activitySet.filter(
        ({ momentEndDate }) =>
          momentEndDate.month() === date.month() &&
          momentEndDate.year() === date.year()
      );
      setFilteredActivities(tempActivities);
    }
  };

  // To get the activities to render in the calendar
  const filterRender = (value) => {
    if (calendarMode === "year")
      return activities.filter(
        ({ momentEndDate }) =>
          momentEndDate.month() === value.month() &&
          momentEndDate.year() === value.year()
      );
    else
      return activities.filter(
        ({ momentEndDate }) => momentEndDate.format("L") === value.format("L")
      );
  };

  const dateCellRender = (value) => {
    const listData = filterRender(value);
    const priorityBadges = priorityMap.map((priorityBadge) => ({
      ...priorityBadge,
      // If it finds at least one it does the filter, else sets the count to 0
      // so the badge doesn´t eve appear
      count: listData.find(({ priority }) => priority === priorityBadge.value)
        ? listData.filter(({ priority }) => priority === priorityBadge.value)
            .length
        : 0,
    }));
    if (listData.length > 0)
      return (
        <div style={{ marginLeft: 20 }}>
          {priorityBadges.map(({ count, color, label }) => (
            <Badge
              title={label}
              count={count}
              style={{
                height: 23,
                minWidth: 23,
                maxWidth: 30.1,
                fontSize: count > 99 ? 10 : 13,
                backgroundColor: color,
              }}
              // since the color is unique we can use it as key
              key={color}
            />
          ))}
        </div>
      );
    else return null;
  };

  const CalendarCardTitle = () => {
    return (
      <>
        <Typography.Text>
          {calendarMode === "year" ? "Mi mes" : "Mi día"}
        </Typography.Text>
        <br />
        <Typography.Text type="secondary" style={{ fontSize: "10px" }}>
          {calendarMode === "year"
            ? activeDate.format("MMMM [de] YYYY")
            : activeDate.format("dddd, D [de] MMMM [de] YYYY")}
        </Typography.Text>
      </>
    );
  };

  return (
    <Content style={{ overflow: "auto" }}>
      <ActivityModal
        visible={activityModal}
        setVisible={setActivityModal}
        setActivity={setEditActivity}
        activity={editActivity}
        company={company}
        ideasOptions={ideas}
        modifiers={{ origin: "ActivitiesPanel" }}
        setModifiers={() => false}
        userInfo={userInfo}
      />
      <Row style={{ width: "100%", height: "100%" }}>
        <Col span={18}>
          <Calendar
            className="calendar-box"
            value={activeDate}
            onSelect={(value) => {
              setActiveDate(value);
              filterActivities(value);
            }}
            onPanelChange={(date, mode) => {
              setCalendarMode(mode);
              filterActivities(activeDate, mode);
            }}
            dateCellRender={dateCellRender}
            monthCellRender={dateCellRender}
          />
        </Col>
        <Col span={6}>
          <Card
            className="calendar-card"
            title={CalendarCardTitle()}
            bodyStyle={{ margin: 0, border: 0, padding: 0 }}
          >
            {filteredActivities.map((activity) => {
              const priority = priorityMap.find(
                ({ value }) => value === activity.priority
              );
              return (
                // div because Card.Grid doesn't have the onClick prop
                <div
                  key={activity.id}
                  onClick={() => openActivityModal(activity)}
                >
                  <Card.Grid
                    hoverable
                    style={{ width: "100%", cursor: "pointer" }}
                    key={activity.id}
                  >
                    <Card.Meta
                      description={
                        <>
                          <Badge color={priority.color} />
                          <Typography.Text>{activity.title}</Typography.Text>
                        </>
                      }
                    />
                    <Typography.Text
                      type="secondary"
                      style={{ fontSize: "10px" }}
                    >
                      {activity.personal
                        ? "Personal"
                        : activity.idea.idABANCA
                        ? activity.idea.idABANCA + ". " + activity.idea.name
                        : activity.idea.name}
                    </Typography.Text>
                  </Card.Grid>
                </div>
              );
            })}
          </Card>
        </Col>
      </Row>
    </Content>
  );
};

export default CalendarPage;
