import React, { useState, useEffect } from "react";
import {
  Select,
  Form,
  Input,
  Button,
  Row,
  Col,
  DatePicker,
  message,
  InputNumber,
  Divider,
  Checkbox,
} from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import firebase from "../../../../../server/firebase";
import { useTranslation } from "react-i18next";
import moment from "moment";

const CostBenefit = (props) => {
  const { t } = useTranslation();
  const [costBenefitForm] = Form.useForm();
  const [tiposAhorro, setTiposAhorro] = useState([]);
  const [tiposFiltrados, setTiposFiltrados] = useState([]);
  const [calendarios, setCalendarios] = useState([]);
  const format = "DD/MM/YYYY";
  const [esProduccion, setEsProduccion] = useState(false);
  const [esPrioritaria, setEsPrioritaria] = useState(false);

  useEffect(() => {
    if (props.company && props.ideaData) {
      if (props.ideaData.status === 3) {
        setEsProduccion(true);
      }
      setEsPrioritaria(props.ideaData.prioritaria === true);
      let tipos = [];
      Object.values(props.company.tipoAhorro).forEach((ta) => {
        tipos.push({ label: ta, value: ta });
      });
      setTiposAhorro(tipos);
      setTiposFiltrados(tipos);
      tipos = [];
      Object.values(props.company.calendarios).forEach((calendar) => {
        tipos.push({ label: calendar, value: calendar });
      });
      setCalendarios(tipos);
    }
    let idea = props.ideaData;
    let attributesArray = Object.keys(idea);
    const formAttributes = [
      "paseProduccion",
      "tipoTarea",
      "usuarioRobot",
      "equipoPredeterminado",
      "idBluePrism",
      "calendario",
      "costeTotal",
      "ahorroExterno",
      "tiposAhorro",
      "indicadores",
    ];
    formAttributes.forEach((a) => {
      if (attributesArray.indexOf(a) === -1) {
        if (a === "tiposAhorro" || a === "indicadores") idea[a] = [];
        else idea[a] = null;
      }
    });
    if (idea.paseProduccion)
      idea.paseProduccion = moment(
        getDateString(props.ideaData.paseProduccion),
        format
      );
    costBenefitForm.setFieldsValue({ ...idea });
    // eslint-disable-next-line
  }, [props.ideaData, props.company]);

  const addTiposAhorro = (tAhorros) => {
    let bool = true;
    let tempPercentage = 0;
    Object.values(tAhorros).forEach((e) => {
      if (e && e.porcentaje && e.tipoAhorro && tempPercentage <= 100) {
        tempPercentage += Number(e.porcentaje);
      } else {
        message.warning(
          "Se tienen que seleccionar un tipo de ahorro y un porcentaje para agregar uno nuevo"
        );
        return (bool = false);
      }
    });
    if (tempPercentage === 100) {
      message.warning("Los valores de porcentaje ya suman 100");
      return (bool = false);
    }
    return bool;
  };

  const getDateString = (time) => {
    return new Date(
      time.seconds * 1000 + time.nanoseconds / 1000000
    ).toLocaleDateString("en-GB");
  };

  const addIndicador = (indicadores) => {
    let bool = true;
    Object.values(indicadores).forEach((i) => {
      if (!i || !i.indicador || !i.tiempoUnitario) {
        message.warning(
          "Se tienen que seleccionar un Indicador y un tiempo para agregar uno nuevo"
        );
        return (bool = false);
      }
    });
    return bool;
  };

  const updateTiposAhorro = (selectedList) => {
    let tipos = tiposAhorro;
    selectedList.forEach((e) => {
      if (e) tipos = tipos.filter((tipo) => tipo.value !== e.tipoAhorro);
    });
    setTiposFiltrados(tipos);
  };

  const saveCostBenefit = (values) => {
    let paseProduccionDate = values.paseProduccion.unix();
    var flag = false;
    values.prioritaria = esPrioritaria;
    //hace el update con la bdd realtime
    Object.entries(values).forEach((k) => {
      if (k[0] === "tiposAhorro") {
        var sumaPorcentaje = 0;
        k[1].forEach((obj) => {
          sumaPorcentaje += parseInt(obj.porcentaje);
        });
        console.log("sumaPorcentaje: ", sumaPorcentaje);
        if (sumaPorcentaje !== 100) {
          message.error("Los porcentajes de tipos de ahorro deben sumar 100");
          flag = true;
        }
      }
      if (k[1] === undefined) values[k[0]] = null;
    });
    if (flag) {
      return;
    }
    if (document.activeElement.id === "guardar") {
      firebase
        .database()
        .ref()
        .child("ideas")
        .child(props.ideaData.id)
        .update({
          ...values,
          paseProduccion: { seconds: paseProduccionDate, nanoseconds: 0 },
        })
        .then(() => message.success(t("Información guardada")))
        .catch((err) => {
          message.error(t("La información no pudo ser guardada"));
          console.error(err);
        });
    } else if (document.activeElement.id === "pasar") {
      firebase
        .database()
        .ref()
        .child("ideas")
        .child(props.ideaData.id)
        .update({
          ...values,
          status: 3,
          paseProduccion: { seconds: paseProduccionDate, nanoseconds: 0 },
        })
        .then(() => message.success(t("Información guardada")))
        .catch((err) => {
          message.error(t("La información no pudo ser guardada"));
          console.error(err);
        });
    }
  };

  /* const goFlightControl = async () => {
    let url = "https://flightcontrol.online/processdetails";
    let idsToken = [];
    if (props.ideaData && props.ideaData.idBluePrism) {
      let id = props.ideaData.idBluePrism;
      id = id.replace("{", "");
      id = id.replace("}", "");
      if (props.userInfo.idsToken) {
        idsToken = props.userInfo.idsToken;
        if (idsToken.findIndex((element) => element === id) === -1) {
          idsToken.push(id);
        }
      } else {
        idsToken.push(id);
      }

      props.userInfo.idsToken = idsToken;

      await firebase
        .database()
        .ref()
        .child("users")
        .orderByChild("uid")
        .equalTo(props.userInfo.uid)
        .limitToFirst(1)
        .once("value")
        .then((snapshot) => {
          firebase
            .database()
            .ref()
            .child("users")
            .child(Object.keys(snapshot.val())[0])
            .update({ idsToken: idsToken });
        });

      url += "?ID=" + id + "&token=" + props.userInfo.token;
      window.open(url);
    }
  }; */

  return (
    <Form
      form={costBenefitForm}
      name="productionForm"
      labelAlign="left"
      onFinish={saveCostBenefit}
    >
      <Row gutter={[40, 20]}>
        <Col span={24}>
          <Row gutter={[40, 20]}>
            <Col span={6}>
              <Form.Item
                name="paseProduccion"
                label={t("Fecha de Pase a Producción")}
                rules={[
                  {
                    required: true,
                    message: t("Campo requerido"),
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder={t("Seleccionar fecha")}
                  format={format}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="prioritaria" label={"¿Es Prioritaria?"}>
                <Checkbox
                  checked={esPrioritaria}
                  onChange={() => {
                    setEsPrioritaria(!esPrioritaria);
                  }}
                >
                  Prioritaria
                </Checkbox>
              </Form.Item>
            </Col>
            {/* <Col span={6}>
              <Button
                disabled={
                  props.ideaData && props.ideaData.idBluePrism ? false : true
                }
                onClick={() => goFlightControl()}
                style={{
                  color: "black",
                  backgroundColor: "#FFFAF0",
                  marginTop: "40px",
                }}
              >
                Flight Control
              </Button>
            </Col> */}
          </Row>
          <Row gutter={[40, 20]}>
            <Col span={6}>
              <Form.Item
                name="usuarioRobot"
                label={t("Usuario Robot")}
                rules={[
                  {
                    required: true,
                    message: t("Campo requerido"),
                  },
                ]}
              >
                <Input type="text" placeholder={t("Usuario Robot")} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="equipoPredeterminado"
                label={t("Equipo predeterminado")}
              >
                <Input placeholder={t("Equipo predeterminado")} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[40, 20]}>
            <Col span={6}>
              <Form.Item
                name="idBluePrism"
                label="ID Blueprism"
                rules={[
                  {
                    required: true,
                    message: t("Campo requerido"),
                  },
                ]}
              >
                <Input placeholder="ID Blueprism" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="calendario"
                label={"Calendario"}
                rules={[
                  {
                    required: true,
                    message: t("Campo requerido"),
                  },
                ]}
              >
                <Select placeholder={"Calendario"} options={calendarios} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="costeTotal" label={"Coste Total"}>
                <InputNumber
                  min={0}
                  style={{ width: "100%" }}
                  placeholder={"Coste total"}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="ahorroExterno" label={"Ahorro Externo"}>
                <Input type="text" placeholder={"Ahorro externo"} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={16}>
            <Col span={12}>
              <Divider type="horizontal">{t("Tipos de ahorro")}</Divider>
              <Form.List name="tiposAhorro">
                {(fields, { add, remove }) => {
                  if (fields < 1) add();
                  return fields.map((field, index) => (
                    <Row
                      style={{ paddingTop: "40px" }}
                      gutter={[20, 40]}
                      key={field.key}
                    >
                      <Col span={10}>
                        <Form.Item
                          {...field}
                          name={[field.name, "tipoAhorro"]}
                          fieldKey={[field.fieldKey, "tipoAhorro"]}
                          rules={[
                            { required: true, message: t("Campo requerido") },
                          ]}
                        >
                          <Select
                            style={{ width: "100%", cursor: "pointer" }}
                            options={tiposFiltrados}
                            placeholder={t("Tipo de ahorro")}
                            onChange={() => {
                              updateTiposAhorro(
                                costBenefitForm.getFieldValue("tiposAhorro")
                              );
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item
                          {...field}
                          name={[field.name, "porcentaje"]}
                          fieldKey={[field.fieldKey, "porcentaje"]}
                          rules={[
                            { required: true, message: t("Campo requerido") },
                          ]}
                        >
                          <Input
                            type="number"
                            placeholder={"Porcentaje"}
                            min={1}
                            max={100}
                            style={{ width: "100%" }}
                            suffix="%"
                          />
                        </Form.Item>
                      </Col>
                      {index + 1 === fields.length ? (
                        <>
                          {fields.length > 1 && (
                            <Col span={1}>
                              <MinusCircleOutlined
                                onClick={() => remove(field.name)}
                              />
                            </Col>
                          )}
                          <Col span={1}>
                            <Button
                              type="primary"
                              onClick={() => {
                                addTiposAhorro(
                                  costBenefitForm.getFieldValue("tiposAhorro"),
                                  index
                                ) && add();
                              }}
                            >
                              +
                            </Button>
                          </Col>
                        </>
                      ) : (
                        <Col span={1}>
                          <MinusCircleOutlined
                            onClick={() => remove(field.name)}
                          />
                        </Col>
                      )}
                    </Row>
                  ));
                }}
              </Form.List>
            </Col>
            <Col span={12}>
              <Divider type="horizontal">{t("Indicadores")}</Divider>
              <Form.List name="indicadores">
                {(fields, { add, remove }) => {
                  if (fields < 1) add();
                  return fields.map((field, index) => (
                    <Row gutter={[20, 40]} key={field.key}>
                      <Col span={11}>
                        <Form.Item
                          {...field}
                          name={[field.name, "indicador"]}
                          fieldKey={[field.fieldKey, "indicador"]}
                          rules={[
                            { required: true, message: t("Campo requerido") },
                          ]}
                        >
                          <Input
                            style={{ width: "100%" }}
                            placeholder={t("Indicador")}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={11}>
                        <Form.Item
                          {...field}
                          name={[field.name, "tiempoUnitario"]}
                          fieldKey={[field.fieldKey, "tiempoUnitario"]}
                          min={1}
                          rules={[
                            { required: true, message: t("Campo requerido") },
                          ]}
                        >
                          <Input
                            type="number"
                            placeholder={t("Tiempo unitario")}
                            min={0}
                            step={0.1}
                            style={{ width: "100%" }}
                            suffix="min"
                          />
                        </Form.Item>
                      </Col>
                      {index + 1 === fields.length ? (
                        <>
                          {fields.length > 1 && (
                            <Col span={1}>
                              <MinusCircleOutlined
                                onClick={() => remove(field.name)}
                              />
                            </Col>
                          )}
                          <Col span={1}>
                            <Button
                              type="primary"
                              onClick={() => {
                                addIndicador(
                                  costBenefitForm.getFieldValue("indicadores")
                                ) && add();
                              }}
                            >
                              +
                            </Button>
                          </Col>
                        </>
                      ) : (
                        <Col span={1}>
                          <MinusCircleOutlined
                            onClick={() => remove(field.name)}
                          />
                        </Col>
                      )}
                    </Row>
                  ));
                }}
              </Form.List>
            </Col>
          </Row>
          <Row gutter={[40, 20]}>
            <Col span={24}>
              <Form.Item>
                <Button
                  type="default"
                  id="guardar"
                  htmlType="submit"
                  style={{ float: "right" }}
                >
                  {t("Guardar")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
          {!esProduccion && (
            <>
              <br></br>
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    id="pasar"
                    style={{ float: "right" }}
                  >
                    Guardar y Pasar a Producción
                  </Button>
                </Form.Item>
              </Col>
            </>
          )}
          <br></br>
        </Col>
      </Row>
    </Form>
  );
};

export default CostBenefit;
