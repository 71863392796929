import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Drawer, message } from "antd";
import moment from "moment";
import { Button, Table, Space, Modal, Form, Input } from "antd";
import "../../../../../../App.css";
import CompanyIdeaDetails from "../../../Details/CompanyIdeaDetails";
import { approveButton, rejectButton } from "./InboxValidations";
import InputSearch from "../../InputSearch";
import { Resizable } from "react-resizable";
import ExcelDownload from "../../../../../CustomComponents/ABANCA/ExcelDownload";

const Inbox = (props) => {
  const [inboxData, setInboxData] = useState(null);
  const [commentForm] = Form.useForm();
  const apiURL =
    "https://us-central1-rpa-manager.cloudfunctions.net/addComment?";

  const [showDatailsDrawer, setShowDetailsDrawer] = useState({
    idea: null,
    visible: false,
    idIdea: null,
  });

  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      className: "clickable-col",
      render: (name, record) => (
        <span
          style={{ padding: "13px 13px 6px 6px", cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {name}
        </span>
      ),
      width: "40%",
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
    },
    {
      title: "Fecha creación",
      dataIndex: "createdDate",
      render: (createdDate) =>
        createdDate
          ? moment.unix(createdDate.seconds).format("DD/MM/YYYY")
          : "",
      width: "15%",
      sorter: {
        compare: (a, b) => a.createdDate.seconds - b.createdDate.seconds,
      },
    },
    {
      title: "Potencial RPA",
      dataIndex: "potencialRPA",
      sortDirections: ["descend", "ascend"],
      width: "20%",
      sorter: {
        compare: (a, b) =>
          a.priority
            ? b.priority
              ? a.priority - b.priority
              : a.priority - 0
            : b.priority
            ? 0 - b.priority
            : 0 - 0,
      },
      render: (potencial) =>
        potencial && (
          <span style={{ textAlign: "center" }}>{`${potencial}%`}</span>
        ),
    },
    {
      title: "",
      key: "action",
      fixed: "right",
      width: "30%",
      render: (text, record) => {
        return (
          <Space size={"middle"} width={"100%"}>
            <Button
              style={{ width: "100px" }}
              key={record.id}
              className={"ant-btn"}
              onClick={(e) => handleApproveButton(record, e)}
            >
              Aprobar
            </Button>
            <Button
              style={{ width: "100px" }}
              key={record.id}
              className={"ant-btn-dangerous"}
              onClick={(e) => handleRejectButton(record, e)}
            >
              Rechazar
            </Button>
          </Space>
        );
      },
    },
  ];

  const Loader = () => (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        textAlign: "center",
        marginTop: "25%",
      }}
    >
      <Spin size="large" spinning={true}></Spin>
    </div>
  );

  const ResizableTitle = (props) => {
    const { onResize, width, ...restProps } = props;

    if (!width) {
      return <th {...restProps} />;
    }

    return (
      <Resizable
        width={width}
        height={0}
        handle={
          <span
            className="react-resizable-handle"
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        }
        onResize={onResize}
        draggableOpts={{ enableUserSelectHack: false }}
      >
        <th {...restProps}></th>
      </Resizable>
    );
  };

  const onClose = () => {
    setShowDetailsDrawer({ idea: null, visible: false, idIdea: null });
  };

  const commentFormRender = () => {
    return (
      <Form form={commentForm}>
        <Form.Item name="comment">
          <Input />
        </Form.Item>
      </Form>
    );
  };

  const onOkCommentForm = async (type, idea) => {
    const mailId = props.userInfo.email.split("@")[0];
    let comment = type ? "Petición aprobada.+" : "Petición rechazada.+";
    comment +=
      commentForm.getFieldValue("comment") &&
      commentForm.getFieldValue("comment").replaceAll(" ", "+");
    const name =
      props.userInfo &&
      props.userInfo.firstName.replaceAll(" ", "+") +
        "+" +
        props.userInfo.lastName1.replaceAll(" ", "+") +
        (props.userInfo.lastName2
          ? "+" + props.userInfo.lastName2.replaceAll(" ", "+")
          : null);
    const apiCall = `${apiURL}id=${idea.id}&comentario=${comment}&mailId=${mailId}&nombre=${name}&tipo=nada`;
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      mode: "no-cors",
    };
    await fetch(apiCall, requestOptions)
      .then(() => {
        handleMail(
          idea,
          type ? "Aprobada" : "Rechazada",
          commentForm.getFieldValue("comment")
        );
        let msg = type ? approveButton(idea, props.ideas) : rejectButton(idea);
        message.success(msg);
      })
      .catch((error) => {
        console.log(error);
        message.error("Error al actualizar la petición");
      });
  };

  const handleApproveButton = (idea, event) => {
    event.preventDefault();
    if (idea.priority >= 0 && idea.priority <= 5) {
      Modal.confirm({
        title: "Añade un comentario para ACEPTAR la petición (no obligatorio)",
        content: commentFormRender(),
        okText: "Aceptar Petición",
        cancelText: "Cancelar",
        onOk: () => onOkCommentForm(true, idea),
        onCancel: () => Modal.destroyAll(),
        afterClose: () => commentForm.resetFields(),
        width: "600px",
        centered: true,
      });
    } else {
      message.warning(
        "Para aprobar la idea debe completarse el cuestionario de justificación de idea en los detalles de la misma"
      );
    }
  };

  const handleRejectButton = (idea, event) => {
    event.preventDefault();
    Modal.confirm({
      title: "Añade un comentario para RECHAZAR la petición (no obligatorio)",
      content: commentFormRender(),
      okText: "Rechazar Petición",
      cancelText: "Cancelar",
      onOk: () => onOkCommentForm(false, idea),
      onCancel: () => Modal.destroyAll(),
      afterClose: () => commentForm.resetFields(),
      width: "600px",
      centered: true,
    });
  };

  const handleRowClick = (row) => {
    setShowDetailsDrawer({ visible: true, idea: row, idIdea: row.id });
  };

  const handleMail = (idea, ideaResult, comment) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    comment ? (comment = "<p>" + comment + "</p>\n") : (comment = "");

    /* var raw =
      '{\n SenderName: "RPA-MANAGER",\n SenderMail:"noreply@abanca.rerobot.ai",\n To: ["' + 
      mail +
      '"],\n Subject:"' +
      ideaResult +
      '" ,\n isHTML: "true",\n HTMLBody:"<h3>Le informamos que la idea: ' +
      idea.name +
      " ha sido " +
      ideaResult.toLowerCase() +
      '</h3>",\n}'; */
    var reciben = [idea.createdBy.email, props.userInfo.email];

    reciben.forEach((mail) => {
      var raw =
        '{\n SenderName: "RPA-MANAGER",\n SenderMail:"noreply@rerobot.com",\n To: ["' +
        mail +
        '"],\n Subject:"' +
        ideaResult +
        '" ,\n isHTML: "true",\n HTMLBody:"<h3>Le informamos que la petición ' +
        idea.name +
        " ha sido " +
        ideaResult.toLowerCase() +
        "</h3>\n" +
        comment +
        '",\n}';

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://automationplatform.azurewebsites.net/api/mailnotification",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    });
  };

  useEffect(() => {
    if (props.ideas) {
      let ideas = [];
      props.ideas.forEach(
        (doc) =>
          doc.status === -1 &&
          ideas.push({
            ...doc,
            id: doc.id,
          })
      );
      setInboxData(ideas);
      setTableState({ data: ideas });

      if (showDatailsDrawer.visible) {
        let index = props.ideas.findIndex(
          (idea) => idea.id === showDatailsDrawer.idIdea
        );
        setShowDetailsDrawer({
          visible: true,
          idea: props.ideas[index],
          idIdea: props.ideas[index].id,
        });
      }
    }
  }, [props.ideas, showDatailsDrawer.visible, showDatailsDrawer.idIdea]);

  const tableComponents = {
    header: {
      cell: ResizableTitle,
    },
  };
  const [tableState, setTableState] = useState({
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
  });

  const handleOnKeyChange = (key) => {
    if (!key) setTableState({ data: inboxData });

    key = key.toLowerCase();
    const filteredData = inboxData.filter(
      (entry) =>
        entry.name.toLowerCase().includes(key) ||
        (entry.idABANCA && entry.idABANCA.toString().includes(key)) ||
        entry.direccion.toLowerCase().includes(key) ||
        entry.dir_general.toLowerCase().includes(key)
    );

    setTableState({ data: filteredData });
  };

  return inboxData ? (
    <div style={{ padding: 10 }}>
      <Drawer
        style={{ background: "white" }}
        theme="light"
        width="100vw"
        onClose={onClose}
        visible={showDatailsDrawer.visible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          ></div>
        }
        destroyOnClose
      >
        <CompanyIdeaDetails
          company={props.company}
          onCloseDrawer={onClose}
          userInfo={props.userInfo}
          companyName={props.companyName}
          mappings={props.mappings}
          idea={showDatailsDrawer.idea}
        />
      </Drawer>
      <Row gutter={[20, 30]}>
        <Col span={24}>
          <Row>
            <Col span={6}>
              <InputSearch handleOnKeyChange={handleOnKeyChange} />
            </Col>
            <Col span={16}></Col>
            <Col span={2}>
              <ExcelDownload
                data={inboxData}
                name={"Recibidas"}
                id={"excel3"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Table
                pagination={{
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }}
                bordered
                components={tableComponents}
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={tableState.data}
                scroll={{ x: 1300 }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  ) : (
    <Loader />
  );
};

export default Inbox;
