import React, { useState, useEffect } from "react";
import { Line } from "@ant-design/charts";

const LineChart = (props) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (props.chartData) {
      setData(props.chartData);
    }
  }, [props.chartData]);

  const config = {
    data,
    height: 400,
    xField: "mes",
    yField: "OTD",
    point: {
      size: 5,
      shape: "circle",
    },
    label: {},
  };
  return <Line {...config} />;
};
export default LineChart;
