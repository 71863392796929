import React from "react";
import { Form, Row, Col, Typography, Card, Slider, Button } from "antd";
import CalculatorJson from "./calculator.json";

const calculator = CalculatorJson.home;

const { Item } = Form;

const MainInformation = ({ nextStep, resetAll }) => {
  const questions = [
    "consistency",
    "repetitive",
    "based_rules",
    "standardization",
    "data_format",
    "volume_information",
    "system_stability",
    "maturity",
  ];

  const initialFormValues = {
    consistency: 0,
    repetitive: 0,
    based_rules: 0,
    standardization: 0,
    data_format: 0,
    volume_information: 0,
    system_stability: 0,
    maturity: 0,
  };

  const marks = {
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    10: 10,
  };

  const onFinish = (values) => {
    // console.log(values);
    nextStep();
  };

  return (
    <Card
      type="inner"
      title={
        <Typography.Title level={5} className="primary-color">
          0. Información Básica
        </Typography.Title>
      }
    >
      <Row>
        <Form
          name="basic"
          labelCol={12}
          layout="vertical"
          size="large"
          initialValues={initialFormValues}
          onFinish={onFinish}
        >
          <Row gutter={[12, 24]}>
            {questions.map((question, index) => (
              <Col
                span={12}
                key={question}
                style={{ padding: "0 50px 0 50px" }}
              >
                <Item
                  name={question}
                  label={`${index + 1}. ` + calculator[`${question}_question`]}
                >
                  <Slider marks={marks} step={10} min={0} max={10} />
                </Item>
              </Col>
            ))}
            <Col
              span={24}
              style={{ padding: "0 50px 0 50px", marginTop: "40px" }}
            >
              <Row justify="end">
                <Button
                  htmlType="submit"
                  shape="round"
                  className="button-submit-secondary submit-fixed submit-secondary-spaced"
                  onClick={resetAll}
                >
                  Cancelar
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  shape="round"
                  className="button-submit submit-fixed"
                >
                  Siguiente
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Row>
    </Card>
  );
};

export default MainInformation;
