import React from "react";
import {
  Row,
  Col,
  Form,
  Checkbox,
  Space,
  Button,
  Card,
  Typography,
  InputNumber,
} from "antd";
import CalculatorJson from "./calculator.json";

const { Item } = Form;

const calculator = CalculatorJson.home;

const initialFormValues = {
  screens: 0,
  login: false,
  generationExcel: false,
  transformationExcel: false,
  macrosExcel: false,
  generationMail: false,
  attachMail: false,
  downloadMail: false,
};

const Interaction = ({ nextStep, resetAll }) => {
  const [form] = Form.useForm();

  const onFinish = () => {
    nextStep();
  };

  return (
    <Card
      type="inner"
      title={
        <Typography.Title level={5} className="primary-color">
          {calculator.interaction_title}
        </Typography.Title>
      }
    >
      <Row>
        <Form
          name="interaction"
          labelCol={12}
          layout="vertical"
          size="large"
          form={form}
          onFinish={onFinish}
          initialValues={initialFormValues}
          requiredMark={false}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Item
                    name="screens"
                    label={calculator.interaction_question_one}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Campo Requerido",
                      },
                    ]}
                  >
                    <InputNumber min={0} />
                  </Item>
                </Col>
                <Col span={24}>
                  <Space size="middle">
                    {calculator.interaction_question_two}
                    <Item valuePropName="checked" name={"login"}>
                      <Checkbox />
                    </Item>
                  </Space>
                </Col>
                <Col span={24}>{calculator.interaction_question_three}</Col>
                {[
                  { name: "generation", value: "generationExcel" },
                  { name: "transformation", value: "transformationExcel" },
                  { name: "macros", value: "macrosExcel" },
                ].map(({ name, value }) => (
                  <Col span={24} offset={1} key={value}>
                    <Item name={value} valuePropName="checked">
                      <Checkbox>{calculator[name]}</Checkbox>
                    </Item>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={[16, 16]}>
                <Col span={24}>{calculator.interaction_question_four}</Col>
                {[
                  { name: "generation", value: "generationMail" },
                  { name: "attach", value: "attachMail" },
                  { name: "download", value: "downloadMail" },
                ].map(({ name, value }) => (
                  <Col span={24} offset={1} key={value}>
                    <Item name={value} valuePropName="checked">
                      <Checkbox>{calculator[name]}</Checkbox>
                    </Item>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="end">
                <Button
                  htmlType="submit"
                  shape="round"
                  className="button-submit-secondary submit-fixed submit-secondary-spaced"
                  onClick={resetAll}
                >
                  Cancelar
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  shape="round"
                  className="button-submit submit-fixed"
                >
                  Siguiente
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Row>
    </Card>
  );
};

export default Interaction;
