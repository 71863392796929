import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Drawer, Table, Tag, Tooltip, Typography } from "antd";
import "../../../../../../App.css";
import CompanyIdeaDetails from "../../../Details/CompanyIdeaDetails";
import InputSearch from "../../InputSearch";
import HEBComments from "../../../Details/HEB/HEBComments";
import HEBAssigned from "../../../Details/HEB/HEBAssigned";
import { presetPalettes } from "@ant-design/colors";
import { Resizable } from "react-resizable";
import moment from "moment";

const ActivitiesSummary = (props) => {
  const [botsData, setBotsData] = useState([]);
  const [users, setUsers] = useState([]);
  const [statusMap, setStatusMap] = useState([]);
  const [activityKeys, setActivityKeys] = useState([]);

  const [showDatailsDrawer, setShowDetailsDrawer] = useState({
    idea: null,
    visible: false,
    idIdea: null,
  });

  const statusFilter = Object.entries(props.company.activityStatus).map(
    ([k, v]) => ({
      text: v,
      value: Number(k),
    })
  );

  const columns = [
    {
      title: "Area",
      dataIndex: "workCategory",
      filters: [...props.areas],
      onFilter: (value, record) => record.workCategory.includes(value),
      width: 120,
    },
    {
      title: "Idea Name",
      dataIndex: "ideaName",
      className: "clickable-col",
      render: (name, record) => (
        <span
          style={{ padding: "13px 13px 6px 6px", cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {name}
        </span>
      ),
      width: 260,
    },
    {
      title: "Bot Name",
      dataIndex: "bot",
      width: 220,
    },
    {
      title: "Stage",
      dataIndex: "stage",
      width: 130,
    },
    {
      title: "Activity",
      dataIndex: "name",
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{ rows: 3, expandable: true, symbol: "more" }}
        >
          {text}
        </Typography.Paragraph>
      ),
      width: 350,
    },
    {
      title: "Comments",
      render: (text, record) => {
        return (
          <HEBComments
            record={record}
            setPopoverRecord={() => false}
            users={users}
            ideas={props.ideas}
            userInfo={props.userInfo}
            stage={record.stage}
            origin={"ActivitiesSummary"}
          />
        );
      },
      width: 250,
    },
    {
      title: "Team",
      render: (text, record) => {
        return (
          <HEBAssigned
            record={record}
            setPopoverRecord={() => false}
            users={users}
            userInfo={props.userInfo}
            ideas={props.ideas}
            stage={record.stage}
            origin={"ActivitiesSummary"}
          />
        );
      },
      width: 180,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (record) => {
        let tag = statusMap.find(({ status }) => status === record);
        return (
          tag && (
            <Tag
              style={{ width: "80px", textAlign: "center" }}
              color={tag.color}
            >
              {tag.name}
            </Tag>
          )
        );
      },
      sorter: (a, b) => a.status - b.status,
      onFilter: (value, record) =>
        record.status.toString().indexOf(value) === 0,
      filters: [...statusFilter],
      width: 120,
      fixed: "right",
    },
    {
      title: "Due date",
      dataIndex: "dueDateDiff",
      render: (text, record) => {
        if (text === -1)
          return (
            <Tag color="default" style={{ width: "88%", textAlign: "center" }}>
              NOT SET
            </Tag>
          );
        else
          return text === 5 ? (
            <Tag color="green" style={{ width: "88%", textAlign: "center" }}>
              COMPLETED
            </Tag>
          ) : (
            <Tooltip
              title={moment
                .unix(record.endDate.seconds)
                .format("dddd, MMMM Do YYYY")}
            >
              {text === 1 ? (
                <Tag color="red" style={{ width: "88%", textAlign: "center" }}>
                  OVERDUE
                </Tag>
              ) : text === 2 ? (
                <Tag
                  color="orange"
                  style={{ width: "88%", textAlign: "center" }}
                >
                  THIS WEEK
                </Tag>
              ) : text === 3 ? (
                <Tag color="lime" style={{ width: "88%", textAlign: "center" }}>
                  THIS MONTH
                </Tag>
              ) : (
                <Tag color="blue" style={{ width: "88%", textAlign: "center" }}>
                  ON TIME
                </Tag>
              )}
            </Tooltip>
          );
      },
      onFilter: (value, record) => record.dueDateDiff === value,
      filters: [
        { text: "OVERDUE", value: 1 },
        { text: "THIS WEEK", value: 2 },
        { text: "THIS MONTH", value: 3 },
        { text: "ON TIME", value: 4 },
        { text: "NOT SET", value: -1 },
        { text: "COMPLETED", value: 5 },
      ],
      width: 120,
      fixed: "right",
    },
  ];

  useEffect(() => {
    if (props.ideas) {
      let bots = [];
      let bot = null;
      let statusMap = [];
      let users = [];
      let colors = [];
      let diff = 0;
      props.ideas.forEach((doc) => {
        if (doc.activities) {
          doc.activities.forEach((stage) => {
            if (stage.activities) {
              bot = doc.bots.find(({ key }) => stage.bot === key);
              stage.activities.forEach((activity) => {
                var asigneesString = "";
                activity.asignee.forEach((assigned) => {
                  asigneesString += assigned.email + " " + assigned.name + " ";
                });
                diff = -1;
                if (activity.status === 2) {
                  diff = 5;
                } else if (
                  Number(
                    moment.unix(activity.endDate.seconds).diff(moment(), "days")
                  ) < 0
                ) {
                  diff = 1;
                } else if (
                  Number(
                    moment
                      .unix(activity.endDate.seconds)
                      .diff(moment().startOf("week"), "days")
                  ) < 7
                ) {
                  diff = 2;
                } else if (
                  Number(
                    moment
                      .unix(activity.endDate.seconds)
                      .diff(moment().endOf("month"), "days")
                  ) < 0
                ) {
                  diff = 3;
                } else {
                  diff = 4;
                }
                bots.push({
                  ...activity,
                  ideaName: doc.name,
                  workCategory: doc.workCategory,
                  bot: bot.name,
                  botKey: stage.bot,
                  ideaId: doc.id,
                  asigneeString: asigneesString,
                  stage: stage.stage,
                  dueDateDiff: diff,
                });
              });
              bot = null;
            }
          });
        }
      });
      Object.entries(props.company.activityStatus).forEach(([k, v]) => {
        switch (Number(k)) {
          case 0:
            statusMap.push({ status: Number(k), color: "orange", name: v });
            break;
          case 1:
            statusMap.push({ status: Number(k), color: "blue", name: v });
            break;
          case 2:
            statusMap.push({ status: Number(k), color: "green", name: v });
            break;
          default:
            statusMap.push({ status: Number(k), color: "grey", name: v });
            break;
        }
        setStatusMap(statusMap);
      });
      setBotsData(bots);
      setTableState({ data: bots });
      if (props.company.users) users = Object.values(props.company.users);

      Object.values(presetPalettes).forEach((colorSet) => {
        colors.push(colorSet.primary);
      });
      let res = users.length / colors.length;
      if (res > 1) {
        for (let a = 0; a < Math.round(res); a++) {
          colors.push(...colors);
        }
      }
      // users=[{companyId:"QrEN5Mn25tnNhdPET601",email:"juane.soto@softtek.com",firstName:"Juan",lastName1:"Soto",lastName2:"Luévano",rol:"User",status:"Active",uid:"7FVuUhLFfBUVXOfDeEo3UQvax7k1",username:"juane.soto@softtek.com",},{companyId:"QrEN5Mn25tnNhdPET601",email:"luis.luna@softtek.com",firstName:"Luis",lastName1:"deLuna",lastName2:"Saucedo",rol:"User",status:"Active",uid:"7FVuUFfBUVXOfDeEo3UQvax7k1",username:"luis.luna@softtek.com",},{companyId:"QrEN5Mn25tnNhdPET601",email:"javier.gutierrez@softtek.com",firstName:"Javier",lastName1:"Gutiérrez",lastName2:"Pérez",rol:"User",status:"Active",uid:"7FVuUhLFfVXOfDeEo3UQvax7k1",username:"javier.gutierrez@softtek.com",},{companyId:"QrEN5Mn25tnNhdPET601",email:"jorge.bohorquez@softtek.com",firstName:"Jorge",lastName1:"Bohorquez",lastName2:"Ibarra",rol:"User",status:"Active",uid:"7FVuUhLFfBUVXOfDeEovax7k1",username:"jorge.bohorquez@softtek.com",},];
      users = users.map((user, index) => ({ ...user, color: colors[index] }));
      setUsers(users);

      if (showDatailsDrawer.visible) {
        let index = props.ideas.findIndex(
          (idea) => idea.id === showDatailsDrawer.idIdea
        );
        setShowDetailsDrawer({
          visible: true,
          idea: props.ideas[index],
          idIdea: props.ideas[index].id,
        });
      }
    }
  }, [
    props.ideas,
    props.company.activityStatus,
    props.company.users,
    showDatailsDrawer.idIdea,
    showDatailsDrawer.visible,
  ]);

  const Loader = () => (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        textAlign: "center",
        marginTop: "25%",
      }}
    >
      <Spin size="large" spinning={true}></Spin>
    </div>
  );

  const ResizableTitle = (props) => {
    const { onResize, width, ...restProps } = props;

    if (!width) {
      return <th {...restProps} />;
    }

    return (
      <Resizable
        width={width}
        height={0}
        handle={
          <span
            className="react-resizable-handle"
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        }
        onResize={onResize}
        draggableOpts={{ enableUserSelectHack: false }}
      >
        <th {...restProps}></th>
      </Resizable>
    );
  };

  const onClose = () => {
    setShowDetailsDrawer({ idea: null, visible: false, idIdea: null });
  };

  const handleRowClick = (record) => {
    let index = props.ideas.findIndex((idea) => idea.id === record.ideaId);
    let keys = { bot: record.botKey, stage: record.stage };
    setActivityKeys(keys);
    setShowDetailsDrawer({
      visible: true,
      idea: props.ideas[index],
      idIdea: props.ideas[index].id,
    });
  };

  const tableComponents = {
    header: {
      cell: ResizableTitle,
    },
  };
  const [tableState, setTableState] = useState({
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
  });

  const handleOnKeyChange = (key) => {
    if (!key) setTableState({ data: botsData });

    key = key.toLowerCase();
    const filteredData = botsData.filter(
      (entry) =>
        entry.ideaName.toLowerCase().includes(key) ||
        entry.name.toLowerCase().includes(key) ||
        entry.workCategory.toLowerCase().includes(key) ||
        entry.asigneeString.toLowerCase().includes(key) ||
        entry.bot.toLowerCase().includes(key) ||
        entry.stage.toLowerCase().includes(key)
    );

    setTableState({ data: filteredData });
  };

  return botsData ? (
    <div style={{ padding: 10 }}>
      <Drawer
        style={{ background: "white" }}
        theme="light"
        width="100vw"
        onClose={onClose}
        visible={showDatailsDrawer.visible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          ></div>
        }
        destroyOnClose
      >
        <CompanyIdeaDetails
          company={props.company}
          onCloseDrawer={onClose}
          userInfo={props.userInfo}
          companyName={props.company.name}
          mappings={props.mappings}
          idea={showDatailsDrawer.idea}
          activeKey={"2"}
          activityKeys={activityKeys}
        />
      </Drawer>
      <Row gutter={[20, 30]}>
        <Col span={24}>
          <Row>
            <Col span={6}>
              <InputSearch handleOnKeyChange={handleOnKeyChange} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Table
                pagination={{
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }}
                bordered
                components={tableComponents}
                rowKey={(record) =>
                  record.ideaId + record.botKey + record.stage + record.id
                }
                columns={columns}
                dataSource={tableState.data}
                scroll={{ x: "max-content" }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  ) : (
    <Loader />
  );
};

export default ActivitiesSummary;
