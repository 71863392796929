import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Drawer } from "antd";
import moment from "moment";
import { Table, Tag, Button } from "antd";
import "../../../../../../App.css";
import CompanyIdeaDetails from "../../../Details/CompanyIdeaDetails";
import { useTranslation } from "react-i18next";
import { Resizable } from "react-resizable";
import { CloseCircleFilled, CheckCircleFilled } from "@ant-design/icons";
import InputSearch from "../../InputSearch";
import ReactExport from "react-data-export";

const MyAssigments = (props) => {
  const [arrayExcel, setArrayExcel] = useState([]);
  const [inboxData, setInboxData] = useState(null);
  const { t } = useTranslation();
  const priorities = {
    VERY_HIGH: t("table.priorities.VERY_HIGH"),
    HIGH: t("table.priorities.HIGH"),
    MEDIUM: t("table.priorities.MEDIUM"),
    LOW: t("table.priorities.LOW"),
    VERY_LOW: t("table.priorities.VERY_LOW"),
  };

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const statuses = new Map([
    [-1, t("table.statuses.0")],
    [0, t("table.statuses.0")],
    [1, t("table.statuses.1")],
    [2, t("table.statuses.2")],
    [3, t("table.statuses.3")],
  ]);
  const statusColor = {
    4: "#C41B24",
    3: "#ccb894",
    2: "#CECE94",
    1: "#97AC85",
    0: "#94B8CC",
  };

  const prioritiesColor = {
    VERY_HIGH: "#C41B24",
    HIGH: "#ccb894",
    MEDIUM: "#CECE94",
    LOW: "#97AC85",
    VERY_LOW: "#94B8CC",
  };
  const [showDatailsDrawer, setShowDetailsDrawer] = useState({
    id: null,
    visible: false,
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "idABANCA",
      sorter: (a, b) => parseInt(a.idABANCA) - parseInt(b.idABANCA),
      width: "5%",
    },
    {
      title: t("table.headers.name"),
      dataIndex: "name",
      width: 300,
      className: "clickable-col",
      render: (name, record) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {name}
        </span>
      ),
    },
    {
      title: t("table.headers.createdDate"),
      dataIndex: "createdDate",
      render: (createdDate) =>
        createdDate
          ? moment.unix(createdDate.seconds).format("DD/MM/YYYY")
          : "",
      sorter: {
        compare: (a, b) => a.createdDate.seconds - b.createdDate.seconds,
      },
    },
    {
      title: t("table.headers.priority"),
      dataIndex: "priority",
      sortDirections: ["descend", "ascend"],
      sorter: {
        compare: (a, b) => a.priority - b.priority,
      },
      render: (priority, record) => {
        if (priority) {
          priority = MapPriority(priority);
          let color = prioritiesColor[priority];
          return (
            <Tag color={color} key={priority}>
              {`${priorities[priority].toUpperCase()}` +
                (record.priority ? ` (${record.priority})` : "")}
            </Tag>
          );
        } else {
          return <Tag key={priority}>{t("SIN ASIGNAR")}</Tag>;
        }
      },
    },
    {
      title: t("table.headers.workCategory"),
      render: (priority, record) => {
        return record.departmentTitle;
      },
    },
    {
      title: t("Petición CER"),
      className: "center-column",
      dataIndex: "peticionCER",
      width: 100,
      render: (peticionCER) =>
        peticionCER ? (
          <CheckCircleFilled
            style={{
              fontWeight: "bolder",
              fontSize: "1.5em",
              color: "#b7eb8f",
            }}
          />
        ) : (
          <CloseCircleFilled
            style={{
              fontWeight: "bolder",
              fontSize: "1.5em",
              color: "#ffa39e",
            }}
          />
        ),
      onFilter: (value, record) => {
        return record.peticionCER === value;
      },
      filters: [
        { text: "Peticion CER", value: true },
        { text: "No peticion CER", value: false },
      ],
    },
    {
      title: t("table.headers.status"),
      dataIndex: "status",
      render: (status) => {
        let color = statusColor[status];
        return (
          <Tag color={color} key={status}>
            {statuses.get(status).toUpperCase()}
          </Tag>
        );
      },
    },
  ];
  const MapPriority = (priority) => {
    if (priority < 0.5) return "VERY_LOW";
    if (priority < 1.5) return "LOW";
    if (priority < 2.5) return "MEDIUM";
    if (priority < 3) return "HIGH";
    if (priority >= 3) return "VERY_HIGH";
  };

  const Loader = () => (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        textAlign: "center",
        marginTop: "25%",
      }}
    >
      <Spin size="large" spinning={true}></Spin>
    </div>
  );

  const ResizableTitle = (props) => {
    const { onResize, width, ...restProps } = props;

    if (!width) {
      return <th {...restProps} />;
    }

    return (
      <Resizable
        width={width}
        height={0}
        handle={
          <span
            className="react-resizable-handle"
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        }
        onResize={onResize}
        draggableOpts={{ enableUserSelectHack: false }}
      >
        <th {...restProps}></th>
      </Resizable>
    );
  };

  const onClose = () => {
    setShowDetailsDrawer({ visible: false, id: null });
  };

  const handleRowClick = (row) => {
    setShowDetailsDrawer({ visible: true, idea: row, idIdea: row.id });
  };

  useEffect(() => {
    if (props.ideas) {
      let ideas = [];
      props.ideas.forEach(
        (doc) =>
          doc.status === 2 &&
          doc.asignee === props.userInfo.uid &&
          ideas.push({
            ...doc,
            id: doc.id,
          })
      );
      setInboxData(ideas);
      setTableState({ data: ideas });
    }
  }, [
    props.ideas,
    props.userInfo.uid,
    showDatailsDrawer.idIdea,
    showDatailsDrawer.visible,
  ]);

  const tableComponents = {
    header: {
      cell: ResizableTitle,
    },
  };

  const [tableState, setTableState] = useState({
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
  });

  const handleOnKeyChange = (key) => {
    if (!key) setTableState({ data: inboxData });

    key = key.toLowerCase();
    const filteredData = inboxData.filter(
      (entry) =>
        entry.name.toLowerCase().includes(key) ||
        (entry.idABANCA && entry.idABANCA.toString().includes(key)) ||
        (entry.departmentTitle &&
          entry.departmentTitle.toLowerCase().includes(key))
    );

    setTableState({ data: filteredData });
  };

  const downloadData = async () => {
    var arrayExcel = [];
    tableState.data.forEach((idea) => {
      arrayExcel.push({
        idABANCA: idea.id,
        name: idea.name,
        createdDate: moment.unix(idea.createdDate.seconds).format("DD/MM/YYYY"),
        priority: idea.priority,
        departmentTitle: idea.departmentTitle,
        peticionCER: idea.peticionCER,
        status: idea.status,
      });
    });
    setArrayExcel(arrayExcel);
    setTimeout(() => {
      var link = document.getElementById("excelBots3");
      link.click();
    }, 1000);
  };

  return inboxData ? (
    <div style={{ padding: 10 }}>
      <Drawer
        style={{ background: "white" }}
        theme="light"
        width="100vw"
        onClose={onClose}
        visible={showDatailsDrawer.visible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          ></div>
        }
      >
        <CompanyIdeaDetails
          company={props.company}
          onCloseDrawer={onClose}
          userInfo={props.userInfo}
          companyName={props.companyName}
          mappings={props.mappings}
          idea={showDatailsDrawer.idea}
        />
      </Drawer>
      <Row gutter={[20, 30]}>
        <Col span={24}>
          <Row>
            <Col span={6}>
              <InputSearch handleOnKeyChange={handleOnKeyChange} />
            </Col>
            <Col span={16}></Col>
            <Col span={2}>
              <Button
                onClick={() => {
                  downloadData();
                }}
                style={{ color: "white", backgroundColor: "#009900" }}
              >
                Excel
              </Button>
              <ExcelFile
                element={
                  <button id={"excelBots3"} style={{ display: "none" }}>
                    Download Data
                  </button>
                }
                filename={"My Assignations"}
              >
                <ExcelSheet key={"0"} data={arrayExcel} name={"Bots"}>
                  <ExcelColumn label="ID" value="idABANCA" key={0} />
                  <ExcelColumn
                    label={t("table.headers.name")}
                    value="name"
                    key={1}
                  />
                  <ExcelColumn
                    label={t("table.headers.createdDate")}
                    value="createdDate"
                    key={2}
                  />
                  <ExcelColumn
                    label={t("table.headers.priority")}
                    value="priority"
                    key={3}
                  />
                  <ExcelColumn
                    label={t("table.headers.workCategory")}
                    value="departmentTitle"
                    key={4}
                  />
                  <ExcelColumn
                    label={t("Petición CER")}
                    value="peticionCER"
                    key={5}
                  />
                  <ExcelColumn
                    label={t("table.headers.status")}
                    value="status"
                    key={6}
                  />
                </ExcelSheet>
              </ExcelFile>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table
                bordered
                components={tableComponents}
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={tableState.data}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  ) : (
    <Loader />
  );
};

export default MyAssigments;
