import React, { useEffect } from "react";
import { Donut } from "@antv/g2plot";
import { useTranslation } from "react-i18next";

const OpportunitiesByStageChart = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    let responseData = props.chartData;
    let graphData = [];
    Object.keys(responseData).forEach((key) => {
      if (responseData[key] > 0)
        graphData.push({
          type: t("dashboard.charts.labels." + key),
          value: responseData[key],
        });
    });

    const donutPlot = new Donut(document.getElementById("ring_chart"), {
      forceFit: true,
      title: {
        visible: false,
        text: "Cantidad de ideas por etapa.",
      },
      description: {
        visible: false,
        text: "",
      },
      radius: 0.8,
      padding: "auto",
      data: graphData,
      angleField: "value",
      colorField: "type",
      statistic: {
        visible: true,
        totalLabel: "Total",
      },
    });

    donutPlot.render();
    //eslint-disable-next-line
  }, []);

  return <div id="ring_chart"></div>;
};

export default OpportunitiesByStageChart;
