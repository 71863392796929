import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Card, Divider, Spin } from "antd";
import BarChartDireccion from "../Abanca/AbancaCharts/BarChartDireccion";
import OpportunitiesByStageChart from "../Abanca/AbancaCharts/OpportunitiesByStageChart";
import PieChartIncidencias from "../Abanca/AbancaCharts/PieChartIncidencias";
import BarChartPorMes from "../Abanca/AbancaCharts/BarChartPorMes";

import {
  getBenefitSumByStatus,
  getLastMonthsIdeas,
  getIdeasCountByStatus,
  getAllWorkCategoryStats,
  getBenefitsByStage,
  getIdeasPorDireccion,
  getIncidencias,
} from "../../../db/firedataCalls";

import "../../../App";

const cardsStyle = { minHeight: "100%", textAlign: "center" };
const colStyle = { padding: "0.5em" };

const AbancaDashboard = (props) => {
  const [loading] = useState(true);

  const [state, setState] = useState({
    loading: true,
    benefitDeployed: 0,
    benefitInProgress: 0,
    benefitInPipeline: 0,
    benefitNotProcessed: 0,
    ideaVelocityChartData: [],
    opportunitiesByStageChartData: {},
    bulletBenefitChartData: [],
    sentIdeasChartData: [],
    benefitByDepartmentChartData: [],
  });

  /** Obtenemos toda la información necesaia para  */
  const getAllInfo = async () => {
    try {
      setState({ ...state, loading: true });
      //Se obtienen las ideas de la empresa
      let ideas = props.ideas;
      let [
        ideasDireccion,
        benefitDeployed,
        benefitInProgress,
        benefitInPipeline,
        benefitNotProcessed,
        ideaVelocityChartData,
        opportunitiesByStageChartData,
        bulletBenefitChartData,
        benefitByDepartmentChartData,
        incidencias,
      ] = await Promise.all([
        getIdeasPorDireccion(ideas),
        getBenefitSumByStatus(3, ideas),
        getBenefitSumByStatus(2, ideas),
        getBenefitSumByStatus(1, ideas),
        getBenefitSumByStatus(0, ideas),
        getLastMonthsIdeas(6, ideas),
        getIdeasCountByStatus(ideas),
        getAllWorkCategoryStats(ideas),
        getBenefitsByStage(ideas),
        getIncidencias(ideas),
      ]);

      setState({
        ...state,
        loading: false,
        benefitDeployed: benefitDeployed,
        benefitInProgress: benefitInProgress,
        benefitInPipeline: benefitInPipeline,
        benefitNotProcessed: benefitNotProcessed,
        ideaVelocityChartData: ideaVelocityChartData,
        opportunitiesByStageChartData: opportunitiesByStageChartData,
        bulletBenefitChartData: bulletBenefitChartData,
        sentIdeasChartData: opportunitiesByStageChartData,
        benefitByDepartmentChartData: benefitByDepartmentChartData,
        ideasDireccion: ideasDireccion,
        incidencias: incidencias,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (props.ideas) getAllInfo();
    // eslint-disable-next-line
  }, [props.ideas]);

  return (
    <Layout>
      {state.loading ? (
        <Spin
          style={{
            height: "100vh",
            width: "100vw",
            textAlign: "center",
            marginTop: "25%",
          }}
          size="large"
          spinning={loading}
        ></Spin>
      ) : (
        <>
          <Row justify="center" gutter={6}>
            <Col
              className="gutter-row"
              xs={24}
              sm={24}
              md={12}
              lg={12}
              style={colStyle}
            >
              <Card style={cardsStyle}>
                <Divider orientation="left">
                  Ideas por Dirección General
                </Divider>
                <Row>
                  <Col span={24}>
                    <BarChartDireccion chartData={state.ideasDireccion} />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col
              className="gutter-row"
              xs={24}
              sm={24}
              md={12}
              lg={12}
              style={colStyle}
            >
              <Card style={cardsStyle}>
                <Divider orientation="left">Ideas por estado</Divider>
                <Row>
                  <Col span={24}>
                    <OpportunitiesByStageChart
                      chartData={state.opportunitiesByStageChartData}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col
              className="gutter-row"
              xs={24}
              sm={24}
              md={12}
              lg={12}
              style={colStyle}
            >
              <Card style={cardsStyle}>
                <Divider orientation="left">
                  Ideas Creadas vs Completadas
                </Divider>
                <Row>
                  <Col span={24}>
                    <BarChartPorMes chartData={props.ideas} />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col
              className="gutter-row"
              xs={24}
              sm={24}
              md={12}
              lg={12}
              style={colStyle}
            >
              <Card style={cardsStyle}>
                <Divider orientation="left">Incidencias</Divider>
                <Row>
                  <Col span={24}>
                    <PieChartIncidencias chartData={state.incidencias} />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </Layout>
  );
};
export default AbancaDashboard;
