import firebase from "../../../../../../server/firebase";
export const onChangeApprovedABANCA = async (idea, value) => {
  let incompletedActivities = [];
  if (idea.activities)
    incompletedActivities = idea.activities.filter(
      (activity) => !activity.completed
    );

  if (!idea.idBluePrism) {
    return "error: Debes completar los datos en la pestaña pase a producción.";
  }

  if (incompletedActivities.length > 0) {
    return "warning";
  } else {
    await firebase
      .database()
      .ref()
      .child("ideas")
      .child(idea.id)
      .update({
        approved: value,
        status: value ? 3 : 2,
      })
      .catch(() => {
        return "error:DB";
      });
    return "true";
  }
};
