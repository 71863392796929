import React, { useState, useEffect } from "react";
import { Button } from "antd";
import OTDLayout from "./OTDLayout";

const clone = require("rfdc")();

const PanelRender = ({ ideas, userInfo }) => {
  const [visible, setVisible] = useState(false);
  const [botStages, setBotStages] = useState([]);
  const [maintenances, setMaintenances] = useState([]);

  useEffect(() => {
    const data = clone(ideas);
    let tempBotStages = [];
    let tempMaintenances = [];
    if (data)
      data.forEach((idea) => {
        if (idea.bots) {
          idea.bots.forEach((bot) => {
            let stageActivities = [];
            if (idea.activities) {
              stageActivities = idea.activities.filter(
                (activity) => activity.bot === bot.key
              );
              if (stageActivities.length >= 1) {
                const ideaBots = stageActivities.map((stageActivity) => ({
                  ...stageActivity,
                  idea: idea.name,
                  ideaId: idea.id,
                  botName: bot.name,
                }));
                tempBotStages = [...tempBotStages, ...ideaBots];
              }
            }
            if (bot.maintenance) {
              const botMaintenances = bot.maintenance.map((maintenance) => ({
                ...maintenance,
                bot: bot.key,
                botName: bot.name,
                idea: idea.name,
                ideaId: idea.id,
              }));
              tempMaintenances = [...tempMaintenances, ...botMaintenances];
            }
          });
        }
      });
    if (tempBotStages && tempBotStages.length > 0) {
      setBotStages([...tempBotStages]);
    }
    if (tempMaintenances && tempMaintenances.length > 0)
      setMaintenances([...tempMaintenances]);
  }, [ideas]);

  return userInfo.companyId === "QrEN5Mn25tnNhdPET601" ||
    (userInfo.companyId === "inRd27ObTby2qbkKnUZF" &&
      (userInfo.uid === "L8ZLkJCL5xOlDh4bofuDnd5mei32" ||
        userInfo.uid === "vLavNAjnjONJIWqRTe3vBNO3acr1" ||
        userInfo.uid === "XATAae88LdMuSjHlOWwdOPHq4K32")) ? (
    <>
      <Button type="primary" onClick={() => setVisible(true)}>
        OTD Panel
      </Button>
      <OTDLayout
        visible={visible}
        setVisible={setVisible}
        botStages={botStages}
        maintenances={maintenances}
      />
    </>
  ) : (
    <></>
  );
};

export default PanelRender;
