import React, { useState, useEffect } from "react";
import { Row, Col, Table, Drawer, Space, Popconfirm, Button, Tag } from "antd";
import moment from "moment";
import CompanyIdeaDetails from "../../../Details/CompanyIdeaDetails";
import firebase from "../../../../../../server/firebase";
import InputSearch from "../../InputSearch";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import ExcelDownload from "../../../../../CustomComponents/ABANCA/ExcelDownload";

const Deployed = (props) => {
  const [deployedData, setDeployedData] = useState({
    ideas: [],
    deployedBenefit: 0,
    totalIdeas: 0,
    failedExecution: 0,
    failedExecutionCost: 0,
    deployedData: { x: "", y: 0 },
  });

  const [showDatailsDrawer, setShowDetailsDrawer] = useState({
    idea: null,
    visible: false,
    idIdea: null,
  });

  const [dirFilter, setDirFilter] = useState([]);
  const [dirGenFilter, setDirGenFilter] = useState([]);
  const [tipoTareaFilter, setTipoTareaFilter] = useState([]);

  const handleRowClick = (row) => {
    setShowDetailsDrawer({ visible: true, idea: row, idIdea: row.id });
  };

  const onClose = () => {
    setShowDetailsDrawer({ idea: null, visible: false, idIdea: null });
  };

  const priorities = {
    VERY_HIGH: "Muy alta",
    HIGH: "Alta",
    MEDIUM: "Media",
    LOW: "Baja",
    VERY_LOW: "Muy baja",
  };
  const prioritiesColor = {
    VERY_HIGH: "#C41B24",
    HIGH: "#ccb894",
    MEDIUM: "#CECE94",
    LOW: "#97AC85",
    VERY_LOW: "#94B8CC",
  };
  const MapPriority = (priority) => {
    if (priority < 0.5) return "VERY_LOW";
    if (priority < 1.5) return "LOW";
    if (priority < 2.5) return "MEDIUM";
    if (priority < 3) return "HIGH";
    if (priority >= 3) return "VERY_HIGH";
  };

  const [tableState, setTableState] = useState({
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
  });

  const handleOnKeyChange = (key) => {
    if (!key) setTableState({ data: deployedData.ideas });

    key = key.toLowerCase();
    const filteredData = deployedData.ideas.filter(
      (entry) =>
        entry.name.toLowerCase().includes(key) ||
        (entry.idABANCA && entry.idABANCA.toString().includes(key)) ||
        entry.direccion.toLowerCase().includes(key) ||
        entry.dir_general.toLowerCase().includes(key) ||
        (entry.operatorUser && entry.operatorUser.toLowerCase().includes(key))
    );

    setTableState({ data: filteredData });
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "idABANCA",
      sorter: (a, b) => parseInt(a.idABANCA) - parseInt(b.idABANCA),
    },
    {
      title: "Nombre",
      dataIndex: "name",
      render: (name, record) => (
        <span
          style={{ padding: "13px 13px 6px 6px", cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {name}
        </span>
      ),
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
    },
    {
      title: "Responsable Operación",
      dataIndex: "operatorUser",
    },
    {
      title: "Dirección General",
      dataIndex: "dir_general",
      render: (text) => {
        return text && text;
      },
      sorter: {
        compare: (a, b) => a.dir_general.localeCompare(b.dir_general),
      },
      filters: dirGenFilter,
      onFilter: (value, record) => {
        return record.dir_general.indexOf(value) === 0;
      },
    },
    {
      title: "Dirección",
      dataIndex: "direccion",
      render: (text) => {
        return text && text;
      },
      sorter: {
        compare: (a, b) => a.direccion.localeCompare(b.direccion),
      },
      filters: dirFilter,
      onFilter: (value, record) => {
        return record.direccion.indexOf(value) === 0;
      },
    },
    {
      title: "Tipo de tarea",
      dataIndex: "tipoTarea",
      render: (text) => (text && text !== 0 ? text : ""),
      filters: tipoTareaFilter,
      onFilter: (value, record) => {
        return record.tipoTarea
          ? record.tipoTarea.indexOf(value) === 0
          : "Sin asignar".indexOf(value) === 0;
      },
    },
    {
      title: "Status",
      dataIndex: "productionStatus",
      render: (record) => {
        let color;
        let mensaje;
        color = record === -1 ? "default" : "success";
        mensaje = record === -1 ? "Detenido" : "En Producción";
        return (
          <>
            <Tag color={color}>{mensaje.toUpperCase()}</Tag>
          </>
        );
      },
      sorter: {
        compare: (a, b) =>
          (a.productionStatus === -1
            ? "Detenido"
            : "En Producción"
          ).localeCompare(
            b.productionStatus === -1 ? "Detenido" : "En Producción"
          ),
      },
      filters: [
        { text: "Detenido", value: "Detenido" },
        { text: "En Producción", value: "En Producción" },
      ],
      onFilter: (value, record) => {
        let status =
          record.productionStatus === -1 ? "Detenido" : "En Producción";
        return status.indexOf(value) === 0;
      },
    },
    {
      title: "Prioridad",
      dataIndex: "priority",
      sortDirections: ["descend", "ascend"],
      sorter: {
        compare: (a, b) =>
          a.priority
            ? b.priority
              ? a.priority - b.priority
              : a.priority - 0
            : b.priority
            ? 0 - b.priority
            : 0 - 0,
      },
      render: (priority, record) => {
        if (priority) {
          priority = MapPriority(priority);
          let color = prioritiesColor[priority];
          return (
            <Tag color={color} key={priority}>
              {`${priorities[priority].toUpperCase()}` +
                (record.priority ? ` (${record.priority})` : "")}
            </Tag>
          );
        } else {
          return <Tag key={priority}>{"SIN ASIGNAR"}</Tag>;
        }
      },
      filters: [
        { text: "Muy baja", value: "Muy baja" },
        { text: "Baja", value: "Baja" },
        { text: "Media", value: "Media" },
        { text: "Alta", value: "Alta" },
        { text: "Muy alta", value: "Muy alta" },
        { text: "Sin asignar", value: "Sin asignar" },
      ],
      onFilter: (value, record) => {
        return record.priority
          ? priorities[MapPriority(record.priority)].indexOf(value) === 0
          : "Sin asignar".indexOf(value) === 0;
      },
    },
    {
      title: "Prioritaria",
      className: "center-column",
      dataIndex: "prioritaria",
      render: (text, record) =>
        text ? (
          <CheckCircleFilled
            style={{
              fontWeight: "bolder",
              fontSize: "1.5em",
              color: "#b7eb8f",
            }}
          />
        ) : (
          <CloseCircleFilled
            style={{
              fontWeight: "bolder",
              fontSize: "1.5em",
              color: "#ffa39e",
            }}
          />
        ),
      onFilter: (value, record) => {
        return record.prioritaria
          ? record.prioritaria === value
          : false === value;
      },
      filters: [
        { text: "Prioritaria", value: true },
        { text: "No Prioritaria", value: false },
      ],
    },
    {
      title: "",
      key: "action",
      fixed: "right",
      width: 250,
      render: (text, record) => {
        return (
          <Space size={"middle"} width={"100%"}>
            <Popconfirm
              placement="bottomLeft"
              title="¿Finalizar la petición?"
              onConfirm={(e) => handleOnFinish(record, e)}
              okText="Sí"
              cancelText="No"
            >
              <Button
                style={{ width: "100px" }}
                key={record.id}
                className={"ant-btn"}
              >
                Finalizar
              </Button>
            </Popconfirm>
            <Popconfirm
              placement="bottomLeft"
              title="Detener la petición?"
              onConfirm={(e) =>
                hanldeOnHold(record, e, record.productionStatus === -1)
              }
              okText="Sí"
              cancelText="No"
            >
              <Button
                style={{ width: "100px" }}
                key={record.id}
                className={
                  record.productionStatus !== -1 ? "ant-btn-dangerous" : null
                }
              >
                {record.productionStatus !== -1 ? "Detener" : "Reanudar"}
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const handleOnFinish = (idea, event) => {
    event.preventDefault();
    //console.log("ideaApprove: ", idea);
    // handleMail(idea, "Aprobada");
    firebase
      .database()
      .ref()
      .child("ideas")
      .child(idea.id)
      .update({ productionStatus: 1 })
      .catch((err) => console.error(err));
  };

  const hanldeOnHold = (idea, event, productionStatus) => {
    event.preventDefault();
    //console.log("ideaReject: ", idea);
    // handleMail(idea, "Rechazada");
    firebase
      .database()
      .ref()
      .child("ideas")
      .child(idea.id)
      .update({ productionStatus: productionStatus ? null : -1 })
      .catch((err) => console.error(err));
  };

  const getUserAndCode = (userUid) => {
    let user = Object.values(props.company.users).find(
      ({ uid }) => uid === userUid
    );
    let deletedUser = Object.values(props.company.deletedUsers).find(
      ({ uid }) => uid === userUid
    );
    return user
      ? user.firstName +
          " " +
          user.lastName1 +
          (user.lastName2 ? " " + user.lastName2 : "") +
          " (" +
          user.username +
          ")"
      : deletedUser.firstName +
          " " +
          deletedUser.lastName1 +
          (deletedUser.lastName2 ? " " + deletedUser.lastName2 : "") +
          " (" +
          deletedUser.username +
          ")";
  };

  useEffect(() => {
    if (props.ideas) {
      let ideas = [];
      let direccion = [];
      let dir_general = [];
      props.ideas.forEach(
        (doc) =>
          doc.status === 3 &&
          ideas.push({
            ...doc,
            id: doc.id,
            operatorUser: doc.operator ? getUserAndCode(doc.operator) : "",
          })
      );
      let benefit = ideas.reduce((acc, idea) => acc + idea.estimatedBenefit, 0);

      let filteredIdeas = ideas.filter(
        (idea) =>
          idea.createdDate.seconds >= moment().subtract(3, "months").unix()
      );
      let months = new Map();
      filteredIdeas.forEach((idea) =>
        months.set(
          moment.unix(idea.createdDate.seconds).month(),
          moment.unix(idea.createdDate.seconds).format("MMMM")
        )
      );

      let chartData = [];

      for (const [key, value] of months.entries()) {
        chartData.push({
          x: value,
          y: filteredIdeas
            .filter(
              (idea) => moment.unix(idea.createdDate.seconds).month() === key
            )
            .reduce((acc, idea) => acc + idea.estimatedBenefit, 0),
        });
      }

      ideas = ideas.filter((i) => i.productionStatus !== 1);
      setTableState({ data: ideas });
      setDeployedData({
        ideas: ideas,
        deployedBenefit: "€" + benefit,
        totalIdeas: ((ideas.length / props.ideas.length) * 100).toFixed(),
        failedExecution: 0,
        failedExecutionCost: 0,
        chartData: chartData,
      });

      ideas.forEach((idea) => {
        if (!direccion.find((dir) => dir.value === idea.direccion))
          direccion.push({ text: idea.direccion, value: idea.direccion });
        if (
          !dir_general.find(
            (dir_general) => dir_general.value === idea.dir_general
          )
        )
          dir_general.push({ text: idea.dir_general, value: idea.dir_general });
      });
      setDirFilter(direccion);
      setDirGenFilter(dir_general);

      if (showDatailsDrawer.visible) {
        let index = props.ideas.findIndex(
          (idea) => idea.id === showDatailsDrawer.idIdea
        );
        setShowDetailsDrawer({
          visible: true,
          idea: props.ideas[index],
          idIdea: props.ideas[index].id,
        });
      }
    }
    let tipos = [];
    Object.values(props.company.tipoTarea).forEach((tipo) => {
      tipos.push({ text: tipo, value: tipo });
    });
    tipos.push({ text: "Sin asignar", value: "Sin asignar" });
    setTipoTareaFilter(tipos);
    // eslint-disable-next-line
  }, [props.ideas, showDatailsDrawer.visible, showDatailsDrawer.idIdea]);

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col /* span={14} */>
          <div>
            <Drawer
              style={{ background: "white" }}
              theme="light"
              width="100vw"
              onClose={onClose}
              visible={showDatailsDrawer.visible}
              footer={
                <div
                  style={{
                    textAlign: "right",
                  }}
                ></div>
              }
              destroyOnClose
            >
              <CompanyIdeaDetails
                company={props.company}
                userInfo={props.userInfo}
                onCloseDrawer={onClose}
                companyName={props.companyName}
                mappings={props.mappings}
                idea={showDatailsDrawer.idea}
              />
            </Drawer>
            <br />
            <Row>
              <Col span={6}>
                <InputSearch handleOnKeyChange={handleOnKeyChange} />
              </Col>
              <Col span={16}></Col>
              <Col span={2}>
                <ExcelDownload
                  data={tableState.data}
                  name={"Producción"}
                  id={"excel4"}
                  company={props.company}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Table
                  pagination={{
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                  }}
                  bordered
                  columns={columns}
                  dataSource={tableState.data}
                  rowKey={(record) => record.id}
                  size="small"
                  scroll={{ x: "max-content" }}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row></Row>
      <br />
      <br />
      <br />
    </>
  );
};

export default Deployed;
