import React, { useEffect, useState } from "react";
import { Table, Row, Col, Drawer, Button } from "antd";
import "../../../../../../App.css";
import moment from "moment";
import InputSearch from "../../InputSearch";
import CreateIdeaHandler from "../../../CreateIdea/CreateIdeaHandler";
import CompanyIdeaDetails from "../../../Details/CompanyIdeaDetails";
import { PanelRender } from "../../../../../CustomComponents/HEB/OTDPanel";
import ReactExport from "react-data-export";
// import firebase from "../../../../../../server/firebase";
// import UID from "uid-safe";

const Search = (props) => {
  const [state, setState] = useState({
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
  });

  const [tableData, setTableData] = useState([]);
  const [showDatailsDrawer, setShowDetailsDrawer] = useState({
    idea: null,
    visible: false,
    idIdea: null,
  });
  const [arrayExcel, setArrayExcel] = useState([]);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const getStatus = (record) => {
    switch (record.status) {
      case -1:
        return "Not processed";
      case 1:
        return "In analysis";
      case 2:
        return "In progress";
      case 3:
        return "Deployed";
      case 4:
        return "Rejected";
      default:
        return "Undefined";
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => (
        <span
          style={{ padding: "13px 13px 6px 6px", cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {record.name}
        </span>
      ),
    },
    {
      title: "Created date",
      dataIndex: "createdDate",
      render: (createdDate) =>
        createdDate
          ? moment.unix(createdDate.seconds).format("DD/MM/YYYY")
          : "",
      sorter: {
        compare: (a, b) =>
          a.createdDate
            ? b.createdDate
              ? a.createdDate.seconds - b.createdDate.seconds
              : a.createdDate.seconds - 0
            : b.createdDate
            ? 0 - b.createdDate.seconds
            : 0 - 0,
        multiple: 2,
      },
    },
    {
      title: "Priority",
      dataIndex: "priority",
      sortDirections: ["descend", "ascend"],
      sorter: {
        compare: (a, b) => a.priority - b.priority,
      },
    },
    {
      title: "Area",
      dataIndex: "workCategory",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status, record) => {
        return getStatus(record);
      },
      sorter: {
        compare: (a, b) => a.status - b.status,
        multiple: 2,
      },
      filters: [
        { text: "Not processed", value: "Not processed" },
        { text: "In analysis", value: "In analysis" },
        { text: "In progress", value: "In progress" },
        { text: "Deployed", value: "Deployed" },
        { text: "Rejected", value: "Rejected" },
      ],
      onFilter: (value, record) => {
        return getStatus(record).indexOf(value) === 0;
      },
    },
  ];

  const handleRowClick = (row) => {
    setShowDetailsDrawer({ visible: true, idea: row, idIdea: row.id });
  };

  useEffect(() => {
    if (props.ideas) {
      let ideas = props.ideas;
      setTableData(ideas);
      setState({
        data: ideas,
      });
      if (showDatailsDrawer.visible) {
        let index = props.ideas.findIndex(
          (idea) => idea.id === showDatailsDrawer.idIdea
        );
        setShowDetailsDrawer({
          visible: true,
          idea: props.ideas[index],
          idIdea: props.ideas[index].id,
        });
      }
    }
  }, [props.ideas, showDatailsDrawer.idIdea, showDatailsDrawer.visible]);

  const { data } = state;

  const handleOnKeyChange = (key) => {
    if (!key) setState({ data: tableData });

    key = key.toLowerCase();

    const filteredData = tableData.filter(
      (entry) =>
        entry.name.toLowerCase().includes(key) ||
        (entry.id && entry.id.toString().includes(key)) ||
        (entry.workCategory && entry.workCategory.toLowerCase().includes(key))
    );

    setState({ data: filteredData });
  };

  const onClose = () => {
    setShowDetailsDrawer({ visible: false, idea: null, idIdea: null });
  };

  const downloadData = async () => {
    var arrayData = [];
    arrayData = tableData.map((data) => {
      var dataEntry = {
        name: data.name,
        createdDate: data.createdDate
          ? moment.unix(data.createdDate.seconds).format("DD/MM/YYYY")
          : "-",
        priority: data.priority,
        workCategory: data.workCategory,
        status: getStatus(data),
      };
      return dataEntry;
    });
    setArrayExcel(arrayData);
    setTimeout(() => {
      var link = document.getElementById("excelBacklog");
      link.click();
    }, 1000);
  };

  return (
    <>
      <Row>
        <Col span={6}>
          <InputSearch handleOnKeyChange={handleOnKeyChange} />
        </Col>

        <Col offset={2} span={3}>
          <CreateIdeaHandler
            userInfo={props.userInfo}
            company={props.company}
            ideas={tableData}
          />
        </Col>
        <Col span={8}>
          {/* <Button
            onClick={() => {
              var newSubCompanyId = UID.sync(18);
              firebase
                .database()
                .ref()
                .child("users")
                .push({
                  companyId: "-MzpQYhPdDZ4_nwTbQX3",
                  email: "generic@rpamanager.softtek.com",
                  firstName: "GENERIC",
                  lastName1: "TEST",
                  lastName2: "USERS",
                  rol: "User",
                  subCompanyId: newSubCompanyId,
                  status: 1,
                  uid: "fTZA6r52zYQEtC3h0ikD5dn1oUC3",
                  username: "generic@rpamanager.softtek.com",
                })
                .then(console.log("Agregado"));
              console.log(newSubCompanyId);
            }}
          >
            Usuario
          </Button> */}
        </Col>
        <Col span={3}>
          <PanelRender ideas={data} userInfo={props.userInfo} />
        </Col>
        <Col span={2}>
          <Button
            onClick={() => {
              downloadData();
            }}
            style={{ color: "white", backgroundColor: "#009900" }}
          >
            Excel
          </Button>
          <ExcelFile
            element={
              <button id={"excelBacklog"} style={{ display: "none" }}>
                Download Data
              </button>
            }
            filename={"Backlog_Data"}
          >
            <ExcelSheet key={"0"} data={arrayExcel} name={"Backlog"}>
              <ExcelColumn label="Name" value="name" key={0} />
              <ExcelColumn label="Created Date" value="createdDate" key={1} />
              <ExcelColumn label="Priority" value="priority" key={2} />
              <ExcelColumn label="Area" value="workCategory" key={3} />
              <ExcelColumn label="Status" value="status" key={4} />
            </ExcelSheet>
          </ExcelFile>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            bordered
            dataSource={data}
            rowKey={(idea) => idea.id}
            columns={columns}
            scroll={{ x: 1300 }}
          />

          <Drawer
            style={{ background: "white" }}
            width="100vw"
            onClose={onClose}
            visible={showDatailsDrawer.visible}
            footer={
              <div
                style={{
                  textAlign: "right",
                }}
              ></div>
            }
            destroyOnClose
          >
            <CompanyIdeaDetails
              company={props.company}
              onCloseDrawer={onClose}
              userInfo={props.userInfo}
              companyName={props.companyName}
              mappings={props.mappings}
              idea={showDatailsDrawer.idea}
            />
          </Drawer>
        </Col>
      </Row>
    </>
  );
};

export default Search;
