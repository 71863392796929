import React, { useEffect, useState } from "react";
import { Chart, Coordinate, Interval } from "bizcharts";
import { Pagination } from "antd";

const BarChartDireccion = (props) => {
  const [data, setData] = useState([]);
  const [pages, setPages] = useState(0);
  const [dataChart, setDataChart] = useState([]);

  function onChange(pageNumber) {
    var final = pageNumber * 10;
    var inicio = final - 10;

    if (props.chartData) {
      let datas = [];
      data.forEach((idea, index) => {
        if (index >= inicio && index < final) {
          datas.push({
            title: idea.title,
            amount: idea.amount,
          });
        }
      });
      setDataChart(datas);
    }
  }

  useEffect(() => {
    let dataChart = [];
    let ideas = props.chartData;
    if (ideas) {
      let data = [];
      Object.entries(ideas).forEach((idea) => {
        data.push({
          title: idea[0],
          amount: idea[1],
        });
      });
      data.sort((a, b) => b.amount - a.amount);
      setData(data);
      if (data.length > 10) {
        dataChart = data.slice(0, 10);
      } else {
        dataChart = data;
      }
      setPages(Object.entries(ideas).length);
      setDataChart(dataChart);
    }
  }, [props.chartData]);

  return (
    <>
      <Chart height={400} data={dataChart} autoFit>
        <Coordinate transpose />
        <Interval position="title*amount" />
      </Chart>
      {pages > 10 && (
        <Pagination total={pages} onChange={onChange}></Pagination>
      )}
    </>
  );
};

export default BarChartDireccion;
