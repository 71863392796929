import React, { useEffect } from "react";
import {
  Modal,
  Form,
  Row,
  Col,
  Input,
  Button,
  InputNumber,
  DatePicker,
  message,
  Space,
  Select,
} from "antd";
import moment from "moment";
import { updateIssueABANCA } from "../../../../db/firedataCalls";

const NewIssueModal = ({
  visible,
  setVisible,
  ideas,
  userInfo,
  issue,
  setIssue,
  origin,
}) => {
  const [issueForm] = Form.useForm();
  const dateFormat = "DD/MM/YYYY";
  const tailLayout = {
    width: "100%",
    backgroundColor: "#f5f5f5",
    borderStyle: "none",
  };

  useEffect(() => {
    if (ideas.length === 1)
      issueForm.setFieldsValue({
        ideaId: ideas[0].id,
      });
    if (issue) {
      issueForm.setFieldsValue({
        ...issue,
        createdDate:
          issue.createdDate &&
          moment(moment.unix(issue.createdDate.seconds), dateFormat),
        closedDate: issue.closedDate
          ? moment(moment.unix(issue.closedDate.seconds), dateFormat)
          : null,
      });
    }
    // eslint-disable-next-line
  }, [issue]);

  const onFinish = () => {
    const tempIssue = issueForm.getFieldsValue();
    const idea = ideas.find(({ id }) => tempIssue.ideaId === id);
    //Changing every undefined for null (firebase needed)
    Object.entries(tempIssue).forEach(([k, v]) => {
      if (v === undefined) tempIssue[k] = null;
    });
    delete tempIssue.ideaId;
    let newIssues = [];
    newIssues = idea.issues ? idea.issues : [];
    if (!issue) {
      const newIssue = {
        ...tempIssue,
        guardia: true,
        createdDate: { seconds: moment().utc().unix(), nanoseconds: 0 },
        assignee: [userInfo.uid],
        id:
          Number(
            newIssues.reduce((max, iss) => (iss.id > max ? iss.id : max), 0)
          ) + 1,
      };
      newIssues.push(newIssue);
    } else {
      let newIssue = { ...issue, ...tempIssue };
      let index = newIssues.findIndex(({ id }) => id === newIssue.id);
      if (newIssue.closedDate)
        newIssue.closedDate = {
          seconds: moment(newIssue.closedDate).unix(),
          nanoseconds: 0,
        };
      if (moment.isMoment(newIssue.createdDate))
        newIssue.createdDate = {
          seconds: moment(newIssue.createdDate).unix(),
          nanoseconds: 0,
        };
      newIssues[index] = newIssue;
    }
    updateIssueABANCA(idea.id, newIssues)
      .then(() => {
        message.success("Guardia creada con éxito");
        setVisible(false);
      })
      .catch(() => {
        message.error("Hubo un error al crear la guardia");
      });
  };

  const onCancel = () => {
    setIssue(null);
    setVisible(false);
    issueForm.resetFields();
  };

  return (
    <Modal visible={visible} onCancel={onCancel} destroyOnClose footer={null}>
      <Form
        name="issueForm"
        form={issueForm}
        onFinish={onFinish}
        preserve={false}
      >
        <Row gutter={[40, 15]} justify="center">
          <Col span={20}>
            <Form.Item
              label="Título"
              labelAlign="left"
              name="issue"
              rules={[
                {
                  required: true,
                  message: "La guardia debe tener un título",
                },
              ]}
            >
              <Input
                style={{ ...tailLayout }}
                allowClear
                bordered={false}
                placeholder="Breve título de la guardia"
              />
            </Form.Item>
          </Col>
          <Col span={20}>
            <Form.Item
              label="Duración (minutos)"
              labelAlign="left"
              name="duration"
            >
              <InputNumber
                placeholder="Duración de la guardia"
                style={{ ...tailLayout }}
              />
            </Form.Item>
          </Col>
          {issue && (
            <>
              <Col span={10}>
                <Form.Item
                  label="Fecha de inicio"
                  labelAlign="left"
                  name="createdDate"
                  style={{ float: "left" }}
                >
                  <DatePicker
                    size="small"
                    bordered={false}
                    placeholder="Selecciona fecha"
                    allowClear={false}
                    style={{ ...tailLayout }}
                  />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  label="Fecha de término"
                  labelAlign="left"
                  name="closedDate"
                  style={{ float: "left" }}
                >
                  <DatePicker
                    size="small"
                    placeholder="Selecciona fecha"
                    disabled={!issue.closed}
                    bordered={false}
                    allowClear={false}
                    style={{ ...tailLayout }}
                  />
                </Form.Item>
              </Col>
            </>
          )}
          <Col span={20}>
            <Form.Item label="Descripción" labelAlign="left" name="description">
              <Input.TextArea
                autoSize={{ minRows: 3, maxRows: 5 }}
                placeholder="Descripción de la guardia"
                style={{ ...tailLayout }}
              />
            </Form.Item>
          </Col>
          <Col span={20}>
            <Form.Item
              label="Petición asociada"
              labelAlign="left"
              name="ideaId"
              rules={[
                {
                  required: true,
                  message: "Necesitas asociar la actividad a una petición",
                },
              ]}
            >
              <Select
                disabled={origin === "DETAILS"}
                bordered={false}
                placeholder="Selecciona una petición"
                style={{ ...tailLayout }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {ideas.map((idea) => {
                  let text = idea.idABANCA
                    ? idea.idABANCA + " " + idea.name
                    : idea.name;
                  return (
                    <Select.Option value={idea.id} key={idea.id} text={text}>
                      {idea.idABANCA && `(${idea.idABANCA}) `}
                      {idea.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={20}>
            <Space>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Guardar
                </Button>
              </Form.Item>
              <Button danger onClick={onCancel}>
                Cancelar
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default NewIssueModal;
