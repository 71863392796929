import React, { useEffect, useState } from "react";
import {
  Table,
  Row,
  Col,
  Drawer,
  Tag,
  Button,
  Popover,
  Typography,
} from "antd";
import "../../../../../../App.css";
import moment from "moment";
import ReactExport from "react-data-export";
import InputSearch from "../../InputSearch";
import CompanyIdeaDetails from "../../../Details/CompanyIdeaDetails";
import { NewIssueModal } from "../../../../../CustomComponents/ABANCA/Issues";
import { ABANCATeams } from "../../../../../CustomComponents/ABANCA/TeamsComponent";

const IssuesSummary = (props) => {
  const [state, setState] = useState({
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
  });
  const [tableData, setTableData] = useState([]);
  const [showDatailsDrawer, setShowDetailsDrawer] = useState({
    idea: null,
    visible: false,
    idIdea: null,
  });
  const [dirFilter, setDirFilter] = useState([]);
  const [dirGenFilter, setDirGenFilter] = useState([]);
  const [issue, setIssue] = useState();
  const [newIssue, setNewIssue] = useState(false);
  const [arrayExcel, setArrayExcel] = useState([]);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  useEffect(() => {
    if (props.ideas) {
      let ideas = props.ideas;
      let issues = [];
      let direccion = [];
      let dir_general = [];
      ideas = ideas.filter((idea) => idea.issues);
      ideas.forEach((idea) => {
        idea.issues.forEach((issue) => {
          let assigness = "";
          issue.assignee &&
            issue.assignee.forEach((assi) => {
              assigness = assigness + getUserAndCode(assi) + " ";
            });
          issues.push({
            idea: idea,
            ...issue,
            dir_general: idea.dir_general,
            direccion: idea.direccion,
            issueSolutionName: issue.solution
              ? getUserAndCode(issue.solution.userUid)
              : "",
            issueAssigneeNames: assigness,
          });
        });
        if (!direccion.find((dir) => dir.value === idea.direccion))
          direccion.push({ text: idea.direccion, value: idea.direccion });
        if (
          !dir_general.find(
            (dir_general) => dir_general.value === idea.dir_general
          )
        )
          dir_general.push({
            text: idea.dir_general,
            value: idea.dir_general,
          });
      });
      setTableData(issues);
      setState({
        data: issues,
      });
      setDirFilter(direccion);
      setDirGenFilter(dir_general);
    }
    if (showDatailsDrawer.visible) {
      let index = props.ideas.findIndex(
        (idea) => idea.id === showDatailsDrawer.idIdea
      );
      setShowDetailsDrawer({
        visible: true,
        idea: props.ideas[index],
        idIdea: props.ideas[index].id,
      });
    }
    //eslint-disable-next-line
  }, [props.ideas, showDatailsDrawer.visible, showDatailsDrawer.idIdea]);

  const getStatus = (record) => {
    if (record.closed) return "Solucionada";
    else return "Pendiente";
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: 50,
    },
    {
      title: "Incidencia",
      dataIndex: "issue",
      width: 250,
      render: (text, record) => (
        <Typography.Link
          strong
          style={{ padding: "13px 13px 6px 6px", cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {text}
        </Typography.Link>
      ),
      sorter: {
        compare: (a, b) => a.issue.localeCompare(b.issue),
      },
    },
    {
      title: "Petición",
      width: 300,
      render: (text, record) => (
        <span
          style={{ padding: "13px 13px 6px 6px", cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {record.idea.name}
        </span>
      ),
      sorter: {
        compare: (a, b) => a.idea.name.localeCompare(b.idea.name),
      },
    },
    {
      title: "Fecha creación",
      width: 150,
      dataIndex: "createdDate",
      render: (createdDate) =>
        createdDate
          ? moment.unix(createdDate.seconds).format("DD/MM/YYYY")
          : "",
      sorter: {
        compare: (a, b) => a.createdDate.seconds - b.createdDate.seconds,
      },
    },
    {
      title: "Dirección General",
      width: 200,
      dataIndex: "dir_general",
      render: (text) => {
        return text && text;
      },
      sorter: {
        compare: (a, b) => a.dir_general.localeCompare(b.dir_general),
      },
      filters: dirGenFilter,
      onFilter: (value, record) => {
        return record.dir_general.indexOf(value) === 0;
      },
    },
    {
      title: "Dirección",
      width: 200,
      dataIndex: "direccion",
      render: (text) => {
        return text && text;
      },
      sorter: {
        compare: (a, b) => a.direccion.localeCompare(b.direccion),
      },
      filters: dirFilter,
      onFilter: (value, record) => {
        return record.direccion.indexOf(value) === 0;
      },
    },
    {
      title: "Duración",
      dataIndex: "duration",
      width: 120,
    },
    {
      title: "Creador(Guardia)/Asignado(Incidencia)",
      dataIndex: "assignee",
      render: (text, record) => {
        return record.assignee ? (
          record.assignee.length > 1 ? (
            teamPopOver(record)
          ) : (
            <Typography.Text>
              {getUserAndCode(record.assignee[0])}
            </Typography.Text>
          )
        ) : (
          "Sin Asignado"
        );
      },
      width: 300,
    },
    {
      title: "Finalizador",
      render: (text, record) => {
        return (
          record.solution && (
            <Typography.Text>{record.issueSolutionName}</Typography.Text>
          )
        );
      },
      width: 300,
    },
    {
      title: "Tipo",
      dataIndex: "guardia",
      width: 120,
      render: (text) =>
        text ? (
          <Tag color="processing">Guardia</Tag>
        ) : (
          <Tag color="success">Incidencia</Tag>
        ),
      sorter: {
        compare: (a, b) =>
          a.guardia ? (b.guardia ? 1 - 1 : 1 - 0) : b.guardia ? 0 - 1 : 0 - 0,
      },

      filters: [
        { text: "Incidencias", value: "issue" },
        { text: "Guardias", value: "guardia" },
      ],
      onFilter: (value, record) => {
        const filter = record.guardia ? "guardia" : "issue";
        return filter.indexOf(value) === 0;
      },
    },
    {
      title: "Estatus",
      fixed: "right",
      width: 150,
      render: (status, record) => {
        return getStatus(record) === "Solucionada" ? (
          <Tag color="success">Solucionada</Tag>
        ) : (
          <Tag color="error">Pendiente</Tag>
        );
      },
      sorter: {
        compare: (a, b) =>
          a.closed ? (b.closed ? 1 - 1 : 1 - 0) : b.closed ? 0 - 1 : 0 - 0,
      },
      filters: [
        { text: "Solucionada", value: "Solucionada" },
        { text: "Pendiente", value: "Pendiente" },
      ],
      onFilter: (value, record) => {
        return getStatus(record).indexOf(value) === 0;
      },
    },
  ];

  const handleRowClick = (row) => {
    setShowDetailsDrawer({
      visible: true,
      idea: row.idea,
      idIdea: row.idea.id,
    });
    setIssue({ activeKey: "4", issue: row });
  };

  const { data } = state;

  const getUserAndCode = (userUid) => {
    if (!userUid) {
      return "";
    }
    const users = Object.values(props.company.users);
    const deletedUsers = Object.values(props.company.deletedUsers);
    const user = users.find(({ uid }) => uid === userUid);
    const deletedUser = deletedUsers.find(({ uid }) => uid === userUid);
    if (user)
      return (
        user.firstName +
        " " +
        user.lastName1 +
        (user.lastName2 ? " " + user.lastName2 : "") +
        " (" +
        user.username +
        ")"
      );
    else if (deletedUser)
      return (
        deletedUser.firstName +
        " " +
        deletedUser.lastName1 +
        (deletedUser.lastName2 ? " " + deletedUser.lastName2 : "") +
        " (" +
        deletedUser.username +
        ")"
      );
    else return "No encontrado";
  };

  const teamPopOver = (element) => {
    return (
      <Popover
        trigger="click"
        placement="bottom"
        content={
          <ABANCATeams
            issue={element}
            idea={null}
            company={props.company}
            readOnly={true}
          />
        }
        destroyTooltipOnHide
      >
        <div>
          <Typography.Link>
            {`${getUserAndCode(element.assignee[0])} +${
              element.assignee.length - 1
            }`}
          </Typography.Link>
        </div>
      </Popover>
    );
  };

  const handleOnKeyChange = (key) => {
    if (!key) setState({ data: tableData });

    key = key.toLowerCase();
    const filteredData = tableData.filter(
      (entry) =>
        entry.issue.toLowerCase().includes(key) ||
        entry.direccion.toLowerCase().includes(key) ||
        entry.dir_general.toLowerCase().includes(key) ||
        entry.idea.name.toLowerCase().includes(key) ||
        (entry.solution &&
          entry.issueSolutionName.toLowerCase().includes(key)) ||
        (entry.assignee && entry.issueAssigneeNames.toLowerCase().includes(key))
    );

    setState({ data: filteredData });
  };

  const onClose = () => {
    setShowDetailsDrawer({ visible: false, idea: null, idIdea: null });
    setIssue();
  };

  const downloadData = async () => {
    var arrayExcel = [];
    data.forEach((issue) => {
      var assignee = "";
      if (issue.assignee) {
        assignee = issue.issueAssigneeNames;
      } else {
        assignee = "Sin Asignado";
      }

      arrayExcel.push({
        id: issue.id,
        issue: issue.issue,
        peticion: issue.idea.name,
        createdDate: issue.createdDate
          ? moment.unix(issue.createdDate.seconds).format("DD/MM/YYYY")
          : "",
        dir_general: issue.dir_general,
        direccion: issue.direccion,
        duration: issue.duration ? issue.duration : "",
        assignee: assignee,
        finalizador: issue.solution && issue.issueSolutionName,
        guardia: issue.guardia ? "Guardia" : "Incidencia",
        status: issue.closed ? "Solucionada" : "Pendiente",
      });
    });

    setArrayExcel(arrayExcel);
    setTimeout(() => {
      var link = document.getElementById("excelIssues");
      link.click();
    }, 1000);
  };

  return (
    <>
      <Row>
        <NewIssueModal
          visible={newIssue}
          setVisible={setNewIssue}
          userInfo={props.userInfo}
          ideas={props.ideas}
          issue={null}
          setIssue={() => null}
          origin={"TABS"}
        />
        <Col span={6}>
          <InputSearch handleOnKeyChange={handleOnKeyChange} />
        </Col>
        <Col offset={2} span={6}>
          <Button type="primary" onClick={() => setNewIssue(true)}>
            + Nueva Guardia
          </Button>
        </Col>
        <Col span={2}>
          <Button
            onClick={() => {
              downloadData();
            }}
            style={{ color: "white", backgroundColor: "#009900" }}
          >
            Excel
          </Button>
          <ExcelFile
            element={
              <button id={"excelIssues"} style={{ display: "none" }}>
                Excel
              </button>
            }
            filename={"IssuesSummary"}
          >
            <ExcelSheet key={"0"} data={arrayExcel} name={"Bots"}>
              <ExcelColumn label="ID" value="id" key={0} />
              <ExcelColumn label="Incidencia" value="issue" key={1} />
              <ExcelColumn label="Peticion" value="peticion" key={2} />
              <ExcelColumn label="Fecha creacion" value="createdDate" key={3} />
              <ExcelColumn
                label="Direccion General"
                value="dir_general"
                key={4}
              />
              <ExcelColumn label="Direccion" value="direccion" key={5} />
              <ExcelColumn label="Duracion" value="duration" key={6} />
              <ExcelColumn
                label="Creador / Asignado"
                value="assignee"
                key={7}
              />
              <ExcelColumn label="Finalizador" value="finalizador" key={8} />
              <ExcelColumn label="Tipo" value="guardia" key={9} />
              <ExcelColumn label="Estatus" value="status" key={10} />
            </ExcelSheet>
          </ExcelFile>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            bordered
            dataSource={data}
            rowKey={(record) => record.idea.id + "_" + record.id}
            columns={columns}
            scroll={{ x: "max-content" }}
          />

          <Drawer
            style={{ background: "white" }}
            width="100vw"
            onClose={onClose}
            visible={showDatailsDrawer.visible}
            footer={
              <div
                style={{
                  textAlign: "right",
                }}
              ></div>
            }
            destroyOnClose
          >
            {issue && (
              <CompanyIdeaDetails
                company={props.company}
                onCloseDrawer={onClose}
                userInfo={props.userInfo}
                companyName={props.companyName}
                mappings={props.mappings}
                idea={showDatailsDrawer.idea}
                modifiers={issue}
              />
            )}
          </Drawer>
        </Col>
      </Row>
    </>
  );
};

export default IssuesSummary;
