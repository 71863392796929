import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import "../../../../../App.css";
import MyAssigments from "./Tabs/MyAssigments";
import Search from "./Tabs/SearchIdeas";
import InProgress from "./Tabs/InProgress";
import Deployed from "./Tabs/Deployed";
import Inbox from "./Tabs/Inbox";
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;
const PRUEBATabs = (props) => {
  const { t } = useTranslation();
  const [ideas, setIdeas] = useState();
  useEffect(() => {
    if (props.ideas) {
      setIdeas(props.ideas);
    }
  }, [props.ideas]);

  return (
    <Tabs
      defaultActiveKey="1"
      type="card"
      size="default"
      style={{ marginRight: 20, marginLeft: 20, paddingTop: 10 }}
    >
      <TabPane tab={t("Peticiones")} key="IDEA">
        <Search
          companyName={props.companyName}
          company={props.company}
          userInfo={props.userInfo}
          mappings={props.mappings}
          ideas={ideas}
        />
      </TabPane>

      <TabPane tab={t("Recibidas")} key="INBOX">
        <Inbox
          companyName={props.companyName}
          company={props.company}
          userInfo={props.userInfo}
          mappings={props.mappings}
          ideas={ideas}
        />
      </TabPane>

      <TabPane tab={t("En progreso")} key="IN_PROGRESS">
        <InProgress
          companyName={props.companyName}
          company={props.company}
          userInfo={props.userInfo}
          mappings={props.mappings}
          ideas={ideas}
        />
      </TabPane>

      <TabPane tab={t("Producción")} key="PROD">
        <Deployed
          companyName={props.companyName}
          company={props.company}
          userInfo={props.userInfo}
          mappings={props.mappings}
          ideas={ideas}
        />
      </TabPane>

      <TabPane tab={t("Mis asignaciones")} key="MY_ASSIGNMENT">
        <MyAssigments
          companyName={props.companyName}
          company={props.company}
          userInfo={props.userInfo}
          mappings={props.mappings}
          ideas={ideas}
        />
      </TabPane>
    </Tabs>
  );
};

export default PRUEBATabs;
