import firebase from "../server/firebase";
import { message } from "antd";

//Función para la creación de ideas Genéricas
export const createGenericIdea = async (values) => {
  try {
    let pushRef = await firebase
      .database()
      .ref()
      .child("generalIdeas")
      .push({ ...values });
    let id = pushRef.key;

    return await firebase
      .database()
      .ref()
      .child("generalIdeas")
      .child(id)
      .update({ id: id });
  } catch (error) {
    console.error(error);
  }
};

//Función para la actualización de Ideas Genéricas
export const updateGenericIdea = async (ideaId, values) => {
  await firebase
    .database()
    .ref()
    .child("generalIdeas")
    .child(ideaId)
    .update(values);
};

//Función para la actualización de Ideas Genéricas con Referencia Visual del Proceso
export const updateGenericIdeaLoader = async (ideaId, values, display) => {
  await firebase
    .database()
    .ref()
    .child("generalIdeas")
    .child(ideaId)
    .update(values)
    .then((display = message.loading("Saving...", 0)))
    .catch((error) => console.error(error));
};
