import React, { useEffect, useState } from "react";
import { Table, Row, Col, Drawer, Tag, Button } from "antd";
import "../../../../../../App.css";
import moment from "moment";
import InputSearch from "../../InputSearch";
import CreateIdeaHandler from "../../../CreateIdea/CreateIdeaHandler";
import CompanyIdeaDetails from "../../../Details/CompanyIdeaDetails";
import { useTranslation } from "react-i18next";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import ReactExport from "react-data-export";

const Search = (props) => {
  const [state, setState] = useState({
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
  });

  const { t } = useTranslation();
  const [tableData, setTableData] = useState([]);
  const [showDatailsDrawer, setShowDetailsDrawer] = useState({
    idea: null,
    visible: false,
    idIdea: null,
  });
  const [arrayExcel, setArrayExcel] = useState([]);

  const priorities = {
    VERY_HIGH: t("table.priorities.VERY_HIGH"),
    HIGH: t("table.priorities.HIGH"),
    MEDIUM: t("table.priorities.MEDIUM"),
    LOW: t("table.priorities.LOW"),
    VERY_LOW: t("table.priorities.VERY_LOW"),
  };
  const prioritiesColor = {
    VERY_HIGH: "#C41B24",
    HIGH: "#ccb894",
    MEDIUM: "#CECE94",
    LOW: "#97AC85",
    VERY_LOW: "#94B8CC",
  };
  const MapPriority = (priority) => {
    if (priority < 0.5) return "VERY_LOW";
    if (priority < 1.5) return "LOW";
    if (priority < 2.5) return "MEDIUM";
    if (priority < 3) return "HIGH";
    if (priority >= 3) return "VERY_HIGH";
  };

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const getStatus = (record) => {
    switch (record.status) {
      case -1:
        return "Sin procesar";
      case 2:
        return "En progreso";
      case 3:
        if (record.productionStatus) {
          return record.productionStatus === -1 ? "Detenido" : "Finalizado";
        }
        return "En producción";
      case 4:
        return "Rechazada";
      default:
        return "Undefined";
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "idABANCA",
      sorter: (a, b) => parseInt(a.idABANCA) - parseInt(b.idABANCA),
      width: "5%",
    },
    {
      title: t("table.headers.name"),
      dataIndex: "name",
      render: (text, record) => (
        <span
          style={{ padding: "13px 13px 6px 6px", cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {record.name}
        </span>
      ),
    },
    {
      title: t("table.headers.createdDate"),
      dataIndex: "createdDate",
      render: (createdDate) =>
        createdDate
          ? moment.unix(createdDate.seconds).format("DD/MM/YYYY")
          : "",
      sorter: {
        compare: (a, b) => a.createdDate.seconds - b.createdDate.seconds,
        multiple: 2,
      },
    },
    {
      title: t("table.headers.priority"),
      dataIndex: "priority",
      sortDirections: ["descend", "ascend"],
      sorter: {
        compare: (a, b) => a.priority - b.priority,
      },
      render: (priority, record) => {
        if (priority) {
          priority = MapPriority(priority);
          let color = prioritiesColor[priority];
          return (
            <Tag color={color} key={priority}>
              {`${priorities[priority].toUpperCase()}` +
                (record.priority ? ` (${record.priority})` : "")}
            </Tag>
          );
        } else {
          return <Tag key={priority}>{t("SIN ASIGNAR")}</Tag>;
        }
      },
    },
    {
      title: t("table.headers.workCategory"),

      render: (priority, record) => {
        return record.departmentTitle;
      },
    },
    {
      title: t("table.headers.status"),
      dataIndex: "status",
      render: (status, record) => {
        return t(getStatus(record));
      },
      sorter: {
        compare: (a, b) => a.status - b.status,
        multiple: 2,
      },
      filters: [
        { text: "Sin procesar", value: "Sin procesar" },
        { text: "En progreso", value: "En progreso" },
        { text: "Detenido", value: "Detenido" },
        { text: "Finalizado", value: "Finalizado" },
        { text: "En producción", value: "En producción" },
        { text: "Rechazadas", value: "Rechazada" },
      ],
      onFilter: (value, record) => {
        return getStatus(record).indexOf(value) === 0;
      },
    },
    {
      title: t("Petición CER"),
      className: "center-column",
      dataIndex: "peticionCER",
      width: 100,
      render: (text, record) =>
        record.peticionCER ? (
          <CheckCircleFilled
            style={{
              fontWeight: "bolder",
              fontSize: "1.5em",
              color: "#b7eb8f",
            }}
          />
        ) : (
          <CloseCircleFilled
            style={{
              fontWeight: "bolder",
              fontSize: "1.5em",
              color: "#ffa39e",
            }}
          />
        ),
      onFilter: (value, record) => {
        return record.peticionCER === value;
      },
      filters: [
        { text: "Peticion CER", value: true },
        { text: "No peticion CER", value: false },
      ],
    },
  ];

  const handleRowClick = (row) => {
    setShowDetailsDrawer({ visible: true, idea: row, idIdea: row.id });
  };

  useEffect(() => {
    if (props.ideas) {
      let ideas = props.ideas;
      setTableData(ideas);
      setState({
        data: ideas,
      });
    }
    if (showDatailsDrawer.visible) {
      let index = props.ideas.findIndex(
        (idea) => idea.id === showDatailsDrawer.idIdea
      );
      setShowDetailsDrawer({
        visible: true,
        idea: props.ideas[index],
        idIdea: props.ideas[index].id,
      });
    }
    console.log(props.userInfo);
  }, [
    props.ideas,
    showDatailsDrawer.idIdea,
    showDatailsDrawer.visible,
    props.userInfo,
  ]);

  const { data } = state;

  const handleOnKeyChange = (key) => {
    if (!key) setState({ data: tableData });

    key = key.toLowerCase();
    const filteredData = tableData.filter(
      (entry) =>
        entry.name.toLowerCase().includes(key) ||
        (entry.idABANCA && entry.idABANCA.toString().includes(key)) ||
        (entry.departmentTitle &&
          entry.departmentTitle.toLowerCase().includes(key))
    );

    setState({ data: filteredData });
  };

  const onClose = () => {
    setShowDetailsDrawer({ visible: false, idea: null, idIdea: null });
  };

  const downloadData = async () => {
    var arrayExcel = [];
    data.forEach((idea) => {
      arrayExcel.push({
        idABANCA: idea.id,
        name: idea.name,
        createdDate: moment.unix(idea.createdDate.seconds).format("DD/MM/YYYY"),
        priority: idea.priority,
        departmentTitle: idea.departmentTitle,
        status: idea.status,
        peticionCER: idea.peticionCER,
      });
    });
    setArrayExcel(arrayExcel);
    setTimeout(() => {
      var link = document.getElementById("excelBots");
      link.click();
    }, 1000);
  };

  return (
    <>
      <Row>
        <Col span={6}>
          <InputSearch handleOnKeyChange={handleOnKeyChange} />
        </Col>

        <Col offset={2} span={3}>
          <CreateIdeaHandler
            userInfo={props.userInfo}
            company={props.company}
            ideas={tableData}
          />
        </Col>
        <Col span={11}></Col>
        <Col span={2}>
          <Button
            onClick={() => {
              downloadData();
            }}
            style={{ color: "white", backgroundColor: "#009900" }}
          >
            Excel
          </Button>
          <ExcelFile
            element={
              <button id={"excelBots"} style={{ display: "none" }}>
                Download Data
              </button>
            }
            filename={"Backlog"}
          >
            <ExcelSheet key={"0"} data={arrayExcel} name={"Bots"}>
              <ExcelColumn label="ID" value="idABANCA" key={0} />
              <ExcelColumn
                label={t("table.headers.name")}
                value="name"
                key={1}
              />
              <ExcelColumn
                label={t("table.headers.createdDate")}
                value="createdDate"
                key={2}
              />
              <ExcelColumn
                label={t("table.headers.priority")}
                value="priority"
                key={3}
              />
              <ExcelColumn
                label={t("table.headers.workCategory")}
                value="departmentTitle"
                key={4}
              />
              <ExcelColumn
                label={t("table.headers.status")}
                value="status"
                key={5}
              />
              <ExcelColumn
                label={t("Petición CER")}
                value="peticionCER"
                key={6}
              />
            </ExcelSheet>
          </ExcelFile>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            bordered
            dataSource={data}
            rowKey={(idea) => idea.id}
            columns={columns}
            scroll={{ x: 1300 }}
          />

          <Drawer
            style={{ background: "white" }}
            width="100vw"
            onClose={onClose}
            visible={showDatailsDrawer.visible}
            footer={
              <div
                style={{
                  textAlign: "right",
                }}
              ></div>
            }
          >
            <CompanyIdeaDetails
              company={props.company}
              onCloseDrawer={onClose}
              userInfo={props.userInfo}
              companyName={props.companyName}
              mappings={props.mappings}
              idea={showDatailsDrawer.idea}
            />
          </Drawer>
        </Col>
      </Row>
    </>
  );
};

export default Search;
