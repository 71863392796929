// import CalculatorJson from "../calculator.json";

// const calculator = CalculatorJson.home;

// Definición de Constantes empleadas en los cálculos

export const checkNullable = [
  { name: "Sí", value: 0 },
  { name: "No", value: 1 },
  { name: "N/A", value: 2 },
];

export const zeroMoreTwo = [
  { name: "0", value: 0 },
  { name: "1", value: 1 },
  { name: "2", value: 2 },
  { name: ">2", value: 3 },
];

export const kDown = {
  definition: "definition_low",
  scale: "0 ≤ C < 2",
  effort: "≈ 40 h.",
};

export const kMedium_down = {
  definition: "definition_medium_low",
  scale: "2 ≤ C < 4",
  effort: "≈ 80 h.",
};

export const kMedium = {
  definition: "definition_medium",
  scale: "4 ≤ C < 6",
  effort: "≈ 120 h.",
};

export const kMedium_high = {
  definition: "definition_medium_high",
  scale: "6 ≤ C < 8",
  effort: "≈ 200 - 250 h.",
};

export const kHigh = {
  definition: "definition_high",
  scale: "8 ≤ C < 10",
  effort: "> 300 h.",
};

export const kComplexity = [
  {
    amount: 1500.0,
    complexity: 1,
    complexityLevel: 0,
    complexityName: "complexity_low",
    definition: kDown,
  },
  {
    amount: 3000,
    complexity: 2,
    complexityLevel: 0,
    complexityName: "complexity_low",
    definition: kDown,
  },
  {
    amount: 4500,
    complexity: 3,
    complexityLevel: 0,
    complexityName: "complexity_medium_low",
    definition: kMedium_down,
  },
  {
    amount: 6000,
    complexity: 4,
    complexityLevel: 0,
    complexityName: "complexity_medium",
    definition: kMedium,
  },
  {
    amount: 7500,
    complexity: 5,
    complexityLevel: 0,
    complexityName: "complexity_medium",
    definition: kMedium,
  },
  {
    amount: 9000,
    complexity: 6,
    complexityLevel: 0,
    complexityName: "complexity_medium_high",
    definition: kMedium_high,
  },
  {
    amount: 10500,
    complexity: 7,
    complexityLevel: 0,
    complexityName: "complexity_medium_high",
    definition: kMedium_high,
  },
  {
    amount: 12000,
    complexity: 8,
    complexityLevel: 0,
    complexityName: "complexity_high",
    definition: kHigh,
  },
  {
    amount: 13500,
    complexity: 9,
    complexityLevel: 0,
    complexityName: "complexity_high",
    definition: kHigh,
  },
  {
    amount: 15000,
    complexity: 10,
    complexityLevel: 0,
    complexityName: "complexity_high",
    definition: kHigh,
  },
  {
    amount: 1000000,
    complexity: 0,
    complexityLevel: 0,
    complexityName: "",
    definition: kHigh,
  },
];

export const mix = 50.0; // mix
export const gadi = 0.2; // G/A/Di
export const dev = 0.6; // Desarro
export const qa = 0.12; // Pruebas

export const readFile = 1.0; // Leer archivo entrada
export const changeInputFile = 0.5; // Modificación archivo entrada
export const imageTemplate = 4.0; // Imagen / Plantilla F.C.
export const email = 2.0; // e-mail
export const pDF = 2.0; // PDF estructurado
export const excel = 1.5; // Excel - Varios
export const manuscript = 4.0; // Manuscrito
export const logIn = 0.5; // Log-in
export const generation = 2.0; // Generación
export const templates = 1.0; // Aplantillados
export const dataTreatment = 1.0; //Tratamiento de datos
export const macros = 1.0; // Macros
export const sendMail = 0.25; //Envío e-mail
export const moveFiles = 0.15; // Mover archivos
export const downloadMailC = 0.25; //Descarga e-mail
export const actionNav = 0.25; // Una acción por navegación Hotkeys
export const actionScreen = 0.5; // Una acción Screen/web Scrapping
export const captureC = 1.0; // Una captura/extracción de datos
export const openNav = 0.25; // Abrir navegador

export const kException = 1.0;
export const kExceptionHandled = 2.0;
export const kDecisionRule = 0.5;

export const kControlQuestionOneList = [
  { name: "1", value: 1 },
  { name: "2-3", value: 2 },
  { name: ">3", value: 3 },
];

export const kControlQuestionTwoList = [
  { name: "0", value: 0 },
  { name: "1-2", value: 1 },
  { name: ">2", value: 2 },
];

export const kControlQuestionThreeList = [
  { name: "0", value: 0 },
  { name: "1", value: 1 },
  { name: "2", value: 2 },
  { name: "3", value: 3 },
  { name: ">3", value: 4 },
];
