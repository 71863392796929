import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import "../../../../../App.css";
import Search from "./Tabs/SearchIdeas";
import { default as Pipeline } from "./Tabs/Analysis";
import InProgress from "./Tabs/InProgress";
import Deployed from "./Tabs/Deployed";
import Inbox from "./Tabs/Inbox";
import BotsSummary from "./Tabs/BotsSummary";
import ActivitiesSummary from "./Tabs/ActivitiesSummary";
import Maintenances from "./Tabs/MaintenancesSummary";

const { TabPane } = Tabs;
const TabsView = (props) => {
  const [ideas, setIdeas] = useState();
  useEffect(() => {
    if (props.ideas) {
      setIdeas(props.ideas);
    }
  }, [props.ideas]);
  return (
    <Tabs
      defaultActiveKey="1"
      type="card"
      size="default"
      style={{ marginRight: 20, marginLeft: 20, paddingTop: 10 }}
    >
      <TabPane tab={"Backlog"} key="IDEA">
        <Search
          companyName={props.companyName}
          company={props.company}
          userInfo={props.userInfo}
          mappings={props.mappings}
          ideas={ideas}
          areas={props.areas}
        />
      </TabPane>

      <TabPane tab={"Not processed"} key="INBOX">
        <Inbox
          companyName={props.companyName}
          company={props.company}
          userInfo={props.userInfo}
          mappings={props.mappings}
          ideas={ideas}
          areas={props.areas}
        />
      </TabPane>

      <TabPane tab={"Analysis"} key="PIPELINE">
        <Pipeline
          companyName={props.companyName}
          company={props.company}
          userInfo={props.userInfo}
          mappings={props.mappings}
          ideas={ideas}
          areas={props.areas}
        />
      </TabPane>

      <TabPane tab={"In progress"} key="IN_PROGRESS">
        <InProgress
          companyName={props.companyName}
          company={props.company}
          userInfo={props.userInfo}
          mappings={props.mappings}
          ideas={ideas}
          areas={props.areas}
        />
      </TabPane>

      <TabPane tab={"Production"} key="PROD">
        <Deployed
          companyName={props.companyName}
          company={props.company}
          userInfo={props.userInfo}
          mappings={props.mappings}
          ideas={ideas}
          areas={props.areas}
        />
      </TabPane>

      <TabPane tab={"Bots Summary"} key="BOTS_SUMMARY">
        <BotsSummary
          companyName={props.companyName}
          company={props.company}
          userInfo={props.userInfo}
          mappings={props.mappings}
          ideas={ideas}
          areas={props.areas}
        />
      </TabPane>

      <TabPane tab={"Activities Summary"} key="ACT_SUMMARY">
        <ActivitiesSummary
          company={props.company}
          userInfo={props.userInfo}
          ideas={ideas}
          areas={props.areas}
        />
      </TabPane>
      <TabPane tab={"Maintenances"} key="MAINTENNCES">
        <Maintenances
          company={props.company}
          userInfo={props.userInfo}
          ideas={ideas}
          areas={props.areas}
        />
      </TabPane>
    </Tabs>
  );
};

export default TabsView;
