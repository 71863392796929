import React, { useState, useEffect } from "react";
import { Row, Col, Table, Drawer, Space, Popconfirm, Button, Tag } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import CompanyIdeaDetails from "../../../Details/CompanyIdeaDetails";
import firebase from "../../../../../../server/firebase";
import InputSearch from "../../InputSearch";
import ReactExport from "react-data-export";

const Deployed = (props) => {
  const { t } = useTranslation();

  const [deployedData, setDeployedData] = useState({
    ideas: [],
    deployedBenefit: 0,
    totalIdeas: 0,
    failedExecution: 0,
    failedExecutionCost: 0,
    deployedData: { x: "", y: 0 },
  });

  const [showDatailsDrawer, setShowDetailsDrawer] = useState({
    idea: null,
    visible: false,
    idIdea: null,
  });

  const [arrayExcel, setArrayExcel] = useState([]);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const handleRowClick = (row) => {
    setShowDetailsDrawer({ visible: true, idea: row, idIdea: row.id });
  };

  const onClose = () => {
    setShowDetailsDrawer({ idea: null, visible: false, idIdea: null });
  };

  const [tableState, setTableState] = useState({
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
  });

  const handleOnKeyChange = (key) => {
    if (!key) setTableState({ data: deployedData.ideas });

    key = key.toLowerCase();
    const filteredData = deployedData.ideas.filter(
      (entry) =>
        entry.name.toLowerCase().includes(key) ||
        (entry.idABANCA && entry.idABANCA.toString().includes(key)) ||
        (entry.departmentTitle &&
          entry.departmentTitle.toLowerCase().includes(key))
    );

    setTableState({ data: filteredData });
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "idABANCA",
      sorter: (a, b) => parseInt(a.idABANCA) - parseInt(b.idABANCA),
      width: "5%",
    },
    {
      title: t("Nombre"),
      dataIndex: "name",
      render: (name, record) => (
        <span
          style={{ padding: "13px 13px 6px 6px", cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {name}
        </span>
      ),
      width: "40%",
    },
    {
      title: t("table.headers.workCategory"),

      render: (priority, record) => {
        return record.departmentTitle;
      },
      width: "40%",
    },
    {
      title: "Status",
      dataIndex: "productionStatus",
      render: (record) => {
        let color;
        let mensaje;
        color = record === -1 ? "default" : "success";
        mensaje = record === -1 ? "Detenido" : "En Producción";
        return (
          <>
            <Tag color={color}>{mensaje.toUpperCase()}</Tag>
          </>
        );
      },
      filters: [
        { text: "Detenido", value: "Detenido" },
        { text: "En Producción", value: "En Producción" },
      ],
      onFilter: (value, record) => {
        let status =
          record.productionStatus === -1 ? "Detenido" : "En Producción";
        return status.indexOf(value) === 0;
      },
      width: "10%",
    },
    {
      title: "",
      key: "action",
      fixed: "right",
      width: 250,
      render: (text, record) => {
        return (
          <Space size={"middle"} width={"100%"}>
            <Popconfirm
              placement="bottomLeft"
              title={t("ApprovePetitionPopMsg")}
              onConfirm={(e) => handleApproveButton(record, e)}
              okText={t("Yes")}
              cancelText="No"
            >
              <Button
                style={{ width: "100px" }}
                key={record.id}
                className={"ant-btn"}
              >
                Finalizar
              </Button>
            </Popconfirm>
            <Popconfirm
              placement="bottomLeft"
              title={t("RejectPetitionPopMsg")}
              onConfirm={(e) =>
                handleRejectButton(record, e, record.productionStatus === -1)
              }
              okText={t("Yes")}
              cancelText="No"
            >
              <Button
                style={{ width: "100px" }}
                key={record.id}
                className={
                  record.productionStatus !== -1 ? "ant-btn-dangerous" : null
                }
              >
                {record.productionStatus !== -1 ? "Detener" : "Reanudar"}
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const handleApproveButton = (idea, event) => {
    event.preventDefault();
    //console.log("ideaApprove: ", idea);
    // handleMail(idea, "Aprobada");
    firebase
      .database()
      .ref()
      .child("ideas")
      .child(idea.id)
      .update({ productionStatus: 1 })
      .catch((err) => console.error(err));
  };

  const handleRejectButton = (idea, event, productionStatus) => {
    event.preventDefault();
    //console.log("ideaReject: ", idea);
    // handleMail(idea, "Rechazada");
    firebase
      .database()
      .ref()
      .child("ideas")
      .child(idea.id)
      .update({ productionStatus: productionStatus ? null : -1 })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (props.ideas) {
      let ideas = [];
      props.ideas.forEach(
        (doc) =>
          doc.status === 3 &&
          ideas.push({
            ...doc,
            id: doc.id,
          })
      );
      let benefit = ideas.reduce((acc, idea) => acc + idea.estimatedBenefit, 0);

      let filteredIdeas = ideas.filter(
        (idea) =>
          idea.createdDate.seconds >= moment().subtract(3, "months").unix()
      );
      let months = new Map();
      filteredIdeas.forEach((idea) =>
        months.set(
          moment.unix(idea.createdDate.seconds).month(),
          moment.unix(idea.createdDate.seconds).format("MMMM")
        )
      );

      let chartData = [];

      for (const [key, value] of months.entries()) {
        chartData.push({
          x: value,
          y: filteredIdeas
            .filter(
              (idea) => moment.unix(idea.createdDate.seconds).month() === key
            )
            .reduce((acc, idea) => acc + idea.estimatedBenefit, 0),
        });
      }

      ideas = ideas.filter((i) => i.productionStatus !== 1);
      setTableState({ data: ideas });
      setDeployedData({
        ideas: ideas,
        deployedBenefit: "€" + benefit,
        totalIdeas: ((ideas.length / props.ideas.length) * 100).toFixed(),
        failedExecution: 0,
        failedExecutionCost: 0,
        chartData: chartData,
      });

      if (showDatailsDrawer.visible) {
        let index = props.ideas.findIndex(
          (idea) => idea.id === showDatailsDrawer.idIdea
        );
        setShowDetailsDrawer({
          visible: true,
          idea: props.ideas[index],
          idIdea: props.ideas[index].id,
        });
      }
    }
  }, [props.ideas, showDatailsDrawer.idIdea, showDatailsDrawer.visible]);

  const downloadData = async () => {
    var arrayExcel = [];
    tableState.data.forEach((idea) => {
      arrayExcel.push({
        idABANCA: idea.id,
        name: idea.name,
        departmentTitle: idea.departmentTitle,
        status: idea.status,
      });
    });
    setArrayExcel(arrayExcel);
    setTimeout(() => {
      var link = document.getElementById("excelBots2");
      link.click();
    }, 1000);
  };

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col /* span={14} */>
          <div>
            <Drawer
              style={{ background: "white" }}
              theme="light"
              width="100vw"
              onClose={onClose}
              visible={showDatailsDrawer.visible}
              footer={
                <div
                  style={{
                    textAlign: "right",
                  }}
                ></div>
              }
            >
              <CompanyIdeaDetails
                company={props.company}
                userInfo={props.userInfo}
                onCloseDrawer={onClose}
                companyName={props.companyName}
                mappings={props.mappings}
                idea={showDatailsDrawer.idea}
              />
            </Drawer>
            <br />
            <Row>
              <Col span={6}>
                <InputSearch handleOnKeyChange={handleOnKeyChange} />
              </Col>
              <Col span={16}></Col>
              <Col span={2}>
                <Button
                  onClick={() => {
                    downloadData();
                  }}
                  style={{ color: "white", backgroundColor: "#009900" }}
                >
                  Excel
                </Button>
                <ExcelFile
                  element={
                    <button id={"excelBots2"} style={{ display: "none" }}>
                      Download Data
                    </button>
                  }
                  filename={"In Progress"}
                >
                  <ExcelSheet key={"0"} data={arrayExcel} name={"Bots"}>
                    <ExcelColumn label="ID" value="idABANCA" key={0} />
                    <ExcelColumn label="Idea" value="name" key={1} />
                    <ExcelColumn
                      label={t("table.headers.workCategory")}
                      value="departmentTitle"
                      key={2}
                    />
                    <ExcelColumn label={t("Estatus")} value="status" key={3} />
                  </ExcelSheet>
                </ExcelFile>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table
                  bordered
                  columns={columns}
                  dataSource={tableState.data}
                  rowKey={(record) => record.id}
                  size="small"
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row></Row>
      <br />
      <br />
      <br />
    </>
  );
};

export default Deployed;
