import React, { useEffect, useState } from "react";
import { Button, Row, Col, Typography } from "antd";
import { getAuthUserInfo } from "../../db/firedataCalls";
import "./Login.css";
import firebase from "../../server/firebase";

const Waiting = () => {
  const { Title, Text } = Typography;
  const [user, setUser] = useState(null);
  const status = {
    pending:
      "your approval is being processed, please contact your administrator for more information",
    disabled:
      "your user has been disabled, please contact your administrator for more information",
  };

  useEffect(() => {
    const currUser = firebase.auth().currentUser;
    const getUser = async () => {
      if (currUser && currUser.uid) {
        const userInfoData = await getAuthUserInfo(currUser.uid);
        const userInfo = {
          ...Object.values(userInfoData)[0],
          dbKey_: Object.keys(userInfoData)[0],
          displayName: currUser.displayName,
        };
        setUser(userInfo);
        console.log(userInfo);
      }
    };
    getUser();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          window.location.reload();
        });
    }, 5000);
  }, []);

  return (
    user && (
      <Row gutter={[0, 10]} className="full-size-container">
        <Col span={24}>
          <Title level={2}>User Status:</Title>
        </Col>
        <Col span={24}>
          <Text style={{ fontSize: 20 }}>
            {user.displayName + ", "}
            {user.status === 3 ? status.pending : status.disabled}
          </Text>
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Button
            className="button-submit"
            shape="round"
            size="large"
            type="primary"
            onClick={() =>
              firebase.auth().signOut() && window.location.reload()
            }
          >
            Sign Out
          </Button>
        </Col>
      </Row>
    )
  );
};

export default Waiting;
