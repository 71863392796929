import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Drawer, message } from "antd";
import moment from "moment";
import { Button, Table, Space, Popconfirm } from "antd";
import "../../../../../../App.css";
import CompanyIdeaDetails from "../../../Details/CompanyIdeaDetails";
import { updateIdeaStatus } from "../../../../../../db/firedataCalls";
import InputSearch from "../../InputSearch";
import firebase from "../../../../../../server/firebase";
import { useTranslation } from "react-i18next";
import { Resizable } from "react-resizable";

const Inbox = (props) => {
  const [inboxData, setInboxData] = useState(null);
  const { t } = useTranslation();

  const [showDatailsDrawer, setShowDetailsDrawer] = useState({
    idea: null,
    visible: false,
    idIdea: null,
  });

  const columns = [
    {
      title: "Idea",
      dataIndex: "name",
      className: "clickable-col",
      render: (name, record) => (
        <span
          style={{ padding: "13px 13px 6px 6px", cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {name}
        </span>
      ),
    },
    {
      title: t("table.headers.createdDate"),
      dataIndex: "createdDate",
      render: (createdDate) =>
        createdDate
          ? moment.unix(createdDate.seconds).format("DD/MM/YYYY")
          : "",
      sorter: {
        compare: (a, b) => a.createdDate.seconds - b.createdDate.seconds,
      },
    },
    {
      title: t("table.headers.priority"),
      dataIndex: "priority",
      sortDirections: ["descend", "ascend"],
      sorter: {
        compare: (a, b) => a.priority - b.priority,
      },
    },
    {
      title: t("table.headers.workCategory"),
      dataIndex: "workCategory",
    },
    {
      title: "",
      key: "action",
      fixed: "right",
      width: 250,
      render: (text, record) => {
        return (
          <Space size={"middle"} width={"100%"}>
            <Popconfirm
              placement="bottomLeft"
              title={t("ApprovePetitionPopMsg")}
              onConfirm={(e) => handleApproveButton(record, e)}
              okText={t("Yes")}
              cancelText="No"
            >
              <Button
                style={{ width: "100px" }}
                key={record.id}
                className={"ant-btn"}
              >
                {t("Aprobar")}
              </Button>
            </Popconfirm>
            <Popconfirm
              placement="bottomLeft"
              title={t("RejectPetitionPopMsg")}
              onConfirm={(e) => handleRejectButton(record, e)}
              okText={t("Yes")}
              cancelText="No"
            >
              <Button
                style={{ width: "100px" }}
                key={record.id}
                className={"ant-btn-dangerous"}
              >
                {t("RejectPetitionBtn")}
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const Loader = () => (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        textAlign: "center",
        marginTop: "25%",
      }}
    >
      <Spin size="large" spinning={true}></Spin>
    </div>
  );

  const ResizableTitle = (props) => {
    const { onResize, width, ...restProps } = props;

    if (!width) {
      return <th {...restProps} />;
    }

    return (
      <Resizable
        width={width}
        height={0}
        handle={
          <span
            className="react-resizable-handle"
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        }
        onResize={onResize}
        draggableOpts={{ enableUserSelectHack: false }}
      >
        <th {...restProps}></th>
      </Resizable>
    );
  };

  const onClose = () => {
    setShowDetailsDrawer({ visible: false, idea: null, idIdea: null });
  };

  const handleApproveButton = (idea, event) => {
    event.preventDefault();
    updateIdeaStatus(idea, 1);
    message.success(t("IdeaApproved"));
  };

  const handleRejectButton = (idea, event) => {
    event.preventDefault();
    firebase
      .database()
      .ref()
      .child("ideas")
      .child(idea.id)
      .update({ status: 4 })
      .catch((err) => console.error(err));
  };

  const handleRowClick = (row) => {
    setShowDetailsDrawer({ visible: true, idea: row, idIdea: row.id });
  };

  useEffect(() => {
    if (props.ideas) {
      let ideas = [];
      props.ideas.forEach(
        (doc) =>
          doc.status === -1 &&
          ideas.push({
            ...doc,
          })
      );

      setInboxData(ideas);
      setTableState({ data: ideas });

      if (showDatailsDrawer.visible) {
        let index = props.ideas.findIndex(
          (idea) => idea.id === showDatailsDrawer.idIdea
        );
        setShowDetailsDrawer({
          visible: true,
          idea: props.ideas[index],
          idIdea: props.ideas[index].id,
        });
      }
    }
  }, [props.ideas, showDatailsDrawer.idIdea, showDatailsDrawer.visible]);

  const tableComponents = {
    header: {
      cell: ResizableTitle,
    },
  };
  const [tableState, setTableState] = useState({
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
  });

  const handleOnKeyChange = (key) => {
    if (!key) setTableState({ data: inboxData });

    key = key.toLowerCase();
    const filteredData = inboxData.filter(
      (entry) =>
        entry.name.toLowerCase().includes(key) ||
        (entry.id && entry.id.toString().includes(key)) ||
        (entry.workCategory && entry.workCategory.toLowerCase().includes(key))
    );

    setTableState({ data: filteredData });
  };

  return inboxData ? (
    <div style={{ padding: 10 }}>
      <Drawer
        style={{ background: "white" }}
        theme="light"
        width="100vw"
        onClose={onClose}
        visible={showDatailsDrawer.visible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          ></div>
        }
        destroyOnClose
      >
        <CompanyIdeaDetails
          company={props.company}
          onCloseDrawer={onClose}
          userInfo={props.userInfo}
          companyName={props.companyName}
          mappings={props.mappings}
          idea={showDatailsDrawer.idea}
        />
      </Drawer>
      <Row gutter={[20, 30]}>
        <Col span={24}>
          <Row>
            <Col span={6}>
              <InputSearch handleOnKeyChange={handleOnKeyChange} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Table
                pagination={{
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }}
                bordered
                components={tableComponents}
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={tableState.data}
                scroll={{ x: 1300 }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  ) : (
    <Loader />
  );
};

export default Inbox;
