import React, { useEffect } from "react";
import { Bullet } from "@antv/g2plot";
import { useTranslation } from "react-i18next";

const BulletBenefitChart = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    let workCategoryStats = props.chartData;
    let arrMax = [];
    let data = [];
    Object.keys(workCategoryStats).forEach((key) => {
      arrMax.push(workCategoryStats[key]["totalIdeasSubmitted"]);
      data.push({
        title: t("dashboard.charts.labels." + key),
        measures: [workCategoryStats[key].totalIdeasDeployed],
        targets: [workCategoryStats[key].totalIdeasSubmitted],
      });
    });
    let max = Math.max(...arrMax);

    new Bullet(document.getElementById("chart_container"), {
      data,
      rangeMax: max,

      title: {
        visible: false,
      },
      description: {
        visible: true,
        text: t("Grafica que muestra el avance por área de negocio"),
      },
    }).render();
    // eslint-disable-next-line
  }, [props.chartData]);

  return <div id="chart_container"></div>;
};

export default BulletBenefitChart;
