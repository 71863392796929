import firebaseAdmin from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyC0NqI0FfE7LHtTht6jRSN-bVDC2zWZ2Z8",
  authDomain: "rpa-manager.firebaseapp.com",
  databaseURL: "https://rpa-manager.firebaseio.com",
  projectId: "rpa-manager",
  storageBucket: "gs://rpa-manager.appspot.com",
  messagingSenderId: "929298468801",
  appId: "1:929298468801:web:dcb586243eb5bfcc29a940",
  measurementId: "G-TNN654TKHY",
};

const firebaseConfigEU = {
  apiKey: "AIzaSyCXbkT0JMzBYkhjhxBW9EmgH4usJuX6cHY",
  authDomain: "rpa-manager-eu.firebaseapp.com",
  databaseURL: "https://rpa-manager-eu.firebaseio.com",
  projectId: "rpa-manager-eu",
  storageBucket: "rpa-manager-eu.appspot.com",
  messagingSenderId: "1061469745357",
  appId: "1:1061469745357:web:08731b4df9e926dd207984",
};

const firebase = firebaseAdmin.initializeApp(
  process.env.EU ? firebaseConfigEU : firebaseConfig
);

export default firebase;
