import React, { useEffect, useState } from "react";
import { Row, Col, message, Select, Divider, Avatar, List } from "antd";
import { updateIssueABANCA } from "../../../../db/firedataCalls";
import { CloseCircleFilled } from "@ant-design/icons";
import { presetPalettes } from "@ant-design/colors";

export const ABANCATeams = (props) => {
  const [team, setTeam] = useState([]);
  const [users, setUsers] = useState([]);
  const [deletedUsers, setDeletedUsers] = useState([]);
  const readOnly = props.readOnly;

  useEffect(() => {
    if (props.company) {
      let tempUsers = Object.values(props.company.users);
      let tempDeletedUsers = Object.values(props.company.deletedUsers);
      let colors = [];
      Object.values(presetPalettes).forEach((colorSet) => {
        colors.push(colorSet.primary);
      });
      let res = (tempUsers.length + tempDeletedUsers.length) / colors.length;
      if (res > 1) {
        for (let a = 0; a < Math.round(res); a++) {
          colors.push(...colors);
        }
      }
      tempUsers = tempUsers.map((user, index) => ({
        ...user,
        color: colors[index],
      }));
      tempDeletedUsers = tempDeletedUsers.map((user, index) => ({
        ...user,
        color: colors[index],
      }));
      if (props.issue && props.issue.assignee) {
        let tempTeam = props.issue.assignee.map((assignee) => {
          let user = tempUsers.find(({ uid }) => uid === assignee);
          tempUsers = tempUsers.filter(({ uid }) => !(uid === assignee));
          tempDeletedUsers = tempDeletedUsers.filter(
            ({ uid }) => !(uid === assignee)
          );
          return user;
        });
        setTeam([...tempTeam]);
      } else setTeam([]);
      setUsers([...tempUsers]);
      setDeletedUsers([...tempDeletedUsers]);
    }
  }, [props.company, props.issue]);

  const returnName = (user) => {
    return (
      user.firstName +
      " " +
      (user.lastName1 &&
        user.lastName1 + (user.lastName2 && " " + user.lastName2))
    );
  };

  const getUserAndCode = (userUid) => {
    let user =
      users.find(({ uid }) => uid === userUid) ||
      deletedUsers.find(({ uid }) => uid === userUid) ||
      team.find(({ uid }) => uid === userUid);
    if (user)
      return (
        user.firstName +
        " " +
        user.lastName1 +
        (user.lastName2 ? " " + user.lastName2 : null) +
        " (" +
        user.username +
        ")"
      );
  };

  const renderAvatar = (user) => {
    if (user)
      return (
        <Avatar style={{ backgroundColor: user.color }}>
          {user.firstName[0] + user.lastName1[0]}
        </Avatar>
      );
  };

  const renderAssignee = (user) => {
    if (user)
      return (
        <>
          {renderAvatar(user)} {getUserAndCode(user.uid)}
        </>
      );
  };

  const saveTeam = (tempTeam, tempUsers, tempDeletedUsers) => {
    let tempIssues = props.idea.issues;
    let index = tempIssues.findIndex(({ id }) => id === props.issue.id);
    let newTeam = tempTeam.map((user) => user.uid);
    tempIssues[index].assignee = [...newTeam];
    updateIssueABANCA(props.idea.id, tempIssues)
      .then(() => {
        setTeam([...tempTeam]);
        setUsers([...tempUsers]);
        setDeletedUsers([...tempDeletedUsers]);
      })
      .catch(() => {
        message.error("Ocurrió un error al asignar");
      });
  };

  const unAssign = (item) => {
    let tempTeam = team;
    let tempUsers = users;
    let tempDeletedUsers = deletedUsers;
    if (
      Object.values(props.company.deletedUsers).includes(
        ({ uid }) => uid === item.uid
      )
    )
      tempDeletedUsers.push({ ...item });
    else tempUsers.push({ ...item });
    tempTeam = tempTeam.filter(({ uid }) => !(uid === item.uid));
    saveTeam(tempTeam, tempUsers, tempDeletedUsers);
  };

  const addToTeam = (id) => {
    let user = users.find(({ uid }) => uid === id);
    let tempTeam = team;
    let tempUsers = users;
    let tempDeletedUsers = deletedUsers;
    tempTeam.push({ ...user });
    tempUsers = tempUsers.filter(({ uid }) => !(uid === user.uid));
    saveTeam(tempTeam, tempUsers, tempDeletedUsers);
  };

  return (
    <Row style={{ width: 400 }}>
      <Col span={24} className="infinite-container">
        <Divider>Equipo</Divider>
        <List
          dataSource={team}
          renderItem={(item) => {
            let user = team.find(({ uid }) => uid === item.uid);
            return (
              <List.Item
                key={item.uid}
                actions={
                  !readOnly && [
                    <CloseCircleFilled onClick={() => unAssign(item)} />,
                  ]
                }
              >
                <List.Item.Meta
                  avatar={renderAvatar(user)}
                  title={returnName(item)}
                  description={item.username}
                />
              </List.Item>
            );
          }}
        />
      </Col>
      {!readOnly && (
        <Col span={24}>
          <Divider>Añadir más</Divider>
          <Select
            showSearch
            placeholder="Buscar usuario"
            style={{ width: "100%" }}
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onSelect={(selected) => {
              addToTeam(selected);
            }}
            value={null}
          >
            {users.map((user) => (
              <Select.Option
                key={user.uid}
                value={user.uid}
                label={getUserAndCode(user.uid)}
              >
                <div className="demo-option-label-item">
                  {renderAssignee(user)}
                </div>
              </Select.Option>
            ))}
            {deletedUsers.map((user) => (
              <Select.Option
                key={user.uid}
                value={user.uid}
                label={getUserAndCode(user.uid)}
                disabled={true}
              >
                <div className="demo-option-label-item">
                  {renderAssignee(user)}
                </div>
              </Select.Option>
            ))}
          </Select>
        </Col>
      )}
    </Row>
  );
};
