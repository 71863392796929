import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  Select,
  InputNumber,
  Alert,
  Row,
  Col,
} from "antd";
import moment from "moment";
import { createIdeaHEB } from "../../../../db/firedataCalls";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const CreateLALA = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingFiles /* setLoadingFiles */] = useState(false);
  const [displayError, setDisplayError] = useState("none");
  const [state, setState] = useState({
    ModalText: props.modalTitle ? props.modalTitle : "",
    visible: false,
    confirmLoading: false,
  });

  const showModal = () => {
    setState({
      visible: true,
    });
  };

  const handleCancel = () => {
    setState({
      visible: false,
    });
    form.resetFields();
  };

  const { visible, confirmLoading } = state;

  const onFinish = async (values) => {
    var localValues = values;

    let user = {
      uid: props.userInfo.uid,
      firstName: props.userInfo.firstName,
      lastName1: props.userInfo.lastName1,
    };
    let _date = moment().utc();
    localValues.workCategory = localValues.workCategory.trim().toUpperCase();
    localValues.companyId = props.userInfo.companyId;
    localValues.updatedDate = null;
    localValues.createdBy = props.userInfo.uid;
    localValues.createdDate = { seconds: _date.unix(), nanoseconds: 0 };
    localValues.status = -1;
    localValues.history = [
      {
        eventType: "CREATED",
        date: localValues.createdDate,
        performer: user,
      },
    ];

    await createIdeaHEB(localValues)
      .then(() => {
        setLoading(false);
        setState({
          visible: false,
        });
        form.resetFields();
      })
      .catch((error) => {
        console.error(error);
        setDisplayError("block");
      });
  };

  useEffect(() => {
    if (props.ideaData) form.setFieldsValue({ ...props.ideaData });
  }, [props.ideaData, form]);

  return (
    <div>
      <Button
        style={{ width: "160px", height: "32" }}
        type="primary"
        onClick={showModal}
      >
        {t("buttons.new_ideaHEB")}
      </Button>
      <Modal
        title={t("buttons.new_ideaHEB")}
        visible={visible}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <Form
          form={form}
          name="create-idea"
          onFinish={onFinish}
          labelCol={{
            span: 7,
          }}
          wrapperCol={{
            span: 17,
          }}
          layout="vertical"
          size="middle"
          style={{ width: "100%" }}
          labelAlign="right"
        >
          <Row gutter={6}>
            <Col span={24}>
              <Form.Item
                labelCol={2}
                wrapperCol={18}
                name="name"
                label={t("form_fields.create_idea_HEB.name.label")}
                rules={[
                  {
                    required: true,
                    message: t(
                      "form_fields.create_idea_HEB.name.error_message"
                    ),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                labelCol={2}
                wrapperCol={18}
                name="description"
                label={t("form_fields.create_idea_HEB.description.label")}
                rules={[
                  {
                    required: true,
                    message: t(
                      "form_fields.create_idea_HEB.description.error_message"
                    ),
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={12}
                wrapperCol={18}
                name="equipo"
                label={t("form_fields.create_idea_HEB.responsableTeam.label")}
                rules={[
                  {
                    required: true,
                    message: t(
                      "form_fields.create_idea_HEB.responsableTeam.error_message"
                    ),
                  },
                ]}
              >
                <Select
                  placeholder={t(
                    "form_fields.create_idea_HEB.responsableTeam.placeholder"
                  )}
                >
                  <Option value={"LALA"} key={"LALA"}>
                    LALA
                  </Option>
                  <Option value={"Softtek"} key={"Softtek"}>
                    Softtek
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={12}
                wrapperCol={18}
                name="asigneeName"
                label={t("form_fields.create_idea_HEB.user.label")}
                rules={[
                  {
                    required: true,
                    message: t(
                      "form_fields.create_idea_HEB.user.error_message"
                    ),
                  },
                ]}
              >
                <Input
                  placeholder={t(
                    "form_fields.create_idea_HEB.user.placeholder"
                  )}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                labelCol={24}
                wrapperCol={18}
                label={t("form_fields.create_idea_HEB.workCategory.label")}
                name="workCategory"
                rules={[
                  {
                    required: true,
                    message: t(
                      "form_fields.create_idea_HEB.workCategory.error_message"
                    ),
                  },
                ]}
              >
                <Input placeholder={"Area"} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={12}
                wrapperCol={8}
                label={t("form_fields.create_idea_HEB.complexity.label")}
                name="complexity"
                rules={[
                  {
                    required: true,
                    message: t(
                      "form_fields.create_idea_HEB.complexity.error_message"
                    ),
                  },
                ]}
              >
                <Select style={{ width: "100%" }}>
                  <Option key="0" value={0}>
                    {"Muy Bajo"}
                  </Option>
                  <Option key="1" value={1}>
                    {"Bajo "}
                  </Option>
                  <Option key="2" value={2}>
                    {"Medio"}
                  </Option>
                  <Option key="3" value={3}>
                    {"Alto"}
                  </Option>
                  <Option key="4" value={4}>
                    {"Muy Alto"}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={12}
                wrapperCol={8}
                label={t("form_fields.create_idea_HEB.priority.label")}
                name="priority"
                rules={[
                  {
                    required: true,
                    message: t(
                      "form_fields.create_idea_HEB.priority.error_message"
                    ),
                  },
                ]}
              >
                <InputNumber></InputNumber>
                {/* <Select style={{ width: "100%" }}>
                  <Option key="0" value={0}>
                    {"Muy Bajo"}
                  </Option>
                  <Option key="1" value={1}>
                    {"Bajo "}
                  </Option>
                  <Option key="2" value={2}>
                    {"Medio"}
                  </Option>
                  <Option key="3" value={3}>
                    {"Alto"}
                  </Option>
                  <Option key="4" value={4}>
                    {"Muy Alto"}
                  </Option>
                </Select> */}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                labelCol={2}
                wrapperCol={18}
                name="descriptionBenefit"
                label={t(
                  "form_fields.create_idea_HEB.descriptionBenefit.label"
                )}
                rules={[
                  {
                    required: true,
                    message: t(
                      "form_fields.create_idea_HEB.descriptionBenefit.error_message"
                    ),
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={6}>
            <Col span={12}>
              <br />
              <Form.Item>
                <Button
                  style={{ width: "100%", height: "32" }}
                  type="primary"
                  htmlType="submit"
                  block={loadingFiles}
                  loading={loading}
                >
                  {t("buttons.save")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col>
              <Alert
                message="Error"
                description={t("form_fields.create_idea_HEB.errorMessage")}
                type="error"
                closable
                style={{ display: displayError }}
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default CreateLALA;
