import React, { useState, useEffect } from "react";
import firebase from "../../../../../server/firebase";
import {
  Row,
  Collapse,
  Button,
  Col,
  Space,
  Typography,
  Card,
  Modal,
  Form,
  Input,
  Upload,
  message,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import ABANCAIssues from "./ABANCAIssues";
import * as XLSX from "xlsx";

const ABANCAOperacion = (props) => {
  const [data, setData] = useState();
  const { Panel } = Collapse;
  const { Paragraph } = Typography;
  const [modalVisible, setModalVisible] = useState(false);
  const [formFAQ] = Form.useForm();
  const [filesList, setFilesList] = useState();
  const [activeTab, setActiveTab] = useState(
    props.modifiers ? props.modifiers.issue && "1" : "0"
  );

  useEffect(() => {
    if (props.ideaData && props.ideaData.operationFAQ)
      setData([...props.ideaData.operationFAQ]);
    else setData([]);
    setFilesList([]);
  }, [props.ideaData]);

  const closeModal = () => {
    setModalVisible(false);
    formFAQ.resetFields();
  };

  const onFinish = async (values) => {
    let operationFAQ = props.ideaData.operationFAQ
      ? props.ideaData.operationFAQ
      : [];
    operationFAQ.push({
      answer: values.answer,
      question: values.question,
      id:
        Number(
          operationFAQ.reduce((max, faq) => (faq.id > max ? faq.id : max), 0)
        ) + 1,
    });
    await firebase
      .database()
      .ref()
      .child("ideas")
      .child(props.ideaData.id)
      .update({ operationFAQ })
      .then(formFAQ.resetFields())
      .catch((error) => console.error(error));

    setModalVisible(false);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleUploadFile = async (file) => {
    let fileExtension = ["xls", "xlsx"];
    let fileType = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];
    if (
      fileExtension.includes(file.name.split(".").pop()) &&
      fileType.includes(file.type)
    ) {
      let operationFAQ = props.ideaData.operationFAQ
        ? props.ideaData.operationFAQ
        : [];
      var reader = new FileReader();
      reader.onload = async function (file) {
        var data = file.target.result;
        let readData = XLSX.read(data, { type: "binary" });
        const wsname = readData.SheetNames[0];
        const ws = readData.Sheets[wsname];
        const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
        dataParse.forEach((data) => {
          if (data[0] !== "" && data[1] !== "") {
            operationFAQ.push({
              answer: data[1].trim(),
              question: data[0].trim(),
              id:
                Number(
                  operationFAQ.reduce(
                    (max, faq) => (faq.id > max ? faq.id : max),
                    0
                  )
                ) + 1,
            });
          }
        });
        if (dataParse) {
          await firebase
            .database()
            .ref()
            .child("ideas")
            .child(props.ideaData.id)
            .update({ operationFAQ })
            .then(setFilesList([]))
            .catch((error) => console.error(error));
          message.success("Preguntas cargadas correctamente");
        } else {
          message.error("Error al cargar los datos");
        }
      };
      reader.readAsBinaryString(file);
    } else {
      message.error("El tipo de archivo no es compatible");
    }
  };

  const operationTab = () => {
    return (
      <Row gutter={[10, 5]}>
        <Col span={22}>
          <Space size="middle">
            <Upload
              name="files"
              listType="text"
              action={(file) => handleUploadFile(file)}
              accept=".xlsx, .xls"
              maxCount={1}
              customRequest={dummyRequest}
              fileList={filesList}
            >
              <Button
                style={{ color: "#009900", borderColor: "#009900" }}
                ghost
              >
                <UploadOutlined />
                Importar desde Excel
              </Button>
            </Upload>
            <Button type="primary" ghost onClick={() => setModalVisible(true)}>
              + Nuevo
            </Button>
          </Space>
        </Col>
        <Col span={24}>
          <Collapse accordion ghost>
            {data &&
              data.map((element, index) => (
                <Panel header={element.question} key={index}>
                  <Paragraph style={{ textAlign: "justify" }}>
                    {element.answer}
                  </Paragraph>
                </Panel>
              ))}
          </Collapse>
        </Col>
      </Row>
    );
  };

  const tabContent = (tab) => {
    switch (tab) {
      case "1":
        return (
          <ABANCAIssues
            modifiers={props.modifiers}
            ideaData={props.ideaData}
            company={props.company}
            userInfo={props.userInfo}
          />
        );
      default:
        return operationTab();
    }
  };

  return (
    <Col span={24}>
      <Row gutter={[10, 20]} justify={"center"}>
        <Modal
          visible={modalVisible}
          onCancel={closeModal}
          width="60%"
          footer={null}
        >
          <Form
            form={formFAQ}
            name="faq_form"
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="Pregunta"
              required={true}
              labelAlign="left"
              name="question"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Respuesta"
              required={true}
              labelAlign="left"
              name="answer"
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginTop: "20px" }}
              >
                Agregar
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Col span={24}>
          <Card
            style={{ width: "100%" }}
            bodyStyle={{ minHeight: "60vh" }}
            tabList={[
              { key: "0", tab: "FAQ" },
              { key: "1", tab: "Incidencias" },
            ]}
            bordered={false}
            activeTabKey={activeTab}
            onTabChange={(key) => setActiveTab(key)}
          >
            {tabContent(activeTab)}
          </Card>
        </Col>
      </Row>
    </Col>
  );
};

export default ABANCAOperacion;
