import React, { useEffect, useState } from "react";
import {DatePicker,} from "antd"
import ChartsByDay from "./ChartByDay";
const { MonthPicker } = DatePicker;

const BarChartPorMes = (props) => {
  const [days, setDays] = useState([]);
  const [produccion, setProd] = useState([]);
  const [dias, setDias] = useState([]);



  function onChangeMonth(date, dateString) {
    if (date) {
      var mes = dateString.split("-")[0];
      var año = dateString.split("-")[1];
      var days = {};
      var produccion = {}
      var dias = []
      var arrDays = []
      var arrProd = []
      props.chartData.forEach((idea) => {
        let date = new Date(idea.createdDate.seconds * 1000);
        if (
          date.getFullYear() === parseInt(año) &&
          date.getMonth() + 1 === parseInt(mes)
        ) {
            if (
              days[
                date.getDate() +
                  "-" +
                  (date.getMonth() + 1) +
                  "-" +
                  date.getFullYear()
              ]
            ) 
              {
                days[
                  date.getDate() +
                    "-" +
                    (date.getMonth() + 1) +
                    "-" +
                    date.getFullYear()
                ] += 1;
              }
              else {
                days[
                  date.getDate() +
                    "-" +
                    (date.getMonth() + 1) +
                    "-" +
                    date.getFullYear()
                ] = 1;
                dias.push(date.getDate() +
                "-" +
                (date.getMonth() + 1) +
                "-" +
                date.getFullYear())
              }
            }
            if (idea.paseProduccion) {
              let dateProd = new Date(idea.paseProduccion.seconds * 1000);
              if (
                dateProd.getFullYear() === parseInt(año) &&
                dateProd.getMonth() + 1 === parseInt(mes)
              ) {
                  if (
                    produccion[
                      dateProd.getDate() +
                        "-" +
                        (dateProd.getMonth() + 1) +
                        "-" +
                        dateProd.getFullYear()
                    ]
                  )
                    produccion[
                      dateProd.getDate() +
                        "-" +
                        (dateProd.getMonth() + 1) +
                        "-" +
                        dateProd.getFullYear()
                    ] += 1;
                  else {
                    produccion[
                      dateProd.getDate() +
                        "-" +
                        (dateProd.getMonth() + 1) +
                        "-" +
                        dateProd.getFullYear()
                    ] = 1;
                    dias.push(dateProd.getDate() +
                    "-" +
                    (dateProd.getMonth() + 1) +
                    "-" +
                    dateProd.getFullYear())
                  }
                    
            }
          }
      });

      dias = dias.sort((a, b) => Date(b) - Date(a))

      dias.forEach(dia => {
        if (days[dia]) {
          arrDays.push(days[dia])
        }
        else {
          arrDays.push(0)
        }

        if (produccion[dia]) {
          arrProd.push(produccion[dia])
        }
        else {
          arrProd.push(0)
        }
      });
      setDias(dias)
      setDays(arrDays);
      setProd(arrProd)
    }
  };

  useEffect(() => {
    let date = new Date();
    let dayString = ((date.getMonth() + 1) + "-" + date.getFullYear()).toString()
    onChangeMonth(date, dayString)
    // eslint-disable-next-line
  }, [props.chartData]);

  return (
    <>
   <MonthPicker 
   onChange={onChangeMonth}
   format={"MM-YYYY"}
   placeholder="Filtrar por mes">
   </MonthPicker>

    {Object.keys(days).length > 0 && (
      <ChartsByDay
      days={days}
      completed={produccion}
      dias={dias}
    ></ChartsByDay>
    )}

    </>
      
  );
};

export default BarChartPorMes;
