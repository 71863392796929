import React, { useState, useEffect } from "react";
import firebase from "../../../../../server/firebase";
import {
  Row,
  Col,
  Space,
  Button,
  Form,
  Input,
  Popconfirm,
  Tag,
  Divider,
  message,
  Table,
} from "antd";
import "../../../../../App.css";
import moment from "moment";

const SOCOFARBots = (props) => {
  const [botStatus, setBotStatus] = useState([]);
  const [bots, setBots] = useState([]);
  const [form] = Form.useForm();
  const [editkey, setEditKey] = useState();
  const [editName, setEditName] = useState();
  const [editId, setEditId] = useState();

  useEffect(() => {
    setBotStatus(props.company.botStatus);
    let tempBots = props.idea.bots ? props.idea.bots : [];
    let stageActivities = [];
    let activities = props.idea.activities ? props.idea.activities : [];
    tempBots.forEach((element, index) => {
      stageActivities = activities.filter(
        (activity) => activity.bot === element.key
      );
      let stage = "";
      if (stageActivities.length === 0) {
        stage = "Nothing";
      } else if (stageActivities.find(({ status }) => status === 1)) {
        stage = selectSage(
          stageActivities.filter(({ status }) => status === 1)
        );
      } else if (stageActivities.find(({ status }) => status === 2)) {
        stage = selectSage(
          stageActivities.filter(({ status }) => status === 2)
        );
      }
      tempBots[index].stage = stage;
    });
    setBots(tempBots);
    // eslint-disable-next-line
  }, [props.idea, props.company, props.idea.bots, props.userInfo]);

  const changeBotStatus = (nextStatus, bot, e) => {
    e.preventDefault();

    let bots = props.idea.bots;
    if (nextStatus === 1) {
      bots[bots.findIndex((b) => b.key === bot.key)].deliveredDate = {
        seconds: moment.utc().unix(),
        nanoseconds: 0,
      };
    }
    bots[bots.findIndex((b) => b.key === bot.key)].status = nextStatus;
    firebase
      .database()
      .ref()
      .child("ideas")
      .child(props.idea.id)
      .update({ bots: bots });
  };

  const onFinish = (values) => {
    let nextKey = 1;
    let tempBots = [];
    if (bots && bots.length > 0) {
      tempBots = bots;
      nextKey = bots.reduce(
        (max, bot) => (bot.key > max ? bot.key : max),
        bots[0].key
      );
      nextKey++;
    }
    tempBots.push({
      key: nextKey,
      name: values.name ? values.name : `Bot ${nextKey}`,
      status: 0,
    });

    firebase
      .database()
      .ref()
      .child("ideas")
      .child(props.idea.id)
      .update({ bots: tempBots });

    form.resetFields();
  };

  const setEditRow = (record, e) => {
    e.preventDefault();
    setEditKey(record.key);
    setEditName(record.name);
    record.fridaId && setEditId(record.fridaId);
  };

  const changeBotName = (e) => {
    setEditName(e.target.value);
  };

  const changeFridaId = (e) => {
    setEditId(e.target.value);
  };

  const onDelete = async () => {
    let bot = bots[bots.findIndex(({ key }) => key === editkey)];
    let actividades = props.idea.activities ? props.idea.activities : [];
    let activities = actividades.filter((activity) => activity.bot === bot.key);
    if (activities.length > 0) {
      message.warning("The Bot has stages, so it can't be deleted");
    } else {
      let tempBots = bots.filter(({ key }) => key !== editkey);
      await firebase
        .database()
        .ref()
        .child("ideas")
        .child(props.idea.id)
        .update({ bots: tempBots });
      setEditId();
      setEditKey();
      setEditName();
    }
  };

  const onSave = async () => {
    let index = bots.findIndex(({ key }) => key === editkey);
    if (!editName || editName === "") {
      setEditName(bots[index].name);
      message.warning("Bot name can't be empty");
    } else {
      let tempBots = bots;
      tempBots[index]["fridaId"] = editId;
      if (!editId || editId === "" || editId.length === 0)
        delete tempBots[index]["fridaId"];
      tempBots[index]["name"] = editName;
      await firebase
        .database()
        .ref()
        .child("ideas")
        .child(props.idea.id)
        .update({ bots: tempBots });
      setEditId();
      setEditKey();
      setEditName();
    }
  };

  const selectSage = (activities) => {
    let stages = [];
    Object.values(props.company.activities).forEach((activity) => {
      stages.push({
        label: activity,
        value: activity,
      });
    });
    for (let i = stages.length - 1; i >= 0; i--) {
      if (activities.find((activity) => activity.stage === stages[i].value)) {
        return stages[i].value;
      }
    }
  };

  const payBot = async (type) => {
    let tempBots = bots;
    let index = tempBots.findIndex(({ key }) => key === editkey);
    if (type === "included" || type === "paid") {
      tempBots[index]["included"] = null;
      tempBots[index]["paid"] = null;
    } else {
      tempBots[index]["maintenancePaid"] = null;
      tempBots[index]["maintenanceIncluded"] = null;
    }
    tempBots[index][type] = true;

    await firebase
      .database()
      .ref()
      .child("ideas")
      .child(props.idea.id)
      .update({ bots: tempBots });
  };

  const botsTableCols = [
    {
      title: "Bot",
      dataIndex: "name",
      render: (record, obj) => {
        return obj.key === editkey ? (
          <Input
            value={editName}
            key={obj.key}
            style={{ width: "100%" }}
            onChange={(e) => changeBotName(e)}
          />
        ) : (
          record
        );
      },
    },
    {
      title: "Stage",
      dataIndex: "stage",
      render: (text) => {
        return <Tag color="default">{text.toUpperCase()}</Tag>;
      },
      width: "10%",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => {
        let color;
        color =
          text === 0
            ? "processing"
            : text === 1
            ? "success"
            : text === 3
            ? "red"
            : "default";
        return record.key === editkey ? (
          <Space size={"middle"} width={"100%"}>
            {record.status === 1 ? (
              <>
                <Popconfirm
                  placement="bottomLeft"
                  title="Disable bot"
                  onConfirm={(e) => changeBotStatus(3, record, e)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button danger key={record.id} style={{ width: "110px" }}>
                    Disable
                  </Button>
                </Popconfirm>
              </>
            ) : record.status === 3 ? (
              <>
                <Popconfirm
                  placement="bottomLeft"
                  title="Enable bot"
                  onConfirm={(e) => changeBotStatus(1, record, e)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button key={record.id} style={{ width: "110px" }}>
                    Enable
                  </Button>
                </Popconfirm>
              </>
            ) : (
              <>
                <Popconfirm
                  placement="bottomLeft"
                  title={"Deliver bot"}
                  onConfirm={(e) => changeBotStatus(1, record, e)}
                  okText="Yes"
                  cancelText="No"
                  disabled={record.status === 1}
                >
                  <Button
                    disabled={record.status === 1}
                    key={record.id}
                    style={{ width: "110px" }}
                  >
                    {record.status === 1 ? botStatus[1] : "Deliver"}
                  </Button>
                </Popconfirm>
                <Popconfirm
                  placement="bottomLeft"
                  title={
                    record.status === 2 ? "Remove bot hold" : "Put bot on hold"
                  }
                  onConfirm={(e) =>
                    changeBotStatus(record.status === 2 ? 0 : 2, record, e)
                  }
                  okText="Yes"
                  cancelText="No"
                  disabled={record.status === 1}
                >
                  <Button
                    key={record.id}
                    disabled={record.status === 1}
                    style={{ width: "110px" }}
                  >
                    {record.status === 2 ? "Continue dev" : "Put on hold"}
                  </Button>
                </Popconfirm>
              </>
            )}
          </Space>
        ) : (
          botStatus && (
            <>
              <Tag color={color}>{botStatus[text].toUpperCase()}</Tag>
            </>
          )
        );
      },
      width: "10%",
    },
    {
      title: "FRIDA ID",
      dataIndex: "fridaId",
      render: (record, obj) => {
        return obj.key === editkey ? (
          <Input
            defaultValue={record}
            type="number"
            key={obj.key}
            style={{ width: 200 }}
            onChange={(e) => changeFridaId(e)}
          />
        ) : (
          record
        );
      },
      width: "15%",
    },
    {
      title: "Bot Payment",
      key: "paid",
      render: (text, record) => {
        return record.key === editkey ? (
          <>
            <Space size={5} width={"100%"}>
              <Popconfirm
                placement="bottomLeft"
                title="Sure?"
                onConfirm={() => payBot("paid")}
                okText="Yes"
                cancelText="No"
                disabled={record.status === 1 ? false : true}
              >
                <Button
                  disabled={record.status === 1 ? false : true}
                  className="green-button"
                  type="primary"
                  ghost
                >
                  PAY
                </Button>
              </Popconfirm>
              <Divider type="vertical" />
              <Popconfirm
                placement="bottomLeft"
                title="Sure?"
                onConfirm={() => payBot("included")}
                okText="Yes"
                cancelText="No"
                disabled={record.status === 1 ? false : true}
              >
                <Button
                  disabled={record.status === 1 ? false : true}
                  type="primary"
                  ghost
                >
                  INCLUDE
                </Button>
              </Popconfirm>
            </Space>
          </>
        ) : record.paid ? (
          <Tag color={"success"}>PAID</Tag>
        ) : record.included ? (
          <Tag color={"processing"}>INCLUDED</Tag>
        ) : (
          <Tag color={"default"}>PENDING</Tag>
        );
      },
      width: "10%",
    },
    {
      title: "Maintenance Payment",
      key: "maintenance",
      render: (text, record) => {
        return record.key === editkey ? (
          <>
            <Space size={5} width={"100%"}>
              <Popconfirm
                placement="bottomLeft"
                title="Sure?"
                onConfirm={() => payBot("maintenancePaid")}
                okText="Yes"
                cancelText="No"
                disabled={record.status === 1 ? false : true}
              >
                <Button
                  disabled={record.status === 1 ? false : true}
                  className="green-button"
                  type="primary"
                  ghost
                >
                  PAY
                </Button>
              </Popconfirm>
              <Divider type="vertical" />
              <Popconfirm
                placement="bottomLeft"
                title="Sure?"
                onConfirm={() => payBot("maintenanceIncluded")}
                okText="Yes"
                cancelText="No"
                disabled={record.status === 1 ? false : true}
              >
                <Button
                  disabled={record.status === 1 ? false : true}
                  type="primary"
                  ghost
                >
                  INCLUDE
                </Button>
              </Popconfirm>
            </Space>
          </>
        ) : record.maintenancePaid ? (
          <Tag color={"success"}>PAID</Tag>
        ) : record.maintenanceIncluded ? (
          <Tag color={"processing"}>INCLUDED</Tag>
        ) : (
          <Tag color={"default"}>PENDING</Tag>
        );
      },
      width: "10%",
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (record) => {
        return (
          (record.key === editkey && (
            <Space size={5} width={"100%"}>
              <Popconfirm
                placement="bottomLeft"
                title={"Edit bot?"}
                onConfirm={onSave}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link">Save</Button>
              </Popconfirm>
              <Divider type="vertical" />
              <Button
                type="link"
                onClick={(e) => {
                  e.preventDefault();
                  setEditId(null);
                  setEditKey(null);
                  setEditName(null);
                }}
              >
                Cancel
              </Button>
              <Divider type="vertical" />
              <Popconfirm
                placement="bottomLeft"
                title={"Delete bot?"}
                onConfirm={onDelete}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link" danger>
                  Delete
                </Button>
              </Popconfirm>
            </Space>
          )) || (
            <Button
              type="link"
              disabled={editkey}
              onClick={(e) => setEditRow(record, e)}
            >
              Edit
            </Button>
          )
        );
      },
      width: "5%",
    },
  ];

  return (
    <Row gutter={[10, 20]}>
      <Col span={24}>
        {props.idea.bots && bots && (
          <>
            <Divider>Bot List</Divider>
            <Table columns={botsTableCols} dataSource={bots} bordered />
          </>
        )}
        <Divider>Add New Bot</Divider>

        <Form onFinish={onFinish} form={form} name="New Bot">
          <Row gutter={[10, 20]}>
            <Col span={8}></Col>
            <Col span={8}>
              <Row gutter={[10, 20]}>
                <Col>
                  <Form.Item
                    name={"name"}
                    label={
                      "Bot name (if empty name would be set automatically, can be edited later):"
                    }
                    labelAlign={"left"}
                  >
                    <Input
                      placeholder={"New bot's name"}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[10, 20]}>
                <Form.Item>
                  <Button
                    type={"primary"}
                    style={{ marginLeft: "6px" }}
                    htmlType="submit"
                  >
                    Add bot
                  </Button>
                </Form.Item>
              </Row>
            </Col>
            <Col span={8}></Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};
export default SOCOFARBots;
