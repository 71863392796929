import React, { useState, useEffect } from "react";
import { Form, Modal } from "antd";
import MainInformation from "./MainInformation";
import Environment from "./Environment";
import Data from "./Data";
import Flow from "./Flow";
import Interaction from "./Interaction";
import Results from "./Results";
import Spinner from "../Spinner";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import "./details.css";
import {
  getCalculatorBrainResult,
  getFinalComplexityResult,
} from "./CalculatorCalcs/Calcs";
import { updateIdea } from "../../db/firedataCalls";
import { updateGenericIdea } from "../../db/genericFiredataCalls";

const ViabilityCalculator = ({ userInfo, idea, company }) => {
  const [step, setStep] = useState(1);
  const [basicValues, setBasicValues] = useState([]);
  const [enviromentValues, setEnvironmentValues] = useState(null);
  const [dataValues, setDataValues] = useState(null);
  const [controlValues, setControlValues] = useState(null);
  const [recalculate, setRecalculate] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    idea.viabilityCalculator && !recalculate ? setStep(6) : setStep(1);
  }, [idea, recalculate]);

  useEffect(() => window.scrollTo(0, 0), [step]);

  const resetAll = () => {
    setStep(1);
    setEnvironmentValues(null);
    setBasicValues([]);
    setDataValues(null);
    setControlValues(null);
    setLoading(false);
    setRecalculate(false);
  };

  const handleChangeStep = (step) => setStep(step);

  const startRecalculate = () => setRecalculate(true);

  const content = () => {
    if (step === 1)
      return (
        <MainInformation
          nextStep={() => handleChangeStep(2)}
          resetAll={resetAll}
        />
      );
    else if (step === 2)
      return (
        <Environment nextStep={() => handleChangeStep(3)} resetAll={resetAll} />
      );
    else if (step === 3)
      return <Data nextStep={() => handleChangeStep(4)} resetAll={resetAll} />;
    else if (step === 4)
      return <Flow nextStep={() => handleChangeStep(5)} resetAll={resetAll} />;
    else if (step === 5) return <Interaction resetAll={resetAll} />;
    else if (step === 6)
      return (
        <Results idea={idea} startRecalculate={() => startRecalculate()} />
      );
  };

  const setFinalResults = async (interaction) => {
    setLoading(true);
    if (
      interaction &&
      basicValues &&
      enviromentValues &&
      dataValues &&
      controlValues
    ) {
      const brainInfo = getCalculatorBrainResult(basicValues);
      const complexityResult = getFinalComplexityResult(
        enviromentValues,
        dataValues,
        controlValues,
        interaction
      );
      const viabilityCalculator = {
        viabilityCalculator: {
          brainArray: basicValues,
          brainInfo: brainInfo,
          complexityResult: complexityResult,
        },
      };
      if (company.name === "Generic") {
        await updateGenericIdea(idea.id, viabilityCalculator);
      } else {
        await updateIdea(idea.id, viabilityCalculator);
      }

      /*
     viabilityCalculator: {
       brainArray[] => basicValues
       brainInfo{} => brainInfo
       complexityReult{} => complexityResult
     }
     */
    }
    setRecalculate(false);
    setLoading(false);
  };

  return (
    <Form.Provider
      onFormFinish={async (name, { values, forms }) => {
        if (name === "basic") {
          let arrayValues = [];
          const questions = [
            "consistency",
            "repetitive",
            "based_rules",
            "standardization",
            "data_format",
            "volume_information",
            "system_stability",
            "maturity",
          ];
          questions.map((question) =>
            arrayValues.push({ name: question, value: values[question] })
          );
          setBasicValues(arrayValues);
        }
        if (name === "environment") setEnvironmentValues(values);
        if (name === "data") {
          // parse values for radio
          values.canIdentifyUniqueData = Number(values.canIdentifyUniqueData);
          setDataValues(values);
        }
        if (name === "flow") {
          // parse values for radio
          ["decisionTree", "exception", "exceptionHandled"].map(
            (value) => (values[value] = Number(values[value]))
          );
          setControlValues(values);
        }
        if (name === "interaction") {
          if (recalculate)
            Modal.confirm({
              title: "Importante",
              content:
                "Al recalcular la información del cálculo anterior se perderá",

              icon: <ExclamationCircleOutlined />,
              onOk: () => setFinalResults(values),
              okText: "Recalcular",
              okButtonProps: {
                shape: "round",
                style: { fontSize: "15px" },
              },
              okType: "danger",
              onCancel: () => resetAll(),
              cancelText: "Cancelar",
              cancelButtonProps: {
                className: "button-submit",
                shape: "round",
              },
            });
          else await setFinalResults(values);
        }
      }}
    >
      {loading ? <Spinner component={content} spinning={loading} /> : content()}
    </Form.Provider>
  );
};

export default ViabilityCalculator;
