import React, { useState } from "react";
import { Form, Input, Button, Row, Col, Typography, Space } from "antd";
import { useTranslation } from "react-i18next";
import { loginUserMicrosoft } from "../../db/firedataCalls";
import { WindowsOutlined } from "@ant-design/icons";
import QueueAnim from "rc-queue-anim";
import "./Login.css";

const SignUp = ({ setSignOption }) => {
  // Steps: 1=User Info, 2=Microsoft logn, 3=Wait text
  const [step, setStep] = useState(1);
  const [values, setValues] = useState();
  const [loginForm] = Form.useForm();
  const { t } = useTranslation();
  const { Title } = Typography;

  const onFinish = (formValues) => {
    Object.entries(formValues).forEach(([k, v]) => {
      if (v === undefined) formValues[k] = null;
    });
    setValues(formValues);
    setStep(2);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const loginLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 11 },
  };

  // TODO: [] Form, []onFinish, []messages, []Add previous
  const stepOne = () => {
    return (
      <Form
        form={loginForm}
        {...loginLayout}
        name="basic"
        preserve={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        requiredMark={false}
      >
        <Space direction="vertical" style={{ width: "100%" }} size="middle">
          <Form.Item
            label="Nombre"
            name="firstName"
            rules={[
              {
                required: true,
                message: t("login.form.username.message"),
              },
            ]}
          >
            <Input autoComplete="" size="large" />
          </Form.Item>
          <Form.Item
            label="Primer Apellido"
            name="lastName1"
            rules={[
              {
                required: true,
                message: t("login.form.username.message"),
              },
            ]}
          >
            <Input autoComplete="" size="large" />
          </Form.Item>
          <Form.Item label="Segundo Apellido" name="lastName2">
            <Input autoComplete="" size="large" />
          </Form.Item>
          <Form.Item label="Código de usuario" name="username">
            <Input autoComplete="" size="large" />
          </Form.Item>
          <Form.Item
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <Button
              className="button-submit"
              shape="round"
              size="large"
              type="primary"
              htmlType="submit"
              style={{ marginRight: 20 }}
            >
              Next
            </Button>
            {cancelButton()}
          </Form.Item>
        </Space>
      </Form>
    );
  };

  const stepTwo = () => {
    return (
      <Col span={24} key="stepTwo" style={{ height: "inherit" }}>
        <Row
          gutter={[0, 20]}
          style={{ height: "80%", alignItems: "center", display: "flex" }}
        >
          <Col span={24}>
            <Button
              className="button-submit"
              shape="round"
              size="large"
              type="primary"
              onClick={() => loginUserMicrosoft(values)}
            >
              <span>
                <WindowsOutlined
                  style={{
                    verticalAlign: "middle",
                    marginRight: 8,
                  }}
                />
                Log in with Microsoft
              </span>
            </Button>
          </Col>
        </Row>
        <Row align="bottom" style={{ height: "20%" }}>
          <Col span={24}>
            <Button
              className="button-submit-secondary"
              shape="round"
              size="large"
              type="primary"
              style={{ marginRight: 20 }}
              onClick={() => setStep(1)}
            >
              Previous
            </Button>
            {cancelButton()}
          </Col>
        </Row>
      </Col>
    );
  };

  const cancelButton = () => {
    return (
      <Button
        className="button-submit-secondary"
        shape="round"
        size="large"
        type="primary"
        onClick={() => setSignOption(0)}
      >
        Cancel
      </Button>
    );
  };

  return (
    <Row gutter={[0, 20]} className="full-size-container">
      <Col span={24} style={{ height: 40 }}>
        <Title level={2}>Sign Up</Title>
      </Col>
      <Col span={24} style={{ height: 260 }}>
        <QueueAnim
          appear
          type={["right", "left"]}
          leaveReverse
          style={{ height: "inherit" }}
          // The default anim duration is 450 milliseconds
          // so 480 milliseconds on the first anim is just
          // to ensure they don't overlap or show at the
          // same time on screen making it a big card
          delay={[480, 0]}
        >
          {step === 1 ? (
            <div key="stepOne">{stepOne()}</div>
          ) : step === 2 && values ? (
            stepTwo()
          ) : null}
        </QueueAnim>
      </Col>
    </Row>
  );
};

export default SignUp;
