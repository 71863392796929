import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Row,
  Col,
  Upload,
  message,
  DatePicker,
} from "antd";
import { createIdea, uploadFile } from "../../../../../db/firedataCalls";
import { UploadOutlined } from "@ant-design/icons";
import moment from "moment";
const areas = require("../../../../../centros.json");

const { Option } = Select;

const DetailsForm = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [workCategories, setWorkCategories] = useState([]);
  const [tiposTarea, setTiposTarea] = useState([]);
  const dateFormat = "DD/MM/YYYY";

  const successUpdate = () => {
    message.success("Información guardada correctamente");
  };

  const errorUpdate = () => {
    message.error("Hubo un error al guardar la información");
  };

  const successFiles = () => {
    message.success("Archivos subidos correctamente");
  };

  const errorFiles = () => {
    message.error("Error al subir archivos");
  };

  const onFinish = (values) => {
    if (loadingFiles) {
      console.error("Uploading files...");
    }

    setLoading(true);
    values["id"] = props.ideaData.id;
    values["uploads"] = form.getFieldValue("uploads");
    /* values = JSON.parse(JSON.stringify(values, function (k, v) {
            if (v === undefined) { return null; } return v;
        })); */
    values.departmentTitle =
      areas.departments[
        areas.departments.findIndex(
          (departmen) => departmen.value === values.departmentValue
        )
      ].title;
    values.departmentLevels =
      areas.departments[
        areas.departments.findIndex(
          (departmen) => departmen.value === values.departmentValue
        )
      ].levels;
    Object.entries(values).forEach((k) => {
      if (k[1] === undefined) delete values[k[0]];
    });
    if (values.estimatedDate) {
      values.estimatedDate = {
        seconds: values.estimatedDate.unix(),
        nanoseconds: 0,
      };
    } else {
      values.estimatedDate = null;
    }
    createIdea(values)
      .then(() => {
        successUpdate();
        setLoading(false);
      })
      .catch((error) => {
        errorUpdate();
        console.error(error);
      });
  };

  const handleUploadFile = (file) => {
    setLoadingFiles(true);
    uploadFile(props.ideaData.id, file)
      .then((uploadResponse) => {
        let uploads = [];

        if (uploadResponse === null) {
          console.error("no se pudo subir el archivo");
        }

        if (!form.getFieldValue("uploads")) {
          form.setFieldsValue({ uploads: uploads });
        }

        let currUploads = form.getFieldValue("uploads");

        if (Array.isArray(currUploads)) {
          //si no existe lo agrega
          if (
            currUploads.findIndex(
              (upload) => upload.name === uploadResponse.name
            ) === -1
          )
            currUploads.push({
              name: uploadResponse.name,
            });
        } else {
          currUploads = [{ name: uploadResponse.name }];
        }

        form.setFieldsValue({ uploads: currUploads });
        successFiles();
        setLoadingFiles(false);
      })
      .catch((error) => {
        console.error(error);
        errorFiles();
      });
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  useEffect(() => {
    if (props.company && props.ideaData) {
      form.setFieldsValue({ ...props.ideaData });
      if (props.ideaData.estimatedDate) {
        form.setFieldsValue({
          estimatedDate: moment(
            moment.unix(props.ideaData.estimatedDate.seconds),
            dateFormat
          ),
        });
      }
      areas.departments.forEach((area) => {
        setWorkCategories((workCategories) => [
          ...workCategories,
          {
            value: area.value,
            name: area.title,
            key: area.value,
          },
        ]);
      });
      let tipos = [];
      Object.values(props.company.tipoTarea).forEach((tt) => {
        tipos.push({ label: tt, value: tt });
      });
      setTiposTarea(tipos);
    }
    // eslint-disable-next-line
  }, [props.ideaData, props.company]);

  return (
    <>
      <Form
        form={form}
        name="datails-form"
        onFinish={onFinish}
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 16,
        }}
        layout="vertical"
        size="medium"
        labelAlign="right"
      >
        <Row gutter={[10, 20]}>
          <Col span={12}>
            <Form.Item
              labelCol={8}
              wrapperCol={14}
              name="name"
              label={"Nombre"}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              labelCol={8}
              wrapperCol={14}
              name="estimatedDate"
              label={"Fecha Estimada"}
            >
              <DatePicker
                format={dateFormat}
                placeholder={"Selecciona Fecha"}
              ></DatePicker>
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Row gutter={[10, 20]}>
          <Col span={12}>
            <Row gutter={[0, 20]}>
              <Col span={24}>
                <Form.Item
                  name="tipoTarea"
                  label={"Tipo de tarea"}
                  labelCol={8}
                  wrapperCol={14}
                  rules={[
                    {
                      required: true,
                      message: "Campo requerido",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder={"Tipo de tarea"}
                    optionFilterProp="children"
                    options={tiposTarea}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  labelCol={8}
                  wrapperCol={14}
                  name="description"
                  label={"Descripción"}
                >
                  <Input.TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={[10, 20]}>
              <Col span={24}>
                <Form.Item
                  colon={false}
                  labelCol={8}
                  wrapperCol={14}
                  labelAlign="left"
                  label={"Área"}
                  name="departmentValue"
                  rules={[
                    {
                      required: true,
                      message: "Campo requerido",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {workCategories.map((area) => {
                      return (
                        <Option key={area.value} value={area.value}>
                          {area.name + " (" + area.value.toString() + ")"}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  labelCol={8}
                  wrapperCol={14}
                  name="comments"
                  label={"Comentarios"}
                >
                  <Input.TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <div className="ant-col ant-form-item-label">
              <label title="Files">{"Archivos"}</label>
            </div>
            <br />
            <Upload
              name="files"
              listType="text"
              action={(file) => handleUploadFile(file)}
              customRequest={dummyRequest}
            >
              <Button>
                <UploadOutlined /> Subir archivo
              </Button>
            </Upload>
          </Col>
          <Col span={12}></Col>
          <Col span={6}>
            <Form.Item style={{ textAlign: "left" }}>
              <Button
                type="primary"
                htmlType="submit"
                block={loadingFiles}
                loading={loading}
              >
                {"Guardar"}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default DetailsForm;
